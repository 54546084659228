import React from 'react'

type Props = {}

const Step2Icon = (props: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48"><circle cx="24" cy="24" r="24" fill="url(#a)"/><g fill="#fff"><path d="M40 17.854s-15.321 5.217-16.521 5.831c-1.2.615-1.636 2.014-.973 3.127.663 1.112 2.174 1.515 3.374.9 1.2-.614 14.12-9.858 14.12-9.858Z"/><path d="M26.435 27.326c.969-.899.969-2.355 0-3.254-.97-.898-2.541-.898-3.51 0-.97.899-.97 2.355 0 3.254.969.898 2.54.898 3.51 0ZM34.176 23.421a.979.979 0 0 0-.408 1.006 9.963 9.963 0 0 1 .217 2.218c-.005.367.317.666.713.666h4c.376 0 .685-.27.712-.617a12.722 12.722 0 0 0-.773-5.477c-.14-.373-.632-.512-.97-.27-1.058.752-2.266 1.61-3.49 2.475Z"/><path d="M35.453 25.937a11.259 11.259 0 0 0-.188-1.558l2.316-1.641a11.416 11.416 0 0 1 .384 3.2h-2.512ZM22.459 15.771c3.31 0 6.294 1.294 8.397 3.365.3.295.764.391 1.173.25a1281.5 1281.5 0 0 1 3.927-1.355c.398-.137.538-.595.273-.902C33.531 14.003 29.38 12 24.725 12 16.593 12 10 18.11 10 25.648c0 .42.021.836.062 1.247.02.21.21.368.437.368.25 0 .448-.191.44-.423a9.855 9.855 0 0 1-.008-.385c0-5.9 5.161-10.684 11.527-10.684Z"/></g><defs><linearGradient id="a" x1="24" x2="24" y1="0" y2="48" gradientUnits="userSpaceOnUse"><stop stop-color="#6765E0"/><stop offset="1" stop-color="#322DB8"/></linearGradient><clipPath id="b"><path fill="#fff" d="M10 12h30v16H10z"/></clipPath></defs></svg>
  )
}

export default Step2Icon;