import React, { Suspense } from "react";
// import Banners from 'src/components/Banner/index';
// import MultipleItems from 'src/components/slick-slider';
// import TopGames from "src/components/top-games/index";
// import HomeExchange from "src/components/home-exchange/index";
//import HomePopular from "src/components/home-popular/index";
// import CenterMode from "src/components/LiveDealerCraousel/index"
// import LiveGamesWrapper from "src/components/home-live-games/index";
// import BBBEdge from 'src/components/BBBEdge/index';
// import FooterContainer from "src/components/footer/footer";
// import FlagsContainer from "src/components/flags/flags";
import { withAppContext } from "src/store/initAppContext";
import withSystem from "src/HOC/withSystem";
import { withTheme } from "styled-components";
// import BonusHomepage from 'src/components/udb/bonus/BonusHomepage';
// import LoyaltyHomeComponent from 'src/components/Loyalty/HomeLoyalty';
// import styled from "styled-components";
import { Helmet } from "react-helmet";

const Banners = React.lazy(() => import("src/components/Banner/index"));
const MultipleItems = React.lazy(() => import("src/components/slick-slider"));
const TopGames = React.lazy(() => import("src/components/top-games/index"));
const HomeExchange = React.lazy(
  () => import("src/components/home-exchange/index")
);
const BonusHomepage = React.lazy(
  () => import("src/components/udb/bonus/BonusHomepage")
);
const LoyaltyHomeComponent = React.lazy(
  () => import("src/components/Loyalty/HomeLoyalty")
);
const CenterMode = React.lazy(
  () => import("src/components/LiveDealerCraousel/index")
);
const LiveGamesWrapper = React.lazy(
  () => import("src/components/home-live-games/index")
);
const FlagsContainer = React.lazy(() => import("src/components/flags/flags"));
const FooterContainer = React.lazy(
  () => import("src/components/footer/footer")
);
const BBBEdge = React.lazy(() => import("src/components/BBBEdge/index"));
const SeoContentHomePage = React.lazy(() => import("src/components/seo-content/seo_content"));

const WhatsappIco = React.lazy(() => import("src/components/WhatsappIco"));

const structuredData = () => {
  const data = {
    "@context": "http://schema.org",
    "@type": "Product",
    "brand": {
      "@type": "Brand",
      "name": "Yolo247"
    },
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.3",
      "bestRating": "5",
      "reviewCount": "742"
    }
  }

  return JSON.stringify(data)
}

function Home(props) {
  const { theme, app:{brandId} } = props;
  const { config: { 
    showBBEedge, 
    showCenterMode, 
    showHomebanners, 
    showLiveGamesWrapper, 
    showSEOdropdown,
    showcasinoslider,
    showTopGamesSection,
    showFooter,
    showFlags,
    showWhatsAppAfterLogin
  } } = theme;
  return (
    <>
      {brandId && brandId == 32 && <Helmet>
        <script type="application/ld+json">
          {
            structuredData()
          }
        </script>
        <meta property="og:title" content="Online Betting ID | Best Site for Casino & Sports | Yolo247" />
        <meta property="og:site_name" content="Yolo247 Club" />
        <meta property="og:url" content="https://yolo247.club" />
        <meta property="og:description" content="Online betting ID makes casino & sports betting experience better. On Yolo247, Get betting ID through WhatsApp instantly. Faster deposit & withdrawal." />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://opt-v3-files.raksahb.com/static/cashsite/brand/6/logo/yoloLogo.png" />
      </Helmet>}

      <Suspense fallback={<div>Loading...</div>}>
        {showHomebanners && <Banners />}
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
       {showcasinoslider && <MultipleItems />}
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        {showTopGamesSection && <TopGames />}
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <HomeExchange />
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <BonusHomepage />
      </Suspense>
      {/* <HomePopular /> */}
      {/* <LoyaltyHomeComponent/> */}
      <Suspense fallback={<div>Loading...</div>}>
        <LoyaltyHomeComponent />
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        {showCenterMode && <CenterMode />}
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
      {showLiveGamesWrapper && <LiveGamesWrapper />}
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
       { showBBEedge && <BBBEdge />}
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
       { showFlags && <FlagsContainer />}
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
       {showSEOdropdown && <SeoContentHomePage/>}
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        {showFooter && <FooterContainer />}
      </Suspense>
     {showWhatsAppAfterLogin &&  <Suspense fallback={<div>Loading...</div>}> <WhatsappIco /></Suspense>}
     
    </>
  );
}

export default withAppContext(withTheme(Home));


