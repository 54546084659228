import React from 'react'

type Props = {}

const TopChoiceDeposit = (props: Props) => {
  return (
    <svg
    width={25}
    height={25}
    viewBox="0 0 25 25"
    // fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_103_2)">
      <path
        d="M25 11.0937C25 8.75 22.8125 7.8125 21.5625 7.8125H18.125C18.75 6.25 19.2188 4.375 18.9063 2.96875C18.125 0.15625 15.7813 0 15 0H14.8438C14.2187 0 13.9062 0.3125 13.5937 0.78125L12.0312 5.15625L7.8125 9.375H0V23.4375H7.8125V21.875C8.125 21.875 8.90625 22.3438 9.6875 22.8125C11.5625 23.75 14.2187 25.1563 16.7187 25.1563C20.4687 25.1563 21.7187 24.6875 22.6562 23.125C23.125 22.1875 23.125 21.4062 23.125 20.9375C23.4375 20.625 23.9062 20.1562 24.0625 19.375C24.2187 18.5937 24.2187 18.125 24.0625 17.6563C24.375 17.1875 24.6875 16.5625 24.8437 15.625C24.8437 14.8438 24.6875 14.2188 24.5312 13.75C24.6875 13.125 25 12.3437 25 11.0937V11.0937ZM3.90625 21.0938C2.96875 21.0938 2.34375 20.4688 2.34375 19.5312C2.34375 18.5937 2.96875 17.9688 3.90625 17.9688C4.84375 17.9688 5.46875 18.5937 5.46875 19.5312C5.46875 20.4688 4.84375 21.0938 3.90625 21.0938ZM22.9687 14.2188C22.9687 14.2188 23.2812 14.5313 23.2812 15.3125C23.2812 16.25 22.6562 16.7187 22.6562 16.7187L22.1875 17.1875L22.5 17.6563C22.5 17.6563 22.8125 18.125 22.5 18.75C22.3437 19.375 21.7187 19.8437 21.7187 19.8437L21.25 20.3125L21.5625 20.9375C21.5625 20.9375 21.875 21.5625 21.4062 22.3438C21.0937 22.9688 20.7813 23.4375 16.875 23.4375C14.6875 23.4375 12.1875 22.1875 10.4687 21.25C9.21875 20.625 8.4375 20.3125 7.8125 20.3125V10.9375H7.96875C8.28125 10.9375 8.59375 10.7813 8.90625 10.625L13.2812 6.25C13.4375 6.09375 13.4375 5.9375 13.5937 5.78125L15.1562 1.5625C15.9375 1.5625 17.0313 1.875 17.3438 3.28125C17.5 4.21875 17.1875 5.78125 16.4062 7.65625C16.25 8.125 16.25 8.4375 16.5625 8.90625C16.7188 9.21875 17.1875 9.375 17.6563 9.375H21.5625C21.7188 9.375 23.4375 9.6875 23.4375 11.0937C23.4375 12.3437 22.9687 12.9688 22.9687 12.9688L22.5 13.5937L22.9687 14.2188V14.2188Z"
        // fill="#F8F8F8"
      />
    </g>
    <defs>
      <clipPath id="clip0_103_2">
        <rect width={25} height={25} fill="white" />
      </clipPath>
    </defs>
  </svg>
  )
}

export default TopChoiceDeposit