import React, { useEffect, useRef, useState } from "react";
import GlobalModal, { Popup } from "src/components/GlobalModal";
import config from "src/config/config";
import styled, { withTheme } from "styled-components";
import { SubmitBtn } from "../../../components/udb/commonStyle";
import { withAppContext } from "src/store/initAppContext";
import { theme } from "styled-tools";
import { getFullNotification } from "src/config/api";
import { Notify } from "src/components/Notification";
import moment from "moment";
import { Player } from '@lottiefiles/react-lottie-player';
import MsgLogo from "src/components/icon/msgLogo";
import Intl from '../../../components/common/Intl';
import MsgLogoBD from "src/components/icon/msgLogoBD";

type notificationsData = {
    id: any;
    notification_header: any;
    notification_type: any;
    status: string;
    created: any
}
type StyleProps = {
  status: string;
  readStatus: string;
}

type notifications = {
  id?: number;
  notification_header: string;
  notification_type: string;
  notification_text: string;
  desktop_image: string;
  created: string;
  status: string;
}

const notificationState: notifications = {
  id: 1,
  notification_header: "",
  notification_text: "",
  notification_type: "",
  desktop_image: "",
  created: "",
  status:""
};

const MessageComponent = (props) => {
  const { app: { brandId },theme } = props;
  const { id, notification_header, notification_type, created, onRead, status } = props;
  const [modalShow, setmodalShow] = useState(false);
  const [notificationBody, setNotificationBody] = useState(notificationState);
  const [imageType, setImageType] = useState("");
  const ref = useRef(null);


  const hideModel = () => {
    setmodalShow(false);
  };

  const showModel = () => {
    setmodalShow(true);
  };


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        hideModel && hideModel();
      }
    };

    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };

  }, [ hideModel ]);


  const innerNotifications = (id) => {
    let params = {
      id,
    };
    getFullNotification(params)
      .then((response) => {
        const { data } = response.data || {};

        setNotificationBody({
          notification_header: data.notification_header,
          notification_text: data.notification_text,
          notification_type: data.notification_type,
          desktop_image: data.desktop_image,
          created: data.created,
          status:data.status
        });
        if (data.desktop_image) {
            let extRegEx = /\.\w+\?/;
            let extension = (data.desktop_image.match(extRegEx)[0]).slice(1, -1);
          setImageType(extension);
        }
        setmodalShow(true);
        //call unread API 
        if(status ==='INITIATED'){
          onRead()
        }

        if (response.data.success === false) {
          Notify.error(response.data.status);
        }
      })
      .catch((err) => { 
        Notify.error(err.response.data.data.message)
      });
  };

  
  
  return (
    <>
      <>
        <BodyWrapperMsg status={status} readStatus={notificationBody.status}>
          <LogoImg>
          { brandId == 33 ? <img src={`${config.imageV3Host}/static/cashsite/${theme.image.baaziaddanotifications}`} />: brandId==34 ? <MsgLogoBD/>: <MsgLogo />}
          </LogoImg>
          <MessageContainer  status={status} readStatus={notificationBody.status}>
            <span>{notification_header}</span>
            <DateWrapper>
            <span className="datestamp">{moment(created).format("DD-MM-YYYY,  h:mm A")}</span>
          </DateWrapper>
          </MessageContainer>
          <MsgCounter>
            <ViewBtn onClick={() => innerNotifications(id)} status={status} readStatus={notificationBody.status}>
            {notificationBody.status === "READ" || status === "READ" ? <Intl langKey="Messages@View" />: <Intl langKey="Messages@View" />}
              </ViewBtn>
          </MsgCounter>
        </BodyWrapperMsg>
      </>
      <>
        {modalShow === false ? (
          ""
        ) : (
          <ModalMainWrapper >
            <GlobalModal
              isModalOpen={showModel}
              style={{ height: "116vh" }}
              className="modal"
             
            >
              <ModalWrap ref={ref}>
                <button
                  className="close"
                  type="button"
                  onClick={hideModel}
                ></button>
                <MessageContainer  status={status} readStatus={notificationBody.status}>
                  <span className="modalSpan">
                    {notificationBody.notification_header}
                  </span>
                </MessageContainer>
                <DateWrapper>
                  <span className="datestamp">
                    {moment(notificationBody.created).format(
                      "YYYY-MM-DD,  h:mm:ss A"
                    )}
                  </span>
                </DateWrapper>
                <MsgContent>
                  <p>{notificationBody.notification_text}</p>
                  {notificationBody.desktop_image !== null &&
                  <ImgWrap>
                    {imageType === "json" ? (
                      <Player
                      autoplay
                      loop
                      controls
                      src={notificationBody.desktop_image}
                      style={{ height: '300px', width: '300px' }}
                    ></Player>
                    ) : (
                      <img
                        src={notificationBody.desktop_image}
                        alt="noimg"
                      />
                    )}
                  </ImgWrap>
                 } 
                </MsgContent>
              </ModalWrap>
            </GlobalModal>
          </ModalMainWrapper>
        )}
      </>
    </>
  );
};

export default withTheme(withAppContext(MessageComponent));

const LogoImg=styled.div`
  /* background: black; */
  img {
    width: 60px;
  }
`

const ModalWrap = styled.div`
  width: 65%;
  min-height: auto;
  max-height: 460px;
  overflow: auto;
  height: auto;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(180deg, #383838 0%, #191919 100%);
  box-shadow: 0px 0px 40px rgba(16, 11, 73, 0.4);
  border-radius: 5px;
  border: 1px solid #ffffff7a;
  padding: 2.4em;
  position: relative;

  .close {
    width: 25px;
    height: 25px;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    cursor: pointer;
    border: none;
    display: inline-block;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 10px;
    top: 10px;
    background-image: url(${config.imageV3Host}/static/cashsite/${theme("image.closeIcon")});
    border-radius: 5px;
  }

  ::-webkit-scrollbar {
    width: 8px;
    // background:darkgrey;
}


::-webkit-scrollbar-thumb {
    border-radius: 5px;
     background: #9b9b9b; 
    -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 50%);
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
    border-radius: 5px;
  }
`;

const ImgWrap = styled.div`
  height: 50%;
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;

  > img {
    max-width: 100%;
    padding-top: 12px;
    max-height: 300px;
  }

`;
const ViewBtn = styled(SubmitBtn)<Pick<StyleProps, "status" | "readStatus">>`
  min-width: 100px;
  width: auto;
  background: ${theme('palette.secondary-background-color-linear-new1')};
  background: ${props => (props.status === "READ" ? '#76777D' : props.readStatus === "READ" ? ' #76777D' : "palette.secondary-background-color-linear-new1")};
  border-radius: 5px;
  height: 32px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor:pointer;
  :hover{
    background: ${props => (props.status === "READ" ? '#8e8e91' : props.readStatus === "READ" ? ' #76777D' : "palette.secondary-background-color-linear-new1")};
  }
`;
const MsgContent = styled.div`
  width: 100%;
  & p {
    width: 100%;
    /* font-family: "Roboto"; */
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    display: flex;
    align-items: center;
    letter-spacing: 0.03em;
    color: ${theme('palette.progressBarBg')};
    padding-top: 0px;
    display: -webkit-box;
    max-width: 100%;
    margin: 0px auto;
  }
`;

const BodyWrapperMsg =styled.div<Pick<StyleProps, "status" | "readStatus">>`
  width: 100%;
  display: flex;
  background-color: ${props => (props.status === "READ" ? '#C5C5C5' : props.readStatus === "READ" ? ' #C5C5C5' : "#FFFFFF")};
  border-radius: 3px;
  margin-bottom: 10px;
  padding: 1rem;
  height: auto;
  max-height: 120px;
  align-items: center;
  justify-content: space-around;
`;

const DateWrapper = styled.div`
  .datestamp {
    /* font-family: "Roboto"; */
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    display: flex;
    align-items: center;
    letter-spacing: 0.03em;
    text-transform: capitalize;
    color: ${theme('palette.progressBarBg')};
    white-space: nowrap;
    margin: 5px 0px;
  }
`;

const MsgCounter = styled.div`
  display: flex;
  width: 100%;
  justify-content: end;
  align-items: center;
  & h4 {
    width: 100%;
    color: #fff;
    font-weight: 700;
    font-size: 12px;
    -webkit-letter-spacing: 0.01em;
    -moz-letter-spacing: 0.01em;
    -ms-letter-spacing: 0.01em;
    letter-spacing: 0.01em;
    text-transform: capitalize;
    color: #ffffff;
    background: rgba(255, 255, 255, 0.21);
    -webkit-backdrop-filter: blur(4150px);
    backdrop-filter: blur(4150px);
    border-radius: 3px;
    padding: 4px;
    margin: 3px 0px;
  }
`;
const MessageContainer = styled.div<Pick<StyleProps, "status" | "readStatus">>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;
  & span {
    display: flex;
    /* font-family: "Roboto"; */
    font-style: normal;
    font-size: 16px;
    font-weight: bold;
    align-items: center;
    letter-spacing: 0.03em;
    color: #000000;
    padding-left: 15px;
  }

  .modalSpan {
    color: #ffffff;
    display: flex;
    /* font-family: "Roboto"; */
    font-style: normal;
    font-size: 15px;
    align-items: center;
    letter-spacing: 0.03em;
    /* text-transform: capitalize; */
    padding-left: 0px;
  }
  .datestamp{
    /* font-family: "Roboto"; */
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    display: flex;
    align-items: center;
    letter-spacing: 0.03em;
    text-transform: capitalize;
    /* color: #53537B; */
    color: ${props => (props.status === "READ" ? '#53537B' : props.readStatus === "READ" ? ' #76777D' : "#53537B")};
    white-space: nowrap;
  }
`;

const ModalMainWrapper = styled.div`
  ${Popup} {
    .modal {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      background: rgb(0 0 0 / 74%);
    }

    .modal-main {
      width: 100%;
      height: 95%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;
