export const submenus = {
    31:{
        casino: {
            leftMenu: {
                links: [
                    {
                        id: '1',
                        url: '/online-casino-games/roulette',
                        icon: '',
                        label: 'TITLE@ROULETTESIDEBAR',
                        authkey: 'showRoulette'
                    },
                    {
                        id: '2',
                        url: '/online-casino-games/blackjack',
                        icon: '',
                        label: 'TITLE@BLACKJACKSIDEBAR',
                        authkey: 'showBlackjack'
                    },
                    {
                        id: '3',
                        url: '/online-casino-games/baccarat',
                        icon: '',
                        label: 'TITLE@BACCARAT',
                        authkey: 'showBaccarat'
                    },
                    {
                        id: '4',
                        url: '/online-casino-games/dragon-tiger',
                        icon: '',
                        label: 'TITLE@DRAGON_TIGER',
                        authkey: 'showDragontiger'
                    }
                ],
                footer: [
                    {
                        id: '1',
                        url: '',
                        icon: '',
                        label: 'MENU@ENJOY'
                    },
                ]
            },
            rightMenu: [
                {
                    id: 'rm3',
                    url:'/online-casino-games/best-casino-games',
                    image: 'DROPDOWN_POPULAR.png',
                    label: 'TITLE@populargames'
                },
                {

                    id: 'rm1',
                    url:'/online-casino-games',
                    image: 'DROPDOWN_CASINO_LIVE_CASINO.png',
                    label: 'TITLE@LIVECASINO',
                    authkey: 'showOnlineCasinoGamesLink'
                },
                {
                    id: 'rm2',
                    url:'/online-casino-games/slotGames',
                    image: 'DROPDOWN_SLOT_BINGO.png',
                    label: 'TITLE@SLOTBINGO'
                }
            
             
                    ]
        },
        instant_games: {
            leftMenu: {
                links: [
                    {
                        id: '1',
                        url: '/instant-games/plinkox',
                        icon: '',
                        label: "TITLE@Plinko",
                        authkey: "showInstantThumbs"
                    },
                    // {
                    //     id: '2',
                    //     url: '/instant-games/footballx',
                    //     icon: '',
                    //     label: 'TITLE@Footballx',
                    //     authkey: "showInstantThumbs"
                    // },
                    // {
                    //     id: '3',
                    //     url: '/instant-games/balloon',
                    //     icon: '',
                    //     label: 'TITLE@Baloon',
                    //     authkey: "showInstantThumbs"
                    // },
                    {
                        id: '4',
                        url: '/instant-games/mines',
                        icon: '',
                        authkey:"showInstantThumbs",
                        label: "TITLE@Mines"
                    },
                    {
                        id: '5',
                        url: '/instant-games/ninjacrash',
                        icon: '',
                        authkey:"showInstantThumbs",
                        label: "TITLE@ninjacrush"
                    },
                    {
                        id: '6',
                        url: '/instant-games/aviatrix',
                        icon: '',
                        authkey:"showInstantThumbs",
                        label: "GAME@AVIATRIX"
                    },
                    {
                        id: '7',
                        url: '/instant-games',
                        icon: '',
                        authkey:"showInstantThumbs",
                        label: "HEADER@VIEWALL"
                    }
                
                
                ],
                footer: [
                    {
                        id: '1',
                        url: '',
                        icon: '',
                        label: 'MENU@ExploreALL'
                    },
                ]
            },
            rightMenu: [
                {
                    id: 'rm1',
                    url: '/instant-games/aviator',
                    image: 'aviator-instant-cashsite-dropdown.png',
                    label: 'TITLE@AVIATOR',
                    authkey: "showInstantThumbs"
                },
                {
                    id: 'rm2',
                    url: '/instant-games/cash show',
                    image: 'DROPDOWN_CASHSHOW_INSTANT.png',
                    label: 'TITLE@CashShow',
                    authkey: "showInstantThumbs"
                },
                {
                    id: 'rm3',
                    url: '/instant-games/jetx',
                    image: 'jetx-instant-cashsite-drowpdown.png',
                    label: 'TITLE@JetX',
                    authkey: "showInstantThumbs"
                }
            ]
        },
        hotgames: {
            leftMenu: {
                links: [
                    {
                        id: '1',
                        url: '/online-casino-games/MONOPOLYLIVE',
                        icon: '',
                        label: 'TITLE@MonopolyLive',
                        authkey: 'showTeenpatti'
                    },
                    {
                        id: '2',
                        url: '/online-casino-games/funkytime',
                        icon: '',
                        label: 'TITLE@FunkyTimes',
                        authkey: 'showTeenpatti2020'
                    },
                    {
                        id: '3',
                        url: '/online-casino-games/lightningdice',
                        icon: '',
                        label: 'TITLE@LightningDice',
                        authkey: 'showTeenpatti2020'
                    },
                    {
                        id: '4',
                        url: '/online-casino-games/DRAGONTIGER',
                        icon: '',
                        label: 'TITLE@DragonTiger',
                        authkey: 'showTeenpatti2020'
                    },
                    {
                        id: '5',
                        url: '/instant-games/cricketx',
                        icon: '',
                        label: 'TITLE@CricketX',
                        authkey: 'showTeenpatti2020'
                    },
                ],
                footer: [
                    {
                        id: '1',
                        url: '',
                        icon: '',
                        label: 'MENU@PLAYANDNEVER'
                    },
                ]
            },
            rightMenu: [
                {
                    id: 'rm1',
                    url:'/online-casino-games/crazytime',
                    image: 'DROPDOWN_CRAZY_TIME.png',
                    label: "TITLE@crazytime",
                    authkey: 'showTeenpattiLink'
                },
                {
                    id: 'rm2',
                    url:'/online-casino-games/lightning-roulette',
                    image: 'DROPDOWN_LIGHTNING_ROULETTE.png',
                    label: 'TITLE@LIGHTINGROULETTE',
                    authkey: 'showTeenpattiLink'
                },
                {
                    id: 'rm3',
                    url:'/teenpatti-online-play',
                    image: 'DROPDOWN_TEENPATTI 20-20.png',
                    label: 'TITLE@TEENPATTI20-20',
                    authkey: 'showTeenpattiLink'
                }
            ]
        },
        sports: {
            leftMenu: {
                links: [
                    {
                        id: '1',
                        url: '/betting-exchange/online-cricket-betting',
                        icon: '',
                        label: 'SPORT@CRICKET',
                        authkey: 'showSportCricket'
                    },
                    {
                        id: '2',
                        url: '/betting-exchange/football-betting',
                        icon: '',
                        label: 'SPORT@FOOTBALL',
                        authkey: 'showSportFootball'
                    },
                    {
                        id: '3',
                        url: '/betting-exchange/tennis-betting',
                        icon: '',
                        label: 'SPORT@TENNIS',
                        authkey: 'showSportTennis'
                    },
                    {
                        id: '4',
                        url: '/betting-exchange/volleyball-betting',
                        icon: '',
                        label: 'SPORT@VolleyBall',
                        authkey: 'showSportVolleyball'
                    },
                    {
                        id: '5',
                        url: '/virtualSports/vci',
                        icon: '',
                        label: 'TITLE@VIRTUALSPORTS',
                        authkey: 'showVirtualSportsvfec'
                    },
                    {
                        id: '6',
                        url: '/sports/tournaments/odi-world-cup-betting',
                        icon: '',
                        label: 'TITLE@Tournaments',
                        authkey: 'showTournaments'
                    }
                ],
                footer: [
                    {
                        id: '1',
                        url: '',
                        icon: '',
                        label: 'MENU@GOONTURN'
                    },
                ]
            },
            rightMenu: [
                {
                    id: 'rm1',
                    url:'/betting-exchange',
                    image: 'DROPDOWN_EXCHANGE_NEW.png',
                    label: 'TITLE@EXCHANGE',
                    authkey: 'showExchangeLink'
                },
                {
                    id: 'rm2',
                    url:'/premium-sportsbook',
                    image: 'premiumSportsbookDropdownYolo.png',
                    label: 'TITLE@PREMIUM_SPORTSBOOK',
                    authkey: 'showPremiumSportsLink'
                },
                {
                    id: 'rm3',
                    url:'/sportsbook',
                    image: 'DROPDOWN_SPORTSBOOK_NEW.png',
                    label: 'MENU@SPORTBOOK',
                    authkey: 'showSportBookLink'
                },
                // {
                //     id: 'rm4',
                //     url:'/virtualSports/vci',
                //     image: 'virtualSportsDesktopDropdown.png',
                //     label: 'GAME@VIRTUALSPORTS',
                //     authkey: 'showVirtualSportsvfel'
                // },
            ]
        },
        promotions: {
            leftMenu: {
                links: [
                    {
                        id: '1',
                        url: '/promotions/welcome-bonus',
                        icon: '',
                        label: "PROMOTIONSTITLE@WELCOME"
                    },
                    {
                        id: '2',
                        url: '/promotions/refill-bonus',
                        icon: '',
                        label: 'PROMOTIONSTITLE@REFILL',
                        authkey: "showRefillBonus"
                    },
                    {
                        id: '3',
                        url: '/promotions/cashback-bonus',
                        icon: '',
                        label: 'PROMOTIONSTITLE@CASHBACK',
                        authkey: "showCashbackBonus"
                    },
                    {
                        id: '4',
                        url: '/promotions/referral-bonus',
                        icon: '',
                        authkey:"showReferralBonus",
                        label: "ProBonus@Referral"
                    },   
                    {
                        id: '5',
                        url: '/promotions/big-bash',
                        icon: '',
                        authkey:"showBBLBonus",
                        label: "PROMOTIONS@BBLBonus"
                    },
                    {
                        id: '6',
                        url: '/promotions/arjith_promo',
                        icon: '',
                        authkey:"showSingWithYolo",
                        label: "PROMOTIONS@singYolo"
                    },
                    {
                        id: '7',
                        url: '/promotions/jetx-tournament',
                        icon: '',
                        authkey:"jetxpromo",                                 
                        label:"jetx"
                    },
                    {
                        id: '8',
                        url: '/promotions/crazyCrash',
                        icon: '',
                        authkey:"showCrazyCrashPromo",
                        label:"PROMOTIONS@crazyCrashPromo"
                    },
                    {   id: '9',
                        url: '/promotions/aviator-bonus',
                        icon: '',
                        authkey:"showAviatorBonus",
                        label:"PROMOTIONS@AviatorBonus"
                    },   
                    {
                        id: '10',
                        url: '/promotions/evolution-first-bet',
                        icon: '',
                        authkey:"showEvolutionpromo",
                        label: "PROMOTIONS@firstperson"
                    },
                    {
                        id: '11',
                        url: '/promotions/funkytime',
                        icon: '',
                        authkey:"showFunkytimePromo",
                        label: "PROMOTIONS@funkytime"
                    },
                    {
                        id: '15',
                        url: '/promotions/evo-baccarat',
                        icon: '',
                        authkey:"showEvoCasinoCupPromo",
                        label: "PROMOTIONS@EvoCasinoCupPromo"
                    },
                    {
                        id: '12',
                        url: '/promotions/worldcup-cashback-bonanza',
                        icon: '',
                        authkey:"showWCBonusPromo",
                        label: "PROMOTIONS@WCCBONAZA"
                    },
                    {
                        id: '13',
                        url: '/promotions/platinum-rush',
                        icon: '',
                        authkey:"showPlatinumRush",
                        label: "PROMOTIONS@Platinumrush"
                    },
                    {
                        id: '14',
                        url: '/promotions/worldcup-betboost',
                        icon: '',
                        authkey:"showWCBonusPromo",
                        label: "TITLE@wcbetboost"
                    },
                    {
                        id: '15',
                        url: '/promotions/catch99-s2',
                        icon: '',
                        authkey:"showCatch99Bonus",
                        label: "PROMOTIONS@CATCH99"
                    },
                    // {
                    //     id: '16',
                    //     url: '/promotions/evolution-crazy-pachinko',
                    //     icon: '',
                    //     authkey:"showCrazyPachinkoPromo",
                    //     label: "TITLE@EvoCrazyPachinko"
                    // },
                    {
                        id: '17',
                        url: '/promotions/jetx-celestial-rush',
                        icon: '',
                        authkey:"showJetXCelestialRush",
                        label: "PROMOTIONS@JetxCelestial"
                    },
                    {
                        id: '18',
                        url: '/promotions/aviatrix-double-dhamaka',
                        icon: '',
                        authkey:"showAviatrixPromo",
                        label: "PROMOTIONS@AviatrixDoubleDhamaka"
                    },
                    {
                        id: '19',
                        url: '/promotions/cricket-cashback-marathon',
                        icon: '',
                        authkey:"showcircketCashbackMarathon",
                        label: "PROMOTIONS@CricketCashbackMarathon"
                    },
                    {
                        id: '20',
                        url: '/promotions/crazytime-epic-bonanza',
                        icon: '',
                        authkey:"showCrazyTimePromo",
                        label: "PROMOTIONS@CrazyTimeEpicBonanza"
                    },
                    {
                        id: '21',
                        url: '/promotions/aviator-high-flyer',
                        icon: '',
                        authkey:"showAviatorHighFlyerPromo",
                        label: "PROMOTIONS@AviatorHighFlyer"
                    }
                   
                ],
                footer: [
                    {
                        id: '1',
                        url: '',
                        icon: '',
                        label: 'MENU@ExploreALL'
                    },
                ]
            },
            rightMenu: [
                {
                    id: 'rm1',
                    url: '/promotions/welcome-bonus',
                    image: 'DROPDOWN_WELCOME_BONUS_NEW.png',
                    label: 'PROMOTIONSTITLE@WELCOME',
                    authkey: "showWelcomeBonus"
                },
                {
                    id: 'rm2',
                    url: '/promotions/refill-bonus',
                    image: 'DROPDOWN_REFILLBONUS_NEW.png',
                    label: 'PROMOTIONSTITLE@REFILL',
                    authkey: "showRefillBonus"
                },
                {
                    id: 'rm3',
                    url: '/promotions/cashback-bonus',
                    image: 'DROPDOWN_CASHBACKBONUS_NEW.png',
                    label: 'PROMOTIONSTITLE@CASHBACK',
                    authkey: "showCashbackBonus"
                }
            ]
        },
    },
    32:{
        casino: {
            leftMenu: {
                links: [
                    {
                        id: '1',
                        url: '/online-casino-games/roulette',
                        icon: '',
                        label: 'TITLE@ROULETTESIDEBAR',
                        authkey: 'showRoulette'
                    },
                    {
                        id: '2',
                        url: '/online-casino-games/blackjack',
                        icon: '',
                        label: 'TITLE@BLACKJACKSIDEBAR',
                        authkey: 'showBlackjack'
                    },
                    {
                        id: '3',
                        url: '/online-casino-games/baccarat',
                        icon: '',
                        label: 'TITLE@BACCARAT',
                        authkey: 'showBaccarat'
                    },
                    {
                        id: '4',
                        url: '/online-casino-games/dragon-tiger',
                        icon: '',
                        label: 'TITLE@DRAGON_TIGER',
                        authkey: 'showDragontiger'
                    }
                ],
                footer: [
                    {
                        id: '1',
                        url: '',
                        icon: '',
                        label: 'MENU@ENJOY'
                    },
                ]
            },
            rightMenu: [
                {
                    id: 'rm3',
                    url:'/online-casino-games/best-casino-games',
                    image: 'DROPDOWN_POPULAR.png',
                    label: 'TITLE@populargames'
                },
                {
                    id: 'rm1',
                    url:'/online-casino-games',
                    image: 'DROPDOWN_CASINO_LIVE_CASINO.png',
                    label: 'TITLE@LIVECASINO',
                    authkey: 'showOnlineCasinoGamesLink'
                },
                {
                    id: 'rm2',
                    url:'/online-casino-games/slotGames',
                    image: 'DROPDOWN_SLOT_BINGO.png',
                    label: 'TITLE@SLOTBINGO'
                }
            
            ]
        },
        instant_games: {
            leftMenu: {
                links: [
                    {
                        id: '1',
                        url: '/instant-games/plinkox',
                        icon: '',
                        label: "TITLE@Plinko",
                        authkey: "showInstantThumbs"
                    },
                    // {
                    //     id: '2',
                    //     url: '/instant-games/footballx',
                    //     icon: '',
                    //     label: 'TITLE@Footballx',
                    //     authkey: "showInstantThumbs"
                    // },
                    // {
                    //     id: '3',
                    //     url: '/instant-games/balloon',
                    //     icon: '',
                    //     label: 'TITLE@Baloon',
                    //     authkey: "showInstantThumbs"
                    // },
                    {
                        id: '4',
                        url: '/instant-games/mines',
                        icon: '',
                        authkey:"showInstantThumbs",
                        label: "TITLE@Mines"
                    },
                    {
                        id: '5',
                        url: '/instant-games/ninjacrash',
                        icon: '',
                        authkey:"showInstantThumbs",
                        label: "TITLE@ninjacrush"
                    },
                    {
                        id: '6',
                        url: '/instant-games/aviatrix',
                        icon: '',
                        authkey:"showInstantThumbs",
                        label: "GAME@AVIATRIX"
                    },
                    {
                        id: '7',
                        url: '/instant-games',
                        icon: '',
                        authkey:"showInstantThumbs",
                        label: "HEADER@VIEWALL"
                    }
                
                
                ],
                footer: [
                    {
                        id: '1',
                        url: '',
                        icon: '',
                        label: 'MENU@ExploreALL'
                    },
                ]
            },
            rightMenu: [
                {
                    id: 'rm1',
                    url: '/instant-games/aviator',
                    image: 'aviator-instant-cashsite-dropdown.png',
                    label: 'TITLE@AVIATOR',
                    authkey: "showInstantThumbs"
                },
                {
                    id: 'rm2',
                    url: '/instant-games/cash show',
                    image: 'DROPDOWN_CASHSHOW_INSTANT.png',
                    label: 'TITLE@CashShow',
                    authkey: "showInstantThumbs"
                },
                {
                    id: 'rm3',
                    url: '/instant-games/jetx',
                    image: 'jetx-instant-cashsite-drowpdown.png',
                    label: 'TITLE@JetX',
                    authkey: "showInstantThumbs"
                }
            ]
        },
        hotgames: {
            leftMenu: {
                links: [
                    {
                        id: '1',
                        url: '/online-casino-games/MONOPOLYLIVE',
                        icon: '',
                        label: 'TITLE@MonopolyLive',
                        authkey: 'showTeenpatti'
                    },
                    {
                        id: '2',
                        url: '/online-casino-games/funkytime',
                        icon: '',
                        label: 'TITLE@FunkyTimes',
                        authkey: 'showTeenpatti2020'
                    },
                    {
                        id: '3',
                        url: '/online-casino-games/lightningdice',
                        icon: '',
                        label: 'TITLE@LightningDice',
                        authkey: 'showTeenpatti2020'
                    },
                    {
                        id: '4',
                        url: '/online-casino-games/DRAGONTIGER',
                        icon: '',
                        label: 'TITLE@DragonTiger',
                        authkey: 'showTeenpatti2020'
                    },
                    {
                        id: '5',
                        url: '/instant-games/cricketx',
                        icon: '',
                        label: 'TITLE@CricketX',
                        authkey: 'showTeenpatti2020'
                    },
                ],
                footer: [
                    {
                        id: '1',
                        url: '',
                        icon: '',
                        label: 'MENU@PLAYANDNEVER'
                    },
                ]
            },
            rightMenu: [
                {
                    id: 'rm1',
                    url:'/online-casino-games/crazytime',
                    image: 'DROPDOWN_CRAZY_TIME.png',
                    label: "TITLE@crazytime",
                    authkey: 'showTeenpattiLink'
                },
                {
                    id: 'rm2',
                    url:'/online-casino-games/lightning-roulette',
                    image: 'DROPDOWN_LIGHTNING_ROULETTE.png',
                    label: 'TITLE@LIGHTINGROULETTE',
                    authkey: 'showTeenpattiLink'
                },
                {
                    id: 'rm3',
                    url:'/teenpatti-online-play',
                    image: 'DROPDOWN_TEENPATTI 20-20.png',
                    label: 'TITLE@TEENPATTI20-20',
                    authkey: 'showTeenpattiLink'
                }
            ]
        },
        sports: {
            leftMenu: {
                links: [
                    {
                        id: '1',
                        url: '/betting-exchange/online-cricket-betting',
                        icon: '',
                        label: 'SPORT@CRICKET',
                        authkey: 'showSportCricket'
                    },
                    {
                        id: '2',
                        url: '/betting-exchange/football-betting',
                        icon: '',
                        label: 'SPORT@FOOTBALL',
                        authkey: 'showSportFootball'
                    },
                    {
                        id: '3',
                        url: '/betting-exchange/tennis-betting',
                        icon: '',
                        label: 'SPORT@TENNIS',
                        authkey: 'showSportTennis'
                    },
                    {
                        id: '4',
                        url: '/betting-exchange/volleyball-betting',
                        icon: '',
                        label: 'SPORT@VolleyBall',
                        authkey: 'showSportVolleyball'
                    },
                    {
                        id: '5',
                        url: '/virtualSports/vci',
                        icon: '',
                        label: 'TITLE@VIRTUALSPORTS',
                        authkey: 'showVirtualSportsvfec'
                    },
                    {
                        id: '6',
                        url: '/sports/tournaments/odi-world-cup-betting',
                        icon: '',
                        label: 'TITLE@Tournaments',
                        authkey: 'showTournaments'
                    }
                ],
                footer: [
                    {
                        id: '1',
                        url: '',
                        icon: '',
                        label: 'MENU@GOONTURN'
                    },
                ]
            },
            rightMenu: [
                {
                    id: 'rm1',
                    url:'/betting-exchange',
                    image: 'DROPDOWN_EXCHANGE_NEW.png',
                    label: 'TITLE@EXCHANGE',
                    authkey: 'showExchangeLink'
                },
                {
                    id: 'rm2',
                    url:'/premium-sportsbook',
                    image: 'premiumSportsbookDropdownYolo.png',
                    label: 'TITLE@PREMIUM_SPORTSBOOK',
                    authkey: 'showPremiumSportsLink'
                },
                {
                    id: 'rm3',
                    url:'/sportsbook',
                    image: 'DROPDOWN_SPORTSBOOK_NEW.png',
                    label: 'MENU@SPORTBOOK',
                    authkey: 'showSportBookLink'
                },
                // {
                //     id: 'rm4',
                //     url:'/virtualSports/vci',
                //     image: 'virtualSportsDesktopDropdown.png',
                //     label: 'GAME@VIRTUALSPORTS',
                //     authkey: 'showVirtualSportsvfel'
                // },
            ]
        },
        promotions: {
            leftMenu: {
                links: [
                    {
                        id: '1',
                        url: '/promotions/welcome-bonus',
                        icon: '',
                        label: "PROMOTIONSTITLE@WELCOME"
                    },
                    {
                        id: '2',
                        url: '/promotions/refill-bonus',
                        icon: '',
                        label: 'PROMOTIONSTITLE@REFILL',
                        authkey: "showRefillBonus"
                    },
                    {
                        id: '3',
                        url: '/promotions/cashback-bonus',
                        icon: '',
                        label: 'PROMOTIONSTITLE@CASHBACK',
                        authkey: "showCashbackBonus"
                    },
                    {
                        id: '4',
                        url: '/promotions/referral-bonus',
                        icon: '',
                        authkey:"showReferralBonus",
                        label: "ProBonus@Referral"
                    },   
                    {
                        id: '5',
                        url: '/promotions/big-bash',
                        icon: '',
                        authkey:"showBBLBonus",
                        label: "PROMOTIONS@BBLBonus"
                    },
                    {
                        id: '6',
                        url: '/promotions/arjith_promo',
                        icon: '',
                        authkey:"showSingWithYolo",
                        label: "PROMOTIONS@singYolo"
                    },
                    {
                        id: '7',
                        url: '/promotions/jetx-tournament',
                        icon: '',
                        authkey:"jetxpromo",                                 
                        label:"jetx"
                    },
                    {
                        id: '8',
                        url: '/promotions/crazyCrash',
                        icon: '',
                        authkey:"showCrazyCrashPromo",
                        label:"PROMOTIONS@crazyCrashPromo"
                    },
                    {   id: '9',
                        url: '/promotions/aviator-bonus',
                        icon: '',
                        authkey:"showAviatorBonus",
                        label:"PROMOTIONS@AviatorBonus"
                    },   
                    {
                        id: '10',
                        url: '/promotions/evolution-first-bet',
                        icon: '',
                        authkey:"showEvolutionpromo",
                        label: "PROMOTIONS@firstperson"
                    },
                    {
                        id: '11',
                        url: '/promotions/funkytime',
                        icon: '',
                        authkey:"showFunkytimePromo",
                        label: "PROMOTIONS@funkytime"
                    },
                    {
                        id: '15',
                        url: '/promotions/evo-baccarat',
                        icon: '',
                        authkey:"showEvoCasinoCupPromo",
                        label: "PROMOTIONS@EvoCasinoCupPromo"
                    },
                    
                    {
                        id: '12',
                        url: '/promotions/worldcup-cashback-bonanza',
                        icon: '',
                        authkey:"showWCBonusPromo",
                        label: "PROMOTIONS@WCCBONAZA"
                    },
                    {
                        id: '13',
                        url: '/promotions/platinum-rush',
                        icon: '',
                        authkey:"showPlatinumRush",
                        label: "PROMOTIONS@Platinumrush"
                    },
                    {
                        id: '14',
                        url: '/promotions/worldcup-betboost',
                        icon: '',
                        authkey:"showWCBonusPromo",
                        label: "TITLE@wcbetboost"
                    },
                    {
                        id: '15',
                        url: '/promotions/catch99-s2',
                        icon: '',
                        authkey:"showCatch99Bonus",
                        label: "PROMOTIONS@CATCH99"
                    },
                    // {
                    //     id: '16',
                    //     url: '/promotions/evolution-crazy-pachinko',
                    //     icon: '',
                    //     authkey:"showCrazyPachinkoPromo",
                    //     label: "TITLE@EvoCrazyPachinko"
                    // },
                    {
                        id: '17',
                        url: '/promotions/jetx-celestial-rush',
                        icon: '',
                        authkey:"showJetXCelestialRush",
                        label: "PROMOTIONS@JetxCelestial"
                    },
                    {
                        id: '18',
                        url: '/promotions/aviatrix-double-dhamaka',
                        icon: '',
                        authkey:"showAviatrixPromo",
                        label: "PROMOTIONS@AviatrixDoubleDhamaka"
                    },
                    {
                        id: '19',
                        url: '/promotions/cricket-cashback-marathon',
                        icon: '',
                        authkey:"showcircketCashbackMarathon",
                        label: "PROMOTIONS@CricketCashbackMarathon"
                    },
                    {
                        id: '20',
                        url: '/promotions/crazytime-epic-bonanza',
                        icon: '',
                        authkey:"showCrazyTimePromo",
                        label: "PROMOTIONS@CrazyTimeEpicBonanza"
                    },
                    {
                        id: '21',
                        url: '/promotions/aviator-high-flyer',
                        icon: '',
                        authkey:"showAviatorHighFlyerPromo",
                        label: "PROMOTIONS@AviatorHighFlyer"
                    }
                ],
                footer: [
                    {
                        id: '1',
                        url: '',
                        icon: '',
                        label: 'MENU@ExploreALL'
                    },
                ]
            },
            rightMenu: [
                {
                    id: 'rm1',
                    url: '/promotions/welcome-bonus',
                    image: 'DROPDOWN_WELCOME_BONUS_NEW.png',
                    label: 'PROMOTIONSTITLE@WELCOME',
                    authkey: "showWelcomeBonus"
                },
                {
                    id: 'rm2',
                    url: '/promotions/refill-bonus',
                    image: 'DROPDOWN_REFILLBONUS_NEW.png',
                    label: 'PROMOTIONSTITLE@REFILL',
                    authkey: "showRefillBonus"
                },
                {
                    id: 'rm3',
                    url: '/promotions/cashback-bonus',
                    image: 'DROPDOWN_CASHBACKBONUS_NEW.png',
                    label: 'PROMOTIONSTITLE@CASHBACK',
                    authkey: "showCashbackBonus"
                }
            ]
        },
    },
    33:{
        casino: {
            leftMenu: {
                links: [
                    {
                        id: '1',
                        url: '/online-casino-games/roulette',
                        icon: '',
                        label: 'TITLE@ROULETTESIDEBAR',
                        authkey: 'showRoulette'
                    },
                    {
                        id: '2',
                        url: '/online-casino-games/blackjack',
                        icon: '',
                        label: 'TITLE@BLACKJACKSIDEBAR',
                        authkey: 'showBlackjack'
                    },
                    {
                        id: '3',
                        url: '/online-casino-games/baccarat',
                        icon: '',
                        label: 'TITLE@BACCARAT',
                        authkey: 'showBaccarat'
                    },
                    {
                        id: '4',
                        url: '/online-casino-games/dragon-tiger',
                        icon: '',
                        label: 'TITLE@DRAGON_TIGER',
                        authkey: 'showDragontiger'
                    }
                ],
                footer: [
                    {
                        id: '1',
                        url: '',
                        icon: '',
                        label: 'MENU@ENJOY'
                    },
                ]
            },
            rightMenu: [
                {
                    id: 'rm3',
                    url:'/online-casino-games/best-casino-games',
                    image: 'DROPDOWN_POPULAR.png',
                    label: 'TITLE@populargames'
                },
                {
                    id: 'rm1',
                    url:'/online-casino-games',
                    image: 'DROPDOWN_CASINO_LIVE_CASINO.png',
                    label: 'TITLE@LIVECASINO',
                    authkey: 'showOnlineCasinoGamesLink'
                },
                {
                    id: 'rm2',
                    url:'/online-casino-games/slotGames',
                    image: 'DROPDOWN_SLOT_BINGO.png',
                    label: 'TITLE@SLOTBINGO'
                }
            
            ]
        },
        instant_games: {
            leftMenu: {
                links: [
                    {
                        id: '1',
                        url: '/instant-games/plinkox',
                        icon: '',
                        label: "TITLE@Plinko",
                        authkey: "showInstantThumbs"
                    },
                    // {
                    //     id: '2',
                    //     url: '/instant-games/footballx',
                    //     icon: '',
                    //     label: 'TITLE@Footballx',
                    //     authkey: "showInstantThumbs"
                    // },
                    // {
                    //     id: '3',
                    //     url: '/instant-games/balloon',
                    //     icon: '',
                    //     label: 'TITLE@Baloon',
                    //     authkey: "showInstantThumbs"
                    // },
                    {
                        id: '4',
                        url: '/instant-games/mines',
                        icon: '',
                        authkey:"showInstantThumbs",
                        label: "TITLE@Mines"
                    },
                    {
                        id: '5',
                        url: '/instant-games/ninjacrash',
                        icon: '',
                        authkey:"showInstantThumbs",
                        label: "TITLE@ninjacrush"
                    },
                    {
                        id: '6',
                        url: '/instant-games/aviatrix',
                        icon: '',
                        authkey:"showInstantThumbs",
                        label: "GAME@AVIATRIX"
                    },
                    {
                        id: '7',
                        url: '/instant-games',
                        icon: '',
                        authkey:"showInstantThumbs",
                        label: "HEADER@VIEWALL"
                    },
                
                
                ],
                footer: [
                    {
                        id: '1',
                        url: '',
                        icon: '',
                        label: 'MENU@ExploreALL'
                    },
                ]
            },
            rightMenu: [
                {
                    id: 'rm1',
                    url: '/instant-games/aviator',
                    image: 'aviator-instant-cashsite-dropdown.png',
                    label: 'TITLE@AVIATOR',
                    authkey: "showInstantThumbs"
                },
                {
                    id: 'rm2',
                    url: '/instant-games/cash show',
                    image: 'DROPDOWN_CASHSHOW_INSTANT.png',
                    label: 'TITLE@CashShow',
                    authkey: "showInstantThumbs"
                },
                {
                    id: 'rm3',
                    url: '/instant-games/jetx',
                    image: 'jetx-instant-cashsite-drowpdown.png',
                    label: 'TITLE@JetX',
                    authkey: "showInstantThumbs"
                }
            ]
        },
        hotgames: {
            leftMenu: {
                links: [
                    {
                        id: '1',
                        url: '/online-casino-games/MONOPOLYLIVE',
                        icon: '',
                        label: 'TITLE@MonopolyLive',
                        authkey: 'showTeenpatti'
                    },
                    {
                        id: '2',
                        url: '/online-casino-games/funkytime',
                        icon: '',
                        label: 'TITLE@FunkyTimes',
                        authkey: 'showTeenpatti2020'
                    },
                    {
                        id: '3',
                        url: '/online-casino-games/lightningdice',
                        icon: '',
                        label: 'TITLE@LightningDice',
                        authkey: 'showTeenpatti2020'
                    },
                    {
                        id: '4',
                        url: '/online-casino-games/DRAGONTIGER',
                        icon: '',
                        label: 'TITLE@DragonTiger',
                        authkey: 'showTeenpatti2020'
                    },
                    {
                        id: '5',
                        url: '/instant-games/cricketx',
                        icon: '',
                        label: 'TITLE@CricketX',
                        authkey: 'showTeenpatti2020'
                    },
                ],
                footer: [
                    {
                        id: '1',
                        url: '',
                        icon: '',
                        label: 'MENU@PLAYANDNEVER'
                    },
                ]
            },
            rightMenu: [
                {
                    id: 'rm1',
                    url:'/online-casino-games/crazytime',
                    image: 'DROPDOWN_CRAZY_TIME.png',
                    label: "TITLE@crazytime",
                    authkey: 'showTeenpattiLink'
                },
                {
                    id: 'rm2',
                    url:'/online-casino-games/lightning-roulette',
                    image: 'DROPDOWN_LIGHTNING_ROULETTE.png',
                    label: 'TITLE@LIGHTINGROULETTE',
                    authkey: 'showTeenpattiLink'
                },
                {
                    id: 'rm3',
                    url:'/teenpatti-online-play',
                    image: 'DROPDOWN_TEENPATTI 20-20.png',
                    label: 'TITLE@TEENPATTI20-20',
                    authkey: 'showTeenpattiLink'
                }
            ]
        },
        sports: {
            leftMenu: {
                links: [
                    {
                        id: '1',
                        url: '/betting-exchange/online-cricket-betting',
                        icon: '',
                        label: 'SPORT@CRICKET',
                        authkey: 'showSportCricket'
                    },
                    {
                        id: '2',
                        url: '/betting-exchange/football-betting',
                        icon: '',
                        label: 'SPORT@FOOTBALL',
                        authkey: 'showSportFootball'
                    },
                    {
                        id: '3',
                        url: '/betting-exchange/tennis-betting',
                        icon: '',
                        label: 'SPORT@TENNIS',
                        authkey: 'showSportTennis'
                    },
                    {
                        id: '4',
                        url: '/betting-exchange/volleyball-betting',
                        icon: '',
                        label: 'SPORT@VolleyBall',
                        authkey: 'showSportVolleyball'
                    },
                    {
                        id: '5',
                        url: '/virtualSports/vci',
                        icon: '',
                        label: 'TITLE@VIRTUALSPORTS',
                        authkey: 'showVirtualSportsvfec'
                    }
                ],
                footer: [
                    {
                        id: '1',
                        url: '',
                        icon: '',
                        label: 'MENU@GOONTURN'
                    },
                ]
            },
            rightMenu: [
                {
                    id: 'rm1',
                    url:'/betting-exchange',
                    image: 'DROPDOWN_EXCHANGE_NEW.png',
                    label: 'TITLE@EXCHANGE',
                    authkey: 'showExchangeLink'
                },
                {
                    id: 'rm2',
                    url:'/premium-sportsbook',
                    image: 'premiumSportsbookDropdownBetdaily.png',
                    label: 'TITLE@PREMIUM_SPORTSBOOK',
                    authkey: 'showPremiumSportsLink'
                },
                {
                    id: 'rm3',
                    url:'/sportsbook',
                    image: 'DROPDOWN_SPORTSBOOK_NEW.png',
                    label: 'MENU@SPORTBOOK',
                    authkey: 'showSportBookLink'
                },
                // {
                //     id: 'rm4',
                //     url:'/virtualSports/vci',
                //     image: 'virtualSportsDesktopDropdown.png',
                //     label: 'GAME@VIRTUALSPORTS',
                //     authkey: 'showVirtualSportsvfel'
                // },
            ]
        },
        promotions: {
            leftMenu: {
                links: [
                    {
                        id: '1',
                        url: '/promotions/welcome-bonus',
                        icon: '',
                        label: "PROMOTIONSTITLE@WELCOME"
                    },
                    {
                        id: '2',
                        url: '/promotions/refill-bonus',
                        icon: '',
                        label: 'PROMOTIONSTITLE@REFILL',
                        authkey: "showRefillBonus"
                    },
                    {
                        id: '3',
                        url: '/promotions/cashback-bonus',
                        icon: '',
                        label: 'PROMOTIONSTITLE@CASHBACK',
                        authkey: "showCashbackBonus"
                    },
                    {
                        id: '4',
                        url: '/promotions/referral-bonus',
                        icon: '',
                        authkey:"showReferralBonus",
                        label: "ProBonus@Referral"
                    },   
                    {
                        id: '5',
                        url: '/promotions/big-bash',
                        icon: '',
                        authkey:"showBBLBonus",
                        label: "PROMOTIONS@BBLBonus"
                    },
                    {
                        id: '6',
                        url: '/promotions/arjith_promo',
                        icon: '',
                        authkey:"showSingWithYolo",
                        label: "PROMOTIONS@singYolo"
                    },
                    {
                        id: '7',
                        url: '/promotions/jetx-tournament',
                        icon: '',
                        authkey:"jetxpromo",                                 
                        label:"jetx"
                    },
                    {
                        id: '8',
                        url: '/promotions/crazyCrash',
                        icon: '',
                        authkey:"showCrazyCrashPromo",
                        label:"PROMOTIONS@crazyCrashPromo"
                    },
                    {   id: '9',
                        url: '/promotions/aviator-bonus',
                        icon: '',
                        authkey:"showAviatorBonus",
                        label:"PROMOTIONS@AviatorBonus"
                    },   
                    {
                        id: '10',
                        url: '/promotions/evolution-first-bet',
                        icon: '',
                        authkey:"showEvolutionpromo",
                        label: "PROMOTIONS@firstperson"
                    },
                    {
                        id: '11',
                        url: '/promotions/funkytime',
                        icon: '',
                        authkey:"showFunkytimePromo",
                        label: "PROMOTIONS@funkytime"
                    },
                    {
                        id: '12',
                        url: '/promotions/evo-baccarat',
                        icon: '',
                        authkey:"showEvoCasinoCupPromo",
                        label: "PROMOTIONS@EvoCasinoCupPromo",
                    },
                    {
                        id: '13',
                        url: '/promotions/worldcup-cashback-bonanza',
                        icon: '',
                        authkey:"showWCBonusPromo",
                        label: "PROMOTIONS@WCCBONAZA"
                    },
                    {
                        id: '14',
                        url: '/promotions/platinum-rush',
                        icon: '',
                        authkey:"showPlatinumRush",
                        label: "PROMOTIONS@Platinumrush"
                    },
                    {
                        id: '15',
                        url: '/promotions/worldcup-betboost',
                        icon: '',
                        authkey:"showWCBonusPromo",
                        label: "TITLE@wcbetboost"
                    },
                    // {
                    //     id: '15',
                    //     url: '/promotions/evolution-crazy-pachinko',
                    //     icon: '',
                    //     authkey:"showCrazyPachinkoPromo",
                    //     label: "TITLE@EvoCrazyPachinko"
                    // },
                    {
                        id: '16',
                        url: '/promotions/jetx-celestial-rush',
                        icon: '',
                        authkey:"showJetXCelestialRush",
                        label: "PROMOTIONS@JetxCelestial"
                    },
                    {
                        id: '17',
                        url: '/promotions/aviatrix-double-dhamaka',
                        icon: '',
                        authkey:"showAviatrixPromo",
                        label: "PROMOTIONS@AviatrixDoubleDhamaka"
                    },
                    {
                        id: '18',
                        url: '/promotions/cricket-cashback-marathon',
                        icon: '',
                        authkey:"showcircketCashbackMarathon",
                        label: "PROMOTIONS@CricketCashbackMarathon"
                    },
                    {
                        id: '19',
                        url: '/promotions/crazytime-epic-bonanza',
                        icon: '',
                        authkey:"showCrazyTimePromo",
                        label: "PROMOTIONS@CrazyTimeEpicBonanza"
                    },
                    {
                        id: '20',
                        url: '/promotions/aviator-high-flyer',
                        icon: '',
                        authkey:"showAviatorHighFlyerPromo",
                        label: "PROMOTIONS@AviatorHighFlyer"
                    }
                ],
                footer: [
                    {
                        id: '1',
                        url: '',
                        icon: '',
                        label: 'MENU@ExploreALL'
                    },
                ]
            },
            rightMenu: [
                {
                    id: 'rm1',
                    url: '/promotions/welcome-bonus',
                    image: 'DROPDOWN_WELCOME_BONUS_NEW.png',
                    label: 'PROMOTIONSTITLE@WELCOME',
                    authkey: "showWelcomeBonus"
                },
                {
                    id: 'rm2',
                    url: '/promotions/refill-bonus',
                    image: 'DROPDOWN_REFILLBONUS_NEW.png',
                    label: 'PROMOTIONSTITLE@REFILL',
                    authkey: "showRefillBonus"
                },
                {
                    id: 'rm3',
                    url: '/promotions/cashback-bonus',
                    image: 'DROPDOWN_CASHBACKBONUS_NEW.png',
                    label: 'PROMOTIONSTITLE@CASHBACK',
                    authkey: "showCashbackBonus"
                }
            ]
        },
    },
    34:{
        casino: {
            leftMenu: {
                links: [
                    {
                        id: '1',
                        url: '/online-casino-games/roulette',
                        icon: '',
                        label: 'TITLE@ROULETTESIDEBAR',
                        authkey: 'showRoulette'
                    },
                    {
                        id: '2',
                        url: '/online-casino-games/blackjack',
                        icon: '',
                        label: 'TITLE@BLACKJACKSIDEBAR',
                        authkey: 'showBlackjack'
                    },
                    {
                        id: '3',
                        url: '/online-casino-games/baccarat',
                        icon: '',
                        label: 'TITLE@BACCARAT',
                        authkey: 'showBaccarat'
                    },
                    {
                        id: '4',
                        url: '/online-casino-games/dragon-tiger',
                        icon: '',
                        label: 'TITLE@DRAGON_TIGER',
                        authkey: 'showDragontiger'
                    }
                ],
                footer: [
                    {
                        id: '1',
                        url: '',
                        icon: '',
                        label: 'MENU@ENJOY'
                    },
                ]
            },
            rightMenu: [
                {
                    id: 'rm3',
                    url:'/online-casino-games/best-casino-games',
                    image: 'DROPDOWN_POPULAR.png',
                    label: 'TITLE@populargames'
                },
                {
                    id: 'rm1',
                    url:'/online-casino-games',
                    image: 'DROPDOWN_CASINO_LIVE_CASINO.png',
                    label: 'TITLE@LIVECASINO',
                    authkey: 'showOnlineCasinoGamesLink'
                },
                {
                    id: 'rm2',
                    url:'/online-casino-games/slotGames',
                    image: 'DROPDOWN_SLOT_BINGO.png',
                    label: 'TITLE@SLOTBINGO'
                }
            
            ]
        },
        instant_games: {
            leftMenu: {
                links: [
                    {
                        id: '1',
                        url: '/instant-games/plinkox',
                        icon: '',
                        label: "TITLE@Plinko",
                        authkey: "showInstantThumbs"
                    },
                    // {
                    //     id: '2',
                    //     url: '/instant-games/footballx',
                    //     icon: '',
                    //     label: 'TITLE@Footballx',
                    //     authkey: "showInstantThumbs"
                    // },
                    // {
                    //     id: '3',
                    //     url: '/instant-games/balloon',
                    //     icon: '',
                    //     label: 'TITLE@Baloon',
                    //     authkey: "showInstantThumbs"
                    // },
                    {
                        id: '4',
                        url: '/instant-games/mines',
                        icon: '',
                        authkey:"showInstantThumbs",
                        label: "TITLE@Mines"
                    },
                    {
                        id: '5',
                        url: '/instant-games/ninjacrash',
                        icon: '',
                        authkey:"showInstantThumbs",
                        label: "TITLE@ninjacrush"
                    },
                    {
                        id: '6',
                        url: '/instant-games/aviatrix',
                        icon: '',
                        authkey:"showInstantThumbs",
                        label: "GAME@AVIATRIX"
                    },
                    {
                        id: '7',
                        url: '/instant-games',
                        icon: '',
                        authkey:"showInstantThumbs",
                        label: "HEADER@VIEWALL"
                    },
                
                ],
                footer: [
                    {
                        id: '1',
                        url: '',
                        icon: '',
                        label: 'MENU@ExploreALL'
                    },
                ]
            },
            rightMenu: [
                {
                    id: 'rm1',
                    url: '/instant-games/aviator',
                    image: 'aviator-instant-cashsite-dropdown.png',
                    label: 'TITLE@AVIATOR',
                    authkey: "showInstantThumbs"
                },
                {
                    id: 'rm2',
                    url: '/instant-games/cash show',
                    image: 'DROPDOWN_CASHSHOW_INSTANT.png',
                    label: 'TITLE@CashShow',
                    authkey: "showInstantThumbs"
                },
                {
                    id: 'rm3',
                    url: '/instant-games/jetx',
                    image: 'jetx-instant-cashsite-drowpdown.png',
                    label: 'TITLE@JetX',
                    authkey: "showInstantThumbs"
                }
            ]
        },
        hotgames: {
            leftMenu: {
                links: [
                    {
                        id: '1',
                        url: '/online-casino-games/MONOPOLYLIVE',
                        icon: '',
                        label: 'TITLE@MonopolyLive',
                        authkey: 'showTeenpatti'
                    },
                    {
                        id: '2',
                        url: '/online-casino-games/funkytime',
                        icon: '',
                        label: 'TITLE@FunkyTimes',
                        authkey: 'showTeenpatti2020'
                    },
                    {
                        id: '3',
                        url: '/online-casino-games/lightningdice',
                        icon: '',
                        label: 'TITLE@LightningDice',
                        authkey: 'showTeenpatti2020'
                    },
                    {
                        id: '4',
                        url: '/online-casino-games/DRAGONTIGER',
                        icon: '',
                        label: 'TITLE@DragonTiger',
                        authkey: 'showTeenpatti2020'
                    },
                    {
                        id: '5',
                        url: '/instant-games/cricketx',
                        icon: '',
                        label: 'TITLE@CricketX',
                        authkey: 'showTeenpatti2020'
                    },
                ],
                footer: [
                    {
                        id: '1',
                        url: '',
                        icon: '',
                        label: 'MENU@PLAYANDNEVER'
                    },
                ]
            },
            rightMenu: [
                {
                    id: 'rm1',
                    url:'/online-casino-games/crazytime',
                    image: 'DROPDOWN_CRAZY_TIME.png',
                    label: "TITLE@crazytime",
                    authkey: 'showTeenpattiLink'
                },
                {
                    id: 'rm2',
                    url:'/online-casino-games/lightning-roulette',
                    image: 'DROPDOWN_LIGHTNING_ROULETTE.png',
                    label: 'TITLE@LIGHTINGROULETTE',
                    authkey: 'showTeenpattiLink'
                },
                {
                    id: 'rm3',
                    url:'/teenpatti-online-play',
                    image: 'DROPDOWN_TEENPATTI 20-20.png',
                    label: 'TITLE@TEENPATTI20-20',
                    authkey: 'showTeenpattiLink'
                }
            ]
        },
        sports: {
            leftMenu: {
                links: [
                    {
                        id: '1',
                        url: '/betting-exchange/online-cricket-betting',
                        icon: '',
                        label: 'SPORT@CRICKET',
                        authkey: 'showSportCricket'
                    },
                    {
                        id: '2',
                        url: '/betting-exchange/football-betting',
                        icon: '',
                        label: 'SPORT@FOOTBALL',
                        authkey: 'showSportFootball'
                    },
                    {
                        id: '3',
                        url: '/betting-exchange/tennis-betting',
                        icon: '',
                        label: 'SPORT@TENNIS',
                        authkey: 'showSportTennis'
                    },
                    {
                        id: '4',
                        url: '/betting-exchange/volleyball-betting',
                        icon: '',
                        label: 'SPORT@VolleyBall',
                        authkey: 'showSportVolleyball'
                    },
                    {
                        id: '5',
                        url: '/virtualSports/vci',
                        icon: '',
                        label: 'TITLE@VIRTUALSPORTS',
                        authkey: 'showVirtualSportsvfec'
                    }
                ],
                footer: [
                    {
                        id: '1',
                        url: '',
                        icon: '',
                        label: 'MENU@GOONTURN'
                    },
                ]
            },
            rightMenu: [
                {
                    id: 'rm1',
                    url:'/betting-exchange',
                    image: 'DROPDOWN_EXCHANGE_NEW.png',
                    label: 'TITLE@EXCHANGE',
                    authkey: 'showExchangeLink'
                },
                {
                    id: 'rm2',
                    url:'/premium-sportsbook',
                    image: 'premiumSportsbookDropdownBetdaily.png',
                    label: 'TITLE@PREMIUM_SPORTSBOOK',
                    authkey: 'showPremiumSportsLink'
                },
                {
                    id: 'rm3',
                    url:'/sportsbook',
                    image: 'DROPDOWN_SPORTSBOOK_NEW.png',
                    label: 'MENU@SPORTBOOK',
                    authkey: 'showSportBookLink'
                },
                // {
                //     id: 'rm4',
                //     url:'/virtualSports/vci',
                //     image: 'virtualSportsDesktopDropdown.png',
                //     label: 'GAME@VIRTUALSPORTS',
                //     authkey: 'showVirtualSportsvfel'
                // },
            ]
        },
        promotions: {
            leftMenu: {
                links: [
                    {
                        id: '1',
                        url: '/promotions/welcome-bonus',
                        icon: '',
                        label: "PROMOTIONSTITLE@WELCOME"
                    },
                    {
                        id: '2',
                        url: '/promotions/refill-bonus',
                        icon: '',
                        label: 'PROMOTIONSTITLE@REFILL',
                        authkey: "showRefillBonus"
                    },
                    {
                        id: '3',
                        url: '/promotions/cashback-bonus',
                        icon: '',
                        label: 'PROMOTIONSTITLE@CASHBACK',
                        authkey: "showCashbackBonus"
                    },
                    {
                        id: '4',
                        url: '/promotions/referral-bonus',
                        icon: '',
                        authkey:"showReferralBonus",
                        label: "ProBonus@Referral"
                    },   
                    {
                        id: '5',
                        url: '/promotions/big-bash',
                        icon: '',
                        authkey:"showBBLBonus",
                        label: "PROMOTIONS@BBLBonus"
                    },
                    {
                        id: '6',
                        url: '/promotions/arjith_promo',
                        icon: '',
                        authkey:"showSingWithYolo",
                        label: "PROMOTIONS@singYolo"
                    },
                    {
                        id: '7',
                        url: '/promotions/jetx-tournament',
                        icon: '',
                        authkey:"jetxpromo",                                 
                        label:"jetx"
                    },
                    {
                        id: '8',
                        url: '/promotions/crazyCrash',
                        icon: '',
                        authkey:"showCrazyCrashPromo",
                        label:"PROMOTIONS@crazyCrashPromo"
                    },
                    {   id: '9',
                        url: '/promotions/aviator-bonus',
                        icon: '',
                        authkey:"showAviatorBonus",
                        label:"PROMOTIONS@AviatorBonus"
                    },   
                    {
                        id: '10',
                        url: '/promotions/evolution-first-bet',
                        icon: '',
                        authkey:"showEvolutionpromo",
                        label: "PROMOTIONS@firstperson"
                    },
                    {
                        id: '11',
                        url: '/promotions/funkytime',
                        icon: '',
                        authkey:"showFunkytimePromo",
                        label: "PROMOTIONS@funkytime"
                    },
                    {
                        id: '12',
                        url: '/promotions/evo-baccarat',
                        icon: '',
                        authkey:"showEvoCasinoCupPromo",
                        label: "PROMOTIONS@EvoCasinoCupPromo"
                    },
                    {
                        id: '13',
                        url: '/promotions/worldcup-cashback-bonanza',
                        icon: '',
                        authkey:"showWCBonusPromo",
                        label: "PROMOTIONS@WCCBONAZA"
                    },
                    {
                        id: '14',
                        url: '/promotions/platinum-rush',
                        icon: '',
                        authkey:"showPlatinumRush",
                        label: "PROMOTIONS@Platinumrush"
                    },
                    {
                        id: '15',
                        url: '/promotions/worldcup-betboost',
                        icon: '',
                        authkey:"showWCBonusPromo",
                        label: "TITLE@wcbetboost"
                    },
                    // {
                    //     id: '15',
                    //     url: '/promotions/evolution-crazy-pachinko',
                    //     icon: '',
                    //     authkey:"showCrazyPachinkoPromo",
                    //     label: "TITLE@EvoCrazyPachinko"
                    // },
                    {
                        id: '16',
                        url: '/promotions/jetx-celestial-rush',
                        icon: '',
                        authkey:"showJetXCelestialRush",
                        label: "PROMOTIONS@JetxCelestial"
                    },
                    {
                        id: '17',
                        url: '/promotions/aviatrix-double-dhamaka',
                        icon: '',
                        authkey:"showAviatrixPromo",
                        label: "PROMOTIONS@AviatrixDoubleDhamaka"
                    },
                    {
                        id: '18',
                        url: '/promotions/cricket-cashback-marathon',
                        icon: '',
                        authkey:"showcircketCashbackMarathon",
                        label: "PROMOTIONS@CricketCashbackMarathon"
                    },
                    {
                        id: '19',
                        url: '/promotions/crazytime-epic-bonanza',
                        icon: '',
                        authkey:"showCrazyTimePromo",
                        label: "PROMOTIONS@CrazyTimeEpicBonanza"
                    },
                    {
                        id: '20',
                        url: '/promotions/aviator-high-flyer',
                        icon: '',
                        authkey:"showAviatorHighFlyerPromo",
                        label: "PROMOTIONS@AviatorHighFlyer"
                    }         
                ],
                footer: [
                    {
                        id: '1',
                        url: '',
                        icon: '',
                        label: 'MENU@ExploreALL'
                    },
                ]
            },
            rightMenu: [
                {
                    id: 'rm1',
                    url: '/promotions/welcome-bonus',
                    image: 'DROPDOWN_WELCOME_BONUS_NEW.png',
                    label: 'PROMOTIONSTITLE@WELCOME',
                    authkey: "showWelcomeBonus"
                },
                {
                    id: 'rm2',
                    url: '/promotions/refill-bonus',
                    image: 'DROPDOWN_REFILLBONUS_NEW.png',
                    label: 'PROMOTIONSTITLE@REFILL',
                    authkey: "showRefillBonus"
                },
                {
                    id: 'rm3',
                    url: '/promotions/cashback-bonus',
                    image: 'DROPDOWN_CASHBACKBONUS_NEW.png',
                    label: 'PROMOTIONSTITLE@CASHBACK',
                    authkey: "showCashbackBonus"
                }
            ]
        },
    }
}
