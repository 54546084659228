import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Intl from 'src/components/common/Intl';
import config from 'src/config/config';
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';
import { theme } from 'styled-tools';
import { FlagsWrapper } from '../flags/flags';
import { MainInfoWrapper as LoyaltyMainInfoWrapper, Tittle as RulesTittle } from '../udb/commonStyle';
import LoyaltyComponent from './LoyaltyComponent';
import {SRTM} from 'src/util/sportradarEvent';
import SEOContents from '../SEOContents';

function LoyaltyInnerPage(props) {
  const { app: {
    brandId
  }, theme } = props

  useEffect(()=>{
    SRTM.init(brandId)
  },[]);

  return (
    <MainInfoWrapper>
      <SEOContents pageName="loyalty"/>
      <BannerWrapper>
        <img src={`${config.imageV3Host}/static/cashsite/${theme.image.loyaltyBannerImage}`} alt="Banner Image" />
      </BannerWrapper>
      <LoyaltyComponent />
      <RulesWrapper>
        <Rules>
          <Tittle>
            <Intl langKey="LOYALTY@RULESREGULATIONS" />
          </Tittle>

          <RulesText>
             <Intl langKey="LOYALTY@RULESTEXT1" />          
          </RulesText>
          <RulesText><Intl langKey="LOYALTY@RULESTEXT2" /></RulesText>
          <RulesText>
          <Intl langKey="LOYALTY@RULESTEXT3" /></RulesText>
          <RulesText>
          <Intl langKey="LOYALTY@RULESTEXT4" /> <br />
          <Intl langKey="LOYALTY@RULESTEXT5" />
          </RulesText>
          <RulesText>
          <Intl langKey="LOYALTY@RULESTEXT6" />  <br />
          <Intl langKey="LOYALTY@RULESTEXT7" />  <br />
          <Intl langKey="LOYALTY@RULESTEXT8" /> 
          </RulesText>
        </Rules>

        <Link className="loginSignup_logo" to="/">
          <img src={brandId ==33 || brandId ==34 ?`${config.imageV3Host}/static/cashsite/${theme.image.new_whitelable_logo_betdaily}`:`${config.imageV3Host}/static/cashsite/${theme.image.new_whitelable_logo}`} alt="Logo" />
        </Link>
      </RulesWrapper>
    </MainInfoWrapper>
  )
}

export default withTheme(withAppContext(LoyaltyInnerPage));

//Styled components
const RulesText = styled.div`
    flex: 1;
    color: #fff;
    text-transform: capitalize;
    text-align: center;
    padding: 15px;
    font-weight: 400;
    font-size: 15px;
`;

const Tittle = styled(RulesTittle)`
    flex: 1;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
`;
const RulesWrapper = styled.div`
    width: 100%;
    display: flex;
    padding: 2em;
    flex-direction: column;
    align-items: flex-end;
    gap: 20px;

    .loginSignup_logo {
        & img {
            width: 250px;
            opacity: 0.5;
        }
    }
`;

const Rules = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    background: #000000;
    border: 2px solid #939BAB;
    border-radius: 5px;
    padding: 2em;
`;

const BannerWrapper = styled.div`
    width: 100%;
    display: flex;

    img {
        max-width: 100%;
    }
`;

export const MainInfoWrapper = styled(LoyaltyMainInfoWrapper)`
    background-image: url(${config.imageV3Host}/static/cashsite/${theme('image.homeExchangeBgImage')});
    background-size: cover;
	background-repeat: no-repeat;
	background-position: top right;

    ${FlagsWrapper} {
        background-image: none;
        padding-bottom: 0;
    }
`;