import React from 'react'
import styled from 'styled-components';
import { Tittle } from './udb/commonStyle'
import Intl from './common/Intl';
import BackBtn from "../images/back-btn.svg"
import { useNavigate } from 'react-router-dom';
import Breadcrumb from './udb/Breadcrumb';
import BackBtnRed from './icon/backBtn';
import { withAppContext } from 'src/store/initAppContext';

interface props {
  title: string,
  link?: string,
  app: {
    brandId: any
  }
}


const UDBHeader = (props) => {

  const { app: { brandId }, link, title } = props;
  
  const navigate = useNavigate();
  const goBack = () => {
    if (link) {
      navigate(link)
    }
    else {
      navigate(-1)
    }
  }
  return (
    <>
      <HeaderTitle>
        {brandId ==33 || brandId==34 ?<div onClick={goBack}><BackBtnRed /></div>:<img src={BackBtn} alt="BackBtn" onClick={goBack} />}
        <Tittle><Intl langKey={title} /></Tittle>
      </HeaderTitle>
      <Breadcrumb />
    </>
  )
}

export default withAppContext(UDBHeader) 

const HeaderTitle = styled.div`
  display: flex;
  align-items: center;

  & img {
    width: 30px;
    height: 30px;
    object-fit: none;
    margin-right: 10px;
    cursor: pointer;
    border-radius: 70px;
  }

  & svg {
    margin-right: 10px;
    cursor: pointer;
  }
`