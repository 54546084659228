import React from 'react'

type Props = {}

const BackBtnRed= (props: Props) => {
  return (
    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_b_74_1035)">
<path d="M0.68457 10.2715C0.68457 4.74864 5.16172 0.271484 10.6846 0.271484H12.6846C18.2074 0.271484 22.6846 4.74864 22.6846 10.2715V12.1734C22.6846 17.6962 18.2074 22.1734 12.6846 22.1734H10.6846C5.16172 22.1734 0.68457 17.6962 0.68457 12.1734V10.2715Z" fill="url(#paint0_linear_74_1035)"/>
<path d="M0.68457 10.2715C0.68457 4.74864 5.16172 0.271484 10.6846 0.271484H12.6846C18.2074 0.271484 22.6846 4.74864 22.6846 10.2715V12.1734C22.6846 17.6962 18.2074 22.1734 12.6846 22.1734H10.6846C5.16172 22.1734 0.68457 17.6962 0.68457 12.1734V10.2715Z" fill="url(#paint1_linear_74_1035)"/>
<path d="M0.68457 10.2715C0.68457 4.74864 5.16172 0.271484 10.6846 0.271484H12.6846C18.2074 0.271484 22.6846 4.74864 22.6846 10.2715V12.1734C22.6846 17.6962 18.2074 22.1734 12.6846 22.1734H10.6846C5.16172 22.1734 0.68457 17.6962 0.68457 12.1734V10.2715Z" fill="#B21318"/>
</g>
<path d="M13.0392 14.2569L10.3301 11.2227L13.0392 8.18848" stroke="white" stroke-width="0.866916" stroke-linecap="round" stroke-linejoin="round"/>
<defs>
<filter id="filter0_b_74_1035" x="-4149.32" y="-4149.73" width="8322" height="8321.9" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feGaussianBlur in="BackgroundImageFix" stdDeviation="2075"/>
<feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_74_1035"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_74_1035" result="shape"/>
</filter>
<linearGradient id="paint0_linear_74_1035" x1="0.68457" y1="0.271484" x2="22.2564" y2="1.37579" gradientUnits="userSpaceOnUse">
<stop stop-color="#6F6DE6"/>
<stop offset="0.489583" stop-color="#5451D4"/>
<stop offset="1" stop-color="#312DB9"/>
</linearGradient>
<linearGradient id="paint1_linear_74_1035" x1="11.6846" y1="0.271484" x2="11.6846" y2="22.1734" gradientUnits="userSpaceOnUse">
<stop stop-color="#B21318"/>
<stop offset="1" stop-color="#7D0204"/>
</linearGradient>
</defs>
</svg>

  )
}

export default BackBtnRed;