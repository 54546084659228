import React, { Component, useEffect, useState } from 'react';
import styled, { withTheme } from 'styled-components';
import { ifProp, theme, withProp } from 'styled-tools';
import { Link } from "react-router-dom";
import { color } from '../../../../sass-files/variable';
import { useParams, useLocation } from "react-router-dom";
import Intl from '../../../common/Intl';
// import Sub2 from 'src/components/top-games/index'

// images
import Cricket from '../../../../images/cricket_icon.png';
import Football from '../../../../images/football_icon.png';
import Tennis from '../../../../images/tennis_icon.png';
// import BasketBall from '../../../../images/basketball_icon.png';
// import Hundred from '../../../../images/hundred.png';
import SpecialmarketNewIcon from '../../../../images/SpecialMarket.png';
// import PSL from '../../../../images/PSL-Mega-Menu.png';
// import IplImage from 'src/images/iplCupIcon.png';
import InplayLogo from "../../../../images/infinite1.png";
// import BigBashIcon from 'src/images/bigbashicon.png';
import config from 'src/config/config';
import { withAppContext } from 'src/store/initAppContext';
import {SRTM} from 'src/util/sportradarEvent';
// import MenuSidebar from 'src/components/home-exchange/menu-sidebar';


const { secondarycolor } = color


const ExchangeTopGames = (props: any) => {
  const { theme, app: { brandId } } = props

  const VolleyBall = `${'/static/media/volleyballicon.png'}`

 
  useEffect(() => {
    window.scrollTo(0, 0);
    SRTM.init(brandId)
  },[])


  let location = useLocation()
  let { pathname } = location;

  return (
    <Container theme={props.app.theme} style={props.theme.palette}>

      <WrapperOne>
        <div className="gamesleft">

          <Link to="/betting-exchange/:0">
            <SubTab className={pathname.includes('/betting-exchange/:0') && 'active'}>
              <div className="unskewtab">
                <img src={`${config.imageS3Host}${InplayLogo}`} alt="InplayLogo" />
                <p><Intl langKey="LOGIN@INPLAYGAMES" /></p>
              </div>
            </SubTab>
          </Link>

          <Link to="/betting-exchange/online-cricket-betting">
            <SubTab className={pathname.includes('/betting-exchange/online-cricket-betting') ? 'active' : null}>
              <div className="unskewtab">
                <img src={`${config.imageS3Host}${Cricket}`} alt="Cricket" />
                <p><Intl langKey="SPORT@CRICKET" /></p>
              </div>
            </SubTab>
          </Link>

          <Link to="/betting-exchange/football-betting">
            <SubTab className={pathname.includes('/betting-exchange/football-betting') ? 'active' : null}>
              <div className="unskewtab">
                <img src={`${config.imageS3Host}${Football}`} alt="Football" />
                <p><Intl langKey="SPORT@FOOTBALL" /></p>
              </div>
            </SubTab>
          </Link>

          <Link to="/betting-exchange/tennis-betting">
            <SubTab className={pathname.includes('/betting-exchange/tennis-betting') ? 'active' : null}>
              <div className="unskewtab">
                <img src={`${config.imageS3Host}${Tennis}`} alt="Tennis" />
                <p><Intl langKey="SPORT@TENNIS" /></p>
              </div>
            </SubTab>
          </Link>

          <Link to="/betting-exchange/volleyball-betting">
            <SubTab className={pathname.includes('/betting-exchange/volleyball-betting') ? 'active' : null}>
              <div className="unskewtab">
                <img src={`${config.imageS3Host}${VolleyBall}`} alt="VolleyBall" />
                <p><Intl langKey="SPORT@VolleyBall" /></p>
              </div>
            </SubTab>
          </Link>

          
          {/* <Link to="/specialMarket">
            <SubTab className={pathname.includes("/specialMarket") || pathname.includes("market/100/-1/256388/1") || pathname.includes("market/100/-1/244539/1") ? 'active' : null}>
              <div className="unskewtab">
                <img src={SpecialmarketNewIcon} />
                <p><Intl langKey="TITLE@SPECIALEVENT" /></p>
              </div>
            </SubTab>
          </Link>  */}

        <Link to="/specialMarket">
            <SubTab className={pathname.includes("/specialMarket") || pathname.includes("market/100/-1/336494/1")||pathname.includes("market/100/-1/353084/1") ? 'active' : null}>
              <div className="unskewtab">
                <img src={SpecialmarketNewIcon} alt="SPECIALEVENT" />
                <p><Intl langKey="TITLE@SPECIALEVENT" /></p>
              </div>
            </SubTab>
          </Link>

     {/* <Link to="/BTI-Sports/BTI-Basketball">
            <SubTab>
              <div className="unskewtab">
                <img src={BasketBall} />
                <p><Intl langKey="SPORT@BASKETBALL" /></p>
              </div>
            </SubTab>
          </Link>  */}
{/* 
          <Link to="/sports/:6">
           <Link to='/specialMarket'> 
               <SubTab  className={pathname.includes('/specialMarket')?'active':null}>
                <div className="unskewtab">
                    <img src={SpecialmarketNewIcon}/>
                    <p><Intl langKey="TITLE@POLITICS" /></p>
                </div>
                </SubTab>
                </Link>  */}

          {/* <Link to='/market/100/-1/23624/1'> 
               <SubTab  className={pathname.includes('/market/100/-1/23624/1')?'active':null}>
                <div className="unskewtab">
                    <img src={PSL}/>
                    <p><Intl langKey="TITLE@PSL" /></p>
                </div>
                </SubTab>
                </Link> */}

          {/* <Link to='/market/100/-1/41195/1'>
            <SubTab className={pathname.includes('/market/100/-1/41195/1') ? 'active' : null}>
              <div className="unskewtab">
                <img src={IplImage} />
                <p><Intl langKey="SUBMENU@IPLCUP2022" /></p>
              </div>
            </SubTab>
          </Link> */}
        </div>
      </WrapperOne>
    </Container>
  )
};
export default withTheme(withAppContext(ExchangeTopGames));


const WrapperOne = styled.div`

width:100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  overflow: hidden;
  padding-bottom: 5px;

  .gamesleft {
      // width: 84%;
      width: auto;
      display: flex;
      align-items: center;
      flex-direction: row;
      /* background: ${theme('palette.gamesleft-background')}; */
      /* box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.17); */
      transform: skew(340deg);
      border-radius: 0px 10px 10px 0px;
      padding-left: 20px;
  }

  .active {
    background: ${theme('palette.topGamesSectionGamesBgHover')};
      p{
        color: ${theme('palette.gamesleft-text-color')};
      }
    }
`;

const SubTab = styled.div`
    width: 195px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 56px;
    transform: skew(-319deg);
    /* border-left: 1px solid ${theme('palette.primary-menu-color')}; */
    background: ${theme('palette.topGamesSectionGamesBg')};
    border: ${theme('palette.topGamesSectionGamesBorder')};
    border-radius: 10px;
    margin-right: 14px;
    border:0.5px solid white;

  :hover{
      background: ${theme('palette.topGamesSectionGamesBgHover')};
      transition: 0.3s ease-in;
      cursor: pointer;
  }

  :hover p {
    color: ${theme('palette.gamesleft-text-color')};
    font-weight: bold;
  }


  img{
    width: 30px;
  }

  p{
    font-size: 15px;
    color: ${theme('palette.gamesleft-text-color')};
    margin:0px;
    padding-left: 5px;
  }


  .unskewtab {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: row;
    height: 50px;
    transform: skew(334deg);
  }
`;

const Container = styled.div`
    width:100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    /* background: ${(props: any) => (props.theme === 'Dark' ? props.style['marketBGcolor'] : 'white')}; */
    overflow: hidden;
    padding-top: 5px;
`;
