import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useLocation } from "react-router-dom";
import styled, { withTheme } from 'styled-components';
// import Logo from '../../images/logo.gif';
import Intl from '../common/Intl';
//import Logo from '../../images/i_exch_Logo-client_GIF.gif';
import { theme } from 'styled-tools';
// import Logo from '../../images/Desktop_landsacape.png';
import { color } from '../../sass-files/variable';
// import Search from '../search/index';
import LoginSignup from '../login-signup/index';
// import Language from '../Lang-Selector/index'
import AccountInfo from './AccountInfo';
// import HeaderMenu from './header-menu';
// import Clock from "../Clock/index";
// import { motion } from 'framer-motion'
import cookieUtil from 'src/util/cookieUtil'
import { ECookieName } from 'src/util/utilModel'
import Marquee from "react-fast-marquee";
// import Speaker from '../../images/speaker-red_blue.png'

import { withAppContext } from '../../store/initAppContext'
// import Modal from '../Modal';
// import CasinoGames from '../eu-content/casino-page/casino-slider-Games';
import { IconWrapper } from '../common/PNGIcon';
import config from 'src/config/config';
import NewHeaderMenu from './NewHeaderMenu';
import GlobalModal from "src/components/GlobalModal";
// import DepositBtn from "src/components/icon/depositpopupBtn";
// import DepositSVG from "src/components/icon/depositBtnNew"
import { SvgIcon, Tittle } from '../udb/commonStyle';
import NotificationIcon from '../icon/NotificationIcon';
import { notificationCount } from 'src/config/api';
import { Notify } from '../Notification';
import { ONE_CLICK_RECYCLE } from 'src/graphql/system';
import { useMutation } from '@apollo/client'
import AgentChangePassword from '../udb/AgentChangePassword';
import { BackButton, TextHeadVertical } from '../Auth/commonStyles';
import Yolologo from '../icon/yolologo';
import { KnowmoreBTN as AppDwnldKnwMore } from '../Bonus';
import { WhatsAppBeforeLogin } from '../WhatsappIco';
import { Helmet } from "react-helmet";
import { getUserTime } from 'src/components/TimerCounter';
import TagManager from 'react-gtm-module';
import mixpanel from 'mixpanel-browser';

const { menucolor } = color
const casinoInfo = {
    gameCode: 'EVOLUTION-moneywheel-MOWDream00000001',
    platformCode: 'WACS'
}


function Header(props) {
    const { app: {
        brandId
    }, theme } = props;


    const { config: {
        showAffiliate = true,
        showAppDownload = true,
        showPromotions = true,
        showBellIcon,
        showDBDeposit,
        showHeaderMarquee,
        showWhatsAppBeforeLogin,
        GTMID,
        showKnowmore
    } } = theme;
    // const [selectedGame, setSelectedGame] = useState([]);
    const isAuth = cookieUtil.get(ECookieName.COOKIE_TOKEN);
    const [isShown, setIsShown] = useState(true);
    const [navType, setNavtype] = useState('');
    const [showDepositMsg, setShowDepositMsg] = useState(false);
    const [showWelcomeMsg, setShowWelcomeMsg] = useState(false);
    const [ipl400PercentPopup, setipl400PercentPopup] = useState(false);
    const [showChagePasswordPopup, setShowChagePasswordPopup] = useState(false)
    const [showNotificationAnimation, setShowNotificationAnimation] = useState(true)
    const [minutes, setMinutes] = useState(0);
    const [seconds, setseconds] = useState(0);
    const [count, setCount] = useState("")
    const [currentNavState, setCurrentNavState] = useState({
        first: true,
        fd: true
    })
    // const [userCreatedAt, setUserCreatedAt] = useState('')

    const navigate = useNavigate();
    const { pathname, state } = useLocation();


    //Adding onclick recycle
    const [triggerOneClickRecycle, { recycleLoading, recycleError, recycleData }]: any = useMutation(ONE_CLICK_RECYCLE);



    useEffect(() => {
        //adding GTM Code
        try {
            const tagManagerArgs = {
                gtmId: GTMID
            }
            TagManager.initialize(tagManagerArgs)

        } catch (error) {
            console.log('Google tag manager initialization issue', error);
        }

        if (pathname === '/' && isAuth) {
            triggerOneClickRecycle()
            .then((response) => {
                if (response) {
                    return;
                }
            })
            .catch((error) => {
                console.log("Error in one click recycle");
            });
            //for showing POPUP
            if (state) {
                const loginState: any = state;

                const { first, fd, fpwd } = loginState;


                //// showing timer popup for ipl400% bonus

                if (first === true && pathname === '/') {
                    setipl400PercentPopup(true)
                }

                if (fpwd) {
                    setShowChagePasswordPopup(fpwd)
                    setCurrentNavState({
                        first,
                        fd
                    })
                } else if (fpwd === false && fd === true) {
                    setShowDepositMsg(true);
                } else if (first) {
                    setShowWelcomeMsg(true)
                }


            }

        }
        if (isAuth) {
            unreadMessages();
        }


        const timer = setTimeout(() => {
            setShowNotificationAnimation(false)
        }, 3000);

        //ADDING Mixpanel 
        if (brandId == 31) {
            mixpanel.init('ef9a3035e3370210d40fe75df8c66e9e', { debug: false });
            mixpanel.track('Pageview');
        }
        else if (brandId == 32) {
            mixpanel.init('28a7840e69b475d81d9a8fc21ffc4646', { debug: false });
            mixpanel.track('Pageview');
        }

        return () => clearTimeout(timer);

    }, []);


    useEffect(() => {
        const created = (brandId == 32 || brandId == 34) ? sessionStorage.getItem('clubUserCreated') : sessionStorage.getItem('userCreatedsign');
        let t = setInterval(() => {
            let balanceMins: any = getUserTime(created);
            let minutesTime = Math.floor(balanceMins);
            let secondsTime = Math.floor((balanceMins - minutesTime) * 60);
            setMinutes(minutesTime);
            setseconds(secondsTime);
            if (balanceMins < 0) {
                clearInterval(t)
            }
        }, 1000)

        return () => {
            clearInterval(t)
        }
    }, []);


    const showWelcomePopups = () => {
        if (currentNavState.fd === true) {
            setShowDepositMsg(true);
        } else if (currentNavState.first) {
            setShowWelcomeMsg(true)
        }
    }


    //Additional Checkup for show popup if url is getting changed

    useEffect(() => {
        if (pathname === '/' && isAuth) {
            const isFirstTimeUser = cookieUtil.get(ECookieName.COOKIE_IS_DEFAULT_PASSWORD);

            if (isFirstTimeUser) {
                // const loginState: any = state;
                // const { fpwd } = loginState;
                setShowChagePasswordPopup(JSON.parse(isFirstTimeUser))
            }
        }

    }, [pathname]);


    const setNav = (boolean, type) => {
        setIsShown(boolean)
        setNavtype(type)
    }

    function unreadMessages() {
        notificationCount()
            .then((response) => {
                const data = response.data
                setCount(data.count)
            })
            .catch((err) => Notify.error(err))
    }


    const scrollToTop = () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }

    let Menuref = useRef(null);
    const ref = Menuref.current;

    useEffect(() => {
        const onMouseLeave = (event) => {
            if (!Menuref?.current?.contains(event.target)) {
                setIsShown(false);
            }
        }
        document.addEventListener("mousedown", onMouseLeave);
    })

    const onMouseLeave = () => {
        if (ref) {
            setIsShown(false);
        }
    }
    const depositNowClickHandler = () => {
        setShowDepositMsg(false);
        setipl400PercentPopup(false)
        navigate('/member/finance/deposit');
    }

    const welcomemsgClickHandler = () => {
        setShowWelcomeMsg(false);
        navigate('/member/finance/deposit');
    }

    const ChagePasswordCloseHandler = () => {
        setShowChagePasswordPopup(false)
    }



    return (
        <HeaderTopWrapper>
            <Helmet>
            {(brandId == 33 || brandId == 34) ? (<link rel="icon" id="favicon" href="https://v3-files.raksahb.com/static/media/betdailyfavicon.png" />) :(<link rel="icon" id="favicon" href="https://opt-v3-files.raksahb.com/static/mobile/brand/31/icon/faviconyolo247.png" />) }
            </Helmet>
            <HeaderTop theme={props.app.theme} customStyle={props.theme.palette}>
                <Container ref={Menuref} onMouseLeave={onMouseLeave}>
                    <HeaderLeft>
                        {brandId === 34 || brandId === 33 ? <Link onClick={scrollToTop} className="betdaily" to="/">
                            {<img src={`${config.imageV3Host}/static/cashsite/${theme.image.new_whitelable_logo_betdaily}`} alt="logo" className='betdaily' />}
                        </Link> : <Link onClick={scrollToTop} className="logo" to="/">
                            {<img src={`${config.imageV3Host}/static/cashsite/${theme.image.new_whitelable_logo}`} alt="Logo" />}
                        </Link>}

                        <Nav theme={props.app.theme} customStyle={props.theme.palette}>
                            <ul>
                               <li className="active"
                                    onMouseEnter={() => setNav(true, 'casino')}
                                    onClick={() => setNav(true, 'casino')}
                                // onMouseLeave={() => setIsShown(true)}
                                >
                                    {/* <Link className="casino_menu" to={{ pathname: '/casino', query: { ...casinoInfo } }}>
                                    Casino
                                </Link> */}
                                    <Link className="casino_menu" to="#">
                                        <Intl langKey="TITLE@CASINO" />
                                    </Link>
                                </li>
                                <li onMouseEnter={() => setNav(true, 'instant_games')} onClick={() => setNav(true, 'instant_games')}><Link className="sports_menu instantGames-new" to="#"><Intl langKey="TITLE@InstantGames" /></Link></li>
                                <li onMouseEnter={() => setNav(true, 'hotgames')}><Link className="hotgames" to="#" onClick={() => setNav(true, 'hotgames')}><Intl langKey="TITLE@HOTGAMES" /></Link></li>

                                <li onMouseEnter={() => setNav(true, 'Sports')} onClick={() => setNav(true, 'Sports')}><Link className="sports_menu" to="#"><Intl langKey="TITLE@SPORT" /></Link></li>

                              

                                {/* <li className='extraWidth' onMouseEnter={() => setNav(true, 'virtual_sports')} onClick={() => setNav(true, 'virtual_sports')}><Link className="sports_menu" to="#"><Intl langKey="TITLE@VIRTUALSPORTS" /></Link></li> */}


                                {showPromotions && <li onMouseEnter={() => setNav(true, 'promotions')} onClick={() => setNav(true, 'promotions')}><Link className="sports_menu" to="#"><Intl langKey="TITLE@Bonus" /></Link></li>}
                                {/* <li onMouseEnter={() => setNav(true, 'BigBash')} onClick={() => setNav(true, 'BigBash')}><Link className="sports_menu" to="#"><Intl langKey="TITLE@BIGBASH" /></Link></li> */}
                            </ul>
                        </Nav>
                    </HeaderLeft>
                    <HeaderRight >

                        {!isAuth && showWhatsAppBeforeLogin && <WhatsAppBeforeLogin />}


                        {isAuth && showBellIcon &&
                            <Link to='/member/message'>
                                <SvgIcons>
                                    {showNotificationAnimation ?
                                        <img src={`${config.imageV3NonOptHost}/static/cashsite/${theme.image.NotifcationAnimation}`} alt="NotifcationAnimation" /> :
                                        <>
                                            <NotificationIcon />
                                            <NotificationCount>{count}</NotificationCount>
                                        </>
                                    }
                                </SvgIcons>
                            </Link>
                        }


                        {
                            isAuth && showDBDeposit ? <DepositHeader><Link id="deposit_headerbutton" to='/member/finance/deposit'><Intl langKey="LOGIN@DEPOSIT" /></Link></DepositHeader> : ''
                        }

                        {
                            isAuth ? <AccountInfo /> : <LoginSignup />
                        }

                        {/* <Language /> */}

                        {showAppDownload && <AppDwnldWrapper>
                            {brandId == 33 || brandId == 34 ? <img src={`${config.imageV3Host}/static/media/betDailyApp.png`} /> : <img src={`${config.imageV3Host}/static/media/appImage.png`} />}
                            <AppDownloadWrapp>
                                <img src={`${config.imageV3Host}/static/cashsite/${theme.image.AppQrCode}`} />
                                <h4>
                                    <Intl langKey="APP@SCANQR" />
                                </h4>

                                {showKnowmore &&
                                    
                                    <KnowmoreBTN onClick={() => navigate('/app-download')}><Intl langKey="Aviator@KnowMore" /></KnowmoreBTN>}
                            </AppDownloadWrapp>
                        </AppDwnldWrapper>
                        }
                        {!showAppDownload && <div style={{ width: '5px' }}></div>}
                    </HeaderRight>

                    {isShown && (
                        <div className='slide-bottom'>
                            <div className="show_menu"
                                //  onClick={() => setNav(true,'casino')}
                                onClick={() => { setIsShown(false) }}
                                onMouseLeave={() => setIsShown(false)}
                            >
                                {/* <HeaderMenu type={navType} /> */}
                                <NewHeaderMenu type={navType} />
                            </div>
                        </div>
                    )}
                </Container>

                {showHeaderMarquee &&
                    <MarqueeWrapper theme={props.app.theme} customStyle={props.theme.palette}>
                        <MarqueeIconWrapper>
                            {brandId == 33 || brandId == 34 ? <img src={`${config.imageS3Host}/static/snk/${theme.image.speakerIcon}`} /> : <img src={`${config.imageS3Host}/static/snk/${theme.image.speakerIconblack}`} />}
                        </MarqueeIconWrapper>
                        <Marquee speed={100}>
                            <>{brandId == 33 || brandId == 34 ? <Intl langKey="TITLE@betdailyMarquee" /> : (<><Intl langKey="TITLE@MARQUE" /><Yolologo /></>)}</> <SpacingWrapper><Intl langKey="TITLE@MARQUE1" /></SpacingWrapper>
                        </Marquee>
                    </MarqueeWrapper>}

                {showDepositMsg && <GlobalModal isModalOpen={showDepositMsg}>
                    <PopUpcontainer>
                        <button
                            className="close"
                            type="button"
                            onClick={() => setShowDepositMsg(false)}
                        ></button>
                        <PopUpActionDiv onClick={depositNowClickHandler}>

                            {/* <SvgWrap>
                            <DepositBtn />
                        </SvgWrap> */}
                        </PopUpActionDiv>
                    </PopUpcontainer>
                </GlobalModal>}

                {minutes > 0 && ipl400PercentPopup &&
                    <GlobalModal isModalOpen={ipl400PercentPopup}>
                    <IplPopUpWrap>
                        <button
                            className="close"
                            type="button"
                            onClick={() => setipl400PercentPopup(false)}
                        ></button>
                        <PopUpActionDiv onClick={depositNowClickHandler} style={{ position: 'absolute', bottom: "0px" }}>
                            <TimerDiv>
                           OFFER ENDS IN <span> 00:{minutes}:{seconds < 10 ? '0' : ''}{seconds}</span> MINUTES
                            </TimerDiv>
                        </PopUpActionDiv>
                    </IplPopUpWrap>
                </GlobalModal>
                 } 

            {showChagePasswordPopup &&
                <GlobalModal isModalOpen={showChagePasswordPopup} >
                    <ChangePasswordContainer>
                        {/* <button
                            className="close"
                            type="button"
                            onClick={() => setShowChagePasswordPopup(false)}
                        ></button> */}
                            <PopUpActionDivAgent>
                                <Link className="loginSignup_logo" to="/">
                                    {brandId == 34 || brandId == 33 ? <img src={`${config.imageV3Host}/static/cashsite/${theme.image.new_whitelable_logo_betdaily}`} className="betD" /> : <img src={`${config.imageV3Host}/static/cashsite/${theme.image.new_whitelable_logo}`} alt="Logo" />}
                                </Link>
                                <TextHeadVertical>
                                    <Tittle>
                                        <Intl langKey="CHANGE_PASSWORD@SUBMIT" />
                                    </Tittle>
                                </TextHeadVertical>
                                <AgentChangePassword userState={showWelcomePopups} onClose={ChagePasswordCloseHandler} />
                            </PopUpActionDivAgent>
                        </ChangePasswordContainer>
                    </GlobalModal>}

            </HeaderTop>

        </HeaderTopWrapper>
    )
}

export default withTheme(withAppContext(Header));

//Styled Components

const SpacingWrapper=styled.div`
padding-right: 7em;
`

const KnowmoreBTN = styled(AppDwnldKnwMore)`
    padding: 7px 20px;
    border-radius: 15px;
    font-weight: bold;
    text-transform: capitalize;
`;

const SvgWrap = styled.div`
    margin: 0;
    padding: 0;
    position: absolute;
    // left: 5%;
    // bottom: 6%;
    // cursor: pointer;
    left: 10px;
    bottom: 78px;
    cursor: pointer;
    height: 45px;
  
`

export const PopUpActionDiv = styled.div`
    width: 100%;
    height: 100%;
    cursor: pointer;
`


export const PopUpcontainer: any = styled.div`
    height: 608px;
    width: 684px;
    position: relative;
    background-image: url(${config.imageV3Host}/static/cashsite/${theme('image.firstTimeloginPopup')});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;

    .close {
        width: 34px;
        height: 34px;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: transparent;
        cursor: pointer;
        border: none;
        display: inline-block;
        margin: 0;
        padding: 0;
        position: absolute;
        right: -7px;
        top: -8px;
        background-image: url(${config.imageV3Host}/static/cashsite/${theme('image.closeIcon')});
        border-radius: 5px;
        cursor: pointer;
        z-index: 1;
    }

`
const PopUpcontainerDeposit = styled(PopUpcontainer)`
background-image: url(${config.imageV3Host}/static/cashsite/${theme('image.firstTimeDepositpopup')});
background-repeat: no-repeat;
background-size: contain;
background-position: center;
height:605px;
width: 705px;
.close {
    right: 14px;
}
.svg{
    left: 41%;
    bottom: 84px;
}
`
export const IplPopUpWrap = styled(PopUpcontainer)`
    background-image: url(${config.imageV3Host}/static/cashsite/${theme('image.ipl400percentBonus')});
    .close {
    top: 91px;
}
`

const AppDownloadWrapp = styled.div`
  display: none;

  h4 {
    color: ${theme('palette.AppDownloadWrappScannerText')};
    font-size: 14px;
    font-weight: bold;
    margin: 0;
    text-align: center;
  }
`;

const AppDwnldWrapper = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 14px;
    background: ${theme('palette.AppDwnldWrapperHeaderBg')};
    position: relative;
    padding-right: 10px;

        p {
            margin: 0;
        }

        > img {
            width: 83px;
            z-index: 2;
        }

        :before {
            content: "";
            width: 27px;
            height: 100%;
            position: absolute;
            left: -14px;
            background: ${theme('palette.AppDwnldWrapperLeftLine')};
            transform: skew(-20deg);
            z-index: 2;
            border-left: ${theme('palette.AppDwnldWrapperLeftLineBorderLeft')};
        }
        :hover {
        ${AppDownloadWrapp} {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 170px;
            padding: 10px;
            background-color: ${theme('palette.AppDownloadWrappScannerBg')};
            box-shadow: 0px -5px 8px rgb(0 0 0 / 60%);
            border: ${theme('palette.AppDownloadWrappScannerBorder')};
            border-radius: 5px;
            position: absolute;
            top:70px;
            right: 0;
            z-index: 9;
            transition: 0.5s ease;
            cursor: initial;
            text-align: center;

            img {
                width: 150px;
            }
        }
    }


`
export const TimerDiv = styled.div`
    color: white;
    font-size: 13px;
    position: absolute;
    bottom: 15%;
    right: 35%;
    span{
        color: red;
        font-size: 20px;
        font-weight: bolder;
        /* letter-spacing: 0.2rem; */
    }
`

const AffiliateHeader = styled.div`
    border-radius: 5px;
    transform: skew(340deg);
    margin-left: 20px;
    /* margin-right: 20px; */
    border: ${theme('palette.AffiliateHeaderBorder')};

    :hover {
        background: ${theme('palette.AffiliateHeaderBgHover')};
        border: ${theme('palette.AffiliateHeaderBorderHover')};
    }

    a {
        width: 80px;
        text-align: center;
        display: block;
        text-decoration: none;
        padding: 9px 0px;
        font-size: 13px;
        transform: skew(-340deg);
        color: ${theme('palette.AffiliateHeaderTextColor')};
        text-transform: uppercase;
        background-color: transparent;
        border: none;
        cursor: pointer;
    }
`;

const DepositHeader = styled.div`
    background: ${theme('palette.DepositHeaderBG')};
    border-radius: 5px;
    transform: skew(340deg);
    margin-left: 10px;
    margin-right: 20px;

    a {
        width: 80px;
        text-align: center;
        display: block;
        text-decoration: none;
        padding: 10px 0px;
        font-size: 13px;
        transform: skew(-340deg);
        color: ${theme('palette.DepositHeaderTextColor')};
        text-transform: uppercase;
        background-color: transparent;
        border: none;
        cursor: pointer;
    }

    :hover {
        background: ${theme('palette.DepositHeaderBgHover')};
    }
`;

const HeaderTop: any = styled.div`
    width: 100%;
    height: 70px;
    background: ${(props: any) => props.customStyle['headerBgColor']};
    position: fixed;
    top: 0;
    z-index: 999;
    border-bottom: 1px solid ${(props: any) => props.customStyle['headerBgBorderBtm']};

    .slide-bottom {
        z-index:99
    }

`;
const HeaderTopWrapper = styled.div`
    width: 100%;
    height: 70px;
    position: sticky;
    top: 0;
    z-index: 998;   

`
const Container = styled.div`
    width: 100%;
    height: 100%;
    margin: 0;
    // padding: 12px 10px;
    padding: 0px;
    padding-left: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    transition: all 0.3s ease-in-out 0s;
    // height:100px;
    flex-wrap:wrap;

    .show_menu {
        width: 100%;
        height: 418px;
        display: flex;
        align-items: center;
        justify-content: center;
        /* position: absolute; */
        // top: 63px;
        // top: 67px;
        left: 0;
        visibility: visible;
        // transition: all 0.5s
        transition: .5s ease;
        z-index: 99999 !important;
    }

    .slide-bottom {
        width: 100%;
        -webkit-animation: slide-bottom 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        animation: slide-bottom 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        position: absolute;
        top: -31px;
        /* top: -85px; */
        left: 0;
        right: 0;
    }

    @-webkit-keyframes slide-bottom {
        0% {
            -webkit-transform: translateY(0);
            transform: translateY(0);
        }
        100% {
            -webkit-transform: translateY(100px);
            transform: translateY(100px);
        }
    }
    @keyframes slide-bottom {
        0% {
            -webkit-transform: translateY(0);
            transform: translateY(0);
        }
        100% {
            -webkit-transform: translateY(100px);
            transform: translateY(100px);
        }
    }

    // .casino_menu:hover > .show_menu {
    //         width: 100%;
    //         display: block;
    //         position: absolute;
    //         bottom: 0;
    // }

    .logo {
        // width: 160px;
        width: 130px;
        // height: 192px;
        // position: absolute;
        // top: 0;
        // left: 0;
        z-index: 1;

        img {
            max-width: 100%;
        }
    }

    .betdaily {
        width: 206px;
        z-index: 1;
    }
    
`;
const HeaderLeft = styled.div`
    display: flex;
    align-items: center;
`;
const HeaderRight = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    position: relative;
    height: 100%;

    .appDwnldWrapper {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 14px;
        background: #202D3E;
        position: relative;
        padding-right: 10px;

        p {
            margin: 0;
        }

        > img {
            width: 83px;
            z-index: 2;
        }

        :before {
            content: "";
            width: 27px;
            height: 100%;
            position: absolute;
            left: -14px;
            background: #202D3E;
            transform: skew(-20deg);
            z-index: 2;
            border-left: 1px solid #fff;
        }
        :hover {
            ${AppDownloadWrapp} {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 170px;
            padding: 10px;
            background-color: #fff;
            box-shadow: 0px -5px 8px rgb(0 0 0 / 60%);
            border: 1px solid #78bbfd;
            border-radius: 5px;
            position: absolute;
            // right: -20px;
            // top: 30px;
            top:70px;
            right: 0;
            z-index: 9;
            transition: 0.5s ease;
            cursor: initial;

            img {
                width: 150px;
            }
            }
        }
    }
`;
const Nav: any = styled.div`
    // margin-left: 8em;

    ul {
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        list-style: none;
        margin-top: 0;
        // padding-top: 9px;
        margin-left: 40px;
        margin-bottom: 0;
    }

    li {
        transition: background 0.3s;
        transform: skew(339deg); /* SKEW */
        margin-right: 10px;
        position: relative;
        background: ${(props: any) => props.customStyle['headerMenuBg']};
        border: ${(props: any) => props.customStyle['headerMenuBorder']};
        border-radius: 5px;
        overflow: hidden;
        white-space: nowrap;

        a {
            width: 135px;
            text-align: center;
            display: block;
            text-decoration: none;
            padding: 10px 4px;
            font-size: 14px;
            transform: skew(-339deg); /* UNSKEW */
            color: ${(props: any) => (props.theme === 'Dark' ? '#FFF' : props.customStyle['headerMenuColor'])};
            font-weight: bold;
            text-transform: uppercase;

            @media only screen and (max-width: 1440px){
                width: 100px;
                font-size: 13px;
            }

            &.instantGames-new{
                min-width: 113px !important;
            }
        }

        a:hover {
            color: ${(props: any) => props.customStyle['headerMenuColorHover']};
        }

        // a:hover + li:before {
        //     background: #D7A04A;
        //     box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        // }

        &.extraWidth {
            a {
                width: auto;
                padding-left: 7px;
                padding-right: 7px;
            }
        }
    }

`;

const MarqueeWrapper: any = styled.div`
    display: flex;
    align-items: center;
    overflow: hidden;
    // width: calc(100% - 30px);
    padding-left: 50px;
    height: 30px;
    color:  ${(props: any) => (props.theme === 'Dark' ? '#f0f0f0' : props.customStyle['marqueDefault'])};
    background: ${(props: any) => (props.theme === 'Dark' ? props.customStyle[''] : props.customStyle['marqueBg'])};
    font-size:13px;
    
    .overlay {
        background: transparent;
        --gradient-color: transparent!important;
    }

`;

const MarqueeIconWrapper: any = styled.div`
	display: flex;
	flex-shrink: 0;
	position: absolute;
	// top: 85%;
	left: 18px;
	z-index: 1;
    height:19px;
	// transform: translateY(-50%);
	font-size: 1.4em;
	${IconWrapper} {
		filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.35));
	}
`;

const SvgIcons = styled(SvgIcon)`
   cursor: pointer;
   margin-right: 0px;
   position: relative;

   svg {
        width: 70px;
        height: 70px;
   }

   img {
        width: 70px;
        height: 70px;
   }
`
const NotificationCount = styled.p`
   position: absolute;
   background-color: ${theme('palette.NotificationCountBg')};
   width: 20px;
   height: 20px;
   margin: 0px;
   border-radius: 50px;
   color: ${theme('palette.NotificationCountColor')};
   top: 10px;
   right: 15px;
   font-size: 10px;
   display: flex;
   justify-content: center;
   align-items: center;
   transition: all 1s ease;
   z-index: 999;
   padding: 10px;
`

//change password popup styles
const ChangePasswordContainer = styled.div`
    height:475px;
    width: 665px;
    background: rgb(255 255 255 / 21%);  
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;


    .close {
        width: 34px;
        height: 34px;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: transparent;
        cursor: pointer;
        border: none;
        display: inline-block;
        margin: 0;
        padding: 0;
        position: absolute;
        right: -7px;
        top: -8px;
        background-image: url(${config.imageV3Host}/static/cashsite/${theme('image.closeIcon')});
        border-radius: 5px;
        cursor: pointer;
        z-index: 1;
    }
    .svg{
        left: 41%;
        bottom: 84px;
    }
`

const PopUpActionDivAgent = styled.div`
        height:400px;
        width: 605px;
        background: linear-gradient(0deg, #000000, #000000), linear-gradient(180deg, #121239 -3.33%, #151439 100%);  
        border-radius: 8px;
        display: flex;

        & ${TextHeadVertical} {
            position: absolute;
            left: -78px;
            top: 45%;
            z-index: 9999;
        }

        & ${Tittle} {
            color: #FFFFFF;
            text-transform: uppercase;
            letter-spacing: 2px;
            margin-top: -20px;
        }

        & .loginSignup_logo {

            img{
                position: absolute;
                left: 75px;
                top: 25px;
                width: 125px;
                object-fit: contain;
                z-index: 9999;
            }

            .betD {
                width:200px;
            }
        }
        
`