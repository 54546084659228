import React from 'react'

type Props = {}

const FaqRed = (props: Props) => {
  return (
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M29.3879 26.7412H37.0959C37.0581 26.6972 36.8125 26.4504 36.1324 25.8156C35.4523 25.1809 35.6349 24.4176 35.8112 24.1152L29.3879 26.7412Z" fill="#B21318"/>
<path d="M38.5555 17.8915C38.5555 22.7882 34.586 26.7577 29.6894 26.7577C24.7928 26.7577 20.8232 22.7882 20.8232 17.8915C20.8232 12.9949 24.7928 9.02539 29.6894 9.02539C34.586 9.02539 38.5555 12.9949 38.5555 17.8915Z" fill="#B21318"/>
<path d="M6.75289 34.9901H14.7498C15.2602 34.1563 16.2775 32.4524 16.263 32.3072C16.2484 32.162 11.2235 30.9763 8.7129 30.4016L7.40616 31.9808C8.24828 32.9245 8.10792 33.5719 7.93248 33.7776L6.75289 34.9901Z" fill="#B21318"/>
<path d="M18.8981 18.6875C17.5149 17.796 15.8677 17.2783 14.0999 17.2783C9.20349 17.2783 5.23389 21.2479 5.23389 26.1443C5.23389 31.0411 9.20349 35.0107 14.0999 35.0107C18.9518 35.0107 22.8937 31.1132 22.9655 26.2781C20.6711 24.4446 19.1385 21.742 18.8981 18.6875Z" fill="#B21318"/>
<rect x="8.96875" y="22.9189" width="9.2529" height="2.36788" rx="1.18394" fill="white"/>
<rect x="8.96875" y="26.998" width="9.2529" height="2.36788" rx="1.18394" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M18.8696 2.83663L17.817 0.749453L17.8168 0.749023H26.0111L24.9313 2.83663H18.8696ZM17.0364 3.63521C16.6733 2.90913 16.0261 1.62266 15.5861 0.749023H10.6035C9.92366 0.749023 9.37256 1.30012 9.37256 1.97993V16.1346C10.698 15.5977 12.1521 15.3013 13.6773 15.3013C15.578 15.3013 17.3682 15.7616 18.9367 16.5739C19.5833 11.1468 24.3225 6.93382 30.0728 6.93382C31.6019 6.93382 33.0595 7.23172 34.3877 7.77115V1.97993C34.3877 1.30012 33.8366 0.749023 33.1568 0.749023H28.1932L26.8188 3.63521C26.607 4.05869 25.9331 4.90565 24.9313 4.90565C23.9294 4.90565 20.4727 4.84516 18.8696 4.81491C18.446 4.79676 17.4865 4.53541 17.0364 3.63521ZM9.37256 37.1219V42.2319C9.37256 42.9117 9.92365 43.4628 10.6035 43.4628H33.1568C33.8366 43.4628 34.3877 42.9117 34.3877 42.2319V28.7544H30.1293L30.0728 28.7545L30.0163 28.7544H28.6463V28.6671C27.2761 28.4977 25.9831 28.0877 24.8134 27.482C24.2211 32.453 20.1952 36.4054 15.1038 37.0346V37.1219H13.7341L13.6773 37.1221L13.6204 37.1219H9.37256ZM19.5271 40.3948C19.0648 40.3948 18.69 40.7696 18.69 41.2319C18.69 41.6942 19.0648 42.0689 19.5271 42.0689H24.2351C24.6974 42.0689 25.0722 41.6942 25.0722 41.2319C25.0722 40.7696 24.6974 40.3948 24.2351 40.3948H19.5271Z" fill="white"/>
<path d="M28.3298 19.3526V19.1355C28.3298 18.6378 28.369 18.2412 28.4474 17.9456C28.5258 17.6501 28.6419 17.4133 28.7957 17.2354C28.9496 17.0544 29.1381 16.8915 29.3612 16.7468C29.5543 16.6201 29.7262 16.4979 29.877 16.3803C30.0308 16.2627 30.1515 16.1375 30.2389 16.0048C30.3294 15.8721 30.3746 15.7213 30.3746 15.5524C30.3746 15.4016 30.3384 15.2689 30.2661 15.1543C30.1937 15.0397 30.0957 14.9507 29.972 14.8874C29.8483 14.824 29.7111 14.7924 29.5603 14.7924C29.3974 14.7924 29.2466 14.8301 29.1079 14.9055C28.9722 14.9809 28.8621 15.0849 28.7776 15.2176C28.6962 15.3503 28.6555 15.5041 28.6555 15.6791H26.3392C26.3452 15.0155 26.496 14.4772 26.7916 14.064C27.0871 13.6478 27.4792 13.3431 27.9678 13.1501C28.4564 12.9541 28.9933 12.856 29.5784 12.856C30.2238 12.856 30.7999 12.9511 31.3066 13.1411C31.8133 13.3281 32.2129 13.6116 32.5055 13.9916C32.798 14.3686 32.9443 14.8406 32.9443 15.4076C32.9443 15.7726 32.881 16.0938 32.7543 16.3713C32.6307 16.6457 32.4572 16.8885 32.234 17.0996C32.0139 17.3077 31.756 17.4978 31.4604 17.6697C31.2433 17.7963 31.0608 17.9275 30.913 18.0633C30.7652 18.196 30.6536 18.3483 30.5782 18.5202C30.5028 18.6891 30.4651 18.8942 30.4651 19.1355V19.3526H28.3298ZM29.4336 22.3928C29.0838 22.3928 28.7837 22.2707 28.5333 22.0264C28.286 21.779 28.1639 21.479 28.1669 21.1261C28.1639 20.7822 28.286 20.4882 28.5333 20.2439C28.7837 19.9996 29.0838 19.8774 29.4336 19.8774C29.7654 19.8774 30.058 19.9996 30.3113 20.2439C30.5677 20.4882 30.6974 20.7822 30.7004 21.1261C30.6974 21.3613 30.6355 21.5755 30.5149 21.7685C30.3973 21.9585 30.2434 22.1108 30.0534 22.2254C29.8634 22.337 29.6568 22.3928 29.4336 22.3928Z" fill="white"/>
</svg>

  )
}

export default FaqRed