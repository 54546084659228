/* eslint-disable */
import React, { createContext, useEffect, useState } from "react";
import styled, { withTheme } from 'styled-components';
import { ifProp, theme, withProp } from 'styled-tools';
//import InPlaySection from "./in-play-desktop/index";
import PopularSection from "./popular-desktop";
import Tabs from '../common/Tab';
import InPlayLiveTv from './inplay-livetv';
//import BetSlip from "../BetSlip/index";
import BetSlip_myBet_tab from "../BetSlip/betslip-mybet_tabs";
// import { color } from '../../sass-files/variable';
import MarketList from "../eu-content/MarketList";
import FavoriteList from "../eu-content/FavoriteMarket"
import OpenMarketList from "../eu-content/OpenMarketList";

import { MatchListContext } from '../../store/MatchListContext';
import { OrderListContext } from '../../store/OrderListContext';
import cookieUtil from "src/util/cookieUtil";
import { ECookieName } from "src/util/utilModel";
import BgDark from '../../images/iMAGE1_iexch.jpg';
import Bg from '../../images/iMAGE1_iexch.png';
import config from "src/config/config";
// import Icon, { IconWrapper } from '../common/PNGIcon';
// import NewsEvent from "../eu-content/news-event";
import { useQuery } from "@apollo/client";
import { GET_EVENTS } from "src/graphql/events";
import util from "src/util/util";
// import { GET_EVENT_NEWS_CLIENT } from "src/graphql/agentMember";
import NewsEvent from "src/pages/eu-content/news-event";
// import Marquee from "react-fast-marquee";
// import Speaker from '../../images/speaker-red_blue.png';
import Intl from '../common/Intl';
// import Switch from "../eu-content/Switch";
// import { getGTMId } from "src/util/gtmUtil";
import { withAppContext } from "src/store/initAppContext";
// import Sidebar, { GameEventSubMenu } from '../eu-content/sidebar/sidebar';
// import SideNav, { Toggle, Nav, NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
// import { ButtonWrapper } from "../eu-content/sidebar/MatchButton";
// import { CategoryNameWrapper } from "../eu-content/sidebar/sidebar";
// import { CategoryIcon } from "../eu-content/sidebar/sidebar";
// import { MainButtonWrapper } from "../eu-content/sidebar/FoldButton";
// import { FootNoteWrapper } from "../common/sidebar/styles/commonSidebarCompnents";
// import { GameEventName } from "../eu-content/sidebar/sidebar";
import CricketSEO from "../SEOComponents/CricketSEO";
// import FootballSEO from "../SEOComponents/FootballSEO";
import SportsExchangeSEO from "../SEOComponents/SportsExchangeSEO";
// import MenuSidebar from "./menu-sidebar";
import BonusPopup from "../BonusPopup";
import { Circle } from "../eu-content/Switch";
import Search from 'src/components/search/index';
// import { Player } from "@lottiefiles/react-lottie-player";
// import { getWhatsAppInfo } from "src/config/api";
import { useLocation } from "react-router-dom";
import FootballSEO from "../SEOComponents/FootballSEO";
import TennisSEO from "../SEOComponents/TennisSEO";
import VollyballSEO from "../SEOComponents/volleyballSEO";
// import Mojos7 from "../SEOComponents/sevnMojos";


//import PopularMarketList from "../eu-content/PopularMarketList";
//import InnerGameDetails from '../eu-content/inner-game-details/game-details';
// const { homeExchangeBgColor } = color
// const tabName = ['In-play', 'Favorites', 'My Markets']


let Inplay = <Intl langKey="TABLE@IN_PLAY" />;
let Favorites = <Intl langKey="MENU@FAVORITES" />;
let MyMarkets = <Intl langKey="MENU@MARKET" />

const tabName = [Inplay, Favorites, MyMarkets]



const HomeExchange = (props) => {
    const { app: { brandId }, memberId = 0, theme: { config } }: any = props;
    const {showSeoFooterInsidePages,showSEOfooterForYolo}=config
    const location = useLocation();
    const getLastqueryString =  location.pathname.split("/").pop();


    // let location = useLocation()

    let { pathname } = location;

    const [isShowInPlay, SetIsShowInPlay] = useState(true);
    const [popularNewsEvent, setPopularNewsEvent] = useState([]);
    const [results, setResults] = useState([]);
    const [categoryId, setCategoryId] = useState(0);
    const [results_popular, setResults_popular] = useState([]);
    const [isDarkTheme, setIsDarkTheme] = useState(false);
    const [backgroundImage, setBackgroundImage] = useState(Bg);
    const [catId, setCatId] = useState(0)



    const { loading, error, data } = useQuery(GET_EVENTS, {
        variables: { input: { categoryId: catId, isInplay: true, singleFancy: true, isFavorite: true } }
    });

    useEffect(() => {
        const themeS = JSON.parse(localStorage.getItem("isDarkTheme")) === null ? false : JSON.parse(localStorage.getItem("isDarkTheme"));
        setIsDarkTheme(themeS)
        setBackgroundImage(cookieUtil.get(ECookieName.COOKIE_THEME) === "Dark" ? BgDark : Bg)

        if(pathname.includes('/betting-exchange/volleyball-betting')){
            setCatId(12);
        } else if (pathname.includes('/betting-exchange/online-cricket-betting')){
            setCatId(4);
        } else if (pathname.includes('/betting-exchange/tennis-betting')) {
            setCatId(2);
        } else if (pathname.includes('/betting-exchange/football-betting')) {
            setCatId(1);
        } else {
            setCatId(0);
        }
    }, [pathname])

    useEffect(() => {
       
        const marketList = util.getValue(data, ['markets', 'list'], []);
        const result = []
        const filtermarketList = marketList.map((market) => result.push(Number(market.eventId)))
        const categoryId = marketList.length > 0 && marketList[0].categoryName || ''
        const isCurrentShowInPlay = marketList.length > 0;
        isCurrentShowInPlay && categoryId !== categoryId &&

            SetIsShowInPlay(isCurrentShowInPlay),
            setResults(result),
            setCategoryId(categoryId)
    }, [])

    const toggleTheme = () => {
        let themeArray = ['Dark', 'Light', 'Default']
        let selectedIndex = !isDarkTheme
        let themedata = themeArray[selectedIndex ? 0 : 1]
        setIsDarkTheme(!isDarkTheme)
        localStorage.setItem("isDarkTheme", JSON.stringify(!isDarkTheme))
        cookieUtil.set(ECookieName.COOKIE_THEME, themeArray[selectedIndex ? 0 : 1])
        setBackgroundImage(cookieUtil.get(ECookieName.COOKIE_THEME) === "Dark" ? BgDark : Bg)

        const { setState } = props.app || {};
        if (typeof setState === 'function') {
            let _reqData = {
                theme: (cookieUtil.get(ECookieName.COOKIE_THEME) === "Dark" ? "Dark" : "Light")
            }
            setState(_reqData)
        }
    }

    return (
        <>
            <SideBarWrapperMain>
                {/* <SidebarWrapper>
                <SideNav onSelect={(selected) => {}}>
                    <SideNav.Toggle />
                    <SideNav.Nav defaultSelected="home">
                        <NavItem eventKey="home">
                            <Sidebar/>
                        </NavItem>
                    </SideNav.Nav>
                </SideNav>
           </SidebarWrapper> */}
                <SidebarWrap theme={props.app.theme}>

                    {/* <MenuSidebar/> */}

                </SidebarWrap>
               { results && results.length>0 && <NewsEvent data={results} />}
                <AppWrapper id="ExchageContainer" className="app_wrapper" theme={props.app.theme} customStyle={props.theme.image}>
                    <MatchListContext memberId={memberId}> 
                        <OrderListContext>
                            <HomepageExchangeWrapper>
                                <InPlayHeader>
                                    <InPlayLiveTv />
                                </InPlayHeader>
                                <ExchangeWrapp>
                                    <Tabs
                                        tabs={tabName}
                                        isTabsHidden={false}
                                        minWidthInContent={'initial'}
                                        render={(index) => {
                                            return (
                                                <>
                                                    {tabName[index] === Inplay && <> <MarketList />  <PopularSection /> </>}
                                                    {tabName[index] === Favorites && <FavoriteList />}
                                                    {tabName[index] === MyMarkets && <OpenMarketList />}
                                                </>
                                            );
                                        }} />
                                </ExchangeWrapp>
                                <BetSlipWrapper>
                                    <ToggleButton>
                                        <Search />

                                        {/* <span><Intl langKey="TITLE@LIGHTTHEME"/></span> */}
                                        {/* <div className="switchdiv">
                                        <Switch currentActiveState={isDarkTheme}
                                            handleClick={toggleTheme}
                                            id={getGTMId(`ExchageContainer`)} />
                                    </div> */}
                                        {/* <span><Intl langKey="TITLE@DARKTHEME"/></span> */}
                                    </ToggleButton>
                                    <BetSlipTittle><Intl langKey="TITLE@BetSlipTittle" /></BetSlipTittle>
                                    <BetSlip_myBet_tab />
                                </BetSlipWrapper>
                                <BonusPopup />
                            </HomepageExchangeWrapper>
                        </OrderListContext>
                    </MatchListContext>
                </AppWrapper>
            </SideBarWrapperMain>
            {getLastqueryString === 'online-cricket-betting' && showSeoFooterInsidePages ? <CricketSEO /> :
                (getLastqueryString === 'betting-exchange' || getLastqueryString === ':0') && (showSeoFooterInsidePages && showSEOfooterForYolo) ? <SportsExchangeSEO /> :
                 getLastqueryString === 'volleyball-betting' && showSEOfooterForYolo ? <VollyballSEO /> :
                 getLastqueryString === 'football-betting' && showSeoFooterInsidePages && showSEOfooterForYolo ? <FootballSEO /> :
                 getLastqueryString === 'tennis-betting' && showSeoFooterInsidePages && showSEOfooterForYolo ? <TennisSEO /> : <></>}
        </>
    )
}
export default withTheme(withAppContext(HomeExchange));

export const BetSlipTittle = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    color: ${theme('palette.InPlayTableBetSlipTittleColor')};
    border-bottom: ${theme('palette.InPlayTableBetSlipTittleBorder')};
    padding: 48px 10px 13px 10px;
    margin-bottom: 17px;
    font-size: 14px;
    font-weight: bold;
    background: ${theme('palette.InPlayTableBetSlipTittleBg')};
`;

const SidebarWrap = styled.div`
    display: flex;
`;

const SideBarWrapperMain = styled.div`
  display: flex;
  position: relative;
`;

export const AppWrapper: any = styled.div`
    display: flex;
    width: 100%;
    height: auto;
    /* background-image: url('${(props: any) => (props.theme === 'Dark' ? `${config.imageS3Host}/static/snk/${props.customStyle['new_whitelable_dark_background']}` : `${config.imageS3Host}/static/snk/${props.customStyle['new_whitelable_light_background']}`)}'); */
    background-image: url('${(props: any) => `${config.imageV3Host}/static/cashsite/${props.customStyle['homeExchangeBgImage']}`}');
    background-size: cover;
	background-repeat: no-repeat;
	background-position: top right;
    background-attachment:fixed;
    /* min-height: 100vh; */
    // padding-left: 4em;
`;

const HomepageExchangeWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 5px 0px 20px;
    flex-wrap: wrap;
    position: relative;
    // overflow: hidden;
    /* background-image: url{bg} */
`;

const InPlayHeader = styled.div`
    // width: 73%;
    margin-top: 5px;
    width: 250px;
    position: absolute;
    left: 0;
    top: 0;
    padding: 10px;
    margin-left: 10px;
    background: ${theme('palette.secondary-background-color-linear-new')};
    /* background: #6778E3; */

    :after {
        content: "";
        width: 45px;
        height: 101%;
        background: ${theme('palette.secondary-background-color-linear-new')};
        position: absolute;
        top: 0;
        right: -15px;
        transform: skew(18deg);
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
    }
`;

const BetSlipWrapper = styled.div`
    width: 25%;
    position: sticky;
    top: 32px;
    overflow-y: scroll;
    min-height:calc(20vh);
    max-height: calc(100vh - 2em);
    right: 0;
    overflow: hidden auto;
    scroll-behavior: smooth;
   
   ::-webkit-scrollbar {
        display: none;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
`;

export const ToggleButton = styled.div`
    color: #fff;
    width: 70%;
    font-size: 15px;
    font-weight: 600;
    /* margin-top: 5px; */
    /* width: 250px; */
    display: flex;
    flex-direction: row;
    /* justify-content: space-evenly; */
    position: absolute;
    right: 0;
    top: 0px;
    padding-left: 10px;
    /* background: ${theme('palette.secondary-background-color-new')}; */

    .switchdiv{
        padding-left: 5px;
        /* padding-left: 0px;
        padding-right: 10px;
        border-radius: 99em;
        border: 1px solid #FFFF;
        width: 46px; */

        ${Circle} {
            display: none;
        }

        button {
            width: 140px;
            height: 48px;
            border-radius: 0;
            border: none;
            margin: 0;

            &::before {
                content: 'LIGHT';
                width: 50%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                top: 0;
                right: unset;
                bottom: unset;
                border-radius: 0;
                pointer-events: none;
                background: #fff;
                left: 0;
                color: #000;
                font-size: 12px;
                font-weight: bold;

            }

            &::after {
                content: 'DARK';
                width: 50%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                top: 0;
                right: 0;
                bottom: unset;
                left: unset;
                border-radius: 0;
                pointer-events: none;
                background: #6778E3;
                font-size: 12px;
                color: #fff;
                font-weight: bold;
            }
        }
    }   

    /* :after {
        content: "";
        width: 45px;
        height: 100%;
        background: #234294;
        position: absolute;
        top: 0;
        right: 345px;
        transform: skew(340deg);
    } */
    
`;

const ExchangeWrapp = styled.div`
    width: 74%;
    padding: 40px 20px 20px 10px;
`;







