const brandDomains = [ 
	{
		domain: 'www.yolo247.com',
		id: 31,
		type: 'CLIENT',
		dev: 'PROD'
	},
	{
		domain: 'staging31.bbb365.link',
		id: 31,
		type: 'CLIENT/AGENT',
		dev: 'STAGING'
	},
	{
		domain: 'cashsite2.bbb365.link',
		id: 31,
		type: 'CLIENT/AGENT',
		dev: 'STAGING'
	},
	{
		domain: 'preprod.yolo247.com',
		id: 31,
		type: 'CLIENT',
		dev: 'PRE-PROD'
	},
	{
		domain: 'www.yolo247.club',
		id: 32,
		type: 'CLIENT',
		dev: 'PROD'
	},
	{
		domain: 'preprod.yolo247.club',
		id: 32,
		type: 'CLIENT',
		dev: 'PRE-PROD'
	},
	{
		domain: 'a-cashsite.bbb365.link',
		id: 32,
		type: 'CLIENT',
		dev: 'STAGING'
	},

    //betdaily whitelabel brandid 33
	{
		domain: 'www.baaziadda.com',
		id: 33,
		type: 'CLIENT',
		dev: 'PROD'
	},
	{
		domain: 'betdaily.bbb365.link',
		id: 33,
		type: 'CLIENT',
		dev: 'STAGING'
	},
	{
		domain: 'preprod.baaziadda.com',
		id: 33,
		type: 'CLIENT',
		dev: 'PRE-PROD'
	},
	///betdaily whitelabel brandid 31 
	{
		domain: 'www.betdaily.club',
		id: 34,
		type: 'CLIENT',
		dev: 'PROD'
	},
	{
		domain: 'club-betdaily.bbb365.link',
		id: 34,
		type: 'CLIENT',
		dev: 'STAGING'
	},
	{
		domain: 'preprod.betdaily.club',
		id: 34,
		type: 'CLIENT',
		dev: 'PRE-PROD'
	},

	//////local host//////
	
	{
		domain: 'localhost:3000',
		id: 31,
		type: 'CLIENT/AGENT',
		dev: 'DEV'
	},
];

 
export default brandDomains;