import React from 'react'
import { Helmet } from "react-helmet";
import { withAppContext } from 'src/store/initAppContext';
import { withTheme } from 'styled-components';


const structuredData = (brandId) => {
    let domainURL = 'https://www.yolo247.com';
    if (brandId === 32) {
        domainURL = 'https://www.yolo247.club';
    }

    const faqData = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [{
          "@type": "Question",
          "name": "Can I play aviatrix on Yolo247 for free?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Yes. Yolo247 offers the option to play for free in demo mode. This feature allows players to practice and become familiar with the game mechanics without the need to wager real money.."
          }
        },{
          "@type": "Question",
          "name": "Why play Yolo247 Aviatrix games?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Playing Yolo247 Aviatrix games offers a thrilling and enjoyable experience. These games are designed to be not only visually appealing but also technologically advanced, providing players with a cutting-edge gaming experience."
          }
        }]
      }
      
      return JSON.stringify(faqData);
   }

const AviatrixSEO = (props: any) => {
    const { app: { brandId }} = props

  return (
    <>
         <Helmet>
                <script type="application/ld+json">
                    {
                        structuredData(brandId)
                    }

                </script>
            </Helmet>
    </>
  )
}

export default withTheme(withAppContext(AviatrixSEO));