import React from 'react'
import { Accordion, AccordionWrapper } from '../seo-content/seo_content'
import { withAppContext } from 'src/store/initAppContext';
import { withTheme } from 'styled-components';

type Props = {
    app:any
}

const ONLINECASINOSEO = (props:Props) => {
    const { app: { brandId } } = props;

    let domainURL = 'https://www.yolo247.com';

    if (brandId === 32) {
        domainURL = 'https://www.yolo247.club';
    }
    else if (brandId === 33) {
        domainURL = 'https://www.baaziadda.com';
    }
    else if (brandId === 34) {
        domainURL = 'https://www.betdaily.club';
    }
    return (
        <>
            <AccordionWrapper>
                <Accordion>
                    <input type="checkbox" id="section1" className="accordion__input" />
                    <label htmlFor="section1" className="accordion__label"><h1>ONLINE CASINO GAME</h1></label>
                    <div className="accordion__content">
                        <h2>Play best of the Online Casino Game</h2>
                        <p>Online casino games are the ultimate entertainment experience for all gambling enthusiasts. We look at why you should bet on your favorite games on Yolo247.</p>
                        <ul>
                            <li>With a wide selection of live casino online games, from Crash to card games, wheel games, and dice games, there's something for everyone.</li>
                            <li>Yolo247's intuitive gaming interface makes it easy to navigate the game and learn the rules of each game quickly.</li>
                            <li>The platform also offers casino gaming enthusiasts a safe and secure gaming experience.</li>
                            <li>Yolo247 offers generous bonuses and rewards for its players. For instance, new players can take advantage of welcome bonuses, while experienced players benefit from reload and cashback bonuses.</li>
                            <li>The best part of playing online casino games on this platform is 24*7 customer support. The experienced team is always ready to help players with their queries through various communication modes.</li>
                        </ul>

                        <h2>Variety of Casino Games</h2>
                        <p>Before diving into the specific games, familiarize yourself with the five captivating categories on Yolo247.</p>

                        <h3>Crash Games</h3>
                        <p>Crash games, also known as Instant Games in the gaming industry, are becoming increasingly popular in recent times. These games are fast-paced and guaranteed to keep you on the edge of your seat. JetX and Aviator are the two crash games available on Yolo247.</p>
                        <ul>
                            <li>In the crash games, players must place their bets on the Jet before it starts taking off and cash out winnings before it flies away or explodes.</li>
                            <li>The game starts with a multiplier of 1x and increases gradually.</li>
                            <li>The key to success in Crash lies in understanding when to cash out winnings.</li>
                        </ul>

                        <h3>Casino Card Games</h3>
                        <p>Card games are popular among live casino players, and Yolo247 offers an exciting selection. This platform's most popular card games include Dragon Tiger, Blackjack, Andar Bahar, Hi Lo, Teenpatti, Poker, and Baccarat. Here are the top three casino cards that you can play right away for bigger winnings:</p>
                        <ul>
                            <li>Blackjack: The goal of this popular card game is to get as close to 21 without going over it or having your opponent beat your score. In Yolo247's live version of Blackjack, you can play multiple hands against dealers. You must have a hand with a higher score than the dealer's but not exceeding 21 points to win.</li>
                            <li>Poker: In Poker, six virtual players will be there at the table, and players need to bet whose combination of the card will be higher.</li>
                            <li>Baccarat: Baccarat is another favorite among casino fans around the globe. There are three main outcomes in this game: The Player wins, Banker wins, or a tie; however, there are other side bets available too.</li>
                        </ul>

                        <h3>Wheel Games</h3>
                        <p>Wheel games involve an exciting and thrilling spin that players of all levels can enjoy. Multiple wheel games are available, including Roulette, Dream Catcher, Funky Time, Crazy Time, and more.</p>
                        <p>It involves a giant spinning wheel with various sections, each representing a different outcome. Players put their wagers on where they think the wheel will come to a stop. Depending on the game and the features, such as multipliers or bonus rounds, may increase your chances of winning.</p>

                        <h3>Dice Games</h3>
                        <p>Dice games are the most played game around the globe. These games involve using one or more dice to determine the outcome of each bet. Popular dice games include Ludo, Snake & Ladder, and Sic Bo. Dice games offer the ultimate excitement and suspense for players.</p>
                        <p>Yolo247 offers an intuitive platform for playing these classic dice games, providing an immersive experience with live dealers.</p>

                        <h3>Slot Games</h3>
                        <p>Slot games involve placing bets and spinning the reels to win a jackpot. Online Slot games typically consist of multiple reels, each containing various symbols. When the reels stop spinning, the combination of symbols that appear determines your winnings.</p>
                        <p>Slot games aim to match specific symbols in a winning combination, which can vary depending on the game. Some slot games also feature bonus rounds and special features to enhance the gameplay experience. Slot games offer casino enthusiasts an exciting and thrilling gaming experience.</p>

                        <h2>How to start playing your favorite casino game on Yolo247?</h2>
                        <p>To start your journey of online casino games with Yolo247, you have to follow this simple 5-step process:</p>
                        <ol>
                            <li>Create your account on Yolo247 by providing the required information.</li>
                            <li>Proceed to log in.</li>
                            <li>Make a deposit to get started.</li>
                            <li>Choose the game you want to play.</li>
                            <li>Place your bets.</li>
                        </ol>

                        <h2>FAQs</h2>
                        <ul>
                            <li>What are Live Online Casino Games? </li>
                            <p>Live casino online games bring all the fun and excitement of a real casino</p>
                            <li>Which are some of the most popular Live Online Casino Games? </li>
                            <p> Andar Bahar, Dragon Tiger, Baccarat, Poker, and Blackjack are popular live online casino games.</p>
                        </ul>

                        <p>
                            <strong>Play Casino Games Now : </strong>
                            <a href={`${domainURL}/online-casino-games/roulette`}>Roulette</a>,&nbsp;
                            <a href={`${domainURL}/online-casino-games/blackjack`}>Blackjack</a>,&nbsp;
                            <a href={`${domainURL}/online-casino-games/baccarat`}>Baccarat</a>,&nbsp;
                            <a href={`${domainURL}/online-casino-games/dragon-tiger`}>Dragon-tiger</a>
                        </p>

                        <p>
                            <strong>Play instant casino Now : </strong>
                            <a href={`${domainURL}/instant-games/aviator`}>Aviator</a>,&nbsp;
                            <a href={`${domainURL}/instant-games/jetx`}>JetX</a>,&nbsp;
                            <a href={`${domainURL}/instant-games/plinkox`}>PlinkoX</a>,&nbsp;
                            <a href={`${domainURL}/instant-games/mines`}>Mines</a>,&nbsp;
                            <a href={`${domainURL}/instant-games/ninjacrash`}>Ninja Crash</a>,&nbsp;
                            <a href={`${domainURL}/instant-games/paperplane`}>Paperplane</a>,&nbsp;
                            <a href={`${domainURL}/instant-games/cash%20show`}>Cash Show</a>
                        </p>

                        <p>
                            <strong>Browse More casino Now : </strong>
                            <a href={`${domainURL}/online-casino-games/`}>Online Casino Games</a>,&nbsp;
                            <a href={`${domainURL}/online-casino-games/slotGames`}>Slot & Bingo casino</a>,&nbsp;
                            <a href={`${domainURL}/online-casino-games/best-casino-games`}>Popular Casino</a>
                        </p>
                    </div>
                </Accordion>
            </AccordionWrapper>
        </>
    )
}

export default withTheme( withAppContext(ONLINECASINOSEO) );