import  { useEffect, useState } from "react";
import styled, { withTheme } from "styled-components";
import {
  BodyWrapper as MessageBodyWrapper,
  FirstCol,
  Header,
  MainInfoWrapper,
  SecondCol,
  TableFooter,
} from "../../../components/udb/commonStyle";
import config from 'src/config/config';
import Intl from "../../../components/common/Intl";
import UDBHeader from "src/components/UDBHeader";
import DatePicker from "react-datepicker";
import {
  DatePickerWrapper,
  BlockPick,
  SvgIcon,
} from "src/components/udb/DatePickerUdb";
import CalenderIcon from "src/components/icon/CalenderIcon";
import Pagination from "src/components/common/Pagination";
import PromotionContainer from "src/components/udb/bonus/PromotionContainer";
import { getUserPromotions } from "src/config/api";
import { Notify } from "src/components/Notification";
import moment from "moment";
import { withAppContext } from "src/store/initAppContext";
import SEOContents from "src/components/SEOContents";


  interface Data {
    promotion_plans?: (PromotionPlansEntity)[] | null;
    total_bonus_amount: number;
  }
 interface PromotionPlansEntity {
    bonus_amount: number;
    rollover: number;
    status: string;
    turnover: string;
    claimedAt: string;
    expiredAt: string;
    promotion_type_id: number;
    title: string;
    isCancel: boolean;
    referee_name: string;
    referralPromoType: any;
    refferalDays: any;
    referralDaysPercent: any;
    dailyDepositBonus: any;
  }
  

const Promotions = (props) => {
  const { theme } = props
  const [loading, setLoading] = useState(true)
  const [startAt, setStartAt] = useState(new Date(new Date().setHours(0, 0, 0))); //Date
  const [endAt, setEndAt] = useState(new Date(new Date().setHours(23, 59, 59))); //Data
  const date = new Date();
  const minDate = date.setDate(new Date().getDate() - 90);
  const [page, setPage] = useState(1); //pagination
  const [count, setCount] = useState(0);
  const limit = 5;
  // const [percent, setPercent] = useState<number>(0);
  const [totalBonusAmount, setTotalBonusAmount] = useState<number>(0);
  const [filtredBonus, setFiltredBonus] = useState([]);

  const handleChangePageIndex = (index: number) => {
    const value = index;
    getPromotionList(false, startAt.toISOString(), endAt.toISOString(), value, limit)
  };

  const handlefromDateChange = (startAt: Date | null) => {
    setStartAt(startAt);
    getPromotionList(true, startAt.toISOString(), endAt.toISOString(), page, limit)
  };

  const handletoDateChange = (endAt: Date | null) => {
    setEndAt(endAt);
    getPromotionList(true, startAt.toISOString(), endAt.toISOString(), page, limit)
  };

  const handleDateChangeRaw = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getPromotionList(false, startAt, endAt, page, limit)
  }, []);

  const getPromotionList = (isDate, startDate, endDate, page, limit) => {
    let params = {
      isDate: isDate,
      startDate,
      endDate,
      page,
      limit
    }
    getUserPromotions(params)
      .then((response) => {
        const {data} = response.data
        setLoading(false)
        setCount(data.count)
        setTotalBonusAmount(data.total_bonus_amount);
        let promotion_plans = data.promotion_plans.map((arrayItem)=>{
          let promotionType = arrayItem.promotion_type_id;
          if(promotionType === 1 || promotionType === 2) {   
            let percentage:any = (arrayItem.turnover / arrayItem.rollover * 100).toFixed(6);
            percentage = percentage> 100 ? 100 : percentage;
             //calculating the days left to reffer
             let currentDate = new Date();
             let expiredDate = new Date(arrayItem.expiredAt);
                         
             // Calculate the time difference in milliseconds
             var totalTimeDiff = expiredDate.getTime() - currentDate.getTime();
           
             // Calculate the number of days
             var totalDaysLeft = Math.floor(totalTimeDiff / (1000 * 60 * 60 * 24));
 
             let referralDaysLeft = totalDaysLeft;
 
             let referralDaysLeftPercent = parseInt((totalDaysLeft / 90 * 100).toFixed(0));

            referralDaysLeft = referralDaysLeft < 0 ? 0 : referralDaysLeft;
            referralDaysLeftPercent = referralDaysLeftPercent < 0 ? 0 : referralDaysLeftPercent;
            return {...arrayItem,percent:percentage,refferalDays:referralDaysLeft,referralDaysPercent:referralDaysLeftPercent }           
        }
            return arrayItem;
        });
        setFiltredBonus(promotion_plans);

        if (response.data.success === false) {
          Notify.error(response.data.data.status);
        }
      })
      .catch((err) => Notify.error("Something Went Wrong, Please Try Again"));
  }   
    
  return (
    <MainInfoWrapper>
      <SEOContents pageName="memberPrormotions" />
      <Header>
        <FirstCol>
          <UDBHeader title="SIDEBAR@PROMOTIONS" />
        </FirstCol>
        <SecondCol>
          <DatePickerWrapper>
            <SvgIcon>
              <CalenderIcon />
            </SvgIcon>
            <BlockPick>
              <DatePicker
                selected={startAt}
                onChange={handlefromDateChange}
                onChangeRaw={handleDateChangeRaw}
                minDate={minDate}
                maxDate={new Date()}
                placeholderText="From"
              />
            </BlockPick>
            <BlockPick>
              <DatePicker
                selected={endAt}
                onChange={handletoDateChange}
                onChangeRaw={handleDateChangeRaw}
                minDate={minDate}
                maxDate={new Date()}
                placeholderText="To"
              />
            </BlockPick>
          </DatePickerWrapper>
        </SecondCol>
      </Header>
      <BodyWrapper>
        <PromotionHeadWrap>
           <PromotionHeadText> 
             {totalBonusAmount === 0 ? <Intl langKey="Promotions@WINBONUS" /> :
             <><Intl langKey="Promotions@TOTALAmnt" /> {Math.round(totalBonusAmount)}  <Intl langKey="Promotions@TOTALAmnts" /> </> }
            </PromotionHeadText>
          </PromotionHeadWrap>    
        <PromotionBoxWrapper>
        { loading ? <></> :
            filtredBonus.length !== 0 ?
            filtredBonus.map((bonus, index) => (
                <PromotionContainer 
                    key={index}
                    promotionStatus={bonus.status}
                    bonusAmount={Math.round(bonus.bonus_amount)}
                    rollOver={bonus.rollover}
                    claimedAt={moment(bonus.claimedAt).format('L')}
                    expiredAt={moment(bonus.expiredAt).format('L')}
                    bonusTitle={bonus.title}
                    turnover={bonus.turnover}
                    percent={bonus.percent||0}
                    isCancel={bonus.isCancel}
                    refereeName={bonus.referee_name}
                    bonusId={bonus.promotion_type_id}
                    referralPromoType={bonus.promo_type}
                    refferalDays={bonus.refferalDays}
                    referralDaysPercent={bonus.referralDaysPercent}
                    dailyDepositBonus={bonus.daily_deposit_bonus}
                />
            )) : 
            <BodyWrapper>
              <img src= {`${config.imageV3Host}/static/cashsite/${theme.image.promotionNoData}`} alt="promotionNoData"  />
            </BodyWrapper>
            }
        </PromotionBoxWrapper>  

        <TableFooter>
          <Pagination
            numberPerPage={5}
            totalLength={count}
            onClick={handleChangePageIndex}
            currentIndex={page}
          />
        </TableFooter>
      </BodyWrapper>
    </MainInfoWrapper>
  );
}

export default withTheme(withAppContext(Promotions));

const BodyWrapper = styled(MessageBodyWrapper)`
  width: 100%;
  justify-content: center;
  background: none;
  box-shadow: none;
  border-radius: 5px;
  padding: 0em;

  > img {
      width: 500px;
      height: 500px;
      object-fit: contain;
  }
`;

const PromotionHeadWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const PromotionHeadText = styled.h4`
  color: #fff;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  color: #ffffff;
  background: linear-gradient(92.89deg, #0ead69 0%, #00d8e6 100%);
  backdrop-filter: blur(4150px);
  border-radius: 3px;
  padding: 5px 10px;
  margin: 0px 0px 5px 0px;
`;
const PromotionBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 0px;
  min-height: 50vh;
  max-height: auto;
`;