import React from 'react'

type Props = {}

const AddbankRed = (props: Props) => {
  return (
<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="5.41748" y="19.5908" width="5.34854" height="12.0671" rx="1.06157" fill="white"/>
<path d="M0.055235 16.4705V12.8535C0.055235 12.3406 0.055235 12.0762 0.460821 11.8583C6.23342 8.75756 17.8799 2.48461 18.2848 2.19882C18.6897 1.91302 19.2483 2.07974 19.477 2.19882C24.7811 5.05548 35.6132 10.8939 36.5087 11.3943C37.4042 11.8947 37.649 12.431 37.6594 12.6366V16.4268C37.6594 17.8751 36.9429 18.2293 36.5846 18.2254H35.6907H1.70561C0.273185 18.2254 0.00851682 17.0554 0.055235 16.4705Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M18.9267 33.0196L1.12749 33.0195C0.5412 33.0195 0.065918 33.4948 0.065918 34.0811V38.5999C0.065918 39.1862 0.541197 39.6614 1.12748 39.6614L21.6548 39.6615C20.1691 37.8001 19.1901 35.5165 18.9267 33.0196Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M21.5507 23.7842V20.6504C21.5507 20.0641 21.0754 19.5889 20.4891 19.5889H17.2637C16.6774 19.5889 16.2021 20.0641 16.2021 20.6504V30.5944C16.2021 31.1807 16.6774 31.656 17.2637 31.656H18.856C18.856 28.6897 19.8617 25.9584 21.5507 23.7842Z" fill="white"/>
<rect x="29.9331" y="26" width="2.70443" height="10.571" rx="1.35222" fill="white"/>
<rect x="26" y="32.6377" width="2.70443" height="10.571" rx="1.35222" transform="rotate(-90 26 32.6377)" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M42.6259 31.6567C42.6259 37.6286 37.7848 42.4697 31.813 42.4697C25.8411 42.4697 21 37.6286 21 31.6567C21 25.6849 25.8411 20.8438 31.813 20.8438C37.7848 20.8438 42.6259 25.6849 42.6259 31.6567ZM29.9339 27.3522C29.9339 26.6054 30.5393 26 31.2861 26C32.0329 26 32.6383 26.6054 32.6383 27.3522V29.9337H35.219C35.9658 29.9337 36.5712 30.5391 36.5712 31.286C36.5712 32.0328 35.9658 32.6382 35.219 32.6382H32.6383V35.2188C32.6383 35.9656 32.0329 36.571 31.2861 36.571C30.5393 36.571 29.9339 35.9656 29.9339 35.2188V32.6382H27.3524C26.6056 32.6382 26.0002 32.0328 26.0002 31.286C26.0002 30.5391 26.6056 29.9337 27.3524 29.9337H29.9339V27.3522Z" fill="#B21318"/>
</svg>

  )
}

export default AddbankRed;