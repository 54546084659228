import { useEffect, useState, PropsWithChildren } from "react";
import React, { Suspense } from 'react';
import styled, { withTheme } from "styled-components";
import CloseIcon from "../images/x-circle.svg";
import Slider from "react-slick";
import "../../node_modules/slick-carousel/slick/slick.css";
import "../../node_modules/slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";
import BonusContainer, {
  BonusConatinerWrap,
  BonusContainerImage,
} from "./udb/bonus/BonusContainer";
import Intl from 'src/components/common/Intl';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import PlaceholderImage from 'src/components/Placeholder';
import { theme } from "styled-tools";

const BonusSlider = React.lazy(() => import('./udb/bonus/BonusSlider'));

const bonusInfoDefaultData = [
  // {
  //   image: "sigupBonusSlide",
  //   name: <Intl langKey="PROMOTIONSTITLE@SIGNUP" />,
  //   url: "signup-bonus",
  //   id: 1
  // },
  {
    image: "welcomeBonusSlide",
    name: <Intl langKey="PROMOTIONSTITLE@WELCOME" />,
    url: "welcome-bonus",
    id: 2,
    authkey:"showWelcomeBonus"
  },
  {
    image: "refillBonusSlide",
    name: <Intl langKey="PROMOTIONSTITLE@REFILL" />,
    url: "refill-bonus",
    id: 3,
    authkey:"showRefillBonus"
  }
  , {
    image: "referalBonusSlide",
    name: <Intl langKey="PROMOTIONS@REFERRl" />,
    url: "referral-bonus",
    id: 4,
    authkey:"showReferralBonus"
  },
  {
    image: "cashbackBonusSlide",
    name: <Intl langKey="PROMOTIONSTITLE@CASHBACK" />,
    url: "cashback-bonus",
    id: 5,
    authkey:"showCashbackBonus"
  }
]

interface Props {
  popupState: string;
}

const BonusPopup = (props) => {
  const [popupStyles, setPopupStyles] = useState("");
  const navigate = useNavigate();

  const { theme } = props
  const themeConfig = theme.config;
  const {  showPromotions} = themeConfig;

  const bonusInfo = bonusInfoDefaultData.filter(elm => {
    const authKey = elm.authkey;
    return themeConfig.hasOwnProperty(authKey) && themeConfig[authKey] && elm    
  });

  useEffect(() => {
    setPopupStyles("-90%");
  }, []);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 2,
    autoplay: false,
    arrows: true,
    pauseOnHover: false
  };

  const toBonus = () => {
    navigate("/promotions");
  };

  const OpenPopup = (e) => {
    e.preventDefault();
    setPopupStyles("5% !important");
  };

  const closePopup = () => {
    setPopupStyles("-90%");
  };

  return (
    <BonusPopupWrapper popupState={popupStyles}>
      <PopupClose onClick={closePopup}>
        <LazyLoadImage src={CloseIcon} alt="CloseIcon" effect="none" height="100%" width="100%"  placeholder={<PlaceholderImage/>}/>
      </PopupClose>
      <BonusPopupHead>
        <BonusPopupTitle>
          <Intl langKey="DASHBOARD@PromotionsAndBonus"/>
        </BonusPopupTitle>
        <BonusPopupBtn onClick={toBonus}>
          <Intl langKey="DASHBOARD@KNOWMORE"/>
        </BonusPopupBtn>
      </BonusPopupHead>

      <BonusCarouselWrapper>
      <Suspense fallback={<div>Loading...</div>}>
          <Slider {...settings}>
              {bonusInfo.map((bonus) => (
                <BonusSlider
                  key={bonus.url}
                  bonusImage={bonus.image}
                  bonusTitle={bonus.name}
                  bonusUUID={`/promotions/${bonus.url}`}
                  imageId={bonus.id}
                />
              ))}
          </Slider>
        </Suspense>
      </BonusCarouselWrapper>

      {showPromotions && <PromotionBtn onClick={OpenPopup}>Promotions</PromotionBtn>}
    </BonusPopupWrapper>
  );
};

export default withTheme(BonusPopup);

const BonusPopupWrapper = styled.div<Pick<Props, "popupState">>`
  width: 90%;
  background: ${theme('palette.promotionSlider')};
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.5);
  min-height: 50vh;
  height: 86vh;
  border-radius: 5px;
  margin: auto;
  padding: 20px 25px;
  position: fixed;
  top: 0;
  bottom: 0;
  right: ${(props) => (props.popupState ? props.popupState : "")};
  transition: 700ms;
  z-index: 99999999;
`;
const BonusPopupHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 85%;
  margin: 20px auto;
`;
const BonusPopupTitle = styled.h4`
  font-weight: 700;
  font-size: 34px;
  line-height: 46px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  margin: 5px 0px;
  color: #ffdda6;

  @media (max-width: 1360px) {
    font-size: 27px;
  }
`;
const BonusPopupBtn = styled.button`
border: 1px solid white;
background: ${theme('palette.betdailyBtnBG')};
border: 1px solid;
  border-radius: 5px;
  padding: 10px 15px;
  font-weight: 400;
  font-size: 19px;
  height: 50px;
  letter-spacing: 0.03em;
  text-transform: capitalize;
  color: black;
  cursor: pointer;
  outline: none;
  border: none;

  @media (max-width: 1360px) {
    font-size: 17px;
  }
`;
const BonusCarouselWrapper = styled.div`
  width: 85%;
  height: auto;
  margin: auto;

  .slick-prev:before,
  .slick-next:before {
    font-size: 30px;
    z-index: 999;
  }

  ${BonusConatinerWrap} {
    width: 90%;
    height: 90%;
    max-width: 326px;
  }

  ${BonusContainerImage} {
    max-height: 475px;
    object-fit: revert;
    max-width: 326px;

    &:hover {
      animation: none !important;
    }
  }

  .slick-slide > div {
    display: flex;
    justify-content: flex-end;
    width: 90%;
  }
`;
const PopupClose = styled.div`
  position: absolute;
  right: -10px;
  top: -20px;
  cursor: pointer;

  & img {
    width: 40px;
    height: 40px;
  }
`;
const PromotionBtn = styled.button`
  /* background: #6778e3; */
  background: ${theme('palette.secondary-background-color-linear-new1')};
  color: white;
  padding: 5px 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  text-transform: uppercase;
  color: #ffffff;
  transform: rotate(-90deg);
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 5px 5px 0px 0px;
  position: absolute;
  top: 50%;
  left: -72px;

  @media (max-width: 1280px) {
    padding: 4px 15px;
    font-size: 15px;
    left: -77px;
  }
`;
const LoaderWrapper = styled.div`
  height: 65vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
