import React from 'react';
import styled, { withTheme } from "styled-components";
import Discount from "src/components/icon/Discount";
import Intl from "src/components/common/Intl";
import { ifProp, theme, withProp } from 'styled-tools';
import { useNavigate } from 'react-router-dom';

type Props = {}

const BounusReuse = ({ titleB, subtitle, redirectFun}) => {
  return (
    <ReusableDivsForTitle onClick={redirectFun}>
      <SvgIcon1 className="Discount">
        <Discount />
      </SvgIcon1>
      <DivForTitle>
        <TitleTag><Intl langKey={titleB} /></TitleTag>
        <Subtitle><Intl langKey={subtitle} /></Subtitle>
      </DivForTitle>
    </ReusableDivsForTitle>
  );
};


function ProBonus(props) {
  const { theme } = props
  const { config: { showReferralBonus } } = theme;

  const navigate = useNavigate()

  return (
    <BounusWrapper>
      <TitleForBonus><Intl langKey="Bonus@Top" /></TitleForBonus>
      {/* <BounusReuse titleB="ProBonus@Sports" subtitle="ProBonus@Sub1" /> */}
      <BounusReuse
        titleB="ProBonus@Welcome"
        subtitle="ProBonus@Sub"
        redirectFun={() => navigate("/promotions/welcome-bonus")}
      />
      <BounusReuse titleB="ProBonus@Reload" subtitle="ProBonus@Sub3" redirectFun={() => navigate("/promotions/refill-bonus")}/>
      <BounusReuse
        titleB="ProBonus@Pregmatic"
        subtitle="ProBonus@Sub4"
        redirectFun={() => navigate("/promotions/cashback-bonus")}
      />
      {showReferralBonus && <BounusReuse
        titleB="ProBonus@Referral"
        subtitle="ProBonus@Sub5"
        redirectFun={() => navigate("/promotions/referral-bonus")}
      />}
      <ButtonBunous onClick={() => navigate("/member/promotions")}><Intl langKey="ProBonusButton@CheckStatus" /></ButtonBunous>
    </BounusWrapper>
  )
}

export default withTheme(ProBonus)

export const BounusWrapper = styled.div`
    background: rgb(0 0 0 / 54%);
  /* background: rgba(88,88,88,0.8); */
  /* box-shadow: 0px 0px 30px rgba(13, 7, 77, 0.25); */
  border-radius: 10px 10px 13px 10px;
  border: 1px solid white;
  width: 100%;
`;

export const TitleForBonus = styled.div`
  padding: 18px;
  width: 100%;
  /* font-family: "Roboto"; */
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  text-transform: capitalize;
  color: #ffffff;
  background: #35353591;
  /* background: ${theme('palette.headerBgColor')}; */
  /* box-shadow: 0px 0px 50px rgba(152, 158, 213, 0.4); */
  border-radius: 13px 13px 0px 0px;
  backdrop-filter: contrast(0.5);
`;

export const TitleTag = styled.h5`
  /* font-family: "Roboto"; */
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  text-transform: capitalize;
  color: #fff;
  margin: 0px;
  transition: 0.5s;
`;

export const ReusableDivsForTitle = styled.div`
  display: flex;
  cursor: pointer;
  transition: 0.9s;
  align-items: center;

  &:hover {
    background: rgba(255, 255, 255, 0.5);
    

    ${TitleTag} {
      color: #ffd085;
      font-size: 14px;
    }
  }
`;

export const ImageForBonus = styled.img`
  padding: 17px;
`;

export const DivForTitle = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0px 10px 10px;
`;

export const ButtonBunous = styled.button`
  padding: 16px;
  width: 100%;
  border-radius: 0px 0px 12px 9px;
  background: ${theme('palette.secondary-background-color-linear-new1')};   
  backdrop-filter: blur(4150px);
  /* font-family: "Roboto"; */
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 12px;
  align-items: center;
  letter-spacing: 0.03em;
  text-transform: capitalize;
  color: #ffffff;
  cursor: pointer;
  border: none;
  outline: none;

  &:focus {
    opacity: 0.9;
    transform: scale(0.99);
    outline: blac;
  }
`;

export const Subtitle = styled.p`
  margin: 0px;
  /* font-family: "Roboto"; */
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 13px;
  color: white;
  letter-spacing: 0.03em;
  text-transform: capitalize;
  padding-top: 3px;
`;

export const SvgIcon1 = styled.div`
  display: flex;

  svg {
    width: 38px;
    height: 19px;
    fill: white;
  }
  &:hover {
    color: black;
  }
`;


