import React from 'react'

type Props = {}

const Step5Icon = (props: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48"><circle cx="24" cy="24" r="24" fill="url(#a)"/><g fill="#fff"><path d="M21.567 13.275a.553.553 0 0 1-.545-.56V10.56c0-.31.244-.561.545-.561.3 0 .545.251.545.561v2.153c0 .31-.244.561-.545.561ZM24.344 14.459a.535.535 0 0 1-.385-.165.572.572 0 0 1 0-.793l1.48-1.523a.534.534 0 0 1 .771 0 .572.572 0 0 1 0 .794l-1.48 1.522a.536.536 0 0 1-.386.165ZM27.588 17.316h-2.093a.553.553 0 0 1-.545-.56c0-.31.244-.562.545-.562h2.093c.302 0 .546.251.546.561 0 .31-.245.561-.546.561ZM18.79 14.459a.535.535 0 0 1-.386-.165l-1.48-1.522a.572.572 0 0 1 0-.794.534.534 0 0 1 .77 0l1.48 1.523a.572.572 0 0 1 0 .793.536.536 0 0 1-.385.165ZM17.639 17.316h-2.094a.553.553 0 0 1-.545-.56c0-.31.244-.562.545-.562h2.094c.301 0 .545.251.545.561 0 .31-.244.561-.545.561ZM31.37 24.587c-.9 0-1.63.75-1.63 1.676v-1.339c0-.926-.73-1.677-1.63-1.677-.9 0-1.63.751-1.63 1.677v-1.353c0-.926-.729-1.677-1.63-1.677-.9 0-1.629.75-1.629 1.677v-6.737c0-.926-.73-1.677-1.63-1.677-.9 0-1.63.75-1.63 1.677l.073 11.295c0 .13-.155.193-.24.097l-1.55-1.744a1.65 1.65 0 0 0-2.28-.196l-.038.03a1.768 1.768 0 0 0-.302 2.414c1.28 1.738 2.563 3.23 3.463 4.575.41.615.67 1.449 1.028 2.097.543.981 2.328 3.598 5.948 3.598 4.455 0 6.086-3.424 6.32-4.827.408-2.453.617-5.111.618-7.906 0-.924-.732-1.68-1.63-1.68Z"/></g><defs><linearGradient id="a" x1="24" x2="24" y1="0" y2="48" gradientUnits="userSpaceOnUse"><stop stop-color="#6765E0"/><stop offset="1" stop-color="#322DB8"/></linearGradient><clipPath id="b"><path fill="#fff" d="M15 10h18v29H15z"/></clipPath></defs></svg>
  )
}

export default Step5Icon;