import React, { useState, useEffect, useRef, useContext } from "react";
import {
  FirstCol,
  Header,
  MainInfoWrapper,
} from "src/components/udb/commonStyle";
import styled, { withTheme } from "styled-components";
import { getDepositStatus } from "src/config/api";
import { useParams, useNavigate } from "react-router-dom";
import DepositMessage from "src/components/udb/Message";
import UDBHeader from "src/components/UDBHeader";
import config from "src/config/config";
import { withAppContext } from "src/store/initAppContext";
import { Player } from "@lottiefiles/react-lottie-player";
import { UserContext } from "../index";
import { TriggerPxlParticular, pixelHandler } from "src/pages/auth/Signup/Signup";
import { depositSigmentPxl } from "./Deposit";
import {SRTM} from 'src/util/sportradarEvent';

type transStateProps = {
  status: string;
  message: string;
  amount: string;
  methodtype?: string;
  showMsg: boolean;
  source?:string
};

const defaultTransState: transStateProps = {
  status: "",
  amount: "",
  message: "",
  showMsg: false,
  source:""
};

const TriggerPxlPurchase = (
  marketingSource = "",
  isprev = false,
  isLead = false,
  isPurchase = false,
  brandID = 31,
  isFtd = true
) => {
  if (marketingSource && isFtd) {
    pixelHandler(marketingSource,isprev,isLead,isPurchase,brandID);
  }
};

function DepositStatus(props) {
  const [transationState, setTransationState] = useState(defaultTransState);
  const [animationImage, setAnimationImage] = useState("");
  const [popup, setPopup] = useState(true);
  const urlParams = useParams();
  const navigate = useNavigate();
  const timer = useRef(undefined);
  const depositeUrl = "/member/finance/deposit";

  const {
    app: { brandId },
    theme,
  } = props;
  const { userInfo } = useContext(UserContext);

  useEffect(() => {
    //for sport radar
    try {
      SRTM.init(brandId)
    } catch (error) {
      console.log(' ')
    }
    
    let isEmpty = Object.values(urlParams);
    if (isEmpty.length) {
      getDepositStatus(urlParams)
        .then((res) => {
          const { data = {} } = res.data;
          const {
            is_first_time_deposit,
            is_redeposit,
            is_manual_deposit,
            is_400_percent_deposit,
            user_source,
          } = res.data;

          const payment_gateway_id = data.payment_gateway_id;
       

          // const { blueTierAnimation,silverTierAnimation,goldTierAnimation,platinumTierAnimation,eliteTierAnimation } = res.data;
          const { status } = data;
          //do not writing anything for mow
          //just hiding popup images in case of failure
          if (!status || status === "FAILURE") {
            setPopup(false);
          }
          // adding animation popups

          //  if (is_first_time_deposit === true) {
          //      setAnimationImage(theme.image.firstDepositAnimation)
          //  } else if(is_redeposit){
          //      setAnimationImage(theme.image.refillDepositAnimation)
          //  } else {
          //     setPopup(false)
          //  }
          
          //       if (is_400_percent_deposit) {
          //         setAnimationImage(theme.image.firstDepositAnimation400)
          //       }
          //       else if (is_first_time_deposit === true && is_400_percent_deposit === false) {
          //         setAnimationImage(theme.image.firstTimeDeposit300Animation);
          //       } else if (userInfo.tier === "BLUE_TIER" && is_redeposit) {
          //         setAnimationImage(theme.image.blueTierAnimation);
          //       } else if (userInfo.tier === "SILVER_TIER" && is_redeposit) {
          //         setAnimationImage(theme.image.silverTierAnimation);
          //       } else if (userInfo.tier === "GOLD_TIER" && is_redeposit) {
          //         setAnimationImage(theme.image.goldTierAnimation);
          //       } else if (userInfo.tier === "PLATINUM_TIER" && is_redeposit) {
          //         setAnimationImage(theme.image.platinumTierAnimation);
          //       } else if (userInfo.tier === "ELITE_TIER" && is_redeposit) {
          //         setAnimationImage(theme.image.eliteTierAnimation);
          //       } else {
          //         setPopup(false);
          //       }

          // optimised the popup code
          if (payment_gateway_id === "f6fea746b42947468e644495aee17d7a" && is_redeposit) {
            switch (userInfo.tier) {
                case "BLUE_TIER":
                    setAnimationImage(theme.image.blueTierAnimation);
                    break;
                case "SILVER_TIER":
                    setAnimationImage(theme.image.silverTierAnimation);
                    break;
                case "GOLD_TIER":
                    setAnimationImage(theme.image.goldTierAnimation);
                    break;
                case "PLATINUM_TIER":
                    setAnimationImage(theme.image.platinumTierAnimation);
                    break;
                case "ELITE_TIER":
                    setAnimationImage(theme.image.eliteTierAnimation);
                    break;
                default:
                    setPopup(false);
                    break;
            }
            } else {
                if (is_400_percent_deposit) {
                    setAnimationImage(theme.image.firstDepositAnimation400);
                } else if (is_first_time_deposit && !is_400_percent_deposit) {
                    setAnimationImage(theme.image.firstTimeDeposit300Animation);
                } else if (is_redeposit) {
                    switch (userInfo.tier) {
                        case "BLUE_TIER":
                            setAnimationImage(theme.image.blueTierAnimation);
                            break;
                        case "SILVER_TIER":
                            setAnimationImage(theme.image.silverTierAnimation);
                            break;
                        case "GOLD_TIER":
                            setAnimationImage(theme.image.goldTierAnimation);
                            break;
                        case "PLATINUM_TIER":
                            setAnimationImage(theme.image.platinumTierAnimation);
                            break;
                        case "ELITE_TIER":
                            setAnimationImage(theme.image.eliteTierAnimation);
                            break;
                        default:
                            setPopup(false);
                            break;
                    }
                } else {
                    setPopup(false);
                }
            }
        
          setTransationState({
            status: status, //'Sucessfully Deposited'
            message: `${data.payment_type} ${
              status === "FAILURE" ? "FAILED" : status
            }`,
            amount: Number(data.amount).toFixed(2),
            showMsg: status,
            source:user_source
          });

          // try {
          //     ReactPixel.init('739104157417039')
          //     ReactPixel.trackSingle('track', 'Purchase', { value: data.amount, currency: 'INR', status: status });
          // } catch (error) { }

          // timer.current = setTimeout(() => {
          //     setTransationState(defaultTransState);
          //     navigate(depositeUrl, { replace: true });
          // }, 10000);

          if(is_first_time_deposit && user_source){
            TriggerPxlPurchase(user_source, false, false, true, brandId, is_first_time_deposit)
          }

          if (user_source && (user_source.toLowerCase() === "channel383")) {
            TriggerPxlParticular(user_source, false, false, true, false, brandId);
          }
          // for Sigment Pixel
          if (brandId === 31) {
            depositSigmentPxl(
              user_source,
              false,
              Number(data.amount).toFixed(2)
            );
          }
          //for sport radar
          if(is_first_time_deposit){
            SRTM.depositFT(brandId,userInfo.username);
          }else{
            SRTM.depositR(brandId,userInfo.username);
          }
         
        })
        .catch((err) => console.log("Error", err));

      setTimeout(() => {
        setPopup(false);
      }, 8000);
    }

    //Return function
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  const closeMode = () => {
    clearTimeout(timer.current);
    setTransationState(defaultTransState);
    navigate(depositeUrl, { replace: true });
  };

  //adding one more variable for tracking event
  
  const showTracICUB = (transationState.source ==='channel1102' || transationState.source ==='channel1105' || transationState.source ==='channel1106')  && brandId === 33;
  const showMomentus = (transationState.source ==='channel10' || transationState.source ==='channel11')  && brandId === 33;

  return (
    <MainInfoWrapper>
      <Header>
        <FirstCol>
          <UDBHeader title="DASHBOARD@DEPOSIT" link={depositeUrl} />
        </FirstCol>
      </Header>
      <>
        {popup === true && animationImage ? (
          <PopUpWrap>
            {/* <img src={`${config.imageV3Host}/static/cashsite/${animationImage}`} alt="1stTimePopup" /> */}
            <Player
              autoplay
              loop
              controls
              // src={`${config.imageV3Host}/static/cashsite/${theme.image.catch99BannerImg}`}
              src={`${config.imageV3NonOptHost}/static/cashsite/${animationImage}`}
              style={{ height: "500px", width: "500px" }}
            ></Player>
          </PopUpWrap>
        ) : (
          <DepositMessage
            message={transationState.message}
            status={transationState.status}
            amount={transationState.amount}
            closeHandler={closeMode}
          />
        )}
      </>
      {transationState.status!=='FAILURE' && showTracICUB && (<TrackingImgWrp><img src='https://tracking.icubeswire.co/aff_a?offer_id=4536&goal_id=6900' width='1' height='1' id='pixelcodeurl' alt="" /></TrackingImgWrp>)}
      {transationState.status!=='FAILURE' && showMomentus && (<TrackingImgWrp><img src='https://momentus.gotrackier.com/pixel?av=659559c38d7a8f4a0e4e5371&goal_value=deposit' width='1' height='1' alt="" /></TrackingImgWrp>)}
    </MainInfoWrapper>
  );
}

export default withTheme(withAppContext(DepositStatus));

//https://tracking.icubeswire.co/aff_a?offer_id=4536&goal_id=6900
//Styled Components
const PopUpWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const TrackingImgWrp = styled.div`
  position: absolute;
  top: -1100px;
  width: 1px;
  height: 1px;
`;
