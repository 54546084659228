import React, { useState } from 'react'
import styled from 'styled-components';
import config from 'src/config/config';
import { SvgIcon } from '../commonStyle';
import { Player } from '@lottiefiles/react-lottie-player';
import TopChoiceDeposit from 'src/components/icon/TopChoiceDeposit';
import { theme } from 'styled-tools';
import { important } from 'polished';

type customRadioButtonProps = {
  name: string;
  label: string | React.ReactNode;
  value: string;
  checked: boolean;
  icon: any;
  theme: any;
  topChoice: boolean;

  onChange: (e: React.ChangeEvent<HTMLInputElement>, value?: string) => void;
} & Omit<React.ComponentProps<'input'>, 'name' | 'onChange' | 'value'>

const CustomRadioDepositButton = ({ name, label, value, checked, icon, theme, topChoice, onChange, ...props }: customRadioButtonProps) => {  

    return (
        <Container checked={checked}>
            <ContainerInputWrap checked={checked}>
                <>
                    {checked === true &&
                            // <Player
                            //     autoplay
                            //     loop
                            //     controls
                            //     src={`${config.imageV3NonOptHost}/static/cashsite/brand/6/json/depositActiveWaterIcon.json`}
                            // ></Player>
                            <GIFContainer>
                                 <img src={`${config.imageV3NonOptHost}/static/cashsite${theme.image.DepositRadioActiveBg}`} alt='' />
                                {/* <img src={`${config.imageV3NonOptHost}/static/cashsite/brand/6/icon/DepositRadioActiveBg.gif`} alt='' /> */}
                            </GIFContainer>
                    }
                    {/* {checked === false && 
                        <Player
                            autoplay
                            loop
                            controls
                            src={`${config.imageV3NonOptHost}/static/cashsite/brand/6/json/depositActiveBorderIcon.json`}
                        ></Player>
                    } */}
                </>
                <input type="radio" name={name} value={value} checked={checked} onChange={(e) => onChange(e, value)} {...props} />
                <span className="checkmark"><RadioSvgIcon>{icon}</RadioSvgIcon><p>{label}</p></span>  
            </ContainerInputWrap>
            {topChoice &&
            <>
                <TopChoiceSvgIcon checked={checked}>
                    <TopChoiceDeposit />
                    <p>Top Choice</p>
                </TopChoiceSvgIcon>
            </>
            }
        </Container>
    )
    }

export default CustomRadioDepositButton;

export const Container = styled.label<Pick<customRadioButtonProps, "checked">>`
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-left: 0px;
  cursor: pointer;
  user-select: none;
  margin-right: 1em;

  @media (max-width: 1400px) {
    font-size: 13px;
  }

input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.checkmark {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 125px;
  width: 125px;
  min-width: 45px;
  background: ${props => props.checked ? "" : theme('palette.DepositCustomRadioBg')}; 
  border-color: ${props => props.checked ? theme('palette.DepositTextColorSecondary') : theme('palette.DepositTextColorPrimary')} !important;
  border: 2px solid;
  border-radius: 19px;

  & > p {
    font-size: 15px;
    color: ${theme('palette.DepositTextColorPrimary')};
    z-index: 999;
    margin: 5px 0px;
  }

  @media (max-width: 1400px) {
     height: 115px;
     width: 115px;
  }

}

input:checked ~ .checkmark {
  box-shadow: 0px 0px 40px rgba(16, 11, 73, 0.4);
  color: ${theme('palette.DepositTextColorPrimary')};
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
`
const ContainerInputWrap = styled.div<Pick<customRadioButtonProps, "checked">>`
    width: 100%;

    & div > div {
       position: absolute;
       z-index: ${(props) => (props.checked === true ? "5" : "99")};
       left: ${(props) => (props.checked === true  ? "-22px" : "-32px")};
       right: 0;
       top: ${(props) => (props.checked === true ? "-21px" : "-37px")};
       width: ${(props) => (props.checked === true  ? "168px" : "195px")};
       height: ${(props) => (props.checked === true ? "165px" : "195px")};
       mix-blend-mode: ${(props) => (props.checked === true ? "overlay" : "difference")};

       @media (max-width: 1400px) {
            width: ${(props) => (props.checked === true  ? "154px" : "180px")};
            height: ${(props) => (props.checked === true ? "152px" : "180px")};
            top: ${(props) => (props.checked === true ? "-19px" : "-35px")};
            left: ${(props) => (props.checked === true  ? "-20px" : "-32px")};
        }
    }

    & span {
        z-index: ${(props) => (props.checked === true ? "9" : "-10")};
    }
`                                                   
const RadioSvgIcon = styled(SvgIcon)`
    height: 65px;
    width: 100px;
    min-width: 45px;
    display: flex;
    justify-content: center;
    padding: 20px;
    margin-right: 0px;

    & svg {
        width:65px;
        height: 65px;
        z-index: 9999;
    }
`
const TopChoiceSvgIcon = styled.div<Pick<customRadioButtonProps, "checked">>`
    position: absolute;
    bottom: -44px;
    left: 0px;
    right: 0px;
    display:flex;
    flex-direction:column;
    align-items: center;

    & svg {
        width:20px;
        height: 20px;
        z-index: 99999999;
        fill: ${(props) => (props.checked === true ? theme('palette.DepositTextColorSecondary') : theme('palette.DepositTextColorPrimary'))};  
    }

    & p {
        font-size: 10px;
        color: ${(props) => (props.checked === true ? theme('palette.DepositTextColorSecondary') : theme('palette.DepositTextColorPrimary'))};
        font-weight: 600;
        margin: 3px 0px;
    }
`
const GIFContainer = styled.div`
        width: 122px;
        height: 123px;
        position: absolute;
        top: 1px;
        left: 1.5px;

        @media (max-width: 1400px) {
            width: 113px;
            height: 115px;
        }

        > img {
            max-width:100%;
            max-height:100%;
            border-radius: 18px;
        }
`