// Library imports
import { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { useForm, Controller } from "react-hook-form";
// import { ifProp } from "styled-tools";
import { AES, pad, mode, enc } from "crypto-js";

// Component imports
import { LoginInput } from "src/components/Auth/Login/Login";
import {
  AuthButtonWrap,
  AuthSubmitBtn,
  OtpButton,
  PhoneNumberAndOtpWrap,
  PhoneNumberAndVerifyWrap,
} from "src/components/Auth/commonStyles";
import { withAppContext } from "src/store/initAppContext";
import OTPTimer from "src/pages/auth/Signup/OTPTimer";
import Intl from "src/components/common/Intl";
import EyeIcon from "src/components/icon/Eye";
import HideIcon from "src/components/icon/Hide";
import VerifyIcon from "../../../images/check-circle.png";
import ArrowIcon from "../../../images/arrow-right-circle.png";
import { theme } from "styled-tools";
import PromoCodeIcon from "src/components/icon/PromoCodeIcon";
import { userNameValidation } from "src/config/api";
import config from "src/config/config";
// import NoneIcon from '../../../images/'

// images imports
// const VerifyIcon = `${"static/Image/check-circle.png"}`;
// const ArrowIcon = `${"static/Image/arrow-right-circle.png"}`;
const NoneIcon = `${"static/Image/"}`;

interface ISignupFormProps {
  onSubmitHandler: (formData: any, event: any) => void;
  onSendOtpHandler: (event: React.MouseEvent<HTMLElement>, value: any) => void;
  otpHandle: boolean;
  otpStatus: boolean;
  networkMessage: string;
  setNetworkMessage: (value: string) => void;
  loading: boolean;
  setOtpLoader: boolean;
  showTimer: boolean;
  showResend: boolean;
  isSuccess?: boolean;
  signupQuery?: any;
  app: any;
}

function SignupForm(props: ISignupFormProps) {
  const [usernameError, setUsernameError] = useState('')
  const [username, setUsername] = useState('')
  const {
    onSubmitHandler,
    onSendOtpHandler,
    otpHandle,
    otpStatus,
    networkMessage,
    setNetworkMessage,
    setOtpLoader,
    loading,
    showTimer,
    showResend,
    isSuccess,
    signupQuery,
  } = props;

  const {
    app: {brandId},
  } = props;

  const Icons = { ArrowIcon, VerifyIcon, NoneIcon };
  const {
    handleSubmit,
    formState: { isValid },
    getValues,
    control,
    reset,
  } = useForm({
    mode: "onChange",
  });

  
  const [values, setValues] = useState({
    password: "",
    showPassword: false,
  });

  const [showPromoInput, setShowPromoInput] = useState(false)
  
  const enablePromoInput = () => {
     setShowPromoInput(prevState =>  !prevState)
  }


  useEffect(() => {
    reset();
  }, [isSuccess]);

  useEffect(() => {
    if (signupQuery.promoCode) {
      sessionStorage.setItem("sessionRefcode", signupQuery.promoCode);
    }
  }, [signupQuery]);

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const formatInput = (e) => {
    // Prevent characters that are not numbers ("e", ".", "+" & "-") ✨
    let checkIfNum;
    if (e.key !== undefined) {
      // Check if it's a "e", ".", "+" or "-"
      checkIfNum =
        e.key === "e" ||
        e.key === "." ||
        e.key === "+" ||
        e.key === "-" ||
        e.key === "ArrowUp" ||
        e.key === "ArrowDown" ||
        e.key === "E";
    } else if (e.keyCode !== undefined) {
      // Check if it's a "e" (69), "." (190), "+" (187) or "-" (189)
      checkIfNum =
        e.keyCode === 69 ||
        e.keyCode === 190 ||
        e.keyCode === 187 ||
        e.keyCode === 189;
    }
    return checkIfNum && e.preventDefault();
  };

  const formatTextInput = (e) => {
    // Prevent characters that are not numbers ("e", ".", "+" & "-") ✨
    let checkIfNum;
    if (e.key !== undefined) {
      // Check if it's a "e", ".", "+" or "-"
      checkIfNum =
        e.key === " " ||
        e.key === "." ||
        e.key === "+" ||
        e.key === "-" ||
        e.key === "ArrowUp" ||
        e.key === "ArrowDown";
    } else if (e.keyCode !== undefined) {
      // Check if it's a "e" (69), "." (190), "+" (187) or "-" (189)
      checkIfNum =
        e.keyCode === 69 ||
        e.keyCode === 190 ||
        e.keyCode === 187 ||
        e.keyCode === 189;
    }
    return checkIfNum && e.preventDefault();
  };


  const handleUsernameValidation = (e, error) => {
      let userNameVal = e.target.value;

      if(username !== userNameVal){
        setUsername(userNameVal);
      }
   
      const currentTimestamp = Math.floor(new Date().getTime() / 1000);
      const TOKEN_SECRET_KEY = 'Rp}ex:?zG0=&m&,DOX$X<:HI>G=LNKeL';

      let userNameData:any = {
        username: userNameVal,
        brand_id: brandId,
        timestamp: currentTimestamp.toString()
    }

      const info = JSON.stringify(userNameData);
      const encryptedTarget = enc.Utf8.parse(info);
      const encryptSecretKey = enc.Utf8.parse(TOKEN_SECRET_KEY);
      const usernameInfo = AES.encrypt(encryptedTarget, encryptSecretKey, {
        iv: enc.Utf8.parse(""),
        padding: pad.Pkcs7,
        mode: mode.CBC,
      }).ciphertext.toString();

      let data = {
        params: usernameInfo
      };

      if(error === undefined && usernameError === ""){
          userNameValidation(data).then((res) => {
            const { message } = res.data;
            if(message && message.is_username_exists){
              setUsernameError("Username already exists")
              setNetworkMessage("Username already exists")
            } else {
              setUsernameError("")
              setNetworkMessage("")
            }
          }).catch((err) => {
            console.log(err.data) 
          })
      } 
  }

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)} autoComplete="off">
      <SignUpContainer className="sign-up-container">
        <Controller
          control={control}
          name="userName"
          defaultValue={""}
          rules={{
            required: {
              value: true,
              message: " * Username is a required field",
            },
            pattern: {
              value: /^[a-zA-Z0-9\b]+$/,
              message: "* Name must not contain special characters and spaces",
            },
            minLength: { value: 4, message: "* Minimum Character : 4" },
            maxLength: { value: 18, message: "* Maximum Character : 18" },
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <>
              <LoginInput
                id="userName"
                placeholder={<Intl langKey="CREATE@USERNAME" />}
                type="text"
                onChange={(e:any) => {
                  setUsernameError('');
                  setNetworkMessage('')
                  return onChange(e);
                }}
                onKeyDown={formatTextInput}
                onBlur={(e) => handleUsernameValidation(e, error)}
                value={value}
                error={error}
                req={true}
                name={"userName"}
                autoComplete="new-password"
              />
              <h1>{error && error.message || usernameError !== "" && usernameError}</h1>
            </>
          )}
        />

        <Controller
          name="password"
          control={control}
          defaultValue={""}
          rules={{
            required: { value: true, message: "* Please enter password" },
            minLength: { value: 8, message: "* Minimum Length : 8" },
            maxLength: { value: 24, message: "* Max Length reached" },
            pattern: {
              value: /^(?=.*?[A-z])(?=.*?[0-9]).{8,}$/,
              message:
                "* Must contain at least one number, one letter, and at least 8 or more characters",
            },
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <SignUpPasswrdWrap>
              <LoginInput
                id="password"
                onChange={onChange}
                value={value}
                error={error}
                placeholder={<Intl langKey="CREATE@PASSWORD" />}
                type={values.showPassword ? "text" : "password"}
                req={true}
                autoComplete="new-password"
              />

              <PasswordHide
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {values.showPassword ? <HideIcon /> : <EyeIcon />}
              </PasswordHide>
              <h1>{error && error.message}</h1>
            </SignUpPasswrdWrap>
          )}
        />
        <Controller
          name="phoneNumber"
          control={control}
          defaultValue={""}
          rules={{
            required: {
              value: true,
              message: "Please provide mobile number",
            },
            pattern: { value: /^[6-9]\d{9}$/, message: "Enter valid number" },
            minLength: { value: 10, message: "* Minimum Character : 10" },
            maxLength: { value: 10, message: "* Too Many Characters" },
          }}
          render={({
            field: { onChange, value },
            fieldState: { error, isTouched },
            formState: { isValid },
          }) => (
            <>
              <PhoneNumberAndOtpWrap>
                <p>+91</p>
                <LoginInput
                  id="phoneNumber"
                  onChange={onChange}
                  onKeyDown={formatInput}
                  onWheel={(e) => e.target.blur()}
                  value={value}
                  error={error}
                  placeholder={<Intl langKey="CREATE@PHONENUMBER" />}
                  type="number"
                  req={true}
                  onPaste={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  onCopy={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  autoComplete="new-password"
                />
                <OtpButton
                  disabled={value.length !== 10 || otpStatus || username === "" || usernameError !== ""}
                  isActive={value.length === 10 && !(error&&error.message) && username !== "" && usernameError === ""}
                  className="otp-button"
                  id="signup_otpbutton"
                  onClick={(event) => {
                    if(!(error&&error.message)) { return onSendOtpHandler(event, getValues)}
                    event.preventDefault();
                    event.stopPropagation();
                  }}
                >
                  {otpStatus ? (
                    <>
                      <Intl langKey="AUTH@SENT" />{" "}
                      <img className="send-otp" src={Icons.VerifyIcon} alt="send otp" />
                    </>
                  ) : (
                    <>
                      {setOtpLoader ? (
                        <Intl langKey="AUTH@SENDING" />
                      ) : (
                        <>
                          {" "}
                          <Intl langKey="AUTH@OTP" />{" "}
                          <img className="send-otp" src={Icons.ArrowIcon} alt="ArrowIcon" />
                        </>
                      )}
                    </>
                  )}
                </OtpButton>
              </PhoneNumberAndOtpWrap>
              <h1>{error && error.message}</h1>
            </>
          )}
        />
        <div className="message">{showTimer ? <OTPTimer /> : null}</div>

        {showResend ? (
          <div className="message">
            {" "}
            <p>
              *<Intl langKey="AUTH@RESENDOTP" />
            </p>
            <span onClick={(event) => onSendOtpHandler(event, getValues)}>
              <Intl langKey="AUTH@RESEND" />
            </span>
          </div>
        ) : null}

        {otpHandle && (
          <>
            <Controller
              name="otpCode"
              control={control}
              defaultValue={""}
              rules={{
                required: { value: true, message: "Otp is required" },
                minLength: { value: 4, message: "*Minimum 4 Numbers" },
              }}
              render={({
                field: { onChange, value },
                fieldState: { error, isTouched },
                formState: { isValid },
              }) => (
                <>
                  <PhoneNumberAndVerifyWrap>
                    <LoginInput
                      id="otpCode"
                      placeholder={<Intl langKey="LOGIN@ENTEROTP" />}
                      type="number"
                      onChange={onChange}
                      onKeyDown={formatInput}
                      onWheel={(e) => e.target.blur()}
                      value={value}
                      error={error}
                      req={true}
                      onPaste={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                      onCopy={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                      autoComplete="new-password"
                    />
                  </PhoneNumberAndVerifyWrap>
                  <h1>{error && error.message}</h1>
                </>
              )}
            />



            <Controller
              name="firstName"
              control={control}
              defaultValue={""}
              rules={{
                required: {
                  value: true,
                  message: " *Please provide the first name",
                },
                pattern: {
                  value: /^[a-zA-Z0-9\b]+$/,
                  message:
                    "* Name must not contain special characters and spaces",
                },
                minLength: { value: 2, message: "* Minimum Character : 2" },
                maxLength: { value: 18, message: "* Maximum Character : 18" },
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <>
                  <LoginInput
                    id="firstName"
                    onChange={onChange}
                    onKeyDown={formatTextInput}
                    value={value}
                    error={error}
                    placeholder={<Intl langKey="SIGNUP@FIRSTNAME" />}
                    type="text"
                    req={true}
                    autoComplete="new-password"
                  />
                  <h1>{error && error.message}</h1>
                </>
              )}
            />

            <Controller
              name="lastName"
              control={control}
              defaultValue={""}
              rules={{
                required: {
                  value: true,
                  message: " *Please provide the last name",
                },
                pattern: {
                  value: /^[a-zA-Z0-9\b]+$/,
                  message:
                    "* Name must not contain special characters and spaces",
                },
                minLength: { value: 2, message: "* Minimum Character : 2" },
                maxLength: { value: 18, message: "* Maximum Character : 18" },
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <>
                  <LoginInput
                    id="lastName"
                    onChange={onChange}
                    onKeyDown={formatTextInput}
                    value={value}
                    error={error}
                    placeholder={<Intl langKey="SIGNUP@LASTNAME" />}
                    type="text"
                    req={true}
                    autoComplete="new-password"
                  />
                  <h1>{error && error.message}</h1>
                </>
              )}
            />
            <Controller
              name="email"
              control={control}
              defaultValue={""}
              rules={{
                required: {
                  value: false,
                  message: " *Please provide valid email",
                },
                pattern: {
                  value:
                    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: "* Email is invalid",
                },
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <>
                  <LoginInput
                    id="email"
                    onChange={onChange}
                    value={value}
                    error={error}
                    placeholder={<Intl langKey="TITLE@Email" />}
                    type="text"
                    req={false}
                    autoComplete="new-password"
                  />
                  <h1>{error && error.message}</h1>
                </>
              )}
            />

        <PromoInputText onClick={enablePromoInput}> <PromoCodeIcon /> I Have a <span>Promo or Referral Code</span></PromoInputText>
            {showPromoInput && 
              <Controller
                name="referrerCode"
                control={control}
                defaultValue={sessionStorage.getItem("sessionRefcode") || ""}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <LoginInput
                      id="referrerCode"
                      onChange={onChange}
                      value={value}
                      error={error}
                      placeholder={<Intl langKey="SIGNUP@REFERRERCODE" />}
                      type="text"
                      req={false}
                    />
                    <h1>{error && error.message}</h1>
                  </>
                )}
              />
            }
          </>
        )}

        <div className={`${isSuccess ? "success-wrap" : "error-wrap"}`}>
          {networkMessage && networkMessage.length !== 0 && (
             <p className="username-error1">{networkMessage}</p>
          )}
        </div>

        <AuthButtonWrap>
          <AuthSubmitBtn
            disabled={isValid && otpHandle && usernameError === "" ? false : true}
            type="submit"
            id="signup_submitbutton"
          >
            <Intl langKey="AUTH@SUBMIT" />
          </AuthSubmitBtn>
        </AuthButtonWrap>
      </SignUpContainer>
    </form>
  );
}

export default withAppContext(SignupForm);

const SignUpPasswrdWrap = styled.div`
  position: relative;
`;

const SignUpContainer = styled.div`
  display: flex;
  flex-direction: column;
  // background: #151439;
  height: auto;
  min-height: 35vh;
  max-height: 52vh;
  overflow-y: auto;
  width: 550px;
  background: linear-gradient(0deg, #000000, #000000),
    radial-gradient(
      50% 50% at 50% 50%,
      rgba(255, 255, 255, 0.15) 0%,
      rgba(255, 255, 255, 0.25) 100%
    );
  padding-top: 1px;
  // border: 1px solid #121239;
  border-radius: 0px 0px 10px 10px;
  max-width: 650px;
  padding: 20px 40px;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
    border-radius: 10px;
  }

  &::-webkit-scrollbar {
    width: 10px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background:${theme('palette.scrollBg')};
    margin-bottom: 10px;
  }

  & h1 {
    font-size: 13px;
    color: red;
    margin: 0 0 3px;
  }

  & .message {
    display: flex;
    justify-content: flex-end;
    margin-top: -4px;
    font-size: 13px;

    p {
      font-size: 12px;
      margin: 3px 0px;
      color: #ff6060;
    }

    span {
      font-size: 12px;
      margin: 3px 0px;
      color: white;
      text-decoration: underline;
      text-transform: uppercase;
      margin-left: 5px;
      cursor: pointer;
    }
  }

  &.timer {
    color: #ff6060;
    display: flex;
    justify-content: flex-end;
    margin-top: -10px;
    font-size: 14px;
    font-style: italic;
  }

  & .error-wrap {
    & p {
      font-size: 15px;
      text-align: center;
      color: white;
      font-weight: 500;
      background-color: #c91414;
      padding: 5px;
      border-radius: 30px;
    }
  }
  & .success-wrap {
    & p {
      font-size: 15px;
      text-align: center;
      color: white;
      font-weight: 500;
      background-color: green;
      padding: 5px;
      border-radius: 30px;
    }
  }
`;

const PasswordHide = styled.div`
  display: block;
  width: 5px;
  position: absolute;
  right: 25px;
  top: 21px;
`;

const PromoInputText = styled.p`
  margin: 3px 0px;
  font-size: 14px;
  color: white;
  width: 60%;
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;

  & svg {
    margin: 0px 3px;
  }

  & span {
    text-decoration: underline;
    letter-spacing: 0.5px;
  }
`