import React from 'react'
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';
import { theme } from 'styled-tools';

function SeoContentHomePage(props) {
    const { app: {
        brandId
    }, theme :{config}} = props;
    const { showSeoFooterInsidePages } = config
    
    let domainURL = 'https://www.yolo247.com';
    if (brandId === 32) {
        domainURL = 'https://www.yolo247.club';
    }
    else if (brandId === 33) {
        domainURL = 'https://www.baaziadda.com';
    }
    else if (brandId === 34) {
        domainURL = 'https://www.betdaily.club';
    }
    // const domainName = (brandId === 31 || brandId === 32) ? 'Yolo247' : 'Betdaily';
    const domainName = brandId === 31 || brandId === 32 ? 'Yolo247' : brandId === 33 ? 'Baazi Adda' : 'Betdaily' ;

    return (
        <>
            <AccordionWrapper>
                <Accordion>
                    <input type="checkbox" id="section1" className="accordion__input" />
                    {/* <label htmlFor="section1" className="accordion__label"><h1>{h1Txt}</h1></label> */}
                    {/* <label htmlFor="section1" className="accordion__label"><h1>Online Betting in India</h1></label> */}
                    <label htmlFor="section1" className="accordion__label"><h1>{brandId == 32 ? "Online Betting ID in India" : "Online Betting in India"}</h1></label>
                    <div className="accordion__content">
                        {/* <h2>{domainName} — Your Online Betting Partner</h2> */}
                        <h2>Your Online Betting Partner</h2>
                        <p>
                            Welcome! <br />
                            If you’re looking for the best online betting site in India, congratulations – you have come to the right place to bet online.<br />
                            With an international gaming license,{domainName} leverages its international betting experience to bring you the widest range of online sports betting and <a href={`${domainURL}/online-casino-games`}>casino games</a> in India. <br />
                            You wouldn’t need another online betting site again with the closest odds and incredible selection of online betting options available right from your mobile device. Take advantage of our sizable <a href={`${domainURL}/promotions/welcome-bonus`}>welcome offer</a>, aggressive promotional bonuses, and global sports betting coverage of all your favourite events, <a href={`${domainURL}/online-casino-games`}>casino games</a> and e-sports.<br />
                            What's the hold up?  On our incredible betting site, you are just a click away from more pre-match, <a href={`${domainURL}/betting-exchange/online-cricket-betting`}>live cricket betting</a>, and <a href={`${domainURL}/virtualSports/vci`}>virtual sports bets</a> than India's ever seen!<br />
                        </p>
                        <p>We have <a href={`${domainURL}/sportsbook`}>Sportsbook</a>, <a href={`${domainURL}/betting-exchange`}>Sports Exchange</a>, <a href={`${domainURL}/online-casino-games`}>Live casino</a>, India card games and a whole lot of games to play and make money while being entertained!</p>
                        <h2> Why Choose {domainName} For Betting in India, You Ask?</h2>
                        <p>As your go-to destination for sports betting, live sports betting, <a href={`${domainURL}/online-casino-games`}>casino games</a>, online betting games, and other forms of digital entertainment, {domainName} stands out among the competition for the following reasons:</p>
                        <ul>
                            <li>There are numerous <a href={`${domainURL}/online-casino-games`}>casino games</a> and a variety of international sports bets are available.</li>
                            <li>We feature excellent odds and exceptional betting promotions. </li>
                            <li>{domainName} makes it incredibly simple and quick to deposit and withdraw money.</li>
                            <li>We are one of the few websites that offer online betting in Indian rupees.</li>
                            <li>Our customer support is available for you 24/7.</li>
                            <li>Our online betting application is the best in India.</li>
                            <li>{domainName} is a completely authorized and legitimate betting company.</li>
                            <li>We keep things simple, to the point & customer centric</li>
                        </ul>

                        <h2>So what about other Betting Sites online? </h2>
                        <p>India is a nation where interest in this genre of entertainment is exploding when it comes to online betting and betting games. A betting website with the finest odds, sports betting markets, bonuses, and customer support is what gamblers are looking for. Companies simultaneously provide a variety of platforms for gamers to try their luck. But are they all as reliable for placing bets?</p>
                        <p>You could (and ought to) conduct your own study, no doubt. But here's something to think about: out of all the sports betting sites, the most dependable ones have a lot of expertise, a big international presence, alliances with reputable companies and athletes, and round-the-clock customer service. {domainName} can provide you with trustworthy <a href={`${domainURL}/app-download`}>online betting apps</a> if you're looking for them in India. In actuality, not many online bookmakers can take pride in all these accomplishments. However, {domainName} is a business that excels in each of these fields.</p>

                        <h2>Top Games</h2>
                        <p>We at {domainName} offer a variety of satta game online for every kind of player - from teen patti to slots and casino to jumanji. You name it and we have it all! </p>
                        <p>Teen Patti, Roulette, Black Jack, Baccarat, and Dragon Tiger are among the most popular games which are enjoyed by everyone on our platform. To be an unbeatable player, all of these games require consistent practice and mastery of skills. Some players enjoy teen patti which has been the most loved game by far on {domainName}. <a href={`${domainURL}/online-casino-games`}>Casino games</a> like roulette, baccarat, and black jack are also equally appreciated table games among our players. These <a href={`${domainURL}/online-casino-games`}>casino games</a> are too good to be a pass for any regular player.</p>
                        <p>Outcomes of these games revolve around your decisions which greatly impacts your earnings during the play. We suggest to play with basic strategy and advise not to bet more than what you can afford. {domainName} always applauds responsible players. </p>
                        <p>Enjoy Great Winnings in <a href={`${domainURL}/online-casino-games`}>Casino Games</a> at {domainName}!</p>

                        <h2>Sports betting</h2>
                        <p><strong>Sports betting</strong> involves placing a bet (also called wager) on the probable outcome of the game. In sports betting, there could only be two outcomes either you might earn a good profit or lose your money depending upon the bet. </p>
                        <p>Bettors (also called Punters) can engage in sports satta on online websites which are known by other names like <a href={`${domainURL}/sportsbook`}>sportsbook</a>, bookie, or betting agency. There are also betting exchange marketplaces that allow you to bet on both back and lay of a sports event. Back means wagering in favour of an outcome & lay is when you bet against the outcome.</p>
                        <p>Most popular sports betting games available </p>
                        <p>A number of sports are solely watched for the purpose of wagering. Sports betting is not limited to just one game, punters globally enjoy betting on games like soccer, baseball, hockey, cycling, boxing, horse racing, etc.</p>
                        <p>The betting popularity of each game may vary depending upon the culture and countries. The Americans are extremely fond of <a href={`${domainURL}/betting-exchange/football-betting`}>betting on football</a> and NBA events whereas the Britishers have a likeness towards tennis. Based on these betting choices, we can say that the five most popular betting games around the world are Football, Tennis, Basketball, and Cricket. </p>
                        <p>At {domainName}, we have a variety of sports satta options for every kind of sports fan. Primarily these games are Cricket, Football, and Tennis due to their unmatchable popularity in India. </p>

                        {/* <h3>Game Rules, Strategies, tips</h3>
                        <p>The game rules usually vary within countries and states. If you understand the general rules of sports betting, it might get easier to bet on sports events whether the bet is placed online or in person. The general sport rules are followed everywhere but the rules & regulations may differ from one <a href={`${domainURL}/sportsbook`}>sportsbook</a> to another. In America, a plus (+) and minus (-) style is used while betting. </p>
                        <p>In sports betting, the odds majorly dictate the outcome of a game. The players should consider all the previous stats & history before deciding a possible odd that would help them win a bet. It’s also advised to divide your bets in spread rather than putting in all your money on a certain outcome. It is extremely rare that people win a large sum of money straight-out, to avoid losses place small bets. </p> */}

                        {/* <h3>Game Offers, Benefits</h3>
                        <p>The benefits of sports betting can be different for every player. Players have different reasons that prompted them to start betting in the first place. These reasons can be income creation, entertainment, thrill, ease, and convenience. Players find it fun and entertaining to wager on live sports satta, it gives them a sense of thrill to guess the odds and the excitement even doubles up when their odds win them money. Online <a href={`${domainURL}/sportsbook`}>sportsbooks</a> offer a sense of comfort and handiness to place bets from anywhere anytime for the players. The players get an array of benefits and offers from sign up to referral and cashback to refill bonuses. At {domainName}, we have it ALL!</p> */}

                        <h3>How to bet on games</h3>
                        <p>Most of these games are listed with a favourite (the team likely to win) and an underdog (the team that will lose). There are several types of bets that affect the winnings of your wager. In order to place a bet, you need to know what each type of bet means. Following are the types of bets you should know about before you start betting - Straight, Spread, Parlay, Over/Under, Future, Prop, etc.</p>
                        <p>Straight Bet - It’s also known as a Single bet where the bettor only predicts the possible winner of the game. </p>
                        <p>Spread Bet - Point spread is a bet on the margins of the victory. </p>
                        <p>Parlay Bet - It is a combination of more than one straight wagers in a single bet which could for a single or multiple games.</p>
                        <p>Over/Under Bet - Here the players bet on the final score of the game predicting whether it would be over or under of the total runs/points/goals.</p>
                        <p>Future Bet - This bet is placed on the outcome of a future game like betting on the possible winning country, player or team.</p>
                        <p>Prop Bet - Prop bet is a wagering on a certain player’s performance during a sport.</p>
                        <p>Once you have chosen the sport & the kind of bet you want to place, just click on the bet cell and it will be copied in your bet slip. Thereafter, you can enter the bet amount and submit. If you win the odds, the amount will be reflected in your account depending on the rules of the <a href={`${domainURL}/sportsbook`}>sportsbook</a>.</p>

                        <h3>Did you Know?(FACTS)</h3>
                        <p>These fun facts will show you how much we all love sports betting globally. To settle your curiosity, we have listed a few facts that all players will find interesting.</p>

                        <ul>
                            <li>Horseracing is the most popular animal sport to bet on.</li>
                            <li>Football sports betting is at top globally.</li>
                            <li>There is only a 50% win rate even for professional bettors.</li>
                            <li>The country that places most number of bets is UK.</li>
                            <li>Most punters skip sport research when placing bets.</li>
                            <li>Most bets placed are induced by emotion.</li>
                            <li>Two biggest betting months - September &amp; December.</li>
                            <li>Sports satta can be illegal yet legal.</li>
                            <li>Americans are known for placing the largest bets.</li>
                            <li>Baseball is the toughest sport to bet on. Imagine 162 games in a season!</li>
                        </ul>

                        <h3>How to play free/paid?</h3>
                        <p>To take part in sports betting, follow these mentioned steps to help you through the process as a beginner.</p>

                        <ul>
                            <li>Sign up on {domainName} to open an account for betting.</li>
                            <li>Finalise an initial budget that you can afford to lose as a beginner. Deposit & claim your welcome bonus</li>
                            <li>Select the sport/game you would like to bet on, this can be chosen based on your interests & experience.</li>
                            <li>Check videos and blogs to learn about the betting process & game basics before placing any bets.</li>
                            <li>Select the type of bet you would like to place.</li>
                            <li>Mention your betting amount and submit the bet.</li>
                        </ul>

                        {/* <h2>What Is Sports Betting?</h2>
                        <p>Before you place an online bet, let's go through the fundamentals and define them. Predicting the outcomes of various sporting events is known as sports betting. People bet money on a particular outcome, and if their predictions come true, they get paid. There is currently a sizable selection of online betting services in India. Let's quickly go over the most common betting types we have on our betting platform.</p> */}

                        <h2>Online Sports Betting Odds</h2>
                        <p>You need to have a solid comprehension of the betting odds and options provided by a bookmaker whether you are just beginning your journey in the world of gambling or you are into professional sports betting.</p>
                        <p>{domainName} uses decimal odds, making it incredibly apparent what your chances are of winning an online bet. The odds show both your potential for winning and the projected likelihood that a particular team or athlete will triumph. You have a better chance of winning when the odds are lower. The reward you receive will be modest at the same time. Big odds, on the other hand, suggest high rewards, though the likelihood of success is smaller.</p>
                        <p>{domainName}, one of the most reputable online <a href={`${domainURL}/sportsbook`}>sportsbook</a> in India, has earned a reputation as one of the finest betting sites in India by offering the most advantageous odds. We dare you to look everywhere. {domainName} offers the best online gaming odds available in India.</p>
                        <p>So, whether you prefer betting on baseball, football, basketball, kabaddi, cricket, or any other sport, you can count on finding competitive odds here. Simply look for it on our online sports betting site.</p>
                        <p>You'll be astounded by how profitable your online bets may be when you're rolling the dice with {domainName} online, from our traditional sports betting to our cutting-edge virtual sports platform.</p>

                        <h3>{domainName} Pre-match Betting Odds</h3>
                        <p>You may place both pre-match and live bets online at {domainName}. Pre-match betting refers to the practice of making bets before the start of a sporting event. You evaluate the chances and odds for both sides to win and make your bets beforehand. You cannot make modifications after the sporting event has begun.</p>
                        <p>Pre-match bets allow you the chance to do a more thorough analysis before making your selections. Additionally, since you don't need to make any decisions right away and don't have to waste time watching the entire action live, you can bet more strategically.</p>

                        <h3>{domainName} Live Betting Odds</h3>
                        <p>Live betting India is usually more exciting as in this case, you are allowed to place your wagers when the match is already begun. It is also known as in-play betting. This option allows bettors to place their wagers throughout the match.</p>
                        <p>The main distinctive feature of this option is that the odds change in response to what is happening during the event. Therefore, you can immerse yourself in the gameplay of betting in India and catch the most profitable odds during the match.</p>

                        {/* <h2>Sports Betting Recommendations by {domainName}</h2>
                        <ul className='number'>
                            <li>Follow large international sports events
                                <p>Even though you are practicing sports betting in India, you are not constrained to solely local sporting events. You can participate in any athletic event globally by using the {domainName} sports betting app. While you are watching the games from Delhi, enjoy placing bets on the English Premier League. Bet on Federer to win Wimbledon while remaining in Mumbai.</p>
                                <p>The worldwide details about your favourite teams are more thrilling, but they're also frequently simpler to forecast because there are so many resources available.</p>
                            </li>
                            <li>Consider all of your online betting options
                                <p>You may place bets on a variety of events with {domainName} online betting India, including totals bets, handicap betting, even/odd plays, and double chance bets. Bet on points, goals, complete games, halftime results, sets, and other outcomes.</p>
                            </li>
                            <li>Employ the tools to maximize your winnings
                                <p>Did you know that you might test multiple bets in place of singles? Placing parlay bets will greatly increase your winnings! The trick is that by multiplying the odds of each bet you make in a parlay, you increase your chances of winning a sizable sum of money. The best part is that you can focus on the game rather than getting lost in the numbers because our state-of-the-art online betting platform will calculate your potential earnings in just a few seconds!</p>
                            </li>
                            <li>Don’t bet more than you can afford
                                <p>Online sports betting should continue to be a good time for you. So keep your excitement in check and refrain from betting money you can't afford to lose. Online gaming responsibly has our full support!</p>
                            </li>
                        </ul> */}

                        <h2>{domainName} Betting App</h2>
                        <p>Making bets while you're on the run is the most exciting thing ever. If you are a serious sports bettor, you value the ease of sports betting tools, and you will surely respect the {domainName} online bet app's usefulness. A user-friendly program makes it possible for sports gamblers to place bets while they're on the go. Even novice gamblers will probably understand how everything works with our mobile app because it is rather simple to use.</p>
                        <p>Versions for iOS and Android-based devices are currently available. Download the one you require, create an account, make a deposit, and start betting on sports.</p>
                        <p>Making mobile betting convenient is the main goal of our mobile app. The same sporting events are covered there as on our website. You won't miss anything as a result. Additionally, you'll be able to receive immediate updates when your bet wins! Download the {domainName} app to stay up to date with the most recent news in the Indian sports betting industry!</p>

                        <h2>Online Betting Offers for You</h2>
                        <p>Online sports betting is always accompanied by significant bonuses, promotions, and other pleasant surprises when using a competent and trustworthy betting site. {domainName} is always aiming to give you the finest benefits for winning bets, including:</p>
                        <ul>
                            <li>Welcome bonus.</li>
                            <li>Seasonal and personal promotions.</li>
                            <li>Top parlays selections & more.</li>
                        </ul>
                        <p>Sign up today to receive some of the greatest online betting promos in India!</p>
                        <p>So, there is no need to wait. India is taking an increasing interest in the rise of international sports betting, and you are about to experience the thrill for yourself! Get the online betting app, register for a free account, and start winning big with {domainName} betting India!</p>
                        <br />
                        {/* <strong>Loyalty Programs/Bazaar</strong><br />
                        <strong>Your Private/Personal Token to Privileged Betting</strong><br />
                        <p>We offer a variety of unique loyalty programs to suit every bettor’s needs. These programs are crafted with utmost attention to customer benefits and rewards. We are giving our customers the flexibility to choose what fits them best.</p>
                        <p>With these loyalty tokens, you can get exclusive access to bonuses and cashback. For Pro Gamblers, we have specially curated limited programs with celebrity dinners, event invites and customised gifts.</p>
                        <p>Pick a Token NOW to Unlock your Benefits!</p> */}

                        <h3>BLUE TIER/CARD/BADGE/TOKEN</h3>
                        <ul>
                            <li>2% Bonus on Every Redeposit</li>
                            <li>2% Cashback Bonus on Weekly Loses</li>
                            <li>Minimum Turnover Required - NA</li>
                        </ul>

                        <h3>SILVER</h3>
                        <ul>
                            <li>3% Bonus on Every Redeposit</li>
                            <li>3% Cashback Bonus on Weekly Loses</li>
                            <li>Minimum Turnover Required - 10L per Month</li>
                        </ul>

                        <h3>GOLD</h3>
                        <ul>
                            <li>2 Exclusive Event Invites</li>
                            <li>4% Bonus on Every Redeposit</li>
                            <li>4% Cashback Bonus on Weekly Loses</li>
                            <li>Minimum Turnover Required - 25L</li>
                        </ul>

                        <h3>PLATINUM</h3>
                        <ul>
                            <li>Celebrity Dinner Invites</li>
                            <li>5 Exclusive Event Invites</li>
                            <li>5% Bonus on Every Redeposit</li>
                            <li>5% Cashback Bonus on Weekly Loses</li>
                            <li>All Inclusive Foreign Trip for Two</li>
                            <li>Minimum Turnover Required - 1 Crore</li>
                        </ul>

                        <h3>ELITE</h3>
                        <ul>
                            <li>By Invitation ONLY</li>
                        </ul>

                        <h2>Payment options</h2>
                        <p>{domainName} has multiple payment options to deposit and withdraw. So, choose your favourite or most convenient option and play! Winnings can be withdrawn as per the condition mentioned.</p>


                        {showSeoFooterInsidePages ? <div>
                            {/* <h2> Yolo247 | Online Betting in India</h2>
                            <p>
                                Online Betting in India is no longer just about winning, but about experience, enrichment and exposure.

                                Yolo247 addresses this niche and provides a holistic online betting experience. It encompasses sports, casino and live games.
                            </p> */}
                            {/* <h3> Sports Betting in India</h3>

                            <p>In India, almost 150 Million users bet online regularly. The number is also increasing every day. Among all the online bets, sports betting tops the list.

                                In Sports Betting, Cricket is the No. 1 Betting option in India.

                                Go through our cricket betting platform and start to bet on cricket online.

                                We offer more than 20 sports for online betting in India. From everyone’s favourite cricket to tennis and football to basketball.

                                We are sure you will find your favourite sport on our list. And when you already love the sport, then why not make enough money with it using online betting?

                                Make sure to go through this list of some popular sports you can bet on:
                            </p>
                            Cricket<br></br>
                            Soccer<br></br>
                            Basketball<br></br>
                            E-Sports<br></br>
                            Tennis<br></br>
                            Baseball<br></br>
                            Volleyball<br></br>
                            Table Tennis<br></br>
                            <h3> Casino in India</h3>

                            <p>Broadly, there are two types of <a href={`${domainURL}/online-casino-games`}>casino games</a>. Be it online or offline, all casinos operate with these two types.
                            </p> */}
                            {/* <h4> Skill-Based <a href={`${domainURL}/online-casino-games`}>Casino Games</a></h4>
                            <p>This is a set of games that are primarily based on skills, strategies and calculations. Though we can’t ignore the ‘luck’ aspect, how-a-player-decides matters more in these games.
                            </p>
                            <h4> Luck-Based <a href={`${domainURL}/online-casino-games`}>Casino Games</a></h4>
                            <p>Luck-based <a href={`${domainURL}/online-casino-games`}>Casino Games</a> are usually more popular and quicker in terms of game duration. Many betting enthusiasts believe that every player must follow certain strategies even in luck-based games.

                                You have a chance to play your favourite <a href={`${domainURL}/online-casino-games`}>Casino games</a> anytime anywhere. Choose from a wide variety of western and Indian-origin games.

                                No need for any other devices, just play from your mobile phone! Master these satta game online to win real money and become the king of skills with a little bit of luck.

                                Check out this list of the interactive games that we offer at YOLO247:
                            </p> */}
                            <a href={`${domainURL}/online-casino-games/blackjack`}>Blackjack</a><br></br>
                            <a href={`${domainURL}/teenpatti-online-play`}>Teen Patti</a><br></br>
                            Andar Bahar<br></br>
                            <a href={`${domainURL}/online-casino-games/dragon-tiger`}>Dragon Tiger</a><br></br>
                            War of Bets<br></br>
                            Lightning Dice<br></br>
                            <a href={`${domainURL}/online-casino-games/roulette`}>Roulette</a><br></br>
                            Dream Catcher<br></br>
                            <a href={`${domainURL}/online-casino-games/baccarat`}>Baccarat</a><br></br>
                            Bingo Machine<br></br>
                            Golden Egg<br></br>
                            Dead or Alive<br></br>
                            Drive Multiplayer Mayhem<br></br>
                            Fruit Shop<br></br>
                            Rome The Golden Age<br></br>
                            Gonzo’s Quest<br></br>
                            Halloween Jack<br></br>
                            Jumanji<br></br>
                            Space Wars<br></br>
                            Tak Olympus<br></br>
                            Monster Pop<br></br>
                            Back To Venus<br></br>
                            Fruit Zen<br></br>
                            Sugar Pop<br></br>
                            Great Rhino<br></br>
                            S Lions<br></br>
                            3 Kingdoms Battle of Red Cliffs<br></br>
                            3 Genie Wishes<br></br>
                            Mighty Kong<br></br>
                            Ocean Treasures<br></br>
                            Panda’s Fortune<br></br>
                            Tales of Egypt<br></br>
                            Vegas Magic<br></br>
                            <h2> What makes Yolo247 the best site for sports betting and Casino in India?</h2>
                            <p>
                                Our unique and open environment is continuously growing with new <a href={`${domainURL}/online-casino-games`}>casino games</a> and new users each day. We have an unmatched assortment of online sports betting and online <a href={`${domainURL}/online-casino-games`}>casino games</a> in varying categories.

                                Our carefully curated sets of games and virtual sports are the right fit for everyone, from beginners to emerging players and skill masters to leaders.

                                Our team takes immense pride in our exceptional 24x7 customer support services, interactive gaming environment, and continuous efforts for innovation.

                                To reinforce our dedication towards our players, we conduct regular analyses to identify potential chances to make online betting even more enjoyable.

                                We identify ourselves with every gaming enthusiast and betting aficionado.
                            </p>
                            <h3> Wide Variety of Games</h3>

                            <p>The games that we offer at Yolo247 are not limited to Western games alone, but also include popular Indian games.

                                We strive hard to provide variations for all kinds of betting enthusiasts. When we have a variety of options to choose from, we can make better decisions.
                            </p>
                            <h3> For all sports & sporting events</h3>

                            <p>Yolo247 is open for all sports and matches. It includes regional, national and international matches and tournaments for all sports.

                                Betting odds, options, types and live scores are available for these sports. You may go to the dedicated sports page or an event-specific separate page to initiate your bets.
                            </p>
                            <h3> 24x7 Customer Live Support</h3>

                            <p>Increasing interaction and engagement among users directly correlates with trust and security.

                                We are constantly in touch with the users through a 24x7 chat box. Our experienced customer executives ensure that no question is left unanswered and no user is left unattended.
                            </p>
                            <h3> Withdraw anytime</h3>

                            <p>All betting journeys culminate in withdrawal. If the withdrawal process is too long or too complicated, it may ruin the entire betting experience.

                                At Yolo247, we ensure that our withdrawal process is as simple as our registration process.
                            </p>
                            <h3> Exciting Rewards</h3>

                            <p>All betting journeys culminate in withdrawal. If the withdrawal process is too long or too complicated, it may ruin the entire betting experience.

                                At Yolo247, we ensure that our withdrawal process is as simple as our registration process.
                            </p>
                            <h3> Blogs, Guides, Tips & Strategies</h3>
                            <p>Learning is required to master any sport or activity. To strengthen your betting skills, we have regularly updated tips, strategies, guides and blogs. Both long form and short form content are generated to provide insights for all games and sports.
                            </p>
                            <h3> News & Predictions</h3>

                            <p>Yolo247 News and predictions are up-to-date and generated based on real-time data. For all sports, we offer a dedicated sports news section.

                                Our pre-match and post-match analyses are prepared by domain experts. For making informed decisions, it is helpful to know expert opinions.
                            </p>
                            <h3> Live Scores & Updates</h3>

                            <p>An exclusive strip for live scores, both cricket and football, is available on all pages. However, a dedicated scorecard is provided for all matches separately as well.

                                You can go through a special section of key moments in all important matches.
                            </p>
                            <h3> Best Betting odds</h3>
                            <p>
                                We offer the most competitive betting odds for all sports and games. Your chance of winning is boosted to make betting even more enjoyable.

                                Your skillset, coupled with a bit of luck, is all you need to make your betting experience rewarding.
                            </p>
                            <h3> All Payment Methods</h3>

                            <p>Though we have separate sections for luck-based games and skill-based games, our payments are unified. All popular payment channels and modes are available on Yolo247.
                            </p>
                            <h3> Betting in Indian Rupees</h3>

                            <p>Though UPI payments are enabled, it will be even easier if there is any option for INR payments. You can make INR transactions on Yolo247 without any hassle.
                            </p>
                            <h2>Betting Options Available in {domainName}?</h2>

                            <p>Starting your betting journey is simple and smoother on {domainName}.</p>

                            Step 1: Visit Yolo247 Website & click Sign Up.<br></br>
                            Step 2: Register with your mobile number & Verify.<br></br>
                            Step 3: Deposit the amount of your choice.<br></br>
                            Step 4: Claim the Welcome Bonus & start placing your bets.<br></br>
                            Step 5: Play. Win. Withdraw. Seamlessly.<br></br>
                            {/* <h3> Yolo247 Login</h3>

                            <p>Your Yolo247 Login is safe and secure. Keep the password and username safer and never share them with anyone.

                                You can add your deposits to your wallet. Your profits will also be credited directly there. Whenever you need to withdraw your profits, you can choose to have them instantly credited to your bank account.

                                It all happens through your login credentials. So, ensure that it is kept safe.

                            </p> */}
                            <h3> Yolo247 Betting App</h3>
                            <p>
                                To make the betting journey simpler and more accessible, we have a mobile application as well.

                                You may simply download the app, use your credentials and start placing bets.

                                The app version just mirrors the website and it is much faster.

                                Our website also allows punters to engage in a variety of sports betting and exchanges. The thrill of online betting is incomparable and unmatchable to any game.

                                The outcomes of these betting exchanges and games revolve around your decisions which significantly impact your earnings during the play. We suggest playing with a basic strategy and advise you not to bet more than what you can afford. Yolo247 always applauds responsible players.

                                Enjoy Great Winnings in <a href={`${domainURL}/online-casino-games`}>Casino Games</a> at Yolo247!
                            </p>
                            <h3>Start Playing your Favorite Game with {domainName}</h3>
                            <p>
                                Everyone has heard of gambling or has been a part of an online casino at least once. Some gamble for money, while others enjoy it as a source of entertainment & personal challenge.

                                Choose the most reliable site, like Yolo247, before you place your bets. It is also recommended that you pick the games that suit your interests and preference.

                                On a lighter note, here are some interesting facts about online casino and gambling.The world’s first online casino Microgaming was launched in 1994 just 3 years after the birth of the internet.<br></br>
                                There are an equal number of female gamblers just as male players.<br></br>
                                Australia is the gambling capital of the world.<br></br>
                                Online casinos are on 365 days a year.<br></br>
                                90% of the slot machines are now online.<br></br>
                                The worldwide gambling population stands at 26%.<br></br>
                            </p>


                            <p>In the industry of gambling in India, there are many mobile apps for <a href={`${domainURL}/online-casino-games`}>casino games</a> and sports betting apps and online sports betting sites.

                                Yolo247, as an online <a href={`${domainURL}/sportsbook`}>sportsbook</a> for major sports and casino site for popular games, ensures a seamless process, excellent customer service, spread betting and prop bet options.

                                Begin your betting journey now!</p>
                        </div> : <></>}
                    </div>
                </Accordion>
            </AccordionWrapper>
        </>
    )
}

export default withTheme(withAppContext(SeoContentHomePage))

//Styled Components
export const Accordion = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    color: ${theme('palette.seoContenAccordionHeadTextColor')};
    background: ${theme('palette.seoContenAccordionHeadBg')};

    .accordion__label, .accordion__content{
        padding: 14px 20px;
    }

    h1 {
        display: block;
        font-size: 1em;
        font-weight: bold;
        margin: 0;
    }

    h2 {
        display: block;
        font-size: 1.5em;
        font-weight: bold;
    }

    h3 {
        display: block;
        font-size: 1.1em;
        font-weight: bold;
    }

    .number {
        list-style: number;

        li {
            font-weight: bold;
        }
    }

    ul {
        li {
            font-size: 0.91em;
        }
    }

    p {
        margin: 5px 0 10px 0;
        color: ${theme('palette.seoContenAccordionInnerTextColor')};
        font-size: 0.91em;
        font-weight: normal;
    }

    a {
        color: #f4a322;
    }

    .linkBg {
        display: flex;
        align-items: center;
        justify-content: left;
        flex-wrap: wrap;
        gap: 5px;

        a {
            background: #1e1e1e;
            border-radius: 5px;
            padding: 5px 10px;
        }
    }

    .textTransformNone {
        text-transform: none;
    }

    .accordion__label{
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${theme('palette.seoContenAccordionHeadTextColor')};
        padding: 20px;
        background: ${theme('palette.seoContenAccordionHeadBg')};
        text-align: center;
        position: relative;
        cursor: pointer;

        :after {
            content: "";
            display: inline-block;
            border-style: solid;
            border-color: ${theme('palette.seoContenAccordionArrowBorder')};
            border-width: 0 2px 2px 0;
            padding: 2.5px;
            vertical-align: middle;
            transition: transform 0.3s;
            transform: rotate(45deg);
            position: absolute;
            right: 20px;
        }
    }

    .accordion__label:hover{
        background: ${theme('palette.seoContenAccordionHeadHoverBg')};
    }

    .accordion__content{
        background: ${theme('palette.seoContenAccordionContentBg')};
        line-height: 1.6;
        font-size: 0.85em;
        opacity: 0;
        margin: 0 10px 10px;
        text-transform: capitalize;
        max-height: 0;
        padding: 0;
        margin: 0;
        transition: 0.3s;
    }

    .accordion__input{
        display: none;
    }

    .accordion__input:checked ~ .accordion__content{
        opacity: 1;
        overflow-x: hidden;
        overflow-y: auto;
        max-height: 300px;
        /* padding: 14px 20px; */
        padding: 1% 15%;
        margin: 0 10px 10px;
        &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
    border-radius: 10px;
  }

  &::-webkit-scrollbar {
    width: 10px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background:gray;
    margin-bottom: 10px;
  }
    }

    .accordion__input:checked ~ .accordion__label:after {
        transform: rotate(225deg);
    }

    .td,th{
        border: 1px solid white;
    }
  
    .table, th, td {
  border: 1px solid white;
  border-collapse: collapse;
}

`;

export const AccordionWrapper = styled.div`
  table,th,td{
    border: 1px solid white;
  }
`;