 const seoData = {
        "31": {
            "default": {
                "title": "Online Casino Games & Sports Prediction Platform - Yolo247",
                "description": "Explore a world of excitement at Yolo247, your gateway to thrilling online games and sports prediction. Play the most popular online real money games and seize your opportunity to win real cash prizes with amazing bonus.",
                "canonical": "https://yolo247.com",
                "alternate": "https://m.yolo247.com",
                "keywords" : "casino games, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
            },
            "onlineCasinoGames": {
                "title": "Play Online Casino Games Live | Get 400% Welcome Bonus",
                "description": "Online Casino games are revolutionizing the gaming world. Find out some of the popular live casino online games on Yolo247.",
                "canonical": "https://yolo247.com/online-casino-games",
                "alternate": "https://m.yolo247.com/online-casino-games",
                "keywords": " Online Casino Games, Live Casino Online, Play Casino games online"
            },
            "blackjack": {
                "title": "Play Blackjack Online at YOLO247 (Don't Get Bored Again) |Blackjack Game | Play Blackjack Online Casino Gambling Site",
                "description": "Play blackjack online on the most safest online casino. Register now at YOLO247 and get exclusive welcome bonus and offers to win your favorite games,Blackjack Game is now open for bets. Play Blackjack online on Yolo247, India’s leading casino gambling site. Bet with real money through UPI. Blackjack Game is a gambling activity that is popular in casinos throughout the world. It is also called Pontoon and Twenty-one.",
                "canonical": "https://yolo247.com/online-casino-games/blackjack",
                "alternate": "https://m.yolo247.com/online-casino-games/blackjack",
                "keywords": "blackjack online,blackjack game,playing blackjack,blackjack playing,play blackjack online,blackjack game rules,online blackjack real money,play blackjack,blackjack online game,online blackjack real money,black jack card games"
            },
            "roulette": {
                "title": "Play Roulette Online | Online Roulette Casino Game",
                "description": "Play Roulette Online with a wide range of betting options. Simple registration process and safer betting platform. Get to play online roulette casino game!",
                "canonical": "https://yolo247.com/online-casino-games/roulette",
                "alternate": "https://m.yolo247.com/online-casino-games/roulette",
                "keywords": "roulette game,roulette online,casino roulette,online roulette india,lightning roulette,online roulette game,roulette casino game,online roulette real money,play roulette online,casino roulette table"
            },
            "lightningroulette": {
                "title": "Play Lightning Roulette Online | Online Roulette Casino Game",
                "description": "Play Lightning Roulette Online with a wide range of betting options. Simple registration process and safer betting platform. Get to play online roulette casino game!",
                "canonical": "https://yolo247.com/online-casino-games/lightning-roulette",
                "alternate": "https://m.yolo247.com/online-casino-games/lightning-roulette",
                "keywords": "lightningroulette game,lightningroulette online,casino lightningroulette,online roulette india,lightning roulette,online roulette game,roulette casino game,online roulette real money,play roulette online,casino roulette table"
            },
            "dragonTiger": {
                "title": "Dragon Tiger Slot Game Online - Play for Free or Real Money",
                "description": "YOLO247 is the leading online casino to play roulette game in India. Play the best real money games here. Sign up now and win welcome bonus!,Dragon Tiger Game is easier to play and win. Register now for Dragon vs Tiger Game & start placing bets on the best casino in India.Dragon Tiger game is a simple yet fast-paced casino game, and it is positioned as Dragon vs Tiger Game.",
                "canonical": "https://yolo247.com/online-casino-games/dragon-tiger",
                "alternate": "https://m.yolo247.com/online-casino-games/dragon-tiger",
                "keywords": "Yolo247 is your best destination for playing Online Dragon Tiger Game. Sign up now and get welcome Bonus to test your luck with the most popular games."
            },
            "baccarat": {
                "title": "Baccarat Casino Online | Safest Baccarat Game Site in India",
                "description": "Baccarat Casino Online is ready to play. Play online Baccarat games in India on the safest game site. Experience the best gaming at Yolo247. ",
                "canonical": "https://yolo247.com/online-casino-games/baccarat",
                "alternate": "https://m.yolo247.com/online-casino-games/baccarat",
                "keywords": "baccarat game,baccarat online,baccarat casino,baccarat gaming,baccarat rule,baccarat game online,baccarat game rules,play baccarat online,baccarat online play,baccarat online free,best baccarat online casino,best casino for baccarat"
            },
            "teenpattiOnlinePlay": {
                "title": "Cashback Bonus | Best Cashback Casino Bonus on Yolo247",
                "description": "Teen Patti Online Play is the trend now! Play the best version of 3 Patti online. Register now for Teen Patti real cash game.",
                "canonical": "https://yolo247.com/teenpatti-online-play",
                "keywords": "teen patti ,teen patti game, teen patti online, teen patti casino"
            },
            "sportsBetting": {
                "title": "Betting Exchange Yolo247 | Best Sports Betting Site in India",
                "description": "Betting Exchange on Yolo247 provides the best sports betting experience. Place bets on any sport through one of the best betting exchange sites in India.",
                "canonical": "https://yolo247.com/betting-exchange",
                "alternate": "https://m.yolo247.com/betting-exchange?id=0",
                "keywords": "betting exchange"
            },
            "onlineCricketSattaBazar": {
                "title": "Bet on Cricket Online | Bet on Cricket Matches Worldwide 247",
                "description": "Join the best site for online cricket betting in India, Yolo247! Bet on Cricket Online & Enjoy Fast deposit & withdrawal.",
                "keywords": " Online Cricket Betting Cricket Betting, cricket betting odds, cricket betting site, best site to bet on cricket, best cricket betting site (s) in India, Yolo247 online cricket betting, Yolo247 cricket betting odds, Yolo247 cricket betting, cricket betting sites, online cricket satta bazar,",
                "canonical": "https://yolo247.com/betting-exchange/online-cricket-betting",
                "alternate": "https://m.yolo247.com/betting-exchange/online-cricket-betting",
            },
            "footballBetting": {
                "title": "Football Betting Online - Predict & Win Real Money on bet app | Football Betting | Best site for betting on Football in India",
                "description": "Online football betting games on YOLO247 offers the best odds on a safe and secure platform with a range of betting options for everyone. Place your bets today!, Football Betting app makes your experience smoother. Betting on football in India is easier. Best odds. Simple process. INR payments. Fast withdrawal.Football betting is gaining momentum, and betting on football in India is available now on many online gambling sites. The core reason is that football as a sport has gained a significant fan base in India.",
                "canonical": "https://yolo247.com/betting-exchange/football-betting",
                "alternate": "https://m.yolo247.com/betting-exchange/football-betting",
                "keywords": "football prediction site,best football prediction sites,betting app for football,football betting,football bet app,online football betting,betting football site,football betting sites,bet online football,bet football online,best football betting sites,best betting football sites"
            },
            "tennisBetting": {
                "title": "Tennis Betting in India | Bet on Tennis Matches Online",
                "description": " Tennis betting offers an opportunity to bet on tennis matches online. Get the best tennis odds on Yolo247. Available for all tournaments with better payouts.",
                "canonical": "https://yolo247.com/betting-exchange/tennis-betting",
                "alternate": "https://m.yolo247.com/betting-exchange/tennis-betting",
                "keywords": "predictions tennis,tennis predictions today,today tennis match prediction,betting on tennis tips,tennis betting,tennis betting online,tennis match prediction,tennis bet prediction,bet online tennis,online tennis betting sites"
            },
            "volleyballBetting": {
                "title": "Volleyball Betting in India | Bet on Volleyball Matches Online",
                "description": "Volleyball betting offers an opportunity to bet on volleyball matches online. Get the best volleyball odds on Yolo247. Available for all tournaments with better payouts.",
                "canonical": "https://yolo247.com/betting-exchange/volleyball-betting",
                "alternate": "https://m.yolo247.com/betting-exchange/volleyball-betting",
                "keywords": "predictions volleyball,volleyball predictions today,today volleyball match prediction,betting on volleyball tips,volleyball betting,volleyball betting online,volleyball match prediction,volleyball bet prediction,bet online volleyball,online volleyball betting sites"
            },
            "promotions": {
                "title": "Play Casino Games | Real Money Gambling | Satta Online 24/7",
                "description": "Play the most popular online casino games at YOLO247 & earn a chance to win real money. Get unbeatable welcome bonus & offers on your favorite satta games in India",
                "canonical" : "https://yolo247.com/promotions",
                "alternate": "https://m.yolo247.com/promotions",
                "keywords" : "casino games, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
            },
            "specialMarket": {
                "title": "Special Markets on Yolo 247",
                "description": "Special Markets on Yolo 247",
                "canonical" : "https://yolo247.com/specialMarket",
                "alternate": "https://m.yolo247.com/specialMarket",
            },
            "virtualSports": {
                "title": "Virtual Sports on Yolo 247",
                "description": "Virtual Sports on Yolo 247",
                "canonical" : "https://yolo247.com/virtualSports/vci",
                "alternate": "https://m.yolo247.com/virtualSports/vci",
            },
            "virtualSportsBL": {
                "title": "Virtual Sports on Yolo 247",
                "description": "Virtual Sports on Yolo 247",
                "canonical" : "https://yolo247.com/virtualSports/vbl",
                "alternate": "https://m.yolo247.com/virtualSports/vbl",
            },
            "virtualSportsFB": {
                "title": "Virtual Sports on Yolo 247",
                "description": "Virtual Sports on Yolo 247",
                "canonical" : "https://yolo247.com/virtualSports/vfb",
                "alternate": "https://m.yolo247.com/virtualSports/vfb",
            },
            "virtualSportsFEL": {
                "title": "Virtual Sports on Yolo 247",
                "description": "Virtual Sports on Yolo 247",
                "canonical" : "https://yolo247.com/virtualSports/vfel",
                "alternate": "https://m.yolo247.com/virtualSports/vfel",
            },
            "virtualSportsFEC": {
                "title": "Virtual Sports on Yolo 247",
                "description": "Virtual Sports on Yolo 247",
                "canonical" : "https://yolo247.com/virtualSports/vfec",
                "alternate": "https://m.yolo247.com/virtualSports/vfec",
            },
            "virtualSportsVTI": {
                "title": "Elevate Your Game with Virtual Tennis Betting ID",
                "description": "Discover the ultimate virtual tennis betting ID for an unparalleled gaming adventure. Predict, bet, and win big with our cutting-edge platform. Join now!",
                "canonical" : "https://yolo247.com/virtualSports/vti",
                "alternate": "https://yolo247.com/virtualSports/vti",
            },
            "virtualSportsWC": {
                "title": "Navigating Virtual World Cup Betting ID Like a Pro | Bet Smart,Win Big",
                "description": "Explore thrilling with virtual World Cup betting ID services for an immersive gaming experience like never before!",
                "canonical" : "https://yolo247.com/virtualSports/vfwc",
                "alternate": "https://yolo247.com/virtualSports/vfwc",
            },
            "slotGames": {
                "title": "Casino Slot Games for Real Money on Yolo247 | Play Now!",
                "description": "Casino slot games are high on entertainment. Play slot games for real money on Yolo247 and earn exciting rewards.",
                "canonical": "https://yolo247.com/online-casino-games/slotGames",
                "alternate": "https://m.yolo247.com/online-casino-games/slotGames",
                "keywords": "slot games for real money, casino slot games"
            },
            "aviator": {
                "title": "Aviator Game | Play Aviator Online Game with real money",
                "description": "Aviator Game is the most profitable online casino game now. You can register and play aviator online game with real money. Fastest profit withdrawals!",
                "canonical": "https://yolo247.com/instant-games/aviator",
                "alternate": "https://m.yolo247.com/instant-games/aviator",
                "keywords": "Aviator game, Aviator online game "
            },
            "cashshow": {
                "title": "Cash Show Game | Live Casino Online Games on Yolo247",
                "description": "Online Casino games are revolutionizing the gaming world. Find out some of the popular live casino online games on Yolo247.",
                "canonical" : "https://yolo247.com/instant-games/cashshow",
                "alternate": "https://m.yolo247.com/instant-games/cashshow",
                "keywords" : "Cash Show game, Cash Show online game "
            },
            "jetx": {
                "title": "Jet X Game | Live Casino Online Games on Yolo247",
                "description": "Online Casino games are revolutionizing the gaming world. Find out some of the popular live casino online games on Yolo247.",
                "canonical" : "https://www.yolo247.com/instant-games/jetx",
                "alternate": "https://m.yolo247.com/instant-games/jetx",
                "keywords" : "Jet X game, Jet X online game "
            },
            "rockpaperscissors": {
                "title": "Rockpaperscissors ID - Grab Your Exclusive Deals Now!",
                "description": "Explore the thrill of rockpaperscissors ID gaming. Join the fun with our interactive platform and challenge opponents worldwide",
                "canonical" : "https://yolo247.com/instant-games/ROCKPAPERSCISSORS",
                "alternate": "https://yolo247.com/instant-games/ROCKPAPERSCISSORS",
                "keywords" : "rockpaperscissors id, rockpaperscissors game, rockpaperscissors online"
            },
            "plinkox": {
                "title": "Plinkox Game | Live Casino Online Games on Yolo247",
                "description": "Online Casino games are revolutionizing the gaming world. Find out some of the popular live casino online games on Yolo247.",
                "canonical" : "https://www.yolo247.com/instant-games/plinkox",
                "alternate": "https://m.yolo247.com/instant-games/plinkox",
                "keywords" : "Plinkox game, Plinkox online game "
            },
            "footballx": {
                "title": "Football X Game | Live Casino Online Games on Yolo247",
                "description": "Online Casino games are revolutionizing the gaming world. Find out some of the popular live casino online games on Yolo247.",
                "canonical" : "https://www.yolo247.com/instant-games/footballx",
                "alternate": "https://m.yolo247.com/instant-games/footballx",
                "keywords" : "Football X game, Football X online game "
            },
            "balloon": {
                "title": "Balloon Game | Live Casino Online Games on Yolo247",
                "description": "Online Casino games are revolutionizing the gaming world. Find out some of the popular live casino online games on Yolo247.",
                "canonical" : "https://www.yolo247.com/instant-games/balloon",
                "alternate": "https://m.yolo247.com/instant-games/balloon",
                "keywords" : "Balloon game, Balloon online game "
            },
            "mines": {
                "title": "Mines Game | Live Casino Online Games on Yolo247",
                "description": "Online Casino games are revolutionizing the gaming world. Find out some of the popular live casino online games on Yolo247.",
                "canonical" : "https://www.yolo247.com/instant-games/mines",
                "alternate": "https://m.yolo247.com/instant-games/mines",
                "keywords" : "Mines game, Online mines game"
            },
            "macludo": {
                "title": "Macludo Casino Games Online - Play & Win 24/7",
                "description": "Ludo Game is lucrative. Register and get the best gaming experience. Play Ludo Game online for real cash and win. Get the profits directly. ",
                "canonical" : "https://yolo247.com/online-casino-games/macludo",
                "alternate": "https://m.yolo247.com/online-casino-games/macludo",
                "keywords" : "Ludo Game, Ludo game Online"
            },
            "crazytime": {
                "title": "Crazytime Game | Play Ludo Game Online for real cash",
                "description": "Crazytime Game is lucrative. Register and get the best gaming experience. Play Ludo Game online for real cash and win. Get the profits directly. ",
                "canonical" : "https://www.yolo247.com/online-casino-games/crazytime",
                "alternate" : "https://m.yolo247.com/online-casino-games/crazytime",
                "keywords" : "Crazytime Game, Crazytime game Online"
            },
            "welcomeBonus": {
                "title": "Betting Promotions at Yolo247 | Best bonuses & offers in India",
                "description": "Betting Promotions tailor-made for you! Get cash back, refill & referral bonuses. Exciting betting offers. Grab the best deals for your next bet. ",
                "canonical" : "https://yolo247.com/promotions/welcome-bonus",
                "alternate": "https://m.yolo247.com/promotions/welcome-bonus",
                "keywords" : "Welcome Bonus"
            },
            "premiumSportsbook": {
                "title": "Premium Sportsbook Betting | Best Online Sportsbook In India",
                "description": " Premium Sportsbook Betting on Yolo247 brings in various options to bet on. Get the best odds for all sports & enjoy the best sports betting experience",
                "canonical" : "https://yolo247.com/premium-sportsbook",
                "alternate": "https://m.yolo247.com/premium-sportsbook",
                "keywords" : "Premium Sportsbook Betting ,Best Online Premium Sportsbook Betting, Sports Betting in India"
            },
            "refillBonus": {
                "title": "Refill bonus | Best Casino Refill Bonus on Yolo247",
                "description": "Yolo247 Daily Deposit Bonus brings in a plethora of casino cashback opportunities. Know the rules, process, T&C of Daily Deposit Bonus on Yolo247.",
                "canonical" : "https://yolo247.com/promotions/refill-bonus",
                "alternate": "https://m.yolo247.com/promotions/refill-bonus",
                "keywords" : "Refill bonus"
            },
            "cashbackBonus": {
                "title": "Cashback Bonus | Best Cashback Casino Bonus on Yolo247",
                "description": "Cashback Bonus for all top games & bets! Claim your cashback casino bonus. Better Offers with Bigger Opportunities! Grab the deal now.",
                "canonical" : "https://yolo247.com/promotions/cashback-bonus",
                "alternate": "https://m.yolo247.com/promotions/cashback-bonus",
                "keywords" : "Cashback Bonus"
            },
            "referralBonus": {
                "title": "Yolo247 Referral Bonus | Get Your Yolo247 Referral Code",
                "description": "Yolo247 Referral Bonus is open for old & new users now. Get Yolo247 Referral Code today. Referral codes are available on site now.",
                "canonical" : "https://yolo247.com/promotions/referral-bonus",
                "alternate": "https://m.yolo247.com/promotions/referral-bonus",
                "keywords" : "Referral Bonus"
            },
            "termsandconditions": {
                "title": "Terms & Conditions | Yolo247 Online Betting T&C",
                "description": "Terms & Conditions of Yolo247 updated! Check the obligations, terms of use, registration details, rules of play, deposit & withdrawal policies and more.",
                "canonical" : "https://yolo247.com/termsandconditions",
                "alternate": "https://m.yolo247.com/about/termsandconditions",
                "keywords" : "Terms & Conditions"
            },
            "responsibleGambling": {
                "title": "Responsible Gambling | Yolo247",
                "description": "Responsible Gambling is highly insisted. At Yolo247, we educate, insist and highly recommend to be aware of the negative impacts of gambling.",
                "canonical" : "https://yolo247.com/responsible-gambling",
                "alternate": "https://m.yolo247.com/about/responsible-gambling",
                "keywords" : "Responsible Gambling"
            },
            "privacyPolicy": {
                "title": "Privacy Policy | Yolo247",
                "description": "Privacy Policy reading is always integral in the process of betting. Go through definitions and types of data collected. Click to know more!",
                "canonical" : "https://yolo247.com/privacy-policy",
                "alternate": "https://m.yolo247.com/about/privacy-policy",
                "keywords" : "privacy policy"
            },
            "kycPolicy": {
                "title": "KYC Policy of Yolo247 | Get to know the KYC requirements",
                "description": "KYC Policy at Yolo247 is clear and transparent. Go through the guidelines, documents required, transaction limits and much more.",
                "canonical" : "https://yolo247.com/kyc-policy",
                "alternate": "https://m.yolo247.com/about/kyc-policy",
                "keywords" : "KYC Policy"
            },
            "exclusionPolicy": {
                "title": "Exclusion Policy  | Self-Exclusion Policy of Yolo247",
                "description": "Exclusion Policy (self-exclusion) is a facility that the site offers to help customers who feel that their Gambling is out of ",
                "canonical" : "https://yolo247.com/exclusion-policy",
                "alternate": "https://m.yolo247.com/about/self-exclusion-policy",
                "keywords" : "exclusion policy "
            },
            "amlPolicy": {
                "title": "AML Policy | Anti Money Laundering Policy of Yolo247",
                "description": "AML policy, also known as Anti-Money Laundering Policy, is actively adhered to by Yolo247. Your safety is our priority. Read more!",
                "canonical" : "https://yolo247.com/aml-policy",
                "alternate": "https://m.yolo247.com/about/aml-policy",
                "keywords" : "AML Policy"
            },
            "aboutus": {
                "title": "About Us | Yolo 247 - World's Biggest Betting Exchange",
                "description": "Yolo247 is an online casino & sports betting platform offering a seamless & exciting gaming experience. Find out more on India's top gaming platform - Yolo247.",
                "canonical" : "https://yolo247.com/aboutus",
                "alternate": "https://m.yolo247.com/about/aboutus",
                "keywords" : "About us, About Yolo247"
            },
            "SPRIBE": {
                "title": "Spribe Casino Games | Get the best Spribe Games on Yolo247",
                "description": "Spribe Casino Games are popular and prevalent. We picked the best games for you. Play with just a single sign-in option and explore the gaming options",
                "canonical" : "https://yolo247.com/instant-games/SPRIBE",
                "alternate": "https://m.yolo247.com/instant-games/SPRIBE",
                "keywords" : "spribe casino games"
            },
            "aviatrix": {
                "title": "Aviatrix Game: Play at Yolo247 & Win Real Money Online",
                "description": "Yolo247 invites you to elevate your gaming experience with Aviatrix, a game that takes you on a journey through the clouds. Play Aviatrix game and Start your winning journey now!",
                "canonical" : "https://yolo247.com/instant-games/aviatrix",
                "alternate": "https://m.yolo247.com/instant-games/aviatrix",
                "keywords" : "Aviatrix casino games"
            },
            "7MOJOS": {
                "title": "7Mojos Games | Explore the top games at the best platform",
                "description": "7Mojos Games in one place! One registration, multiple gaming options and better gaming experience. Explore the demo versions as well. Register now. ",
                "canonical" : "https://yolo247.com/online-casino-games/7MOJOS",
                "alternate": "https://m.yolo247.com/online-casino-games/7MOJOS",
                "keywords" : "7Mojos Games"
            },
            "supernowa": {
                "title": "Play Supernowa Casino Games on Yolo247",
                "description": " Supernowa casino games are one of the best in business. Check out Supernowa gaming and see the games offered on Yolo247.",
                "canonical" : "https://yolo247.com/online-casino-games/supernowa",
                "alternate": "https://m.yolo247.com/online-casino-games/supernowa",
                "keywords" : "Supernowa Casino Games,Supernowa gaming, Supernowa casino"
            },
            "pragmatic": {
                "title": "Pragmatic Casino Game on Yolo247 | Get 400% Welcome Bonus",
                "description": "Pragmatic Casino Games are available now! Providing the ultimate gaming experience with a wide range of Pragmatic games online. Explore now!",
                "canonical" : "https://yolo247.com/online-casino-games/slotGames/pragmaticplay",
                "alternate": "https://m.yolo247.com/online-casino-games/slotGames/pragmaticplay",
                "keywords" : "Pragmatic casino, pragmatic games"
            },
            "superspade": {
                "title": "Top Super Spade Games On Yolo247 | Play Now!",
                "description": " Super Spade games are popular because of exciting features, gameplay & payouts. Play Super Spade casino games on Yolo247 and win big. Join now!",
                "canonical" : "https://yolo247.com/online-casino-games/superspade",
                "alternate": "https://m.yolo247.com/online-casino-games/superspade",
                "keywords" : "super spade games,play super spade casino games, super spade casino"
            },
            "tvbet": {
                "title": "Play TVBET Casino Games Online | Real Money Games",
                "description": "TVBET casino games provide engaging games. A wide variety of games await online casino gaming enthusiasts. Find out more about Yolo247 TVBET Games.",
                "canonical" : "https://yolo247.com/online-casino-games/tvbet",
                "alternate": "https://m.yolo247.com/online-casino-games/tvbet",
                "keywords" : "TVBET casino games, Yolo247 TVBET games"
            },
            "netent": {
                "title": "Netent Casino Game on Yolo247 | Get Upto 400% Bonus",
                "description": "NetEnt Games provide the widest range of online slots and casino slot games. Through one registration, explore all the top slot games online.",
                "canonical" : "https://yolo247.com/online-casino-games/slotGames/netent",
                "alternate": "https://m.yolo247.com/online-casino-games/slotGames/netent",
                "keywords" : "netent games"
            },
            "betgames": {
                "title": "BetGames On Yolo247 | Play BetGames & Win Big",
                "description": "BetGames is one of the modern-day game providers in Yolo247. Several casino gaming enthusiasts love the games. Here's why.BetGames is another top live dealer casino game provider that is available on Yolo247 from the comfort of your home. The best part of BetGames is that instead of promoting state-of-the-art technology, they have made their gaming solutions much more accessible to new gamers who want to explore the gaming industry at their own pace.Over the years, the live casino has been the hot trend in the gaming industry, and BetGames, too, has tapped into this market, creating some of the most awe-inspiring live dealer games. Most of these games make you feel like you are in a physical casino.",
                "canonical" : "https://yolo247.com/online-casino-games/betgames",
                "alternate": "https://m.yolo247.com/online-casino-games/betgames",
                "keywords" : "betgames,Yolo247 BetGames"
            },
            "sportsbook": {
                "title": "Sportsbook Betting | Best Online Sportsbook In India",
                "description": " Sportsbook Betting on Yolo247 brings in various options to bet on. Get the best odds for all sports & enjoy the best sports betting experience",
                "canonical" : "https://yolo247.com/sportsbook",
                "alternate": "https://m.yolo247.com/sportsbook",
                "keywords" : "Sportsbook Betting,Best Online Sportsbook, Sports Betting in India"
            },
            "appDownload": {
                "title": "Yolo247 App Download | Free Download for Android",
                "description": "Get the YOLO247 app for Android devices, supporting English and Hindi. Download the latest version from the Google Play Store and register to use its features.",
                "canonical" : "https://yolo247.com/app-download",
                "alternate": "https://m.yolo247.com/app-download",
                "keywords" : "Yolo App Download, Yolo Android App"
            },
            "signup": {
                "title": "User Registration Page",
                "description": "User Registration Page",
                "canonical" : "https://yolo247.com/signup",
                "alternate": "https://m.yolo247.com/signup",
                "keywords" : "Yolo User Registration"
            },
            "faq": {
                "title": "Faq Page",
                "description": "Faq Page",
                "canonical" : "https://yolo247.com/faq",
                "alternate": "https://m.yolo247.com/faq",
            },
            "affiliate": {
                "title": "Affiliate Page",
                "description": "Affiliate Page",
                "canonical" : "https://yolo247.com/affiliate",
                "alternate": "https://m.yolo247.com/affiliate",
            },
            "loyalty": {
                "title": "Loyalty Page",
                "description": "Loyalty Page",
                "canonical" : "https://yolo247.com/loyalty",
                "alternate": "https://m.yolo247.com/loyalty",
            },
            "login": {
                "title": "User Login Page",
                "description": "User Login Page",
                "canonical" : "https://yolo247.com/login",
                "alternate": "https://m.yolo247.com/login",
                "keywords" : "Yolo Login"
            },
            "instantGames": {
                "title": "Instant Games | Play Best Instant Casino Online on Yolo247",
                "description": "Instant Games on Yolo247 are always on demand. Play all your favourite instant casino games online on your favourite betting platform.",
                "canonical" : "https://yolo247.com/instant-games",
                "alternate": "https://m.yolo247.com/instant-games",
                "keywords" : "Instant games,casino games, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites "
            },
            "majorLeagueCricketBetting": {
                "title": "MLC Betting in India | Major League Cricket Betting Online",
                "description": "MLC Betting is available on Yolo247! Get the best odds & markets for Major League Cricket betting online. Go through our free MLC gambling guide.",
                "canonical" : "https://yolo247.com/sports/tournaments/major-league-cricket-betting",
                "alternate": "https://m.yolo247.com/sports/tournaments/major-league-cricket-betting",
                "keywords" : "Major League Cricket Betting Online, MLC Betting in India"
            },
            "dailyDepositBonus": {
                "title": "Refill bonus | Best Casino Refill Bonus on Yolo247",
                "description": "Yolo247 Daily Deposit Bonus brings in a plethora of casino cashback opportunities. Know the rules, process, T&C of Daily Deposit Bonus on Yolo247.",
                "canonical" : "https://yolo247.com/daily-deposit-bonus",
                "alternate": "https://m.yolo247.com/daily-deposit-bonus",
                "keywords" : "daily deposit bonus, deposit bonus, bonus, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites "
            },
            "popular games": {
                "title": "Best Casino Games Online | Get Instant Welcome Bonus & Offer",
                "description": "Explore the most popular and best casino casino online at Yolo247. Choose from thousands of classic casino casino, including online slots, blackjack and roulette.",
                "canonical" : "https://yolo247.com/online-casino-games/best-casino-games",
                "alternate": "https://m.yolo247.com/online-casino-games/best-casino-games",
                "keywords" : "Best Casino Games Popular Casino Games, online casino real money"
            },
            "evolution": {
                "title": "Play Evolution Gaming Casino | Upto 400%  Bonus",
                "description": "Evolution Gaming is one of the best gaming platforms. Learn how to enjoy an array of Evolution Gaming casino games on Yolo247",
                "canonical" : "https://yolo247.com/online-casino-games/evolution-gaming",
                "alternate": "https://m.yolo247.com/online-casino-games/evolution-gaming",
                "keywords" : "Evolution Gaming, Evolution Gaming Casino"
            },
            
            "spribe": {
                "title": "Spribe Games | Play The Best Yolo247 Spribe Games",
                "description": "Spribe games use the latest technology to build innovative and immersive live casino games for users. Find out how to experience thrilling Yolo247 Spribe Games.",
                "canonical" : "https://yolo247.com/instant-games/SPRIBE",
                "alternate": "https://m.yolo247.com/instant-games/SPRIBE",
                "keywords" : "Spribe Games,Yolo247 Spribe games,Yolo Spribe games,Spribe live casino games"
            },
            "cricketx": {
                "title": "Cricketx Game | Play The Best Yolo247 Cricket Games",
                "description": "Find out how to experience thrilling Yolo247 Cricketx Games.",
                "canonical" : "https://yolo247.com/instant-games/cricketx",
                "alternate": "https://m.yolo247.com/instant-games/cricketx",
                "keywords" : "Cricketx Games,Yolo247 Cricketx games,Yolo Cricketx games,Cricketx casino games"
           },
            "ezugi": {
                "title": "Ezugi Live Casino | Play Yolo247 Ezugi Games",
                "description": " Ezugi's state-of-the-art live casino games are a massive hit among fans. Let us do a deep dive into Ezugi Live Casino games.Ezugi live casino is a popular gaming provider in the online gaming world. A huge number of casino gaming enthusiasts play Ezugi live casino games on Yolo247. Unlike some of the other competitors, Ezugi gaming provides you with an ultimate gaming experience.A couple of things make Ezugi live casino dealer games so different. Let's learn more about the gaming provider.",
                "canonical" : "https://yolo247.com/online-casino-games/ezugi-live-casino",
                "alternate": "https://m.yolo247.com/online-casino-games/ezugi-live-casino",
                "keywords" : "Ezugi Live Casino,Ezugi, Ezugi Gaming, Yolo247 Ezugi Live Casino"
            },
            "sexygaming": {
                "title": "Play Sexy Gaming Casino Online | Get 400% Signup  Bonus",
                "description": "Sexy Gaming casino is one of Asia's most popular live casino game providers. Play sexy gaming casino games on Yolo247 and win exciting rewards.",
                "canonical" : "https://yolo247.com/online-casino-games/sexygaming",
                "alternate": "https://m.yolo247.com/online-casino-games/sexygaming",
                "keywords" : "sexy gaming casino,sexy gaming casino games, yolo247 sexy gaming casino games, yolo247 sexy gaming live casino game"
            },
            "monopolylive": {
                "title": " Monopoly Live | Play Live Casino Games on Yolo247",
                "description": "Monopoly Live.Play Monopoly Live games on Yolo247.",
                "canonical" : "https://yolo247.com/online-casino-games/MONOPOLYLIVE",
                "alternate": "https://m.yolo247.com/online-casino-games/MONOPOLYLIVE",
                "keywords" : "MONOPOLY LIVE"
            },
            "funkytime": {
                "title": " Funkytime | Play Live Casino Games on Yolo247",
                "description": "Funkytime.Play Funkytime games on Yolo247.",
                "canonical" : "https://yolo247.com/online-casino-games/funkytime",
                "alternate": "https://m.yolo247.com/online-casino-games/funkytime",
                "keywords" : "funkytime"
            },
            "lightningdice": {
                "title": " Lightning Dice | Play Live Casino Games on Yolo247",
                "description": "Lightning Dice. Play Lightning Dice games on Yolo247.",
                "canonical" : "https://yolo247.com/online-casino-games/lightningdice",
                "alternate": "https://m.yolo247.com/online-casino-games/lightningdice",
                "keywords" : "Lightning Dice"
            },
            "macaw": {
                "title": "Macaw Games | Top Macaw Casino Online Games on Yolo247",
                "description": "Macaw games are thrilling and are available online on Yolo247. Learn more about the popular Macaw casino online games.",
                "canonical" : "https://yolo247.com/online-casino-games/macaw",
                "alternate": "https://m.yolo247.com/online-casino-games/macaw",
                "keywords" : "macaw games, macaw casino online"
            },
            "ag": {
                "title": "Play Your Favourite AG Game Online at Yolo247",
                "description": "Amazing Gaming has established itself as one of the best gaming solutions providers. Play Amazing Gaming games on Yolo247.",
                "canonical" : "https://yolo247.com/online-casino-games/AG",
                "alternate": "https://m.yolo247.com/online-casino-games/AG",
                "keywords" : "Amazing Gaming"
            },
            "asiaCupBonanza": {
                "title": "Asia Cup Bonanza | Best Cricket Betting Odds",
                "description": "Online cricket betting on Yolo247 provides the best betting odds for cricket in India. Get live score, fancy bets & fast withdrawal. Bet on cricket now!",
                "keywords": "Asia Cup Bonanza, Online Cricket Betting, Cricket Betting, cricket betting odds, cricket betting site, best site to bet on cricket, best cricket betting site (s) in India, Yolo247 online cricket betting, Yolo247 cricket betting odds, Yolo247 cricket betting, cricket betting sites, online cricket satta bazar",
                "canonical" : "https://yolo247.com/asia-cup-bonanza",
                "alternate": "https://m.yolo247.com/asia-cup-bonanza"
            },

            "bingo": {
                "title": "Play Bingo Casino Game on Yolo247 | Upto 400%  Bonus",
                "description": "Explore a world of excitement at Yolo247, your gateway to thrilling online games and sports prediction. Play the most popular online real money games and seize your opportunity to win real cash prizes with amazing bonus.",
                "canonical": "https://yolo247.com/online-casino-games/bingo",
                "alternate": "https://m.yolo247.com/online-casino-games/bingo",
                "keywords" : "casino games, bingo, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
            },
            "Funkytime": {
                "title": "Play Funky Time Casino Online Game at Yolo247",
                "description": "Online Casino games are revolutionizing the gaming world. Find out some of the popular live casino online games on Yolo247.",
                "canonical": "https://yolo247.com/online-casino-games/Funkytime",
                "alternate": "https://m.yolo247.com/online-casino-games/Funkytime",
                "keywords" : "casino games, Funkytime, Funky Time, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
            },
            "evo": {
                "title": "Evo Casino Game on Yolo247 | Get Upto 400%  Bonus",
                "description": "Evolution Gaming is one of the best gaming platforms. Learn how to enjoy an array of Evolution Gaming casino games on Yolo247",
                "canonical" : "https://yolo247.com/online-casino-games/evo",
                "alternate": "https://m.yolo247.com/online-casino-games/evo",
                "keywords" : "Evo Gaming, Evolution Gaming Casino, Evo Casino Game"
            },
            "nlc": {
                "title": "NLC Casino Game on Yolo247 | 400% Signup  Bonus",
                "description": "Casino slot games are high on entertainment. Play slot games for real money on Yolo247 and earn exciting rewards.",
                "canonical": "https://yolo247.com/online-casino-games/slotGames/nlc",
                "alternate": "https://m.yolo247.com/online-casino-games/slotGames/nlc",
                "keywords": "nlc casino Game, nlc, nlc games, slot games for real money, casino slot games"
            },
            "Funky Time": {
                "title": "Funky Time Online | Play for Free or Real Money",
                "description": "Online Casino games are revolutionizing the gaming world. Find out some of the popular live casino online games on Yolo247.",
                "canonical": "https://yolo247.com/online-casino-games/Funky%20Time",
                "alternate": "https://m.yolo247.com/online-casino-games/Funky%20Time",
                "keywords" : "casino games, Funkytime, Funky Time, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
            },
            "First-Person-Blackjack": {
                "title": "Play Blackjack Live Casino Games | Free or Real Money",
                "description": "Play blackjack online on the most safest online casino. Register now at YOLO247 and get exclusive welcome bonus and offers to win your favorite games,Blackjack Game is now open for bets. Play Blackjack online on Yolo247, India’s leading casino gambling site. Bet with real money through UPI. Blackjack Game is a gambling activity that is popular in casinos throughout the world. It is also called Pontoon and Twenty-one.",
                "canonical": "https://yolo247.com/online-casino-games/first%20person%20blackjack",
                "alternate": "https://m.yolo247.com/online-casino-games/first%20person%20blackjack",
                "keywords": "First Person BlackJack,first person black jack,blackjack online,blackjack game,playing blackjack,blackjack playing,play blackjack online,blackjack game rules,online blackjack real money,play blackjack,blackjack online game,online blackjack real money,black jack card games"
            },
            "teenpatti2020-2020": {
                "title": "Play Teenpatti Live Casino Games | Free or Real Money",
                "description": "Teenpatti 2020 is a popular casino card game on Yolo247. Play this exciting card game for endless entertainment and big wins.",
                "canonical" : "https://yolo247.com/online-casino-games/teenpatti%2020%2020",
                "alternate": "https://www.yolo247.com/online-casino-games/teenpatti%2020%2020",
                "keywords" : "Teenpatti 2020"
            },
            //member dashboard pages
            "memberDashboard": {
                "canonical" : "https://yolo247.com/member/profile",
                "alternate": "https://m.yolo247.com/member/profile"
            },
            "memberProfileAccountInfo": {
                "canonical" : "https://yolo247.com/member/profile/accountinfo",
                "alternate": "https://m.yolo247.com/member/profile/accountinfo"
            },
            "memberProfileChangePassword": {
                "canonical" : "https://yolo247.com/member/profile/password",
                "alternate": "https://m.yolo247.com/member/profile/password"
            },
            "memberProfileBankDetails": {
                "canonical" : "https://yolo247.com/member/profile/bankdetails",
                "alternate": "https://m.yolo247.com/member/profile/bankdetails"
            },
            "memberProfileKYC": {
                "canonical" : "https://yolo247.com/member/profile/kycpage",
                "alternate": "https://m.yolo247.com/member/profile/kycpage"
            },
            "memberFinanceDeposit": {
                "canonical" : "https://yolo247.com/member/finance/deposit",
                "alternate": "https://m.yolo247.com/member/finance/deposit"
            },
            "memberFinanceWithdrawal": {
                "canonical" : "https://yolo247.com/member/finance/withdrawal",
                "alternate": "https://m.yolo247.com/member/finance/withdrawal"
            },
            "memberFinanceHistory": {
                "canonical" : "https://yolo247.com/member/finance/history",
                "alternate": "https://m.yolo247.com/member/finance/history"
            },
            "memberFinanceTransfer": {
                "canonical" : "https://yolo247.com/member/finance/transfer",
                "alternate": "https://m.yolo247.com/member/finance/transfer"
            },
            "memberMyBets": {
                "canonical" : "https://yolo247.com/member/betDetails/mybets",
                "alternate": "https://m.yolo247.com/member/betDetails/mybets"
            },
            "memberP&L": {
                "canonical" : "https://yolo247.com/member/betDetails/profitandloss",
                "alternate": "https://m.yolo247.com/member/betDetails/profitandloss"
            },
            "memberCasinoStatement": {
                "canonical" : "https://yolo247.com/member/betDetails/casinostatement",
                "alternate": "https://m.yolo247.com/member/betDetails/casinostatement"
            },
            "memberSportsbookStatement": {
                "canonical" : "https://yolo247.com/member/betDetails/sportbookstatement",
                "alternate": "https://m.yolo247.com/member/betDetails/sportbookstatement"
            },
            "memberSTPStatement": {
                "canonical" : "https://yolo247.com/member/betDetails/splitThePotStatement",
                "alternate": "https://m.yolo247.com/member/betDetails/splitThePotStatement"
            },
            "memberGalaxysStatement": {
                "canonical" : "https://yolo247.com/member/betDetails/galaxyStatement",
                "alternate": "https://m.yolo247.com/member/betDetails/galaxyStatement"
            },
            "memberAviatrixStatement": {
                "canonical" : "https://yolo247.com/member/betDetails/aviatrixStatement",
                "alternate": "https://m.yolo247.com/member/betDetails/aviatrixStatement"
            },
            "memberAccountStatement": {
                "canonical" : "https://yolo247.com/member/betDetails/accountStatement",
                "alternate": "https://m.yolo247.com/member/betDetails/accountStatement"
            },
            "memberVirtualSportsStatement": {
                "canonical" : "https://yolo247.com/member/betDetails/virtualSportsStatement",
                "alternate": "https://m.yolo247.com/member/betDetails/virtualSportsStatement"
            },
            "memberTurnoverStatement": {
                "canonical" : "https://yolo247.com/member/betDetails/turnoverStatement",
                "alternate": "https://m.yolo247.com/member/betDetails/turnoverStatement"
            },
            "memberPrormotions": {
                "canonical" : "https://yolo247.com/member/promotions",
                "alternate": "https://m.yolo247.com/member/promotions"
            },
            "memberMessages": {
                "canonical" : "https://yolo247.com/member/message",
                "alternate": "https://m.yolo247.com/member/message"
            },
            "ninjacrash": {
                "title": "Plan NinjaCrash Game Online & Win Real Money 2024",
                "description": "Gear up for the ultimate challenge in NinjaCrash! Plan your moves strategically, play online, and stand a chance to win real money in 2024.",
                "canonical" : "https://yolo247.com/instant-games/ninjacrash",
                "alternate": "https://m.yolo247.com/instant-games/ninja%20crash",
                "keywords" : "NinjaCrash game, Online NinjaCrash game"
            },
            "big-bash": {
                "title": "Play Casino Games | Real Money Gambling | Satta Online 24/7",
                "description": "Play the most popular online casino games at YOLO247 & earn a chance to win real money. Get unbeatable welcome bonus & offers on your favorite satta games in India",
                "canonical" : "https://yolo247.com/promotions/big-bash",
                "alternate": "https://m.yolo247.com/promotions/big-bash",
                "keywords" : "casino games, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
            },
           
        },
        "32": {
            "default": {
                "title": "Online Betting ID | Best Site for Casino & Sports | Yolo247",
                "description": "Online betting ID makes casino & sports betting experience better. On Yolo247, get betting ID through WhatsApp instantly. Faster deposit & withdrawal.",
                "canonical" : "https://yolo247.club/",
                "alternate": "https://m.yolo247.club/",
                "keywords" : "casino games, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site, online betting id, bet id, betting id online, online betting id provider"
            },
            "bingo": {
                "title": "Play Bingo Casino Game on Yolo247 | Upto 400%  Bonus",
                "description": "Explore a world of excitement at Yolo247, your gateway to thrilling online games and sports prediction. Play the most popular online real money games and seize your opportunity to win real cash prizes with amazing bonus.",
                "canonical": "https://yolo247.club/online-casino-games/bingo",
                "alternate": "https://m.yolo247.club/online-casino-games/bingo",
                "keywords" : "casino games, bingo, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
            },
            "teenpatti2020-2020": {
                "title": "Play Teenpatti Live Casino Games | Free or Real Money",
                "description": "Teenpatti 2020 is a popular casino card game on Yolo247. Play this exciting card game for endless entertainment and big wins.",
                "canonical" : "https://yolo247.club/online-casino-games/teenpatti%2020%2020",
                "alternate": "https://www.yolo247.club/online-casino-games/teenpatti%2020%2020",
                "keywords" : "Teenpatti 2020"
            },      
            
            "First-Person-Blackjack": {
                "title": "Play Blackjack Live Casino Games | Free or Real Money",
                "description": "Play blackjack online on the most safest online casino. Register now at YOLO247 and get exclusive welcome bonus and offers to win your favorite games,Blackjack Game is now open for bets. Play Blackjack online on Yolo247, India’s leading casino gambling site. Bet with real money through UPI. Blackjack Game is a gambling activity that is popular in casinos throughout the world. It is also called Pontoon and Twenty-one.",
                "canonical": "https://yolo247.club/online-casino-games/first%20person%20blackjack",
                "alternate": "https://m.yolo247.club/online-casino-games/first%20person%20blackjack",
                "keywords": "First Person BlackJack,first person black jack,blackjack online,blackjack game,playing blackjack,blackjack playing,play blackjack online,blackjack game rules,online blackjack real money,play blackjack,blackjack online game,online blackjack real money,black jack card games"
            },
            "Funky Time": {
                "title": "Funky Time Online | Play for Free or Real Money",
                "description": "Online Casino games are revolutionizing the gaming world. Find out some of the popular live casino online games on Yolo247.",
                "canonical": "https://yolo247.club/online-casino-games/Funky%20Time",
                "alternate": "https://m.yolo247.club/online-casino-games/Funky%20Time",
                "keywords" : "casino games, Funkytime, Funky Time, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
            },
            "evo": {
                "title": "Evo Casino Game on Yolo247 | Get Upto 400%  Bonus",
                "description": "Evolution Gaming is one of the best gaming platforms. Learn how to enjoy an array of Evolution Gaming casino games on Yolo247",
                "canonical" : "https://yolo247.club/online-casino-games/evo",
                "alternate": "https://m.yolo247.club/online-casino-games/evo",
                "keywords" : "Evo Gaming, Evolution Gaming Casino, Evo Casino Game"
            },
            "nlc": {
                "title": "NLC Casino Game on Yolo247 | 400% Signup  Bonus",
                "description": "Casino slot games are high on entertainment. Play slot games for real money on Yolo247 and earn exciting rewards.",
                "canonical": "https://yolo247.club/online-casino-games/slotGames/nlc",
                "alternate": "https://m.yolo247.club/online-casino-games/slotGames/nlc",
                "keywords": "nlc casino Game, nlc, nlc games, slot games for real money, casino slot games"
            },
            "Funkytime": {
                "title": "Play Funky Time Casino Online Game at Yolo247",
                "description": "Online Casino games are revolutionizing the gaming world. Find out some of the popular live casino online games on Yolo247.",
                "canonical": "https://yolo247.club/online-casino-games/Funkytime",
                "alternate": "https://m.yolo247.club/online-casino-games/Funkytime",
                "keywords" : "casino games, Funkytime, Funky Time, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
            },
            "ag": {
                "title": "Play Your Favourite AG Game Online at Yolo247",
                "description": "Amazing Gaming has established itself as one of the best gaming solutions providers. Play Amazing Gaming games on Yolo247.",
                "canonical" : "https://yolo247.club/online-casino-games/AG",
                "alternate": "https://m.yolo247.club/online-casino-games/AG",
                "keywords" : "Amazing Gaming"
            },
            "slotGames": {
                "title": "Slot Game ID | Betting Opportunities Online | Yolo247",
                "description": "Experience the future of online gaming with advanced slot game IDs. Enjoy a seamless journey into the world of virtual entertainment!",
                "canonical": "https://yolo247.club/online-casino-games/slotGames",
                "alternate": "https://m.yolo247.club/online-casino-games/slotGames",
                "keywords": "slot games for real money, casino slot games, casino slot casino id, Slot game id, slot casino id for real money"
            },
            "macaw": {
                "title": "Macaw Games | Top Macaw Casino Online Games on Yolo247",
                "description": "Macaw games are thrilling and are available online on Yolo247. Learn more about the popular Macaw casino online games.",
                "canonical" : "https://yolo247.club/online-casino-games/macaw",
                "alternate": "https://m.yolo247.club/online-casino-games/macaw",
                "keywords" : "macaw games, macaw casino online"
            },
            "sexygaming": {
                "title": "Play Sexy Gaming Casino Online | Get 400% Signup  Bonus",
                "description": "Sexy Gaming casino is one of Asia's most popular live casino game providers. Play sexy gaming casino games on Yolo247 and win exciting rewards.",
                "canonical" : "https://yolo247.club/online-casino-games/sexygaming",
                "alternate": "https://m.yolo247.club/online-casino-games/sexygaming",
                "keywords" : "sexy gaming casino,sexy gaming casino games, yolo247 sexy gaming casino games, yolo247 sexy gaming live casino game"
            },
            "monopolylive": {
                "title": "Monopoly ID -Grab Your Exclusive Deals Now!",
                "description": "Unlock the secrets of Monopoly ID on our dynamic webpage. From game updates to expert advice, find everything you need for success",
                "canonical" : "https://yolo247.club/online-casino-games/MONOPOLYLIVE",
                "alternate": "https://m.yolo247.club/online-casino-games/MONOPOLYLIVE",
                "keywords" : "MONOPOLY LIVE, monopoly id, monopoly live casino, monopoly live casino game, Play monopoly live online, monopoly live game"
            },
            "lightningdice": {
                "title": " Lightning Dice | Play Live Casino Games on Yolo247",
                "description": "Lightning Dice. Play Lightning Dice games on Yolo247.",
                "canonical" : "https://yolo247.club/online-casino-games/lightningdice",
                "alternate": "https://m.yolo247.club/online-casino-games/lightningdice",
                "keywords" : "Lightning Dice"
            },
            "onlineCasinoGames": {
                "title": "Live Casino ID: Play Exciting casino Online",
                "description": "Step into the excitement at live casino ID. Play your favorite casino with live dealers for an unparalleled gaming experience",
                "canonical": "https://yolo247.club/online-casino-games",
                "alternate": "https://m.yolo247.club/online-casino-games",
                "keywords": " Online Casino Games, Live Casino Online, Play Casino games online, casino id, live casino id, Roulette ID Provider"
            },
            "blackjack": {
                "title": "Secure Your Blackjack ID and Experience Online Thrills",
                "description": "Blackjack ID Your passport to a new era of gaming. Elevate your strategy, enhance your skills, and conquer the tables",
                "canonical": "https://yolo247.club/online-casino-games/blackjack",
                "alternate": "https://m.yolo247.club/online-casino-games/blackjack",
                "keywords": "blackjack online,blackjack game,playing blackjack,blackjack playing,play blackjack online,blackjack game rules,online blackjack real money,play blackjack,blackjack online game,online blackjack real money,black jack card games,blackjack id,blackjack online,blackjack online real money,"
            },
            "roulette": {
                "title": "Online Roulette with Roulette ID- Win Big with Yolo247",
                "description": "Elevate your gaming adventure with Roulette ID, the premier platform for immersive and rewarding roulette action.",
                "canonical": "https://yolo247.club/online-casino-games/roulette",
                "alternate": "https://m.yolo247.club/online-casino-games/roulette",
                "keywords": "roulette game,roulette online,casino roulette,online roulette india,lightning roulette,online roulette game,roulette casino game,online roulette real money,play roulette online,casino roulette table,roulette id,online roulette,online roulette real money,"
            },
            "lightningroulette": {
                "title": "Spin to Win with Lightning Roulette ID | Grab Exclusive Bonuses",
                "description": "Get ready for high-voltage fun with Lightning Roulette ID. Spin the wheel and feel the excitement with unique twists and chances to win big",
                "canonical": "https://yolo247.club/online-casino-games/lightning-roulette",
                "alternate": "https://m.yolo247.club/online-casino-games/lightning-roulette",
                "keywords": "lightning roulette game,lightning roulette online,casino lightning roulette,online roulette india,lightning roulette,online roulette game,roulette casino game,online roulette real money,play roulette online,casino roulette table,lightningroulette id,lightning roulette online,lightning roulette online casino,play lightning roulette online"
            },
            "dragonTiger": {
                "title": "Secure Your Blackjack ID and Experience Online Thrills",
                "description": "Elevate your gaming experience with Dragon Tiger ID. Uncover a world of entertainment and fortune in this exciting online platform",
                "canonical": "https://yolo247.club/online-casino-games/dragon-tiger",
                "alternate": "https://m.yolo247.club/online-casino-games/dragon-tiger",
                "keywords": "dragon tiger id, dragon tiger online, Dragon tiger real money game, Dragon Tiger"
            },
            "baccarat": {
                "title": "Get Your Baccarat ID for Seamless Online Play with Yolo247",
                "description": "Elevate your gaming with Baccarat ID. Dive into the captivating world of online baccarat and redefine your winning moments.",
                "canonical": "https://yolo247.club/online-casino-games/baccarat",
                "alternate": "https://m.yolo247.club/online-casino-games/baccarat",
                "keywords": "baccarat game,baccarat online,baccarat casino,baccarat gaming,baccarat rule,baccarat game online,baccarat game rules,play baccarat online,baccarat online play,baccarat online free,best baccarat online casino,best casino for baccarat,baccarat id,baccarat online"
            },
            "teenpattiOnlinePlay": {
                "title": "Grab Your Teenpatti ID | Play at Yolo247 Now!",
                "description": "Experience the magic of Teen Patti ID online. Play with friends, showcase your skills, and win amazing prizes. Join the gaming revolution now!",
                "canonical": "https://m.yolo247.club/teenpatti-online-play",
                "keywords": "teen patti ,teen patti game, teen patti online, teen patti casino, teen patti id, teen patti online play, teenpatti online"
            },
            "sportsBetting": {
                "title": "Sports Betting ID- Place a Bet On Live Sports ",
                "description": "Explore Live Sports on Yolo247 with Sports Betting ID- Betting Exchange offers you the best sports betting experience in India.  400% Welcome Bonus | Instant Withdrawal",
                "canonical": "https://yolo247.club/betting-exchange",
                "alternate": "https://m.yolo247.club/betting-exchange?id=0",
                "keywords": " betting exchange, sports betting id, best online sports betting id provider, sports betting id provider, online sports betting id provider, online sports betting id"
            },
            "onlineCricketSattaBazar": {
                "title": "Get Your Cricket Betting ID - Bet On Your Favorite Sports",
                "description": "Get Your Cricket Betting ID and bet on your favorite cricket matches in one of the best cricket betting sites in India. Place bets on Sportsbook, Exchange, and Virtual Sports on Yolo247",
                "keywords": "Online Cricket Betting, Cricket Betting, cricket betting odds, cricket betting site, best site to bet on cricket, best cricket betting site (s) in India, Yolo247 online cricket betting, Yolo247 cricket betting odds, Yolo247 cricket betting, cricket betting sites, online cricket satta bazar, cricket betting id, online cricket betting id, betting id cricket, online cricket betting id in india, online id for cricket betting",
                "canonical": "https://yolo247.club/betting-exchange/online-cricket-betting",
                "alternate": "https://m.yolo247.club/betting-exchange/online-cricket-betting",
            },
            "footballBetting": {
                "title": "Your Ultimate Football Betting ID Provider- Yolo247",
                "description": "Kick off your winning streak with our football betting ID. Instant sign-up, smooth transactions, and a plethora of options. Your path to victory starts here!",
                "canonical": "https://yolo247.club/betting-exchange/football-betting",
                "alternate": "https://m.yolo247.club/betting-exchange/football-betting",
                "keywords": "football prediction site,best football prediction sites,betting app for football,football betting,football bet app,online football betting,betting football site,football betting sites,bet online football,bet football online,best football betting sites,best betting football sites, football betting id, football betting online, online football betting india"
            },
            "tennisBetting": {
                "title": "Tennis Betting Id- Turning Predictions into Profits",
                "description": "Enhance your tennis betting thrill with a dedicated betting ID. Quick access and seamless transactions for an elevated gaming experience. Join now!",
                "canonical": "https://yolo247.club/betting-exchange/tennis-betting",
                "alternate": "https://m.yolo247.club/betting-exchange/tennis-betting",
                "keywords": "predictions tennis,tennis predictions today,today tennis match prediction,betting on tennis tips,tennis betting,tennis betting online,tennis match prediction,tennis bet prediction,bet online tennis,online tennis betting sites,tennis betting id,tennis betting,tennis id,best tennis betting sites"
            },
            "volleyballBetting": {
                "title": "Trusted Volleyball Betting ID Platform - Yolo247   ",
                "description": "Volleyball betting ID is made simple with our dedicated ID. Instant registration, seamless transactions, and a world of betting opportunities at your fingertips",
                "canonical": "https://yolo247.club/betting-exchange/volleyball-betting",
                "alternate": "https://m.yolo247.club/betting-exchange/volleyball-betting",
                "keywords": "predictions volleyball,volleyball predictions today,today volleyball match prediction,betting on volleyball tips,volleyball betting,volleyball betting online,volleyball match prediction,volleyball bet prediction,bet online volleyball,online volleyball betting sites,volleyball betting id,volleyball betting,volleyball betting odds,best online volleyball betting sites"
            },
            "promotions": {
                "title": "Play Casino Games | Real Money Gambling | Satta Online 24/7",
                "description": "Play the most popular online casino games at YOLO247 & earn a chance to win real money. Get unbeatable welcome bonus & offers on your favorite satta games in India",
                "canonical" : "https://yolo247.club/promotions",
                "alternate": "https://m.yolo247.club/promotions",
                "keywords" : "casino games, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
            },
            "specialMarket": {
                "title": "Special Markets on Yolo 247",
                "description": "Special Markets on Yolo 247",
                "canonical" : "https://yolo247.club/specialMarket",
                "alternate": "https://m.yolo247.club/specialMarket",
            },
            "virtualSports": {
                "title": "Hit the Jackpot with Online Cricket ID: Your way to Win Big!",
                "description": "Transform into a cricket champion with your online circket ID. Engage in competitive matches, tournaments, and connect with a community of passionate gamers",
                "canonical" : "https://yolo247.club/virtualSports/vci",
                "alternate": "https://m.yolo247.club/virtualSports/vci",
            },
            "virtualSportsBL": {
                "title": "Elevate Your Game with Virtual basketball betting id",
                "description": "Unlock the excitement with premier basketball betting IDs. Safe, secure, and loaded with options for the ultimate hoops betting thrill.",
                "canonical" : "https://yolo247.club/virtualSports/vbl",
                "alternate": "https://m.yolo247.club/virtualSports/vbl",
            },
            "virtualSportsFB": {
                "title": "Bundesliga Betting: Place Your Virtual Sports Bets at Yolo247",
                "description": "Unleash the power of your Bundesliga betting ID for a premier football wagering experience. Predict, bet, and celebrate victories!",
                "canonical" : "https://yolo247.club/virtualSports/vfb",
                "alternate": "https://m.yolo247.club/virtualSports/vfb",
            },
            "virtualSportsFEL": {
                "title": "EPL Betting ID | Bet on Premier League Matches Online",
                "description": "Get in the game with an EPL betting ID. Elevate your stakes, enjoy bonuses, and experience football like never before. Bet smart, win big!",
                "canonical" : "https://yolo247.club/virtualSports/vfel",
                "alternate": "https://m.yolo247.club/virtualSports/vfel",
            },
            "virtualSportsFEC": {
                "title": "European Cup Betting ID : Place Your Bets and Win Big!",
                "description": "Join the action with our European Cup betting ID. Elevate your game with top-notch security and access to premium odds. Bet with confidence!",
                "canonical" : "https://yolo247.club/virtualSports/vfec",
                "alternate": "https://m.yolo247.club/virtualSports/vfec",
            },
            "virtualSportsVTI": {
                "title": "Elevate Your Game with Virtual Tennis Betting ID",
                "description": "Discover the ultimate virtual tennis betting ID for an unparalleled gaming adventure. Predict, bet, and win big with our cutting-edge platform. Join now!",
                "canonical" : "https://yolo247.club/virtualSports/vti",
                "alternate": "https://yolo247.club/virtualSports/vti",
            },
            "virtualSportsWC": {
                "title": "Navigating Virtual World Cup Betting ID Like a Pro | Bet Smart,Win Big",
                "description": "Explore thrilling with virtual World Cup betting ID services for an immersive gaming experience like never before!",
                "canonical" : "https://yolo247.club/virtualSports/vfwc",
                "alternate": "https://m.yolo247.club/virtualSports/vfwc",
            },
            "aviator": {
                "title": "Get Aviator ID & Explore More Instant casino - Yolo247",
                "description": "Unlock a world of aviation with Aviator ID – your passport to exclusive pilot resources and communities. Elevate your flying experience now!",
                "canonical" : "https://yolo247.club/instant-games/aviator",
                "alternate": "https://m.yolo247.club/instant-games/aviator",
                "keywords" : "Aviator game, Aviator online game, aviator id, aviator betting sites, aviator game betting, aviator betting game"
            },
            "cashshow": {
                "title": "Cash Show Game | Live Casino Online Games on Yolo247",
                "description": "Online Casino games are revolutionizing the gaming world. Find out some of the popular live casino online games on Yolo247.",
                "canonical" : "https://yolo247.club/instant-games/cashshow",
                "alternate": "https://m.yolo247.club/instant-games/cashshow",
                "keywords" : "Cash Show game, Cash Show online game "
            },
            "jetx": {
                "title": "Claim Your jetx ID & Get Exclusive Bonus Rewards",
                "description": "Unlock limitless possibilities with JetX ID – your gateway to seamless online experiences and secure digital identity management",
                "canonical" : "https://www.yolo247.club/instant-games/jetx",
                "alternate": "https://m.yolo247.club/instant-games/jetx",
                "keywords" : "Jet X game, Jet X online game, jetx id, jetx game, jetx online"
            },
            "rockpaperscissors": {
                "title": "Rockpaperscissors ID - Grab Your Exclusive Deals Now!",
                "description": "Explore the thrill of rockpaperscissors ID gaming. Join the fun with our interactive platform and challenge opponents worldwide",
                "canonical" : "https://yolo247.club/instant-games/ROCKPAPERSCISSORS",
                "alternate": "https://yolo247.club/instant-games/ROCKPAPERSCISSORS",
                "keywords" : "rockpaperscissors id, rockpaperscissors game, rockpaperscissors online"
            },
            "plinkox": {
                "title": "Grab Plinkox ID - Inspect More Instant casino - Yolo247",
                "description": "Step into the future with Plinkox ID - Your trusted partner for secure, seamless, and personalized digital experiences",
                "canonical" : "https://www.yolo247.club/instant-games/plinkox",
                "alternate": "https://m.yolo247.club/instant-games/plinkox",
                "keywords" : "Plinkox game, Plinkox online game "
            },
            "footballx": {
                "title": "Get Footballx ID Play FootballX Online Game on yolo247",
                "description": "Step into the future of football with FootballX ID. Access exclusive features, connect with like-minded fans, and celebrate the game you love",
                "canonical" : "https://www.yolo247.club/instant-games/footballx",
                "alternate": "https://m.yolo247.club/instant-games/footballx",
                "keywords" : "Football X game, Football X online game "
            },
            "balloon": {
                "title": "Get Balloon Betting ID with 400% bonus- Yolo247",
                "description": "Find your perfect balloon betting ID platform. Enjoy a variety of casino, competitive odds, and a secure environment for online betting",
                "canonical" : "https://www.yolo247.club/instant-games/balloon",
                "alternate": "https://m.yolo247.club/instant-games/balloon",
                "keywords" : "Balloon game, Balloon online game "
            },
            "cricketx": {
                "title": "Claim Your CricketX ID & Get Exclusive Bonus Rewards",
                "description": "Elevate your cricket experience with CricketX ID. Access real-time updates, player profiles, and immersive content. Be part of the game!",
                "canonical" : "https://yolo247.club/instant-games/cricketx",
                "alternate": "https://m.yolo247.club/instant-games/cricketx",
                "keywords" : "Cricketx Games,Yolo247 Cricketx games,Yolo Cricketx games,Cricketx casino games,CricketX id,cricket x bet,cricket x game"
            },
            "mines": {
                "title": "Get Mines Game ID & Explore More Instant casino - Yolo247",
                "description": "Elevate your betting journey with a mines betting ID. Unleash the thrill of wagering on a cutting-edge platform",
                "canonical" : "https://www.yolo247.club/instant-games/mines",
                "alternate": "https://m.yolo247.club/instant-games/mines",
                "keywords" : "Mines game, Online mines game"
            },
            "macludo": {
                "title": "Macludo Casino Games Online - Play & Win 24/7",
                "description": "Ludo Game is lucrative. Register and get the best gaming experience. Play Ludo Game online for real cash and win. Get the profits directly. ",
                "canonical" : "https://yolo247.club/online-casino-games/macludo",
                "alternate": "https://m.yolo247.club/online-casino-games/macludo",
                "keywords" : "Ludo Game, Ludo game Online"
            },
            "crazytime": {
                "title": "Maximize Your Fun with Crazy Time ID on Yolo247",
                "description": "Experience the ultimate gaming frenzy with Crazy Time ID. Join the chaos, win big, and let the craziness unfold in every click!",
                "canonical" : "https://www.yolo247.club/online-casino-games/crazytime",
                "alternate" : "https://m.yolo247.club/online-casino-games/crazytime",
                "keywords" : "Crazytime Game, Crazytime game Online, crazy time betting, crazy time online game, crazy time id"
            },
            "welcomeBonus": {
                "title": "Play Online Casino & Sports casino and Get 400%  Welcome Bonus at Yolo247",
                "description": "Begin your adventure with a bang! Our welcome bonus is your ticket to a thrilling journey of bonuses and rewards.",
                "canonical" : "https://yolo247.club/promotions/welcome-bonus",
                "alternate": "https://m.yolo247.club/promotions/welcome-bonus",
                "keywords" : "Welcome Bonus, casino welcome bonus"
            },
            "refillBonus": {
                "title": "Refill Casino Bonus: Claim Your Way to Boosted Winnings Today!",
                "description": "Reload your luck with our exclusive refill casino bonus. Elevate your gaming experience with extra rewards. Play smarter, win bigger!",
                "canonical" : "https://yolo247.club/promotions/refill-bonus",
                "alternate": "https://m.yolo247.club/promotions/refill-bonus",
                "keywords" : "Refill bonus, Refill casino bonus"
            },
            "cashbackBonus": {
                "title": "Cash Back Bonus | Get A 5% Cashback Bonus Instantly ",
                "description": "Explore the world of online casinos with cashback bonuses. Enhance your gameplay, recover losses, and enjoy a rewarding casino adventure like never before",
                "canonical" : "https://yolo247.club/promotions/cashback-bonus",
                "alternate": "https://m.yolo247.club/promotions/cashback-bonus",
                "keywords" : "Cashback Bonus, casino cashback bonus, online casino with cashback bonus"
            },
            "referralBonus": {
                "title": "Referral Bonus Bonanza: Elevate Your Rewards Game Today",
                "description": "Unleash the power of online casino referral bonuses! Elevate your play with exclusive rewards and exciting promotions on yolo247",
                "canonical" : "https://yolo247.club/promotions/referral-bonus",
                "alternate": "https://m.yolo247.club/promotions/referral-bonus",
                "keywords" : "Referral Bonus, online casino referral bonus, casino referral bonus, best online casino referral bonus"
            },
            "termsandconditions": {
                "title": "Terms & Conditions | Yolo247 Online Betting T&C",
                "description": "Terms & Conditions of Yolo247 updated! Check the obligations, terms of use, registration details, rules of play, deposit & withdrawal policies and more.",
                "canonical" : "https://yolo247.club/termsandconditions",
                "alternate": "https://m.yolo247.club/about/termsandconditions",
                "keywords" : "Terms & Conditions"
            },
            "responsibleGambling": {
                "title": "Responsible Gambling | Yolo247",
                "description": "Responsible Gambling is highly insisted. At Yolo247, we educate, insist and highly recommend to be aware of the negative impacts of gambling.",
                "canonical" : "https://yolo247.club/responsible-gambling",
                "alternate": "https://m.yolo247.club/about/responsible-gambling",
                "keywords" : "Responsible Gambling"
            },
            "privacyPolicy": {
                "title": "Privacy Policy | Yolo247",
                "description": "Privacy Policy reading is always integral in the process of betting. Go through definitions and types of data collected. Click to know more!",
                "canonical" : "https://yolo247.club/privacy-policy",
                "alternate": "https://m.yolo247.club/about/privacy-policy",
                "keywords" : "privacy policy"
            },
            "kycPolicy": {
                "title": "KYC Policy of Yolo247 | Get to know the KYC requirements",
                "description": "KYC Policy at Yolo247 is clear and transparent. Go through the guidelines, documents required, transaction limits and much more.",
                "canonical" : "https://yolo247.club/kyc-policy",
                "alternate": "https://m.yolo247.club/about/kyc-policy",
                "keywords" : "KYC Policy"
            },
            "exclusionPolicy": {
                "title": "Exclusion Policy  | Self-Exclusion Policy of Yolo247",
                "description": "Exclusion Policy (self-exclusion) is a facility that the site offers to help customers who feel that their Gambling is out of ",
                "canonical" : "https://yolo247.club/exclusion-policy",
                "alternate": "https://m.yolo247.club/about/self-exclusion-policy",
                "keywords" : "exclusion policy "
            },
            "amlPolicy": {
                "title": "AML Policy | Anti Money Laundering Policy of Yolo247",
                "description": "AML policy, also known as Anti-Money Laundering Policy, is actively adhered to by Yolo247. Your safety is our priority. Read more!",
                "canonical" : "https://yolo247.club/aml-policy",
                "alternate": "https://m.yolo247.club/about/aml-policy",
                "keywords" : "AML Policy"
            },
            "aboutus": {
                "title": "About Us | Yolo 247 - World's Biggest Betting ID Provider",
                "description": "Discover the world's biggest betting ID provider,With a wide range of options and a seamless user experience, Join us today and experience the ultimate thrill of online betting with Yolo 247.",
                "canonical" : "https://yolo247.club/aboutus",
                "alternate": "https://m.yolo247.club/about/aboutus",
                "keywords" : "About us, About Yolo247"
            },
            "SPRIBE": {
                "title": "Spribe Casino Games | Get the best Spribe Games on Yolo247",
                "description": "Spribe Casino Games are popular and prevalent. We picked the best games for you. Play with just a single sign-in option and explore the gaming options",
                "canonical" : "https://yolo247.club/instant-games/SPRIBE",
                "alternate": "https://m.yolo247.club/instant-games/SPRIBE",
                "keywords" : "spribe casino games"
            },
            "aviatrix": {
                "title": "Aviatrix Game: Play at Yolo247 & Win Real Money Online",
                "description": "Yolo247 invites you to elevate your gaming experience with Aviatrix, a game that takes you on a journey through the clouds. Play Aviatrix game and Start your winning journey now!",
                "canonical" : "https://yolo247.club/instant-games/aviatrix",
                "alternate": "https://m.yolo247.club/instant-games/aviatrix",
                "keywords" : "Aviatrix casino games"
            },
            "7MOJOS": {
                "title": "7Mojos Games | Explore the top games at the best platform",
                "description": "7Mojos Games in one place! One registration, multiple gaming options and better gaming experience. Explore the demo versions as well. Register now. ",
                "canonical" : "https://yolo247.club/online-casino-games/7MOJOS",
                "alternate": "https://m.yolo247.club/online-casino-games/7MOJOS",
                "keywords" : "7Mojos Games"
            },
            "supernowa": {
                "title": "Play Supernowa Casino Games on Yolo247",
                "description": " Supernowa casino games are one of the best in business. Check out Supernowa gaming and see the games offered on Yolo247.",
                "canonical" : "https://yolo247.club/online-casino-games/supernowa",
                "alternate": "https://m.yolo247.club/online-casino-games/supernowa",
                "keywords" : "Supernowa Casino Games,Supernowa gaming, Supernowa casino"
            },
            "pragmatic": {
                "title": "Pragmatic Casino Game on Yolo247 | Get 400% Welcome Bonus",
                "description": "Pragmatic Casino Games are available now! Providing the ultimate gaming experience with a wide range of Pragmatic games online. Explore now!",
                "canonical" : "https://yolo247.club/online-casino-games/slotGames/pragmaticplay",
                "alternate": "https://m.yolo247.club/online-casino-games/slotGames/pragmaticplay",
                "keywords" : "Pragmatic casino, pragmatic games"
            },
            "superspade": {
                "title": "Top Super Spade Games On Yolo247 | Play Now!",
                "description": " Super Spade games are popular because of exciting features, gameplay & payouts. Play Super Spade casino games on Yolo247 and win big. Join now!",
                "canonical" : "https://yolo247.club/online-casino-games/superspade",
                "alternate": "https://m.yolo247.club/online-casino-games/superspade",
                "keywords" : "super spade games,play super spade casino games, super spade casino"
            },
            "tvbet": {
                "title": "Play TVBET Casino Games Online | Real Money Games",
                "description": "TVBET casino games provide engaging games. A wide variety of games await online casino gaming enthusiasts. Find out more about Yolo247 TVBET Games.",
                "canonical" : "https://yolo247.club/online-casino-games/tvbet",
                "alternate": "https://m.yolo247.club/online-casino-games/tvbet",
                "keywords" : "TVBET casino games, Yolo247 TVBET games"
            },
            "netent": {
                "title": "Netent Casino Game on Yolo247 | Get Upto 400%  Bonus",
                "description": "NetEnt Games provide the widest range of online slots and casino slot games. Through one registration, explore all the top slot games online.",
                "canonical" : "https://yolo247.club/online-casino-games/slotGames/netent",
                "alternate": "https://m.yolo247.club/online-casino-games/slotGames/netent",
                "keywords" : "netent games"
            },
            "betgames": {
                "title": "BetGames On Yolo247 | Play BetGames & Win Big",
                "description": "BetGames is one of the modern-day game providers in Yolo247. Several casino gaming enthusiasts love the games. Here's why.BetGames is another top live dealer casino game provider that is available on Yolo247 from the comfort of your home. The best part of BetGames is that instead of promoting state-of-the-art technology, they have made their gaming solutions much more accessible to new gamers who want to explore the gaming industry at their own pace.Over the years, the live casino has been the hot trend in the gaming industry, and BetGames, too, has tapped into this market, creating some of the most awe-inspiring live dealer games. Most of these games make you feel like you are in a physical casino.",
                "canonical" : "https://yolo247.club/online-casino-games/betgames",
                "alternate": "https://m.yolo247.club/online-casino-games/betgames",
                "keywords" : "betgames,Yolo247 BetGames"
            },
            "sportsbook": {
                "title": "Get your sportsbook ID & Place a bet on your favorite sports",
                "description": "Unleash the power of your sportsbook ID for a premium betting experience. Join us for quick registration, seamless transactions, and endless excitement",
                "canonical" : "https://yolo247.club/sportsbook",
                "alternate": "https://m.yolo247.club/sportsbook",
                "keywords" : "Sportsbook Betting,Best Online Sportsbook, Sports Betting in India, sportsbook id, online sportsbook betting, sportsbook betting, best sportsbook betting sites"
            },
            "premiumSportsbook": {
                "title": "Best Online Sportsbook ID: Experience Victory with Every Wager!",
                "description": "Bet smarter with the best ID sportsbook. Elevate your gaming experience with instant deposits, fast withdrawals, and a wide array of sports to choose from",
                "canonical" : "https://yolo247.club/premium-sportsbook",
                "alternate": "https://m.yolo247.club/premium-sportsbook",
                "keywords" : "Premium Sportsbook Betting ,Best Online Premium Sportsbook Betting, Sports Betting in India, best id sportsbook, sportsbook betting online, sportsbook betting"
            },
            "appDownload": {
                "title": "Yolo247 App Download | Free Download for Android",
                "description": "Get the YOLO247 app for Android devices, supporting English and Hindi. Download the latest version from the Google Play Store and register to use its features.",
                "canonical" : "https://yolo247.club/app-download",
                "alternate": "https://m.yolo247.club/app-download",
                "keywords" : "Yolo App Download, Yolo Android App"
            },
            "login": {
                "title": "Secure Yolo247 Login: Access Your Account Safely",
                "description": "Login to your Yolo247 account, Safely access your account, and enjoy the convenience of managing your Yolo247 services hassle-free.",
                "canonical" : "https://yolo247.club/login",
                "alternate": "https://m.yolo247.club/login",
                "keywords" : "Yolo Login"
            },
            "signup": {
                "title": "Betting Made Easy: Register on yolo247 for Your Online Betting ID",
                "description": "Yolo 247 Signup Your key to a seamless and efficient entry into an exciting Online Gaming world, Don't miss out on the fun, sign up now!",
                "canonical" : "https://yolo247.club/signup",
                "alternate": "https://m.yolo247.club/signup",
                "keywords" : "Yolo User Registration"
            },
            "faq": {
                "title": "FAQ Yolo247 | Online BettingID Provider in India",
                "description": "Get the most out of Yolo 247 with our concise FAQ. Troubleshoot issues, discover hidden features, and enhance your experience!",
                "canonical" : "https://yolo247.club/faq",
                "alternate": "https://m.yolo247.club/faq",
            },
            "affiliate": {
                "title": "Affiliate Page",
                "description": "Affiliate Page",
                "canonical" : "https://yolo247.club/affiliate",
                "alternate": "https://m.yolo247.club/affiliate",
            },
            "loyalty": {
                "title": "Loyalty Page",
                "description": "Loyalty Page",
                "canonical" : "https://yolo247.club/loyalty",
                "alternate": "https://m.yolo247.club/loyalty",
            },
            "instantGames": {
                "title": "Instant Games | Play Best Instant Casino Online on Yolo247",
                "description": "Instant Games on Yolo247 are always on demand. Play all your favourite instant casino games online on your favourite betting platform.",
                "canonical" : "https://yolo247.club/instant-games",
                "alternate": "https://m.yolo247.club/instant-games",
                "keywords" : "Instant games,casino games, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites "
            },
            "majorLeagueCricketBetting": {
                "title": "MLC Betting in India | Major League Cricket Betting Online",
                "description": "MLC Betting is available on Yolo247! Get the best odds & markets for Major League Cricket betting online. Go through our free MLC gambling guide.",
                "canonical" : "https://yolo247.club/sports/tournaments/major-league-cricket-betting",
                "alternate": "https://m.yolo247.club/sports/tournaments/major-league-cricket-betting",
                "keywords" : "Major League Cricket Betting Online, MLC Betting in India"
            },
            "dailyDepositBonus": {
                "title": "Refill bonus | Best Casino Refill Bonus on Yolo247",
                "description": "Yolo247 Daily Deposit Bonus brings in a plethora of casino cashback opportunities. Know the rules, process, T&C of Daily Deposit Bonus on Yolo247.",
                "canonical" : "https://yolo247.club/daily-deposit-bonus",
                "alternate": "https://m.yolo247.club/daily-deposit-bonus",
                "keywords" : "daily deposit bonus, deposit bonus, bonus, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites "
            },
            "popular games": {
                "title": "Live Casino ID: Bet, Spin, and Win Instantly - Yolo247",
                "description": "Get ready for non-stop fun with a personalized casino ID. Join the excitement, play your favorite casino, and win big!",
                "canonical" : "https://yolo247.club//online-casino-games/best-casino-games",
                "alternate": "https://m.yolo247.club/online-casino-games/best-casino-games",
                "keywords" : "Best Casino Games Popular Casino Games, online casino real money, popular games in india, popular pc games, popular video games, online casino betting sites, casino id"
            },
            "evolution": {
                "title": "Play Evolution Gaming Casino | Upto 400%  Bonus",
                "description": "Evolution Gaming is one of the best gaming platforms. Learn how to enjoy an array of Evolution Gaming casino games on Yolo247",
                "canonical" : "https://yolo247.club/online-casino-games/evolutiongaming",
                "alternate": "https://m.yolo247.club/online-casino-games/evolution-gaming",
                "keywords" : "Evolution Gaming, Evolution Gaming Casino"
             },
             "spribe": {
                 "title": "Spribe Games | Play The Best Yolo247 Spribe Games",
                 "description": "Spribe games use the latest technology to build innovative and immersive live casino games for users. Find out how to experience thrilling Yolo247 Spribe Games.",
                 "canonical" : "https://yolo247.club/instant-games/SPRIBE",
                 "alternate": "https://m.yolo247.club/instant-games/SPRIBE",
                 "keywords" : "Spribe Games,Yolo247 Spribe games,Yolo Spribe games,Spribe live casino games"
            },
            "ezugi": {
                "title": "Ezugi Live Casino | Play Yolo247 Ezugi Games",
                "description": " Ezugi's state-of-the-art live casino games are a massive hit among fans. Let us do a deep dive into Ezugi Live Casino games.Ezugi live casino is a popular gaming provider in the online gaming world. A huge number of casino gaming enthusiasts play Ezugi live casino games on Yolo247. Unlike some of the other competitors, Ezugi gaming provides you with an ultimate gaming experience.A couple of things make Ezugi live casino dealer games so different. Let's learn more about the gaming provider.",
                "canonical" : "https://yolo247.club/online-casino-games/ezugi-live-casino",
                "alternate": "https://m.yolo247.club/online-casino-games/ezugi-live-casino",
                "keywords" : "Ezugi Live Casino,Ezugi, Ezugi Gaming, Yolo247 Ezugi Live Casino"
            },
             "asiaCupBonanza": {
                "title": "Asia Cup Bonanza | Best Cricket Betting Odds",
                "description": "Online cricket betting on Yolo247 provides the best betting odds for cricket in India. Get live score, fancy bets & fast withdrawal. Bet on cricket now!",
                "keywords": "Asia Cup Bonanza, Online Cricket Betting, Cricket Betting, cricket betting odds, cricket betting site, best site to bet on cricket, best cricket betting site (s) in India, Yolo247 online cricket betting, Yolo247 cricket betting odds, Yolo247 cricket betting, cricket betting sites, online cricket satta bazar",
                "canonical" : "https://yolo247.club/asia-cup-bonanza",
                "alternate": "https://m.yolo247.club/asia-cup-bonanza"
            },
             //member dashboard pages
             "memberDashboard": {
                "canonical" : "https://yolo247.club/member/profile",
                "alternate": "https://m.yolo247.club/member/profile"
            },
            "memberProfileAccountInfo": {
                "canonical" : "https://yolo247.club/member/profile/accountinfo",
                "alternate": "https://m.yolo247.club/member/profile/accountinfo"
            },
            "memberProfileChangePassword": {
                "canonical" : "https://yolo247.club/member/profile/password",
                "alternate": "https://m.yolo247.club/member/profile/password"
            },
            "memberProfileBankDetails": {
                "canonical" : "https://yolo247.club/member/profile/bankdetails",
                "alternate": "https://m.yolo247.club/member/profile/bankdetails"
            },
            "memberProfileKYC": {
                "canonical" : "https://yolo247.club/member/profile/kycpage",
                "alternate": "https://m.yolo247.club/member/profile/kycpage"
            },
            "memberFinanceDeposit": {
                "canonical" : "https://yolo247.club/member/finance/deposit",
                "alternate": "https://m.yolo247.club/member/finance/deposit"
            },
            "memberFinanceWithdrawal": {
                "canonical" : "https://yolo247.club/member/finance/withdrawal",
                "alternate": "https://m.yolo247.club/member/finance/withdrawal"
            },
            "memberFinanceHistory": {
                "canonical" : "https://yolo247.club/member/finance/history",
                "alternate": "https://m.yolo247.club/member/finance/history"
            },
            "memberFinanceTransfer": {
                "canonical" : "https://yolo247.club/member/finance/transfer",
                "alternate": "https://m.yolo247.club/member/finance/transfer"
            },
            "memberMyBets": {
                "canonical" : "https://yolo247.club/member/betDetails/mybets",
                "alternate": "https://m.yolo247.club/member/betDetails/mybets"
            },
            "memberP&L": {
                "canonical" : "https://yolo247.club/member/betDetails/profitandloss",
                "alternate": "https://m.yolo247.club/member/betDetails/profitandloss"
            },
            "memberCasinoStatement": {
                "canonical" : "https://yolo247.club/member/betDetails/casinostatement",
                "alternate": "https://m.yolo247.club/member/betDetails/casinostatement"
            },
            "memberSportsbookStatement": {
                "canonical" : "https://yolo247.club/member/betDetails/sportbookstatement",
                "alternate": "https://m.yolo247.club/member/betDetails/sportbookstatement"
            },
            "memberSTPStatement": {
                "canonical" : "https://yolo247.club/member/betDetails/splitThePotStatement",
                "alternate": "https://m.yolo247.club/member/betDetails/splitThePotStatement"
            },
            "memberGalaxysStatement": {
                "canonical" : "https://yolo247.club/member/betDetails/galaxyStatement",
                "alternate": "https://m.yolo247.club/member/betDetails/galaxyStatement"
            },
            "memberAviatrixStatement": {
                "canonical" : "https://yolo247.club/member/betDetails/aviatrixStatement",
                "alternate": "https://m.yolo247.club/member/betDetails/aviatrixStatement"
            },
            "memberAccountStatement": {
                "canonical" : "https://yolo247.club/member/betDetails/accountStatement",
                "alternate": "https://m.yolo247.club/member/betDetails/accountStatement"
            },
            "memberVirtualSportsStatement": {
                "canonical" : "https://yolo247.club/member/betDetails/virtualSportsStatement",
                "alternate": "https://m.yolo247.club/member/betDetails/virtualSportsStatement"
            },
            "memberTurnoverStatement": {
                "canonical" : "https://yolo247.club/member/betDetails/turnoverStatement",
                "alternate": "https://m.yolo247.club/member/betDetails/turnoverStatement"
            },
            "memberPrormotions": {
                "canonical" : "https://yolo247.club/member/promotions",
                "alternate": "https://m.yolo247.club/member/promotions"
            },
            "memberMessages": {
                "canonical" : "https://yolo247.club/member/message",
                "alternate": "https://m.yolo247.club/member/message"
            },
            "ninjacrash": {
                "title": "Plan NinjaCrash Game Online & Win Real Money 2024",
                "description": "Gear up for the ultimate challenge in NinjaCrash! Plan your moves strategically, play online, and stand a chance to win real money in 2024.",
                "canonical" : "https://yolo247.club/instant-games/ninjacrash",
                "alternate": "https://m.yolo247.club/instant-games/ninja%20crash",
                "keywords" : "NinjaCrash game, Online NinjaCrash game"
            },
            "big-bash": {
                "title": "Play Casino Games | Real Money Gambling | Satta Online 24/7",
                "description": "Play the most popular online casino games at YOLO247 & earn a chance to win real money. Get unbeatable welcome bonus & offers on your favorite satta games in India",
                "canonical" : "https://yolo247.club/promotions/big-bash",
                "alternate": "https://m.yolo247.club/promotions/big-bash",
                "keywords" : "casino games, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
            },
        },
        "33": {
            "default": {
                "title": "Play Online Casino Games in India 24/7 - Win Real Money ₹₹",
                "description": "Experience non-stop thrills with our Online Casino Games in India. Play to win Real Money and enjoy the excitement of the game. Start your winning journey now!",
                "canonical" : "https://www.Baaziadda.com/",
                "alternate": "https://m.Baaziadda.com/",
                "keywords" : "casino games, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
            },
            "onlineCasinoGames": {
                "title": "Online Casino Games in India | Win Real Money in India",
                "description": "With our Online Casino Games, Win real money and experience the thrill of gaming like never before. Join us at BaaziAdda for an unforgettable journey to riches!",
                "canonical": "https://www.Baaziadda.com/online-casino-games",
                "alternate": "https://m.Baaziadda.com/online-casino-games",
                "keywords": "online casino games,online casino real money,games casino,casino gaming,online casino,online casino india,casino games online,online casino for real money,live casino online,no deposit bonus casino,best online casino in india,online casino india real money,best online casinos in india,live casino india,casino with bonus,bet online casino,best casino apps in india,best casinos in india,best online casino,online betting games,casino games name,online casino games real money,casio india online,online casino app,online casino games india,online gambling games,casino for real money"
            },
            "blackjack": {
                "title": "Blackjack Online Casino Game - Play & Win ₹₹₹",
                "description": "Blackjack: Turn your skills into real money rewards! Play and win. The cards are in your favor – are you ready?",
                "canonical": "https://www.Baaziadda.com/online-casino-games/blackjack",
                "alternate": "https://m.Baaziadda.com/online-casino-games/blackjack",
                "keywords": "blackjack online,blackjack game,playing blackjack,blackjack playing,play blackjack online,blackjack game rules,online blackjack real money,play blackjack,blackjack online game,online blackjack real money,black jack card games"
            },
            "roulette": {
                "title": "Play Roulette Online for Free & Real Money - BaaziAdda",
                "description": "Spin the wheel of fortune! Play roulette online for free or real money at BaaziAdda. Unleash the excitement and test your luck today for a chance to Win Big!",
                "canonical": "https://www.Baaziadda.com/online-casino-games/roulette",
                "alternate": "https://m.Baaziadda.com/online-casino-games/roulette",
                "keywords": "roulette game,roulette online,casino roulette,online roulette india,lightning roulette,online roulette game,roulette casino game,online roulette real money,play roulette online,casino roulette table"
            },
            "lightningroulette": {
                "title": "Play Lightning Roulette Online | Win Real Money in India",
                "description": "Spin the wheel of fortune with Lightning Roulette online at BaaziAdda! Win real money in India as you feel the electrifying thrill of every spin.",
                "canonical": "https://baaziadda.com/online-casino-games/lightning-roulette",
                "alternate": "https://m.baaziadda.com/online-casino-games/lightning-roulette",
                "keywords": "lightning roulette game,lightning roulette online,casino lightning roulette,online roulette india,lightning roulette,online roulette game,roulette casino game,online roulette real money,play roulette online,casino roulette table"
            },
            "dragonTiger": {
                "title": "Dragon Tiger Online | Play for Real Money at BaaziAdda",
                "description": "Enter the mystical world of Dragon Tiger online. Play for real money at BaaziAdda and let the fortunes unfold.",
                "canonical": "https://www.Baaziadda.com/online-casino-games/dragontiger",
                "alternate": "https://m.Baaziadda.com/online-casino-games/dragontiger",
                "keywords": "Baaziadda is your best destination for playing Online Dragon Tiger Game. Sign up now and get welcome Bonus to test your luck with the most popular games."
            },
            "baccarat": {
                "title": "Play Baccarat Online for Free & Real Money in India 2024",
                "description": "Play for free or try your luck with real money in India 2024. Join BaaziAdda for an immersive gaming experience and big wins!",
                "canonical": "https://www.Baaziadda.com/online-casino-games/baccarat",
                "alternate": "https://m.Baaziadda.com/online-casino-games/baccarat",
                "keywords": "baccarat game,baccarat online,baccarat casino,baccarat gaming,baccarat rule,baccarat game online,baccarat game rules,play baccarat online,baccarat online play,baccarat online free,best baccarat online casino,best casino for baccarat"
            },
            "teenpattiOnlinePlay": {
                "title": "Teen Patti Online | Win Real Money in India - BaaziAdda",
                "description": "Embrace the spirit of Teen Patti online at BaaziAdda! Win real money in India as you play this beloved card game. Play Now!",
                "canonical": "https://www.baaziadda.com/teenpatti-online-play",
                "keywords": "teen patti ,teen patti game, teen patti online, teen patti casino"
            },
            "sportsBetting": {
                "title": "Betting Exchange | Everything you need to Win at Betting",
                "description": "Find everything you need to elevate your betting game and maximize your wins. Take control of your bets and conquer the exchange!",
                "canonical": "https://www.Baaziadda.com/betting-exchange",
                "alternate": "https://m.Baaziadda.com/betting-exchange?id=0",
                "keywords": "sports betting,sports satta,sports betting app,sports betting in india,bet on sports online,bet on sports,sports book,sportsbetting,sports bet,sports betting sites,best sports betting apps,betting sites with free registration bonus,best sports betting sites,online cricket satta bazar,online cricket betting,cricket satta,cricket betting app,cricket online betting apps,cricket betting,cricket betting sites,best cricket betting app,cricket betting apps in india,best cricket betting apps in india,cricket betting sites,live cricket betting,cricket betting app download,indian cricket betting sites,best betting sites for cricket in india,cricket bet online india,best cricket betting sites,cricket betting website,cricket online betting sites,online betting apps cricket,cricket betting online sites in india,football betting,best football prediction sites,betting app for football,football prediction site,football bet app,online football betting,betting football site,football betting sites,bet online football,bet football online,best football betting sites,best betting football sites,tennis betting,today tennis match prediction,betting on tennis tips,predictions tennis,tennis predictions today,tennis betting online,tennis match prediction,tennis bet prediction,bet online tennis,online tennis betting sites"
            },
            "onlineCricketSattaBazar": {
                "title": "Online Cricket Betting in India | Best Odds at BaaziAdda",
                "description": "Elevate your game with online cricket betting at BaaziAdda! Enjoy the best odds and dive into the excitement of cricket. Play Now!",
                "canonical": "https://www.Baaziadda.com/betting-exchange/online-cricket-satta-bazar",
                "alternate": "https://m.Baaziadda.com/betting-exchange/online-cricket-satta-bazar",
                "keywords": "Online Cricket Betting, Cricket Betting, cricket betting odds, cricket betting site, best site to bet on cricket, best cricket betting site (s) in India, Baaziadda online cricket betting, Baaziadda cricket betting odds, Baaziadda cricket betting, cricket betting sites, online cricket satta bazar",
            },
            "footballBetting": {
                "title": "Football Betting Online in India | Best Odds at BaaziAdda",
                "description": "Explore the best odds and immerse yourself in the thrill of the game. Bet on your favorite teams and players for a chance to win real money in India!",
                "canonical": "https://www.Baaziadda.com/betting-exchange/football-betting",
                "alternate": "https://m.Baaziadda.com/betting-exchange/football-betting",
                "keywords": "football prediction site,best football prediction sites,betting app for football,football betting,football bet app,online football betting,betting football site,football betting sites,bet online football,bet football online,best football betting sites,best betting football sites"
            },
            "tennisBetting": {
                "title": "Online Tennis Betting in India | Best Odds at BaaziAdda",
                "description": "Enjoy the best odds and experience the intensity of every match. Bet on your favorite players and win real money in India with every ace!",
                "canonical": "https://www.Baaziadda.com/betting-exchange/tennis-betting",
                "alternate": "https://m.Baaziadda.com/betting-exchange/tennis-betting",
                "keywords": "predictions tennis,tennis predictions today,today tennis match prediction,betting on tennis tips,tennis betting,tennis betting online,tennis match prediction,tennis bet prediction,bet online tennis,online tennis betting sites"
            },
            "volleyballBetting": {
                "title": "Volleyball Betting in India | Best Odds at BaaziAdda",
                "description": "Enjoy the best odds and experience the intensity of every match. Bet on your favorite players and win real money in India with every ace!",
                "canonical": "https://yolo247.club/betting-exchange/volleyball-betting",
                "alternate": "https://m.yolo247.club/betting-exchange/volleyball-betting",
                "keywords": "predictions volleyball,volleyball predictions today,today volleyball match prediction,betting on volleyball tips,volleyball betting,volleyball betting online,volleyball match prediction,volleyball bet prediction,bet online volleyball,online volleyball betting sites"
            },
            "promotions": {
                "title": "Play Casino Games | Real Money Gambling | Satta Online 24/7",
                "description": "Play the most popular online casino games at Baaziadda & earn a chance to win real money. Get unbeatable welcome bonus & offers on your favorite satta games in India",
                "canonical" : "https://www.Baaziadda.com/promotions",
                "alternate" : "https://m.Baaziadda.com/promotions",
                "keywords" : "casino games, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
            },
            "popular games": {
                "title": "Best Casino Games to Win Money Online | Play Now & Win Big",
                "description": "Discover the Best Casino Games to Win money online! Unleash the thrill of gaming and turn your luck into real money rewards. Let the winning begin!",
                "canonical" : "https://www.Baaziadda.com/online-casino-games/populargames",
                "alternate" : "https://m.Baaziadda.com/online-casino-games/populargames",
                "keywords" : "popular games in india, popular pc games, popular video games"
            },
            "slotGames": {
                "title": "Play Slot Games Online & Win Real Money | BaaziAdda",
                "description": "Spin the reels and win big! Play Slot Games Online at BaaziAdda to win real money. Your jackpot awaits – start spinning now and let the winnings roll in!",
                "canonical" : "https://www.Baaziadda.com/online-casino-games/slotGames",
                "alternate" : "https://m.Baaziadda.com/online-casino-games/slotGames",
                "keywords" : "Slot games, Online slots, casino slot games"
            },
            "aviator": {
                "title": "Play Aviator Casino Game Online 🕹️ at BaaziAdda",
                "description": "Take off with excitement! Play the Aviator Casino Game online at BaaziAdda. Get ready for a gaming experience like no other, where big wins await.",
                "canonical" : "https://www.Baaziadda.com/instant-games/aviator",
                "alternate" : "https://m.Baaziadda.com/instant-games/aviator",
                "keywords" : "Aviator game, Aviator online game "
            },
            "cashshow": {
                "title": "Cash Show Online | Play at BaaziAdda and Win Big",
                "description": "Join the Cash Show online at BaaziAdda and claim your chance to win big! Play for real money and showcase your skills in this exciting game.",
                "canonical" : "https://www.baaziadda.com/instant-games/cashshow",
                "alternate": "https://m.baaziadda.com/instant-games/cashshow",
                "keywords" : "Cash Show game, Cash Show online game "
            },
            "jetx": {
                "title": "JetX Online | Play for Real Money at BaaziAdda",
                "description": "Rev up the thrill with JetX online at BaaziAdda. Play for real money and experience the rush of high-speed gaming.",
                "canonical" : "https://www.baaziadda.com/instant-games/jetx",
                "alternate": "https://m.baaziadda.com/instant-games/jetx",
                "keywords" : "Jet X game, Jet X online game"
            },
            "rockpaperscissors": {
                "title": "Rockpaperscissors ID - Grab Your Exclusive Deals Now!",
                "description": "Explore the thrill of rockpaperscissors ID gaming. Join the fun with our interactive platform and challenge opponents worldwide",
                "canonical" : "https://baaziadda.com/instant-games/ROCKPAPERSCISSORS",
                "alternate": "https://baaziadda.com/instant-games/ROCKPAPERSCISSORS",
                "keywords" : "rockpaperscissors id, rockpaperscissors game, rockpaperscissors online"
            },
            "plinkox": {
                "title": "PlinkoX Game Online | Play for Real Money at BaaziAdda",
                "description": "Drop, win, repeat! Immerse yourself in the PlinkoX game online at BaaziAdda. Play for real money.",
                "canonical" : "https://www.baaziadda.com/instant-games/plinkox",
                "alternate": "https://m.baaziadda.com/instant-games/plinkox",
                "keywords" : "Plinkox game, Plinkox online game "
            },
            "footballx": {
                "title": "Football X Game | Live Casino Online Games on baaziadda",
                "description": "Online Casino games are revolutionizing the gaming world. Find out some of the popular live casino online games on baaziadda.",
                "canonical" : "https://www.baaziadda.com/instant-games/footballx",
                "alternate": "https://m.baaziadda.com/instant-games/footballx",
                "keywords" : "Football X game, Football X online game "
            },
            "balloon": {
                "title": "Balloon Game | Live Casino Online Games on baaziadda",
                "description": "Online Casino games are revolutionizing the gaming world. Find out some of the popular live casino online games on baaziadda.",
                "canonical" : "https://www.baaziadda.com/instant-games/balloon",
                "alternate": "https://m.baaziadda.com/instant-games/balloon",
                "keywords" : "Balloon game, Balloon online game "
            },
            "mines": {
                "title": "Play Mines Online | Play for Real Money at BaaziAdda",
                "description": "Unearth treasures and win real money! Play Mines online at BaaziAdda and experience the excitement of this thrilling game.",
                "canonical" : "https://www.baaziadda.com/instant-games/mines",
                "alternate": "https://m.baaziadda.com/instant-games/mines",
                "keywords" : "Mines game, Online mines game"
            },
            "ninjacrash": {
                "title": "Plan NinjaCrash Game Online & Win Real Money 2024",
                "description": "Gear up for the ultimate challenge in NinjaCrash! Plan your moves strategically, play online, and stand a chance to win real money in 2024.",
                "canonical" : "https://www.baaziadda.com/instant-games/ninjacrash",
                "alternate": "https://m.baaziadda.com/instant-games/ninja%20crash",
                "keywords" : "NinjaCrash game, Online NinjaCrash game"
            },
            "aviatrix": {
                "title": "Aviatrix Casino Game Online | Play Now & Win Big",
                "description": "Embark on a thrilling adventure with Aviatrix Casino Game online. Play now at BaaziAdda and seize the opportunity to win big.",
                "canonical" : "https://www.baaziadda.com/instant-games/aviatrix",
                "alternate": "https://m.baaziadda.com/instant-games/aviatrix",
                "keywords" : "aviatrix game, Online aviatrix game"
            },
            "macludo": {
                "title": "Ludo Game | Play Ludo Game Online for real cash",
                "description": "Ludo Game is lucrative. Register and get the best gaming experience. Play Ludo Game online for real cash and win. Get the profits directly. ",
                "canonical" : "https://www.Baaziadda.com/online-casino-games/macludo",
                "alternate" : "https://m.Baaziadda.com/online-casino-games/mac%20ludo",
                "keywords" : "Ludo Game, Ludo game Online"
            },
            "crazytime": {
                "title": "Play CrazyTime Online for Free & Real Money - BaaziAdda",
                "description": "Dive into the whirlwind of excitement with CrazyTime online at BaaziAdda! Brace yourself for a gaming experience like no other – it's time to unleash the craziness and win big!",
                "canonical" : "https://www.baaziadda.com/online-casino-games/crazytime",
                "alternate" : "https://m.baaziadda.com/online-casino-games/crazytime",
                "keywords" : "Crazytime Game, Crazytime game Online"
            },
            "monopolylive": {
                "title": "Monopoly Live, Win Real Money | BaaziAdda",
                "description": "Step into the world of Monopoly Live and win real money at BaaziAdda. Play now for a chance to build your empire and claim victory!",
                "canonical" : "https://baaziadda.com/online-casino-games/MONOPOLYLIVE",
                "alternate": "https://m.baaziadda.com/online-casino-games/MONOPOLYLIVE",
                "keywords" : "MONOPOLY LIVE"
            },
            "funkytime": {
                "title": "Funkytime: Play at BaaziAdda & Win Real Money Online",
                "description": "Play Funkytime and win real money online. Unleash your groove and enjoy a unique gaming experience. Let the funky wins roll in!",
                "canonical": "https://baaziadda.com/online-casino-games/Funkytime",
                "alternate": "https://m.baaziadda.com/online-casino-games/Funkytime",
                "keywords" : "casino games, Funkytime, Funky Time, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
            },
            "lightningdice": {
                "title": "Lightningdice | Play at BaaziAdda & Win Real Money Online",
                "description": "Feel the electrifying thrill of Lightningdice at BaaziAdda! Play and win real money online as you roll the lightning-infused dice.",
                "canonical" : "https://baaziadda.com/online-casino-games/lightningdice",
                "alternate": "https://m.baaziadda.com/online-casino-games/lightningdice",
                "keywords" : "Lightning Dice"
            },
            "cricketx": {
                "title": "Play CricketX Live Online & Win Real Money at BaaziAdda",
                "description": "Experience the excitement of CricketX Live at BaaziAdda! Play online, showcase your cricket skills, and win real money.",
                "canonical" : "https://baaziadda.com/instant-games/cricketx",
                "alternate": "https://m.baaziadda.com/instant-games/cricketx",
                "keywords" : "Cricketx Games,BaaziAdda Cricketx games,BaaziAdda Cricketx games,Cricketx casino games"
            },
            "virtualSports": {
                "title": "Online Betting for Sports and Casino at BaaziAdda",
                "description": "Experience the ultimate thrill with online betting for sports and casino at BaaziAdda! Enjoy the best of both worlds as you bet on your favorite sports and casino games.",
                "canonical" : "https://baaziadda.com/virtualSports/vci",
                "alternate": "https://baaziadda.com/virtualSports/vci",
            },
            "virtualSportsBL": {
                "title": "Virtual Sports on Baazi Adda",
                "description": "Virtual Sports on Baazi Adda",
                "canonical" : "https://baaziadda.com/virtualSports/vbl",
                "alternate": "https://baaziadda.com/virtualSports/vbl",
            },
            "virtualSportsFB": {
                "title": "Virtual Sports on Baazi Adda",
                "description": "Virtual Sports on Baazi Adda",
                "canonical" : "https://baaziadda.com/virtualSports/vfb",
                "alternate": "https://baaziadda.com/virtualSports/vfb",
            },
            "virtualSportsFEL": {
                "title": "Virtual Sports on Baazi Adda",
                "description": "Virtual Sports on Baazi Adda",
                "canonical" : "https://baaziadda.com/virtualSports/vfel",
                "alternate": "https://baaziadda.com/virtualSports/vfel",
            },
            "virtualSportsFEC": {
                "title": "Virtual Sports on Baazi Adda",
                "description": "Virtual Sports on Baazi Adda",
                "canonical" : "https://baaziadda.com/virtualSports/vfec",
                "alternate": "https://m.baaziadda.com/virtualSports/vfec",
            },
            "virtualSportsVTI": {
                "title": "Elevate Your Game with Virtual Tennis Betting ID",
                "description": "Discover the ultimate virtual tennis betting ID for an unparalleled gaming adventure. Predict, bet, and win big with our cutting-edge platform. Join now!",
                "canonical" : "https://baaziadda.com/virtualSports/vti",
                "alternate": "https://baaziadda.com/virtualSports/vti",
            },
            "virtualSportsWC": {
                "title": "Navigating Virtual World Cup Betting ID Like a Pro | Bet Smart,Win Big",
                "description": "Explore thrilling with virtual World Cup betting ID services for an immersive gaming experience like never before!",
                "canonical" : "https://baaziadda.com/virtualSports/vfwc",
                "alternate": "https://baaziadda.com/virtualSports/vfwc",
            },
            "premiumSportsbook": {
                "title": "Premium Sportsbook | Online Sports Betting at BaaziAdda",
                "description": "Go for gold with our Premium Sportsbook at BaaziAdda! Engage in online sports betting with the best odds and premium features.",
                "canonical" : "https://baaziadda.com/premium-sportsbook",
                "alternate": "https://m.baaziadda.com/premium-sportsbook",
                "keywords" : "Premium Sportsbook Betting ,Best Online Premium Sportsbook Betting, Sports Betting in India"
            },
            "welcomeBonus": {
                "title": "400% Welcome Bonus to get you started (2024)💰",
                "description": "Claim your fortune with a whopping 400% Welcome Bonus in 2024 at BaaziAdda! 🎉 Your journey to big wins starts with an unbeatable bonus! Sign up now!",
                "canonical" : "https://www.Baaziadda.com/promotions/welcome-bonus",
                "alternate" : "https://m.Baaziadda.com/promotions/welcome-bonus",
                "keywords" : "Welcome Bonus"
            },
            "refillBonus": {
                "title": "Get the BaaziAdda Refill Bonus 💰 and Win Big!",
                "description": "Refill your luck and Win Big with BaaziAdda's Refill Bonus! 💰 Enjoy extra playing power. It's the perfect boost to increase your chances of winning!",
                 "canonical" : "https://www.Baaziadda.com/promotions/refill-bonus",
                "alternate" : "https://m.Baaziadda.com/promotions/refill-bonus",
                "keywords" : "Refill bonus"
            },
            "cashbackBonus": {
                "title": "Cashback Bonus 💰 at BaaziAdda 2024",
                "description": "Enjoy the thrill of gaming with Cashback Bonus at BaaziAdda in 2024! 💰 It's your chance to turn every bet into a win!",
                "canonical" : "https://www.Baaziadda.com/promotions/cashback-bonus",
                "alternate" : "https://m.Baaziadda.com/promotions/cashback-bonus",
                "keywords" : "Cashback Bonus"
            },
            "referralBonus": {
                "title": "Referral Bonus 💰 at BaaziAdda 2024",
                "description": "Share the gaming joy with your friends, and when they join, you both reap the rewards 💰 It's a win-win – start referring now!",
                "canonical" : "https://Baaziadda.com/promotions/referral-bonus",
                "alternate": "https://m.Baaziadda.com/promotions/referral-bonus",
                "keywords" : "Referral Bonus"
            },
            "termsandconditions": {
                "title": "Terms & Conditions | BaaziAdda Online Casino",
                "description": "Read the Terms & Conditions at BaaziAdda Online Casino to ensure a smooth and fair gaming experience. Stay informed, play responsibly, and maximize your chances of winning!",
                "canonical" : "https://www.Baaziadda.com/about/termsandconditions",
                "alternate" : "https://m.Baaziadda.com/about/termsandconditions",
                "keywords" : "Terms & Conditions"
            },
            "responsibleGambling": {
                "title": "Responsible Gambling | BaaziAdda Online Casino",
                "description": "Dive into our Responsible Gambling policies to ensure a safe and enjoyable gaming environment. Your well-being is our top priority!",
                "canonical" : "https://www.Baaziadda.com/about/responsible-gambling",
                "alternate" : "https://m.Baaziadda.com/about/responsible-gambling",
                "keywords" : "Responsible Gambling"
            },
            "privacyPolicy": {
                "title": "Privacy Policy | BaaziAdda Online Casino",
                "description": "Your privacy matters! Discover how we safeguard your information with the Privacy Policy. Play with confidence, knowing that your data is secure and protected.",
                "canonical" : "https://www.Baaziadda.com/about/privacy-policy",
                "alternate" : "https://m.Baaziadda.com/about/privacy-policy",
                "keywords" : "privacy policy"
            },
            "kycPolicy": {
                "title": "KYC Policy | BaaziAdda Online Casino",
                "description": "Learn about our Know Your Customer policy at BaaziAdda. Ensuring a secure gaming space, we prioritize transparency and accountability to enhance your gaming.",
                "canonical" : "https://www.Baaziadda.com/about/kyc-policy",
                "alternate" : "https://m.Baaziadda.com/about/kyc-policy",
                "keywords" : "KYC Policy"
            },
            "exclusionPolicy": {
                "title": "Exclusion Policy | BaaziAdda Online Casino",
                "description": "Play responsibly with our Exclusion Policy. Learn about the tools we provide to promote responsible gaming and help you stay in control of your play.",
                "canonical" : "https://www.Baaziadda.com/about/self-exclusion-policy",
                "alternate" : "https://m.Baaziadda.com/about/self-exclusion-policy",
                "keywords" : "exclusion policy "
            },
            "amlPolicy": {
                "title": "AML Policy | BaaziAdda Online Casino",
                "description": "Explore our Anti-Money Laundering (AML) Policy. We are committed to maintaining the integrity of our platform for a fair and secure gaming experience.",
                "canonical" : "https://www.Baaziadda.com/about/aml-policy",
                "alternate" : "https://m.Baaziadda.com/about/aml-policy",
                "keywords" : "AML Policy"
            },
            "aboutus": {
                "title": "About Us | BaaziAdda Online Casino",
                "description": "Get to know us better! Explore the story behind BaaziAdda. Discover our mission, values, and commitment to providing an exceptional gaming experience.",
                "canonical" : "https://www.Baaziadda.com/about/aboutus",
                "alternate" : "https://m.Baaziadda.com/about/aboutus",
                "keywords" : "About us, About Baaziadda"
            },
            "faq": {
                "title": "Frequently Asked Questions | BaaziAdda Online Casino",
                "description": "Have questions? Find answers in our Frequently Asked Questions section at BaaziAdda Online Casino.",
                "canonical" : "https://baaziadda.com/faq",
                "alternate": "https://baaziadda.com/faq",
            },
            "affiliate": {
                "title": "Affiliate | BaaziAdda Online Casino",
                "description": "Join the BaaziAdda Affiliate program and turn your passion for gaming into rewards. Start earning commissions and enjoy the benefits of our rewarding partnership!",
                "canonical" : "https://baaziadda.com/affiliate",
                "alternate": "https://m.baaziadda.com/affiliate",
            },
            "bingo": {
                "title": "Play Bingo Online - endless fun & real money at BaaziAdda",
                "description": "Experience the thrill of playing bingo online at our top-rated gaming platform. Enjoy a wide variety of exciting bingo games & wing big",
                "canonical": "https://baaziadda.com/online-casino-games/bingo",
                "alternate": "https://m.baaziadda.com/online-casino-games/bingo",
                "keywords" : "casino games, bingo, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
            },
            "SPRIBE": {
                "title": "Spribe Casino Games | Get the best Spribe Games on Baaziadda",
                "description": "Spribe Casino Games are popular and prevalent. We picked the best games for you. Play with just a single sign-in option and explore the gaming options",
                "canonical" : "https://www.Baaziadda.com/instant-games/SPRIBE",
                "alternate" : "https://m.Baaziadda.com/instant-games/SPRIBE",
                "keywords" : "spribe casino games"
            },
            "7MOJOS": {
                "title": "7Mojos Games | Explore the top games at the best platform",
                "description": "7Mojos Games in one place! One registration, multiple gaming options and better gaming experience. Explore the demo versions as well. Register now. ",
                "canonical" : "https://www.Baaziadda.com/online-casino-games/7MOJOS",
                "alternate" : "https://m.Baaziadda.com/online-casino-games/7MOJOS",
                "keywords" : "7Mojos Games"
            },
            "supernowa": {
                "title": "Supernowa Games | Live Casino Games from Baaziadda",
                "description": "Supernowa games at the best! Play live casino games, online card games and slots on the safest gaming platform. Click to explore.",
                "canonical" : "https://m.Baaziadda.com/online-casino-games/supernowa",
                "alternate" : "https://m.Baaziadda.com/online-casino-games/supernowa",
                "keywords" : "supernowa games"
            },
            "pragmatic": {
                "title": "Pragmatic Casino Games online| Play on Baaziadda",
                "description": "Pragmatic Casino Games are available now! Providing the ultimate gaming experience with a wide range of Pragmatic games online. Explore now!",
                "canonical" : "https://www.Baaziadda.com/online-casino-games/slotGames/pragmaticplay",
                "alternate" : "https://m.Baaziadda.com/online-casino-games/slotGames/pragmaticplay",
                "keywords" : "Pragmatic casino, pragmatic games"
            },
            "superspade": {
                "title": "Super Spade Games | Online Casino on Baaziadda",
                "description": "Super Spade Games are online casino's delight. Play the best games with just one sign-in. Simple process, simpler registration. Join now!",
                "canonical" : "https://www.Baaziadda.com/online-casino-games/superspade",
                "alternate" : "https://m.Baaziadda.com/online-casino-games/superspade",
                "keywords" : "super spade games"
            },
            "tvbet": {
                "title": "TVBet Games | Live Betting & Gaming on Baaziadda",
                "description": "TV Bet Games offers a wide variety of online gaming options. Explore all the top-rated games in one platform through one sign-in. Join now!",
                "canonical" : "https://www.Baaziadda.com/online-casino-games/tvbet",
                "alternate" : "https://m.Baaziadda.com/online-casino-games/tvbet",
                "keywords" : "tvbet games"
            },
            "netent": {
                "title": "NetEnt Games | Slots & Casino Games on Baaziadda",
                "description": "NetEnt Games provide the widest range of online slots and casino slot games. Through one registration, explore all the top slot games online.",
                "canonical" : "https://www.Baaziadda.com/online-casino-games/slotGames/netent",
                "alternate" : "https://m.Baaziadda.com/online-casino-games/slotGames/netent",
                "keywords" : "netent games"
            },
            "betgames": {
                "title": "BetGames | Live Dealer of Online Games on Baaziadda",
                "description": "BetGames provides live casino games. Play a plethora of live casino games with one single registration. Faster withdrawal & 24x7 customer support.",
                "canonical" : "https://www.Baaziadda.com/online-casino-games/betgames",
                "alternate" : "https://m.Baaziadda.com/online-casino-games/betgames",
                "keywords" : "betgames"
            },
            "sportsbook": {
                "title": "Sportsbook Online | Sports Betting at BaaziAdda",
                "description": "Elevate your sports betting experience with Sportsbook Online at BaaziAdda! Enjoy a seamless and thrilling betting experience with the best odds.",
                "canonical" : "https://m.Baaziadda.com/sportsbook",
                "alternate" : "https://m.Baaziadda.com/sportsbook",
                "keywords" : "BTI Sports, BTI Sports Betting"
            },
            "appDownload": {
                "title": "BaaziAdda APK  App - Download & Win!",
                "description": "Check out the main reasons why you need to download the Baaziadda app on your device. Learn more about the benefits of our app, bonuses and other offers",
                "canonical" : "https://www.Baaziadda.com/app-download",
                "alternate" : "https://m.Baaziadda.com/app-download",
                "keywords" : "Baaziadda App Download, Baaziadda Android App"
            },
            "login": {
                "title": "Login BaaziAdda | Play Online Casino Games",
                "description": "Baaziadda Login now to play a wide range of exciting Online casino games and win big.don't miss out start playing today!",
                "canonical" : "https://www.Baaziadda.com/login",
                "alternate" : "https://m.Baaziadda.com/login",
                "keywords" : "Baaziadda Login"
            },
            "signup": {
                "title": "Sign Up Now - Baaziadda & explore Online Casino Games",
                "description": "Signup at the Baaziadda official website today for access to premium Sports & Casino Games",
                "canonical" : "https://baaziadda.com/signup",
                "alternate": "https://m.baaziadda.com/signup",
                "keywords" : "Baaziadda User Registration"
            },
            "instantGames": {
                "title": "Instant Games | Enjoy the Best Instant Casino Games Now!",
                "description": " Experience the thrill of instant games and win big in seconds! Play a wide variety of exciting casino games for instant entertainment & instant rewards. Join & start winning instantly!.",
                "canonical" : "https://www.Baaziadda.com/instant-games",
                "alternate": "https://m.Baaziadda.com/instant-games",
                "keywords" : "Instant games,casino games, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites "
            },
            "dailyDepositBonus": {
                "title": "Baaziadda Daily Deposit Bonus | Best Casino Daily Deposit Scheme",
                "description": "Baaziadda Daily Deposit Bonus brings in a plethora of casino cashback opportunities. Know the rules, process, T&C of Daily Deposit Bonus on Baaziadda.",
                "canonical" : "https://www.Baaziadda.com/daily-deposit-bonus",
                "alternate": "https://m.Baaziadda.com/daily-deposit-bonus",
                "keywords" : "daily deposit bonus, deposit bonus, bonus, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites "
            },
            "asiaCupBonanza": {
                "title": "Asia Cup Bonanza | Best Cricket Betting Odds",
                "description": "Online cricket betting on Baaziadda provides the best betting odds for cricket in India. Get live score, fancy bets & fast withdrawal. Bet on cricket now!",
                "keywords": "Asia Cup Bonanza, Online Cricket Betting, Cricket Betting, cricket betting odds, cricket betting site, best site to bet on cricket, best cricket betting site (s) in India, Baaziadda online cricket betting, Baaziadda cricket betting odds, Baaziadda cricket betting, cricket betting sites, online cricket satta bazar",
                "canonical" : "https://www.baaziadda.com/asia-cup-bonanza",
                "alternate": "https://m.baaziadda.com/asia-cup-bonanza"
            },
            "big-bash": {
                "title": "Play Casino Games | Real Money Gambling | Satta Online 24/7",
                "description": "Play the most popular online casino games at YOLO247 & earn a chance to win real money. Get unbeatable welcome bonus & offers on your favorite satta games in India",
                "canonical" : "https://www.baaziadda.com/promotions/big-bash",
                "alternate": "https://m.baaziadda.com/promotions/big-bash",
                "keywords" : "casino games, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
            },
        },
        "34":{
            "default": {
                "title": "Play Casino Games | Real Money Gambling | Satta Online 24/7",
                "description": "Play the most popular online casino games at Betdaily & earn a chance to win real money. Get unbeatable welcome bonus & offers on your favorite satta games in India",
                "canonical" : "https://www.betdaily.club/",
                "alternate" : "https://m.betdaily.club/",
                "keywords" : "casino games, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
            },
            "onlineCasinoGames": {
                "title": "Online Casino Games (Real Money) - Play with Any Currency",
                "description": "Experience the ultimate online casino games in India at Betdaily. Play online casino for real money and enjoy the thrill of gambling with exciting bonus offers.",
                "canonical": "https://www.betdaily.club/online-casino-games",
                "alternate": "https://m.betdaily.club/online-casino-games",
                "keywords": "online casino games,online casino real money,games casino,casino gaming,online casino,online casino india,casino games online,online casino for real money,live casino online,no deposit bonus casino,best online casino in india,online casino india real money,best online casinos in india,live casino india,casino with bonus,bet online casino,best casino apps in india,best casinos in india,best online casino,online betting games,casino games name,online casino games real money,casio india online,online casino app,online casino games india,online gambling games,casino for real money"
            },
            "blackjack": {
                "title": "Play Blackjack Online at Betdaily (Don't Get Bored Again)",
                "description": "Play blackjack online on the most safest online casino. Register now at Betdaily and get exclusive welcome bonus and offers to win your favorite games",
                "canonical": "https://www.betdaily.club/online-casino-games/blackjack",
                "alternate": "https://m.betdaily.club/online-casino-games/blackjack",
                "keywords": "blackjack online,blackjack game,playing blackjack,blackjack playing,play blackjack online,blackjack game rules,online blackjack real money,play blackjack,blackjack online game,online blackjack real money,black jack card games"
            },
            "roulette": {
                "title": "Play Roulette Game Online - Free Registration & Instant Payouts",
                "description": "Betdaily is the leading online casino to play roulette game in India. Play the best real money games here. Sign up now and win welcome bonus!",
                "canonical": "https://www.betdaily.club/online-casino-games/roulette",
                "alternate": "https://m.betdaily.club/online-casino-games/roulette",
                "keywords": "roulette game,roulette online,casino roulette,online roulette india,lightning roulette,online roulette game,roulette casino game,online roulette real money,play roulette online,casino roulette table"
            },
            "lightningroulette": {
                "title": "Play Lightning Roulette Online | Win Real Money in India",
                "description": "Spin the wheel of fortune with Lightning Roulette online at Betdaily! Win real money in India as you feel the electrifying thrill of every spin.",
                "canonical": "https://betdaily.club/online-casino-games/lightning-roulette",
                "alternate": "https://m.betdaily.club/online-casino-games/lightning-roulette",
                "keywords": "lightning roulette game,lightning roulette online,casino lightning roulette,online roulette india,lightning roulette,online roulette game,roulette casino game,online roulette real money,play roulette online,casino roulette table"
            },
            "dragonTiger": {
                "title": "Online Dragon Tiger Game - Get Instant Wins 💸 at Betdaily",
                "description": "Betdaily is the leading online casino to play roulette game in India. Play the best real money games here. Sign up now and win welcome bonus!",
                "canonical": "https://www.betdaily.club/online-casino-games/dragon-tiger",
                "alternate": "https://m.betdaily.club/online-casino-games/dragon-tiger",
                "keywords": "Betdaily is your best destination for playing Online Dragon Tiger Game. Sign up now and get welcome Bonus to test your luck with the most popular games."
            },
            "baccarat": {
                "title": "Play Online Baccarat Game - Get the Best Odds at Betdaily",
                "description": "Play baccarat online game with the latest baccarat rules and winnings to suit your taste. Crafted by experts, the Betdaily baccarat casino is just a click away. Play now!!",
                "canonical": "https://www.betdaily.club/online-casino-games/baccarat",
                "alternate": "https://m.betdaily.club/online-casino-games/baccarat",
                "keywords": "baccarat game,baccarat online,baccarat casino,baccarat gaming,baccarat rule,baccarat game online,baccarat game rules,play baccarat online,baccarat online play,baccarat online free,best baccarat online casino,best casino for baccarat"
            },
            "teenpattiOnlinePlay": {
                "title": "Teen Patti Online | Win Real Money in India - Betdaily",
                "description": "Embrace the spirit of Teen Patti online at Betdaily! Win real money in India as you play this beloved card game. Play Now!",
                "canonical": "https://www.betdaily.club/teenpatti-online-play",
                "keywords": "teen patti ,teen patti game, teen patti online, teen patti casino"
            },
            "sportsBetting": {
                "title": "Betting Exchange | Everything you need to Win at Betting",
                "description": "Find everything you need to elevate your betting game and maximize your wins. Take control of your bets and conquer the exchange!",
                "canonical": "https://www.betdaily.club/betting-exchange",
                "alternate": "https://m.betdaily.club/betting-exchange",
                "keywords": "sports betting,sports satta,sports betting app,sports betting in india,bet on sports online,bet on sports,sports book,sportsbetting,sports bet,sports betting sites,best sports betting apps,betting sites with free registration bonus,best sports betting sites,online cricket satta bazar,online cricket betting,cricket satta,cricket betting app,cricket online betting apps,cricket betting,cricket betting sites,best cricket betting app,cricket betting apps in india,best cricket betting apps in india,cricket betting sites,live cricket betting,cricket betting app download,indian cricket betting sites,best betting sites for cricket in india,cricket bet online india,best cricket betting sites,cricket betting website,cricket online betting sites,online betting apps cricket,cricket betting online sites in india,football betting,best football prediction sites,betting app for football,football prediction site,football bet app,online football betting,betting football site,football betting sites,bet online football,bet football online,best football betting sites,best betting football sites,tennis betting,today tennis match prediction,betting on tennis tips,predictions tennis,tennis predictions today,tennis betting online,tennis match prediction,tennis bet prediction,bet online tennis,online tennis betting sites"
            },
            "onlineCricketSattaBazar": {
                "title": "Online Cricket Betting in India | Best Odds at Betdaily",
                "description": "Elevate your game with online cricket betting at Betdaily! Enjoy the best odds and dive into the excitement of cricket. Play Now!",
                "keywords": "Online Cricket Betting, Cricket Betting, cricket betting odds, cricket betting site, best site to bet on cricket, best cricket betting site (s) in India, Betdaily online cricket betting, Betdaily cricket betting odds, Betdaily cricket betting, cricket betting sites, online cricket satta bazar",
                "canonical": "https://www.betdaily.club/betting-exchange/online-cricket-satta-bazar",
                "alternate": "https://m.betdaily.club/betting-exchange/online-cricket-satta-bazar"
            },
            "footballBetting": {
                "title": "Football Betting Online in India | Best Odds at Betdaily",
                "description": "Explore the best odds and immerse yourself in the thrill of the game. Bet on your favorite teams and players for a chance to win real money in India!",
                "canonical": "https://www.betdaily.club/betting-exchange/football-betting",
                "alternate": "https://m.betdaily.club/betting-exchange/football-betting",
                "keywords": "football prediction site,best football prediction sites,betting app for football,football betting,football bet app,online football betting,betting football site,football betting sites,bet online football,bet football online,best football betting sites,best betting football sites"
            },
            "tennisBetting": {
                "title": "Online Tennis Betting in India | Best Odds at Betdaily",
                "description": "Enjoy the best odds and experience the intensity of every match. Bet on your favorite players and win real money in India with every ace!",
                "canonical": "https://www.betdaily.club/betting-exchange/tennis-betting",
                "alternate": "https://m.betdaily.club/betting-exchange/tennis-betting",
                "keywords": "predictions tennis,tennis predictions today,today tennis match prediction,betting on tennis tips,tennis betting,tennis betting online,tennis match prediction,tennis bet prediction,bet online tennis,online tennis betting sites"
            },
            "volleyballBetting": {
                "title": "Volleyball Betting in India | Best Odds at Betdaily",
                "description": "Enjoy the best odds and experience the intensity of every match. Bet on your favorite players and win real money in India with every ace!",
                "canonical": "https://yolo247.club/betting-exchange/volleyball-betting",
                "alternate": "https://m.yolo247.club/betting-exchange/volleyball-betting",
                "keywords": "predictions volleyball,volleyball predictions today,today volleyball match prediction,betting on volleyball tips,volleyball betting,volleyball betting online,volleyball match prediction,volleyball bet prediction,bet online volleyball,online volleyball betting sites"
            },
            "promotions": {
                "title": "Play Casino Games | Real Money Gambling | Satta Online 24/7",
                "description": "Play the most popular online casino games at Betdaily & earn a chance to win real money. Get unbeatable welcome bonus & offers on your favorite satta games in India",
                "canonical" : "https://www.betdaily.club/promotions",
                "alternate" : "https://m.betdaily.club/promotions",
                "keywords" : "casino games, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
            },
            "popular games": {
                "title": "Best Casino Games Online | Get Instant Welcome Bonus & Offer",
                "description": "Explore the most popular and best casino casino online at Betdaily. Choose from thousands of classic casino casino, including online slots, blackjack and roulette.",
                "canonical" : "https://www.betdaily.club/online-casino-games/populargames",
                "alternate" : "https://m.betdaily.club/online-casino-games/populargames",
                "keywords" : "popular games in india, popular pc games, popular video games"
            },
            "slotGames": {
                "title": "Play Slot Games - Online Slots | Betdaily",
                "description": "Slot Games at the best! Play online slots with easy registration and easier withdrawal process. Get 100+ casino slot games to choose from.",
                "canonical" : "https://www.betdaily.club/online-casino-games/slotGames",
                "alternate" : "https://m.betdaily.club/online-casino-games/slotGames",
                "keywords" : "Slot games, Online slots, casino slot games"
            },
            "aviator": {
                "title": "Play Aviator Casino Game Online 🕹️ at Betdaily",
                "description": "Take off with excitement! Play the Aviator Casino Game online at Betdaily. Get ready for a gaming experience like no other, where big wins await.",
                "canonical" : "https://www.betdaily.club/instant-games/aviator",
                "alternate" : "https://m.betdaily.club/instant-games/aviator",
                "keywords" : "Aviator game, Aviator online game "
            },
            "cashshow": {
                "title": "Play Aviator Casino Game Online 🕹️ at Betdaily",
                "description": "Take off with excitement! Play the Aviator Casino Game online at Betdaily. Get ready for a gaming experience like no other, where big wins await.",
                "canonical" : "https://www.betdaily.club/instant-games/cashshow",
                "alternate": "https://m.betdaily.club/instant-games/cashshow",
                "keywords" : "Cash Show game, Cash Show online game "
            },
            "jetx": {
                "title": "JetX Online | Play for Real Money at Betdaily",
                "description": "Rev up the thrill with JetX online at Betdaily. Play for real money and experience the rush of high-speed gaming.",
                "canonical" : "https://www.betdaily.club/instant-games/jetx",
                "alternate": "https://m.betdaily.club/instant-games/jetx",
                "keywords" : "Jet X game, Jet X online game"
            },
            "rockpaperscissors": {
                "title": "Rockpaperscissors ID - Grab Your Exclusive Deals Now!",
                "description": "Explore the thrill of rockpaperscissors ID gaming. Join the fun with our interactive platform and challenge opponents worldwide",
                "canonical" : "https://betdaily.club/instant-games/ROCKPAPERSCISSORS",
                "alternate": "https://betdaily.club/instant-games/ROCKPAPERSCISSORS",
                "keywords" : "rockpaperscissors id, rockpaperscissors game, rockpaperscissors online"
            },
            "plinkox": {
                "title": "Plinkox Game | Live Casino Online Games on Betdaily",
                "description": "Online Casino games are revolutionizing the gaming world. Find out some of the popular live casino online games on Betdaily.",
                "canonical" : "https://www.betdaily.club/instant-games/plinkox",
                "alternate": "https://m.betdaily.club/instant-games/plinkox",
                "keywords" : "Plinkox game, Plinkox online game "
            },
            "footballx": {
                "title": "Football X Game | Live Casino Online Games on Betdaily",
                "description": "Online Casino games are revolutionizing the gaming world. Find out some of the popular live casino online games on Betdaily.",
                "canonical" : "https://www.betdaily.club/instant-games/footballx",
                "alternate": "https://m.betdaily.club/instant-games/footballx",
                "keywords" : "Football X game, Football X online game "
            },
            "balloon": {
                "title": "Balloon Game | Live Casino Online Games on Betdaily",
                "description": "Online Casino games are revolutionizing the gaming world. Find out some of the popular live casino online games on Betdaily.",
                "canonical" : "https://www.betdaily.club/instant-games/balloon",
                "alternate": "https://m.betdaily.club/instant-games/balloon",
                "keywords" : "Balloon game, Balloon online game "
            },
            "mines": {
                "title": "Mines Game | Live Casino Online Games on Betdaily",
                "description": "Online Casino games are revolutionizing the gaming world. Find out some of the popular live casino online games on Betdaily.",
                "canonical" : "https://www.betdaily.club/instant-games/mines",
                "alternate": "https://m.betdaily.club/instant-games/mines",
                "keywords" : "Mines game, Online mines game "
            },
            "ninjacrash": {
                "title": "Plan NinjaCrash Game Online & Win Real Money 2024",
                "description": "Gear up for the ultimate challenge in NinjaCrash! Plan your moves strategically, play online, and stand a chance to win real money in 2024.",
                "canonical" : "https://www.betdaily.club/instant-games/ninjacrash",
                "alternate": "https://m.betdaily.club/instant-games/ninja%20crash",
                "keywords" : "NinjaCrash game, Online NinjaCrash game"
            },
            "aviatrix": {
                "title": "Aviatrix Casino Game Online | Play Now & Win Big",
                "description": "Embark on a thrilling adventure with Aviatrix Casino Game online. Play now at Betdaily and seize the opportunity to win big.",
                "canonical" : "https://www.betdaily.club/instant-games/aviatrix",
                "alternate": "https://m.betdaily.club/instant-games/aviatrix",
                "keywords" : "aviatrix game, Online aviatrix game"
            },
            "macludo": {
                "title": "Ludo Game | Play Ludo Game Online for real cash",
                "description": "Ludo Game is lucrative. Register and get the best gaming experience. Play Ludo Game online for real cash and win. Get the profits directly. ",
                "canonical" : "https://www.betdaily.club/online-casino-games/macludo",
                "alternate" : "https://m.betdaily.club/online-casino-games/macludo",
                "keywords" : "Ludo Game, Ludo game Online"
            },
            "crazytime": {
                "title": "Play CrazyTime Online for Free & Real Money - Betdaily",
                "description": "Dive into the whirlwind of excitement with CrazyTime online at Betdaily! Brace yourself for a gaming experience like no other – it's time to unleash the craziness and win big!",
                "canonical" : "https://www.betdaily.club/online-casino-games/crazytime",
                "alternate" : "https://m.betdaily.club/online-casino-games/crazytime",
                "keywords" : "Crazytime Game, Crazytime game Online"
            },
            "monopolylive": {
                "title": "Monopoly Live, Win Real Money | Betdaily",
                "description": "Step into the world of Monopoly Live and win real money at Betdaily. Play now for a chance to build your empire and claim victory!",
                "canonical" : "https://betdaily.club/online-casino-games/MONOPOLYLIVE",
                "alternate": "https://m.betdaily.club/online-casino-games/MONOPOLYLIVE",
                "keywords" : "MONOPOLY LIVE"
            },
            "funkytime": {
                "title": "Funkytime: Play at Betdaily & Win Real Money Online",
                "description": "Play Funkytime and win real money online. Unleash your groove and enjoy a unique gaming experience. Let the funky wins roll in!",
                "canonical": "https://betdaily.club/online-casino-games/Funkytime",
                "alternate": "https://m.betdaily.club/online-casino-games/Funkytime",
                "keywords" : "casino games, Funkytime, Funky Time, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
            },
            "lightningdice": {
                "title": "Lightningdice | Play at Betdaily & Win Real Money Online",
                "description": "Feel the electrifying thrill of Lightningdice at Betdaily! Play and win real money online as you roll the lightning-infused dice.",
                "canonical" : "https://betdaily.club/online-casino-games/lightningdice",
                "alternate": "https://m.betdaily.club/online-casino-games/lightningdice",
                "keywords" : "Lightning Dice"
            },
            "cricketx": {
                "title": "Play CricketX Live Online & Win Real Money at Betdaily",
                "description": "Experience the excitement of CricketX Live at Betdaily! Play online, showcase your cricket skills, and win real money.",
                "canonical" : "https://betdaily.club/instant-games/cricketx",
                "alternate": "https://m.betdaily.club/instant-games/cricketx",
                "keywords" : "Cricketx Games,Betdaily Cricketx games,Betdaily Cricketx games,Cricketx casino games"
            },
            "virtualSports": {
                "title": "Online Betting for Sports and Casino at Betdaily",
                "description": "Experience the ultimate thrill with online betting for sports and casino at Betdaily! Enjoy the best of both worlds as you bet on your favorite sports and casino games.",
                "canonical" : "https://betdaily.club/virtualSports/vci",
                "alternate": "https://betdaily.club/virtualSports/vci",
            },
            "virtualSportsBL": {
                "title": "Virtual Sports on Betdaily",
                "description": "Virtual Sports on Betdaily",
                "canonical" : "https://betdaily.club/virtualSports/vbl",
                "alternate": "https://betdaily.club/virtualSports/vbl",
            },
            "virtualSportsFB": {
                "title": "Virtual Sports on Betdaily",
                "description": "Virtual Sports on Betdaily",
                "canonical" : "https://betdaily.club/virtualSports/vfb",
                "alternate": "https://betdaily.club/virtualSports/vfb",
            },
            "virtualSportsFEL": {
                "title": "Virtual Sports on Betdaily",
                "description": "Virtual Sports on Betdaily",
                "canonical" : "https://betdaily.club/virtualSports/vfel",
                "alternate": "https://betdaily.club/virtualSports/vfel",
            },
            "virtualSportsFEC": {
                "title": "Virtual Sports on Betdaily",
                "description": "Virtual Sports on Betdaily",
                "canonical" : "https://betdaily.club/virtualSports/vfec",
                "alternate": "https://m.betdaily.club/virtualSports/vfec",
            },
            "virtualSportsVTI": {
                "title": "Elevate Your Game with Virtual Tennis Betting ID",
                "description": "Discover the ultimate virtual tennis betting ID for an unparalleled gaming adventure. Predict, bet, and win big with our cutting-edge platform. Join now!",
                "canonical" : "https://betdaily.club/virtualSports/vti",
                "alternate": "https://betdaily.club/virtualSports/vti",
            },
            "virtualSportsWC": {
                "title": "Navigating Virtual World Cup Betting ID Like a Pro | Bet Smart,Win Big",
                "description": "Explore thrilling with virtual World Cup betting ID services for an immersive gaming experience like never before!",
                "canonical" : "https://betdaily.club/virtualSports/vfwc",
                "alternate": "https://betdaily.club/virtualSports/vfwc",
            },
            "premiumSportsbook": {
                "title": "Premium Sportsbook | Online Sports Betting at Betdaily",
                "description": "Go for gold with our Premium Sportsbook at Betdaily! Engage in online sports betting with the best odds and premium features.",
                "canonical" : "https://betdaily.club/premium-sportsbook",
                "alternate": "https://m.betdaily.club/premium-sportsbook",
                "keywords" : "Premium Sportsbook Betting ,Best Online Premium Sportsbook Betting, Sports Betting in India"
            },
            "welcomeBonus": {
                "title": "400% Welcome Bonus to get you started (2024)💰",
                "description": "Claim your fortune with a whopping 400% Welcome Bonus in 2024 at Betdaily! 🎉 Your journey to big wins starts with an unbeatable bonus! Sign up now!",
                "canonical" : "https://www.betdaily.club/promotions/welcome-bonus",
                "alternate" : "https://m.betdaily.club/promotions/welcome-bonus",
                "keywords" : "Welcome Bonus"
            },
            "refillBonus": {
                "title": "Get the Betdaily Refill Bonus 💰 and Win Big!",
                "description": "Refill your luck and Win Big with Betdaily's Refill Bonus! 💰 Enjoy extra playing power. It's the perfect boost to increase your chances of winning!",
                "canonical" : "https://www.betdaily.club/promotions/refill-bonus",
                "alternate" : "https://m.betdaily.club/promotions/refill-bonus",
                "keywords" : "Refill bonus"
            },
            "cashbackBonus": {
                "title": "Cashback Bonus 💰 at Betdaily 2024",
                "description": "Enjoy the thrill of gaming with Cashback Bonus at Betdaily in 2024! 💰 It's your chance to turn every bet into a win!",
                "canonical" : "https://www.betdaily.club/promotions/cashback-bonus",
                "alternate" : "https://m.betdaily.club/promotions/cashback-bonus",
                "keywords" : "Cashback Bonus"
            },
            "referralBonus": {
                "title": "Referral Bonus 💰 at Betdaily 2024",
                "description": "Share the gaming joy with your friends, and when they join, you both reap the rewards 💰 It's a win-win – start referring now!",
                "canonical" : "https://betdaily.club/promotions/referral-bonus",
                "alternate": "https://m.betdaily.club/promotions/referral-bonus",
                "keywords" : "Referral Bonus"
            },
            "termsandconditions": {
                "title": "Terms & Conditions | Betdaily Online Casino",
                "description": "Read the Terms & Conditions at Betdaily Online Casino to ensure a smooth and fair gaming experience. Stay informed, play responsibly, and maximize your chances of winning!",
                "canonical" : "https://www.betdaily.club/about/termsandconditions",
                "alternate" : "https://m.betdaily.club/about/termsandconditions",
                "keywords" : "Terms & Conditions"
            },
            "responsibleGambling": {
                "title": "Responsible Gambling | Betdaily Online Casino",
                "description": "Dive into our Responsible Gambling policies to ensure a safe and enjoyable gaming environment. Your well-being is our top priority!",
                "canonical" : "https://www.betdaily.club/about/responsible-gambling",
                "alternate" : "https://m.betdaily.club/about/responsible-gambling",
                "keywords" : "Responsible Gambling"
            },
            "privacyPolicy": {
                "title": "Privacy Policy | Betdaily Online Casino",
                "description": "Your privacy matters! Discover how we safeguard your information with the Privacy Policy. Play with confidence, knowing that your data is secure and protected.",
                "canonical" : "https://www.betdaily.club/about/privacy-policy",
                "alternate" : "https://m.betdaily.club/about/privacy-policy",
                "keywords" : "privacy policy"
            },
            "kycPolicy": {
                "title": "KYC Policy | Betdaily Online Casino",
                "description": "Learn about our Know Your Customer policy at Betdaily. Ensuring a secure gaming space, we prioritize transparency and accountability to enhance your gaming.",
                "canonical" : "https://www.betdaily.club/about/kyc-policy",
                "alternate" : "https://m.betdaily.club/about/kyc-policy",
                "keywords" : "KYC Policy"
            },
            "exclusionPolicy": {
                "title": "Exclusion Policy | Betdaily Online Casino",
                "description": "Play responsibly with our Exclusion Policy. Learn about the tools we provide to promote responsible gaming and help you stay in control of your play.",
                "canonical" : "https://www.betdaily.club/about/self-exclusion-policy",
                "alternate" : "https://m.betdaily.club/about/self-exclusion-policy",
                "keywords" : "exclusion policy "
            },
            "amlPolicy": {
                "title": "AML Policy | Betdaily Online Casino",
                "description": "Explore our Anti-Money Laundering (AML) Policy. We are committed to maintaining the integrity of our platform for a fair and secure gaming experience.",
                "canonical" : "https://www.betdaily.club/about/aml-policy",
                "alternate" : "https://m.betdaily.club/about/aml-policy",
                "keywords" : "AML Policy"
            },
            "aboutus": {
                "title": "About Us | Betdaily Online Casino",
                "description": "Get to know us better! Explore the story behind Betdaily. Discover our mission, values, and commitment to providing an exceptional gaming experience.",
                "canonical" : "https://www.betdaily.club/about/aboutus",
                "alternate" : "https://m.betdaily.club/about/aboutus",
                "keywords" : "About us, About Betdaily"
            },
            "faq": {
                "title": "Frequently Asked Questions | Betdaily Online Casino",
                "description": "Have questions? Find answers in our Frequently Asked Questions section at Betdaily Online Casino.",
                "canonical" : "https://betdaily.club/faq",
                "alternate": "https://betdaily.club/faq",
            },
            "affiliate": {
                "title": "Affiliate | Betdaily Online Casino",
                "description": "Join the Betdaily Affiliate program and turn your passion for gaming into rewards. Start earning commissions and enjoy the benefits of our rewarding partnership!",
                "canonical" : "https://betdaily.club/affiliate",
                "alternate": "https://m.betdaily.club/affiliate",
            },
            "bingo": {
                "title": "Play Bingo Online - endless fun & real money at Betdaily",
                "description": "Experience the thrill of playing bingo online at our top-rated gaming platform. Enjoy a wide variety of exciting bingo games & wing big",
                "canonical": "https://betdaily.club/online-casino-games/bingo",
                "alternate": "https://m.betdaily.club/online-casino-games/bingo",
                "keywords" : "casino games, bingo, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
            },
            "SPRIBE": {
                "title": "Spribe Casino Games | Get the best Spribe Games on Betdaily",
                "description": "Spribe Casino Games are popular and prevalent. We picked the best games for you. Play with just a single sign-in option and explore the gaming options",
                "canonical" : "https://www.betdaily.club/instant-games/SPRIBE",
                "alternate" : "https://m.betdaily.club/instant-games/SPRIBE",
                "keywords" : "spribe casino games"
            },
            "7MOJOS": {
                "title": "7Mojos Games | Explore the top games at the best platform",
                "description": "7Mojos Games in one place! One registration, multiple gaming options and better gaming experience. Explore the demo versions as well. Register now. ",
                "canonical" : "https://www.betdaily.club/online-casino-games/7MOJOS",
                "alternate" : "https://m.betdaily.club/online-casino-games/7MOJOS",
                "keywords" : "7Mojos Games"
            },
            "supernowa": {
                "title": "Supernowa Games | Live Casino Games from Betdaily",
                "description": "Supernowa games at the best! Play live casino games, online card games and slots on the safest gaming platform. Click to explore.",
                "canonical" : "https://www.betdaily.club/online-casino-games/supernowa",
                "alternate" : "https://m.betdaily.club/online-casino-games/supernowa",
                "keywords" : "supernowa games"
            },
            "pragmatic": {
                "title": "Pragmatic Casino Games online| Play on Betdaily",
                "description": "Pragmatic Casino Games are available now! Providing the ultimate gaming experience with a wide range of Pragmatic games online. Explore now!",
                "canonical" : "https://www.betdaily.club/online-casino-games/slotGames/pragmaticplay",
                "alternate" : "https://m.betdaily.club/online-casino-games/slotGames/pragmaticplay",
                "keywords" : "Pragmatic casino, pragmatic games"
            },
            "superspade": {
                "title": "Super Spade Games | Online Casino on Betdaily",
                "description": "Super Spade Games are online casino's delight. Play the best games with just one sign-in. Simple process, simpler registration. Join now!",
                "canonical" : "https://www.betdaily.club/online-casino-games/superspade",
                "alternate" : "https://m.betdaily.club/online-casino-games/superspade",
                "keywords" : "super spade games"
            },
            "tvbet": {
                "title": "TVBet Games | Live Betting & Gaming on Betdaily",
                "description": "TV Bet Games offers a wide variety of online gaming options. Explore all the top-rated games in one platform through one sign-in. Join now!",
                "canonical" : "https://www.betdaily.club/online-casino-games/tvbet",
                "alternate" : "https://m.betdaily.club/online-casino-games/tvbet",
                "keywords" : "tvbet games"
            },
            "netent": {
                "title": "NetEnt Games | Slots & Casino Games on Betdaily",
                "description": "NetEnt Games provide the widest range of online slots and casino slot games. Through one registration, explore all the top slot games online.",
                "canonical" : "https://www.betdaily.club/online-casino-games/slotGames/netent",
                "alternate" : "https://m.betdaily.club/online-casino-games/slotGames/netent",
                "keywords" : "netent games"
            },
            "betgames": {
                "title": "BetGames | Live Dealer of Online Games on Betdaily",
                "description": "BetGames provides live casino games. Play a plethora of live casino games with one single registration. Faster withdrawal & 24x7 customer support.",
                "canonical" : "https://www.betdaily.club/online-casino-games/betgames",
                "alternate" : "https://m.betdaily.club/online-casino-games/betgames",
                "keywords" : "betgames"
            },
            "sportsbook": {
                "title": "Sportsbook Online | Sports Betting at Betdaily",
                "description": "Elevate your sports betting experience with Sportsbook Online at Betdaily! Enjoy a seamless and thrilling betting experience with the best odds.",
                "canonical" : "https://www.betdaily.club/sportsbook",
                "alternate" : "https://m.betdaily.club/sportsbook",
                "keywords" : "BTI Sports, BTI Sports Betting"
            },
            "appDownload": {
                "title": "Betdaily APK  App - Download & Win!",
                "description": "Check out the main reasons why you need to download the Betdaily app on your device. Learn more about the benefits of our app, bonuses and other offers",
                "canonical" : "https://www.betdaily.club/app-download",
                "alternate" : "https://m.betdaily.club/app-download",
                "keywords" : "Betdaily App Download, Betdaily Android App"
            },
            "login": {
                "title": "Login Betdaily | Play Online Casino Games",
                "description": "Betdaily Login now to play a wide range of exciting Online casino games and win big.don't miss out start playing today!",
                "canonical" : "https://www.betdaily.club/login",
                "alternate" : "https://m.betdaily.club/login",
                "keywords" : "Betdaily Login"
            },
            "signup": {
                "title": "Sign Up Now - Betdaily & explore Online Casino Games",
                "description": "Signup at the Betdaily official website today for access to premium Sports & Casino Games",
                "canonical" : "https://betdaily.club/signup",
                "alternate": "https://m.betdaily.club/signup",
                "keywords" : "Betdaily User Registration"
            },
            "instantGames": {
                "title": "Instant Games | Enjoy the Best Instant Casino Games Now!",
                "description": " Experience the thrill of instant games and win big in seconds! Play a wide variety of exciting casino games for instant entertainment & instant rewards. Join & start winning instantly!.",
                "canonical" : "https://www.betdaily.club/instant-games",
                "alternate": "https://m.betdaily.club/instant-games",
                "keywords" : "Instant games,casino games, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites "
            },
            "dailyDepositBonus": {
                "title": "Betdaily Daily Deposit Bonus | Best Casino Daily Deposit Scheme",
                "description": "Betdaily Daily Deposit Bonus brings in a plethora of casino cashback opportunities. Know the rules, process, T&C of Daily Deposit Bonus on Betdaily.",
                "canonical" : "https://www.betdaily.club/daily-deposit-bonus",
                "alternate": "https://m.betdaily.club/daily-deposit-bonus",
                "keywords" : "daily deposit bonus, deposit bonus, bonus, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites "
            },
            "asiaCupBonanza": {
                "title": "Asia Cup Bonanza | Best Cricket Betting Odds",
                "description": "Online cricket betting on Betdaily provides the best betting odds for cricket in India. Get live score, fancy bets & fast withdrawal. Bet on cricket now!",
                "keywords": "Asia Cup Bonanza, Online Cricket Betting, Cricket Betting, cricket betting odds, cricket betting site, best site to bet on cricket, best cricket betting site (s) in India, Betdaily online cricket betting, Betdaily cricket betting odds, Betdaily cricket betting, cricket betting sites, online cricket satta bazar",
                "canonical" : "https://www.betdaily.club/asia-cup-bonanza",
                "alternate": "https://m.betdaily.club/asia-cup-bonanza"
            },
            "big-bash": {
                "title": "Play Casino Games | Real Money Gambling | Satta Online 24/7",
                "description": "Play the most popular online casino games at YOLO247 & earn a chance to win real money. Get unbeatable welcome bonus & offers on your favorite satta games in India",
                "canonical" : "https://www.betdaily.club/promotions/big-bash",
                "alternate": "https://m.betdaily.club/promotions/big-bash",
                "keywords" : "casino games, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
            },
        }
    }

export default seoData;