import { Link } from 'react-router-dom';
import styled, { withTheme } from 'styled-components'
import ArrowRight from "../../../images/bonus-arrow-right.svg";
import config from 'src/config/config';
import { theme } from 'styled-tools';
import { withAppContext } from 'src/store/initAppContext';
import { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import PlaceholderImage from 'src/components/Placeholder';




interface BonusProps {
    bonusImage: any,
    bonusTitle: any,
    bonusUUID: any,   
    imageId: number,
    theme: any
}

const BonusContainer = (props: BonusProps) => {
    const [imageName, setImageName] = useState()
    const { theme } = props
     
    useEffect(() => {
        switch(props.imageId) {
            case 1 : 
            setImageName(theme.image.FirstTimeDeposit)
            break;
            case 2 :
            setImageName(theme.image.ReDeposit)
            break;
            case 3 :
            setImageName(theme.image.RebatBonus)
            break;
            default:     
        }
    },[])
        
    return (
        <>
            <BonusConatinerWrap>
                <Link to={props.bonusUUID}>
                    <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${imageName}`} alt="Bonus Images"  effect="none" height="100%" width="100%" visibleByDefault={false} placeholder={<PlaceholderImage/>}/>
                </Link>
                <Link to={props.bonusUUID}>
                    <BonusContainerTitle>
                        <BonusArrow>
                            <img src={ArrowRight} alt="ArrowRight" />
                        </BonusArrow>
                        <p>{props.bonusTitle}</p>
                    </BonusContainerTitle>
                </Link>
            </BonusConatinerWrap>
        </>
    )
}

export default withTheme(withAppContext(BonusContainer));

export const BonusConatinerWrap = styled.div`
    position: relative;
    /* height: 60%; */
    height: 540px;
    width: 32%; 
    border-radius: 3px;
    margin-bottom: 10px;
    margin-right: 1%;


    @media only screen and (min-width: 1700px){
        height: 700px;
    }
`
export const BonusContainerImage: any = styled.img`
    width: 100%;
    object-fit: cover;
    height: 100%;
    object-position: center top;

    @media (min-width: 1500px){
        object-fit: cover;
    }
`
const BonusContainerTitle = styled.div`
    position: absolute;
    bottom:0px;
    background: ${theme('palette.BonusContainerBgColor')};
    height: 140px;
    padding: 20px;
    -webkit-clip-path: polygon(20% 0%, 80% 0%, 100% 100%, 0% 100%);
    clip-path: polygon(0% 40%,100% 0%,100% 100%,0% 100%);
    display: flex;
    align-items: flex-end;
    width: 100%;

    :hover {
        background: ${theme('palette.BonusContainerBgColorHover')};
    }

    & p {
        font-style: normal;
        font-weight: 800;
        font-size: 23px;
        letter-spacing: 0.01em;
        text-transform: capitalize;
        color: #FFFFFF;
        bottom: 0px;
        margin: 0px 0px 10px;

        @media (max-width: 1400px) {
            font-weight: 700;
            font-size: 23px;
        }
        @media (max-width: 1280px) {
            font-weight: 700;
            font-size: 13px;
        }
    }
`

const BonusArrow = styled.div`
    & img {
        position: absolute;
        top: 20px;
        right: 5px;
    }
`

