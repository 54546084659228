import React from 'react'

type Props = {}

const Step7Icon = (props: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48"><circle cx="24" cy="24" r="24" fill="url(#a)"/><g><path fill="#fff" d="M33.674 30.199a2.562 2.562 0 0 1-3.624 3.623l-4.971-4.973a4.797 4.797 0 0 1-4.446-1.286c-1.39-1.39-1.686-3.354-1.151-4.934.048-.139.255-.15.329-.076l2.876 2.876a.998.998 0 0 0 1.41 0l1.189-1.19a1.002 1.002 0 0 0 .001-1.41l-2.876-2.873c-.073-.074-.063-.282.076-.329a4.795 4.795 0 0 1 6.214 5.598l4.972 4.973.001.001Zm2.769-8.26-2.344-.366a10.318 10.318 0 0 0-1.234-2.985l1.453-1.993a.68.68 0 0 0-.066-.854l-1.994-1.994a.682.682 0 0 0-.854-.066l-1.993 1.454a10.317 10.317 0 0 0-2.985-1.234l-.366-2.344a.684.684 0 0 0-.65-.557h-2.82c-.31 0-.603.25-.652.557l-.366 2.344a10.329 10.329 0 0 0-2.985 1.234l-1.992-1.454a.682.682 0 0 0-.854.066l-1.994 1.994a.681.681 0 0 0-.067.854l1.455 1.993a10.306 10.306 0 0 0-1.235 2.985l-2.343.366a.683.683 0 0 0-.558.651v2.82c0 .31.251.603.558.65l2.343.367a10.344 10.344 0 0 0 1.235 2.985l-1.455 1.992a.681.681 0 0 0 .067.855l1.994 1.993a.682.682 0 0 0 .854.066l1.992-1.454c.912.558 1.916.978 2.985 1.234l.366 2.344c.048.306.34.557.651.557h2.82c.31 0 .602-.25.65-.557l.367-2.344c.365-.087.723-.194 1.072-.32l-2.244-2.243a7.679 7.679 0 0 1-1.255.111A7.647 7.647 0 1 1 24 16.353a7.647 7.647 0 0 1 7.495 9.158l2.204 2.204c.16-.417.293-.847.399-1.287l2.344-.367a.682.682 0 0 0 .557-.65v-2.82a.68.68 0 0 0-.557-.65v-.001Z"/></g><defs><linearGradient id="a" x1="24" x2="24" y1="0" y2="48" gradientUnits="userSpaceOnUse"><stop stop-color="#6765E0"/><stop offset="1" stop-color="#322DB8"/></linearGradient><clipPath id="b"><path fill="#fff" d="M11 11h26v26H11z"/></clipPath></defs></svg>
  )
}

export default Step7Icon;