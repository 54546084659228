import React, { useEffect, useState } from 'react'
// import Breadcrumb from 'src/components/udb/Breadcrumb'
// import UDBHeader from 'src/components/UDBHeader';
import config from 'src/config/config';
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';
import { theme } from 'styled-tools';
import Intl from 'src/components/common/Intl';
import AndroidIcon from 'src/components/icon/AndroidIcon';
import AppDwnldAnimationSvg from 'src/components/icon/AppDwnldAnimation1';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import PlaceholderImage from 'src/components/Placeholder';
import AppBenefits from 'src/components/appBenefits';
import DownldAndInstallStepsContent from 'src/components/appDownldAndInstallSteps';
import Footer from 'src/components/footer/footer';

//Images
import Step1Icon from '../../components/icon/Step1Icon';
import Step2Icon from '../../components/icon/Step2Icon';
import Step3Icon from '../../components/icon/Step3Icon';
import Step4Icon from '../../components/icon/Step4Icon';
import Step5Icon from '../../components/icon/Step5Icon';
import Step6Icon from '../../components/icon/Step6Icon';
import Step7Icon from '../../components/icon/Step7Icon';
import BetdailyAppStepOneIcon from "../../components/icon/BetdailyAppStepOneIcon";
import BetdailyAppStepTwoIcon from "../../components/icon/BetdailyAppStepTwoIcon";
import BetdailyAppStepThreeIcon from "../../components/icon/BetdailyAppStepThreeIcon";
import BetdailyAppStepFourIcon from "../../components/icon/BetdailyAppStepFourIcon";
import BetdailyAppStepFiveIcon from "../../components/icon/BetdailyAppStepFiveIcon";
import BetdailyAppStepSixIcon from "../../components/icon/BetdailyAppStepSixIcon";
import BetdailyAppStepSevenIcon from "../../components/icon/BetdailyAppStepSevenIcon";
import BetdailyAppDownloadBg from 'src/components/icon/BetdailyAppDownloadBg';
import SEOContents from 'src/components/SEOContents';
import {SRTM} from 'src/util/sportradarEvent';
import BazziAddaAppDownloadBg from 'src/components/icon/BazziAddaAppDownloadBg';

function AppDownloadPage(props) {
    const { app: {
        brandId
      }, theme } = props;

      const [apkUrl, setApkUrl] = useState('')

      const downloadAndInsatallData =  [
        {
            id: 1,
            imgsrc: theme.image.StepOneMobImgOne,
            icon: theme.image.BetdailyAppDownloadStepOne,
            title: <Intl langKey="YoloAppDwnld@ClickOnMenuOptions"/>,
            desc: <Intl langKey="YoloAppDwnld@ClickTheMenu"/>
        },
        {
            id: 2,
            imgsrc: theme.image.StepTwoMobImg,
            icon: theme.image.BetdailyAppDownloadStepTwo,
            title: <Intl langKey="YoloAppDwnld@DownloadTheAppFile"/>,
            desc: <Intl langKey="YoloAppDwnld@ClickTheDownldAndroid"/>
        },
        {
            id: 3,
            imgsrc: theme.image.StepThreeMobImg,
            icon: theme.image.BetdailyAppDownloadStepThree,
            title: <Intl langKey="YoloAppDwnld@WaitForTheFile"/>,
            desc: <Intl langKey="YoloAppDwnld@OnceTheProcessIs"/>
        },
        {
            id: 4,
            imgsrc: theme.image.StepFourthMobImg,
            icon: theme.image.BetdailyAppDownloadStepFour,
            title: <Intl langKey="YoloAppDwnld@InstallTheYoloApp"/>,
            desc: <Intl langKey="YoloAppDwnld@WaitForTheInsatalationprocess"/>
        }
    ]

     const appBenefitData = [
        {
            id: 1,
            icon: brandId === 31 || brandId === 32 ? <Step1Icon/> : <BetdailyAppStepOneIcon />,
            titleNumber: "01",
            desc: <Intl langKey="YoloAppDwnld@BenefitsStep1"/>,
        },
        {
          id: 2,
          icon: brandId === 31 || brandId === 32 ? <Step2Icon/> : <BetdailyAppStepTwoIcon />,
          titleNumber: "02",
          desc: <Intl langKey="YoloAppDwnld@BenefitsStep2"/>,
        },
        {
          id: 3,
          icon: brandId === 31 || brandId === 32 ? <Step3Icon/> : <BetdailyAppStepThreeIcon />,
          titleNumber: "03",
          desc: <Intl langKey="YoloAppDwnld@BenefitsStep3"/>,
        },
        {
          id: 4,
          icon: brandId === 31 || brandId === 32 ? <Step4Icon/> : <BetdailyAppStepFourIcon />,
          titleNumber: "04",
          desc: <Intl langKey="YoloAppDwnld@BenefitsStep4"/>,
        },
        {
          id: 5,
          icon: brandId === 31 || brandId === 32 ? <Step5Icon/> : <BetdailyAppStepFiveIcon />,
          titleNumber: "05",
          desc: <Intl langKey="YoloAppDwnld@BenefitsStep5"/>,
        },
        {
          id: 6,
          icon: brandId === 31 || brandId === 32 ? <Step6Icon/> : <BetdailyAppStepSixIcon />,
          titleNumber: "06",
          desc: <Intl langKey="YoloAppDwnld@BenefitsStep6"/>,
        },
        {
          id: 7,
          icon: brandId === 31 || brandId === 32 ? <Step7Icon/> : <BetdailyAppStepSevenIcon />,
          titleNumber: "07",
          desc: <Intl langKey="YoloAppDwnld@BenefitsStep7"/>,
        }
    ]

    useEffect(() => {
        SRTM.init(brandId)
    }, [])


    useEffect(() => {
        switch (brandId) {
          case 31:
            setApkUrl("https://app-yolo-prod.s3.eu-west-1.amazonaws.com/yolo247.apk");
            break;
          case 32:
            setApkUrl("https://app-yolo-club.s3.eu-west-1.amazonaws.com/yolo-club.apk");
            break;
          case 33:
            setApkUrl("https://app-baaziadda-com.s3.eu-west-1.amazonaws.com/baaziadda-com.apk");
            break;
          case 34:
            setApkUrl("https://app-betdaily-club.s3.eu-west-1.amazonaws.com/betdaily-club.apk");
            break;
          default:
            setApkUrl("https://app-yolo-prod.s3.eu-west-1.amazonaws.com/yolo247.apk");
        }
      },[brandId])

      const brandApp = (brandId === 31 || brandId === 32) ? 'yolo app' : (brandId === 33) ? 'baazi adda app' : 'betdaily app';

    return (
        <>
            <MainWrapper>
                <SEOContents pageName="appDownload" />
                <BannerSection>
                    <BanerLeftContent>
                    {brandId === 31 || brandId === 32 ? <Intl langKey="YoloAppDwnld@BrandName"/> : "" }<span className='customColor'><Intl langKey="YoloAppDwnld@BrandNameLast"/></span> <Intl langKey="YoloAppDwnld@App"/><br></br><Intl langKey="YoloAppDwnld@DownloadApp"/>
                    </BanerLeftContent>
                    <AnimateImg className={brandId === 31 || brandId === 32 ? "AnimateYolo" : "AnimateBD"}>
                        <AnimateImgSecond>
                            <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.AnimationImgSecond}`} effect="none" placeholder={<PlaceholderImage/>} alt={brandApp}/>
                        </AnimateImgSecond>
                        {brandId === 31 || brandId === 32 ?  <AppDwnldAnimationSvg/> : brandId === 33 ?  <BazziAddaAppDownloadBg/> : <BetdailyAppDownloadBg /> }
                        <AnimateImgThired>
                            <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.AnimationImgThired}`} effect="none" placeholder={<PlaceholderImage/>} alt={brandApp}/>
                        </AnimateImgThired>
                    </AnimateImg>
                    <BanerRightContent>
                        <FlexWrap><Intl langKey="YoloAppDwnld@for"/><span className='customColor'><Intl langKey="YoloAppDwnld@Android"/></span></FlexWrap>
                        <SmallText><Intl langKey="YoloAppDwnld@TheYoloAppIsAvailable"/></SmallText>
                     
                            <DownloadBtn href={apkUrl}>
                                <AndroidIcon/>
                                <Intl langKey="YoloAppDwnld@Download"/>
                            </DownloadBtn>
                        
                    </BanerRightContent>
                </BannerSection>

                <ScreenShotSection>
                    <TextCenterWrap>
                        <FlexWrap><Intl langKey="YoloAppDwnld@App"/><span className='customColor'><Intl langKey="YoloAppDwnld@ScreenShot"/> </span></FlexWrap>
                        <SmallText><Intl langKey="YoloAppDwnld@TakeALookAtTheScreenshot"/></SmallText>
                    </TextCenterWrap>
                    <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.AppScreenshotsImg}`} effect="none" placeholder={<PlaceholderImage/>} alt={brandApp}/>
                </ScreenShotSection>

                <ScanTheQRSection>
                    <ScanTheQRWrapper>
                        <BlockContent>
                            <span className='customColor'><Intl langKey="YoloAppDwnld@ScanTheQr"/></span>
                            <p><Intl langKey="YoloAppDwnld@AndDownloadTheApp"/></p>
                                <DownloadBtn href={apkUrl}>
                                    <AndroidIcon/>
                                    <Intl langKey="YoloAppDwnld@Download"/>
                                </DownloadBtn> 
                        </BlockContent>
                        <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.AppQrCode}`} effect="none" placeholder={<PlaceholderImage/>} alt={brandApp}/>
                    </ScanTheQRWrapper>
                </ScanTheQRSection>

                <AppBenefitsWrapper>
                    <TextLeftWrap>
                        <Intl langKey="YoloAppDwnld@AppBenefitsFor"/>
                        <span className='customColor'><Intl langKey="YoloAppDwnld@MobileUsers"/></span>
                        <SmallText><Intl langKey="YoloAppDwnld@InOrderForYouBet"/></SmallText>
                    </TextLeftWrap>
                    <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.MobileUserImg}`} effect="none" placeholder={<PlaceholderImage/>}/>
                </AppBenefitsWrapper>

                <AppBenefitsSteps>
                    {appBenefitData.map((appbenefit) => (
                        <AppBenefits 
                            key={appbenefit.id} 
                            id={appbenefit.id} 
                            icon={appbenefit.icon} 
                            titleNumber={appbenefit.titleNumber} 
                            desc={appbenefit.desc}  
                        />
                    ))}
                </AppBenefitsSteps>

                <DownAndInstall>
                    <TextCenterWrap>
                        <FlexWrap className='twoRow'>
                            <h2><Intl langKey="YoloAppDwnld@HowTo"/><Intl langKey="YoloAppDwnld@DownloadInHowTo"/><span className='customColor'> {brandApp}</span></h2>
                            <h2><span className='customColor'><Intl langKey="YoloAppDwnld@AndInstall"/></span></h2>
                        </FlexWrap>
                        <SmallText className='textAlignCenter'><Intl langKey="YoloAppDwnld@TechnicallyTheYolo247Apk"/></SmallText>
                    </TextCenterWrap>
                </DownAndInstall>

                <DownldAndInstallStepsWrapper>
                    {downloadAndInsatallData.map((dwnldInstall) => (
                        <DownldAndInstallStepsContent
                            key={dwnldInstall.id}
                            id={dwnldInstall.id}
                            imgsrc={dwnldInstall.imgsrc}
                            icon={dwnldInstall.icon}
                            title={dwnldInstall.title}
                            desc={dwnldInstall.desc} 
                            apkUrl={apkUrl}
                        />
                    ))}
                </DownldAndInstallStepsWrapper>
            </MainWrapper>
            <Footer/>
        </>
    )
}

export default withTheme(withAppContext(AppDownloadPage))

//Styled Components
const DownldAndInstallStepsWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8rem;
    padding-bottom: 100px;
`;

const DownAndInstall = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    padding: 4rem 0;
`;

const AppBenefitsSteps = styled.div`
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 3rem 2rem;
    padding: 4rem 0;
`;

const AppBenefitsWrapper = styled.div`
    width: 80%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;

    img {
        /* max-width: 100%; */
        animation: myAnim 3s ease 0s infinite alternate forwards;
        width: 100%;

        @keyframes myAnim {
            0% {
                transform: scale(0.7);
            }

            100% {
                transform: scale(0.9);
            }
        }
    }
`;

const BlockContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .customColor {
        font-size: 20px;
        font-weight: 700;
    }

    p {
        width: 190px;
        margin: 20px 0;
        margin-top: 5px;
        font-size: 18px;
        color: ${theme('palette.appDownloadPageSmallText')};
    }
`;

const ScanTheQRWrapper = styled.div`
    display: flex;
    align-items: center;
    background: ${theme('palette.appDownloadPageScanTheQRWrapperBg')};
    border: ${theme('palette.appDownloadPageScanTheQRWrapperBorder')};
    border-radius: 8px;
    padding: 3rem;
    gap: 2rem;

    img {
        width: 220px;
    }
`;

const ScanTheQRSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 4rem;
`;

export const TextLeftWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 5px;
    font-size: 40px;
    font-family: 'Inter-Bold';
    color: ${theme('palette.appDownloadPageSmallText')};
`;

const TextCenterWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-family: 'Inter-Bold';
`;

const ScreenShotSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 4rem 0;

    img {
        width: 80%;
        margin: 0 auto;
        display: block;
    }
`;

const AnimateImgThired = styled.div`
    width: 300px;
    position: absolute;
    right: 3rem;
    bottom: 6rem;
    animation: myAnim 3s ease 0s infinite alternate forwards;

        @keyframes myAnim {
            0% {
                transform: scale(0.7);
            }

            100% {
                transform: scale(0.9);
            }
        }

    img {
        max-width: 100%;
    }
`;

export const AnimateImgSecond = styled.div`
    width: 345px;
    position: absolute;
    left: 7rem;
    top: 2rem;
    z-index: 1;
    animation: myAnim 3s ease 0s infinite alternate forwards;

    @keyframes myAnim {
        0% {
            transform: scale(0.7);
        }

        100% {
            transform: scale(0.9);
        }
    }

    img {
        max-width: 100%;
    }
`;

export const DownloadBtn = styled.a`
    width: 168px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-weight: 500;
    font-size: 14px;
    border-radius: 30px;
    padding: 8px 5px;
    color: ${theme('palette.appDownloadPageDownloadBtnColor')};
    background: ${theme('palette.appDownloadPageDownloadBtnBg')};
    margin: 0;
    border: none;
    box-shadow: none;
    cursor: pointer;

    :hover {
        background: ${theme('palette.appDownloadPageCustomColor')};
        color: ${theme('palette.appDownloadPageDownloadBtnColorHover')};

       svg {
        width: 20px;
            path {
               fill: ${theme('palette.appDownloadPageDownloadBtnColorHover')};
            } 
        }
    }

    svg {
        width: 20px;
    }
`;

const AnimateImg = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    svg {
        width: 700px;
        animation: myAnim 3s ease 0s infinite alternate forwards;

        @keyframes myAnim {
            0% {
                transform: scale(0.7);
            }

            100% {
                transform: scale(0.9);
            }
        }
    }
    
`;

export const SmallText = styled.div`
    font-weight: 500;
    font-family: 'Inter';
    font-size: 15px;
    color: ${theme('palette.appDownloadPageSmallText')};
    text-align: justify !important;


    &.textAlignCenter {
        width: 62%;
        text-align: center;
    }
`;

export const FlexWrap = styled.div`
     display: flex;
     align-items: center;
     gap: 10px;
     color: ${theme('palette.appDownloadPageSmallText')};
     font-size: 40px;

     &.twoRow {
        flex-direction: column;
        gap: unset;
     }

     h2 {
        margin: 0;
        padding: 0;
        font-size: unset;
        text-transform: capitalize;
     }
`;

const BanerRightContent = styled.div`
    width: 32%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
    color: ${theme('palette.appDownloadPageTextWhite')};
    font-family: 'Inter-Bold';
    position: absolute;
    right: 0;
    bottom: 1rem;

    @media only screen and (min-width: 1500px){
        width: 440px;
        right: 5%;
    }
`;

const BanerLeftContent = styled.div`
    color: ${theme('palette.appDownloadPageTextWhite')};
    text-align: right;
    font-family: 'Inter-Bold';
    font-size: 40px;
    position: absolute;
    left: 10%;
    top: 50px;
`;

const BannerSection = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    position: relative;

    .AnimateBD {
        width: 700px;
        height: 700px;

        animation: myAnim 3s ease 0s infinite alternate forwards;

        @keyframes myAnim {
            0% {
                transform: scale(0.8);
            }

            100% {
                transform: scale(0.9);
            }
        }
    }
`;

const MainWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    background: url(${config.imageV3Host}/static/cashsite/${theme('image.AppDownloadBg')});
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 1rem 4rem;

    .customColor {
        color: ${theme('palette.appDownloadPageCustomColor')};
    }
`;