import React, { useEffect, useState } from 'react'
import { getWhatsAppInfo } from 'src/config/api'
import styled, { withTheme } from 'styled-components'
// import Intl from "src/components/common/Intl";
// import WhatsappAni from './icon/WhatsappAni';
import cookieUtil from 'src/util/cookieUtil';
import { ECookieName } from 'src/util/utilModel';
// import { Player } from '@lottiefiles/react-lottie-player';
import config from 'src/config/config';
import { withAppContext } from 'src/store/initAppContext';
import { Player } from '@lottiefiles/react-lottie-player';
import Intl from './common/Intl';

type WhatsAppDataInterface = {
    phoneNumber: string,
    type: string,
    message: string,
    memo: string
}

type StyleProps = {
    rightSpace: string
}

export const clubsitePixel = (brandID, isprev = false, isLead = false,) => {
    const _location = typeof window === 'object' ? window.location : null;
    const _search = _location && _location.pathname === "/" && _location.search;
    const marketingSource = (_search || ' ').split('?marketingSource=')[1]
    if (marketingSource && (brandID === 32)) {
        if (marketingSource === "channel354") {
            import("react-facebook-pixel")
                .then((x) => x.default)
                .then((ReactPixel) => {
                    ReactPixel.init("537461558078361", null, {
                        autoConfig: true,
                        debug: false,
                    });
                    if (isprev) {
                        ReactPixel.pageView();
                        //ReactPixel.track("Lead", "");
                    } 
                    if (isLead) {
                        ReactPixel.track("Lead", "");
                    }
                });
        }
    }

}

const WhatsAppBeforeLoginComp = (props) => {
    const [whatsAppData, setWhatsAppData] = useState({} as WhatsAppDataInterface)
    const isAuth = cookieUtil.get(ECookieName.COOKIE_TOKEN);
    const { app: { brandId }} = props;

    useEffect(() => {
        fetchWhatsAppInfo();
        //pixel page View
       clubsitePixel(brandId, true)
    }, []);

    const fetchWhatsAppInfo = () => {
        getWhatsAppInfo(brandId).then((res) => {
            const { data = {} } = res.data || {}
            setWhatsAppData(data)
        }).catch((err) => {
            console.error(err)
        })
    }

    const imgSrc = '/static/cashsite/brand/6/icon/whatsappOnlyOnce.gif';

    return whatsAppData.phoneNumber !== "" ? (
        <WhatsAppNewWrapper id="WhatsappIcoWrapper">
            {!isAuth && (
                <a  href={`https://wa.me/${(whatsAppData.phoneNumber||"").trim()}?text=${whatsAppData.message}`} onClick={()=>clubsitePixel(brandId,false, true)} title="Get an ID instantly">
                    <img className='whatsAppImg' src={`${config.imageV3NonOptHost}${imgSrc}`} alt="Get an ID instantly" />
                </a>)
            }
        </WhatsAppNewWrapper>
    ) : null
}

export const WhatsAppBeforeLogin  = withAppContext(WhatsAppBeforeLoginComp);


function WhatsappIco(props) {
    const { app: { brandId, token }, theme } = props;
    const { showWhatsAppAfterLogin, showWhatsAppBeforeLogin } = theme.config;

    const [whatsAppData, setWhatsAppData] = useState({} as WhatsAppDataInterface)
    const isAuth = cookieUtil.get(ECookieName.COOKIE_TOKEN);
    const [tooltipState, setTooltipState] = useState(true)

    useEffect(() => {
        fetchWhatsAppInfo();
    }, [])

    //fetching whatsapp details
    const fetchWhatsAppInfo = () => {
        getWhatsAppInfo(brandId).then((res) => {
            const { data = {} } = res.data || {};
            setWhatsAppData(data)
        }).catch((err) => {
            console.error(err)
        })
    }

    const closeTooltip = () => {
        setTooltipState(false)
    }

    // const url = '/static/cashsite/brand/6/icon/watsapNewIcon.png'
    // const urlRedStyledicon='/static/cashsite/brand/6/icon/watsapNewIconRedShadow.png'

    return (
        <>
        {whatsAppData && whatsAppData.phoneNumber !== '' && showWhatsAppBeforeLogin && (
                <WhatsAppWrapper rightSpace={props.rightSpace}>
                    {!token && tooltipState && (
                        <WhatsAppTooltip rightSpace={props.rightSpace}>
                        <TooltipCross onClick={closeTooltip}><span>x</span></TooltipCross>
                        <WhatsAppTooltipText href={`https://wa.me/${(whatsAppData.phoneNumber||"").trim()}?text=${whatsAppData.message}`}>
                            <h6><Intl langKey="Whatsapp@whatsappText" /></h6>
                            <p><Intl langKey="Whatsapp@whatsappTextsub" /></p>
                        </WhatsAppTooltipText>
                        </WhatsAppTooltip> 
                        )  
                        }

                        {!token && (
                        <a href={`https://wa.me/${(whatsAppData.phoneNumber||"").trim()}?text=${whatsAppData.message}`}>
                            <Player
                            autoplay
                            loop
                            controls
                            src={`${config.imageV3NonOptHost}/static/cashsite/brand/6/icon/whatsappIcon.json`}
                            style={{ height: '50px', width: '50px' }}
                            ></Player>
                        </a>
                        )} 
                </WhatsAppWrapper>
        )}

        <WhatsAppWrapper rightSpace={props.rightSpace}>
                {token && showWhatsAppAfterLogin && (
                        <a href={`https://wa.me/${(whatsAppData.phoneNumber||"").trim()}?text=${whatsAppData.message}`}>
                            <Player
                                autoplay
                                loop
                                controls
                                src={`${config.imageV3NonOptHost}/static/cashsite/brand/6/icon/whatsappIcon.json`}
                                style={{ height: '50px', width: '50px' }}
                            ></Player>
                        </a>
                    )} 
        </WhatsAppWrapper> 
    </>
)}

export default withTheme(withAppContext(WhatsappIco));

const WhatsAppNewWrapper = styled.div`
// width:80px;
height:60px;
display: flex;
align-items: center;
justify-content: flex-end;
// animation:adjustWidth 10s linear 1s;
overflow:hidden;
img {
    max-height:55px;
    max-width:220px;
    padding-right:10px
}

// @keyframes adjustWidth {
//     0% { width:420px;}
//     50% {width:auto}
//     90% { width:auto}
//     100% { width:80px; overflow:hidden}

// }
`;


const WhatsAppWrapper = styled.div<Pick<StyleProps, 'rightSpace'>>`
    position: fixed;
    bottom: 20px;
    right: ${(props) => (props.rightSpace ? props.rightSpace : "20px")};
    z-index: 999999;
    cursor: pointer;
    border-radius: 50px;

    :hover {
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }

    & img {
        width: 50px;
        height: 50px;
    }
    & a {
      z-index: 99;
    }
    &.rpos {
      bottom: 0px;
    right: 5px;
    }
`
export const WhatsAppTooltip = styled.div<Pick<StyleProps, 'rightSpace'>>`
      position: fixed;
      bottom: 40px;
      right: ${(props) => (props.rightSpace ? props.rightSpace : "20px")};
      cursor: pointer;
      background: linear-gradient(90deg, #1C1E23 13.28%, #363B41 103.91%);
      box-shadow: 0px 0px 9px #4ED565;
      width: auto;
      padding: 0px 20px 10px 20px;
      border-radius: 10px;
      min-width: 250px;
`
export const TooltipCross = styled.div`
  width: 100%;
  height: 15px;

  & span {
    position: absolute;
    right: 10px;
    font-size: 18px;
    color: #7DD221;
    font-weight: 400;   
    /* font-family : 'Roboto'; */
  }
`

export const WhatsAppTooltipText = styled.a`
   & h6 {
        font-size: 12px;
        font-weight: 400;
        color: white;
        /* font-family: 'Roboto'; */
        margin: 3px 0px;
        text-transform: capitalize;
        letter-spacing: 1px;
      }
      
      & p {
        /* font-family: 'Roboto'; */
        font-size: 10px;
        font-weight: 400;
        color: white;
        width: 70px;
        min-width: 70px;
        max-width: 85px;
        border-bottom: 1px solid #059602;
        margin: 3px 0px;
        text-transform: capitalize;
        font-style: italic;
      }
`