import { AES, pad, mode, enc } from "crypto-js";
import config from "src/config/config";
import SHA256 from "crypto-js/sha256"

const brands = [31];

const encriptUserId = (id: string) => {
    if (id) {
        const TOKEN_SECRET_KEY = config.secretKey;
        const encriptedID = SHA256(`${id}${TOKEN_SECRET_KEY}`).toString();
        return encriptedID;
    }
    return '';
    // if (id) {
    //     const TOKEN_SECRET_KEY = config.secretKey;
    //     const encriptedID = AES.encrypt(id, TOKEN_SECRET_KEY).toString();
    //     return encriptedID;
    // }
    // return id;
}

const decryptUserId = (id: string) => {
    if (id) {
        const TOKEN_SECRET_KEY = config.secretKey;
        const decryptedID = AES.decrypt(id, TOKEN_SECRET_KEY).toString(enc.Utf8)
        return decryptedID;
    }
    return id;
}

type objType = {
    matchId?: string;
    teamHomeName?: string;
    teamAwayName?: string;
    scheduled?: string;
    tournamentName?: string;
    sport?: string;
    oddsHome?: string;
    oddsDraw?: string;
    oddsAway?: string;
    language?: string;
}

type objCasinoType = {
    id: number | string;
    name?: string;
    category?: string
    image?: string
}

export const SRTM = {
    init(brand: number) {
        if (brands.includes(brand)) {
            try {
                (function (a: any, b, c, d, e, f) {
                    a[e] = a[e] || [];
                    let x: any = b.createElement(c);
                    let y = b.getElementsByTagName(c)[0];
                    x.src = d + "?id=" + f;
                    x.setAttribute("data-srtmn", e);
                    y.parentNode.insertBefore(x, y);
                })(
                    window,
                    document,
                    "script",
                    "https://tm.ads.sportradar.com/dist/tag-manager.js",
                    "srtmCommands",
                    "STM-AAAAV0"
                );

            } catch (error) {
                console.log('Error in SRTM init');

            }
        }
    },
    regStartEvent(brand) {
        if (brands.includes(brand) && typeof window.srtmCommands === 'object') {
            window.srtmCommands.push({
                event: "track.user.registration",
                payload: { action: "start" },
            });
        }
    },
    regFinishEvent(brand, useId = "") {
        if (brands.includes(brand) && typeof window.srtmCommands === 'object') {
            window.srtmCommands.push({
                event: "track.user.registration",
                payload: { action: "complete", userId: encriptUserId(useId) },
            });
        }
    },
    loginEvent(brand, useId = "") {
        if (brands.includes(brand) && typeof window.srtmCommands === 'object') {
            window.srtmCommands.push({
                event: "track.user.login",
                payload: { action: "complete", userId: encriptUserId(useId) },
            });
        }
    },
    depositFT(brand, useId = "") {
        if (brands.includes(brand) && typeof window.srtmCommands === 'object') {
            window.srtmCommands.push({
                event: "track.betting.deposit",
                payload: {
                    action: "created",
                    label: "first_deposit",
                    userId: encriptUserId(useId),
                    transactionId: "",
                    amount: "",
                    currency: "",
                },
            });
        }
    },
    depositR(brand, useId = "") {
        if (brands.includes(brand) && typeof window.srtmCommands === 'object') {
            window.srtmCommands.push({
                event: "track.betting.deposit",
                payload: {
                    action: "created",
                    userId: encriptUserId(useId),
                    transactionId: "",
                    amount: "",
                    currency: "",
                },
            });
        }
    },
    retargetSportEvent(brand, obj: objType) {
        if (brands.includes(brand) && typeof window.srtmCommands === 'object') {
            window.srtmCommands.push({
                event: "srt.retargeting",
                payload: {
                    sportsEvent: {
                        match_id: obj.matchId || "",
                        sr_match_id: "",
                        team_home_name: obj.teamHomeName || "",
                        team_home_name_short: "",
                        team_home_logo: "",
                        team_away_name: obj.teamAwayName || "",
                        team_away_name_short: "",
                        team_away_logo: "",
                        scheduled: obj.scheduled || "",
                        status: "",
                        tournament_name: obj.tournamentName || "",
                        sport: obj.sport || "",
                        odds_home: obj.oddsHome || "",
                        odds_draw: obj.oddsDraw || "",
                        odds_away: obj.oddsAway || "",
                        language: obj.language || "",
                    },
                },
            })
        }
    },
    retargetCasinoEvent(brand, obj: objCasinoType) {
        if (brands.includes(brand) && typeof window.srtmCommands === 'object') {
            window.srtmCommands.push({
                event: "srt.retargeting",
                payload: {
                    casinoEvent: {
                        game: {
                            id: obj.id,
                            name: obj.name || "",
                            category: obj.category || "",
                            image: obj.image || "",
                            provider: {
                                id: "",
                                game: "",
                            },
                        }, event: {
                            type: "page_view",
                        },
                        version: "1-0-0",
                    },
                },
            })
        }
    },
}