import React from 'react'

type Props = {}

const ViewBankRed = (props: Props) => {
  return (
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="5.97803" y="19.5908" width="5.34854" height="12.0671" rx="1.06157" fill="white"/>
    <path d="M0.615782 16.4705V12.8535C0.615782 12.3406 0.615782 12.0762 1.02137 11.8583C6.79397 8.75756 18.4404 2.48461 18.8453 2.19882C19.2502 1.91302 19.8089 2.07974 20.0376 2.19882C25.3417 5.05548 36.1737 10.8939 37.0692 11.3943C37.9647 11.8947 38.2095 12.431 38.22 12.6366V16.4268C38.22 17.8751 37.5034 18.2293 37.1451 18.2254H36.2513H2.26615C0.833732 18.2254 0.569064 17.0554 0.615782 16.4705Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M19.4872 33.0196L1.68803 33.0195C1.10175 33.0195 0.626465 33.4948 0.626465 34.0811V38.5999C0.626465 39.1862 1.10174 39.6614 1.68803 39.6614L22.2154 39.6615C20.7297 37.8001 19.7507 35.5165 19.4872 33.0196Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M22.1117 23.7846V20.6504C22.1117 20.0641 21.6364 19.5889 21.0502 19.5889H17.8248C17.2385 19.5889 16.7632 20.0641 16.7632 20.6504V30.5944C16.7632 31.1807 17.2385 31.656 17.8247 31.656H19.4173C19.4173 28.6899 20.4229 25.9587 22.1117 23.7846Z" fill="white"/>
    <path d="M43.5937 31.6567C43.5937 37.6286 38.7526 42.4697 32.7807 42.4697C26.8089 42.4697 21.9678 37.6286 21.9678 31.6567C21.9678 25.6849 26.8089 20.8438 32.7807 20.8438C38.7526 20.8438 43.5937 25.6849 43.5937 31.6567Z" fill="#B21318"/>
    <g clip-path="url(#clip0_201_478)">
    <path d="M32.7813 33.4826C33.7902 33.4826 34.6081 32.6647 34.6081 31.6558C34.6081 30.647 33.7902 29.8291 32.7813 29.8291C31.7724 29.8291 30.9546 30.647 30.9546 31.6558C30.9546 32.6647 31.7724 33.4826 32.7813 33.4826Z" fill="white"/>
    <path d="M39.6041 31.5011C39.067 30.1117 38.1345 28.9101 36.9219 28.0449C35.7093 27.1797 34.2698 26.6888 32.7812 26.6328C31.2927 26.6888 29.8531 27.1797 28.6406 28.0449C27.428 28.9101 26.4955 30.1117 25.9584 31.5011C25.9221 31.6014 25.9221 31.7113 25.9584 31.8116C26.4955 33.201 27.428 34.4026 28.6406 35.2678C29.8531 36.133 31.2927 36.6239 32.7812 36.6799C34.2698 36.6239 35.7093 36.133 36.9219 35.2678C38.1345 34.4026 39.067 33.201 39.6041 31.8116C39.6404 31.7113 39.6404 31.6014 39.6041 31.5011ZM32.7812 34.6248C32.1941 34.6248 31.6202 34.4507 31.1321 34.1245C30.6439 33.7984 30.2634 33.3347 30.0387 32.7923C29.8141 32.2499 29.7553 31.6531 29.8698 31.0772C29.9844 30.5014 30.2671 29.9725 30.6822 29.5573C31.0974 29.1422 31.6263 28.8595 32.2021 28.7449C32.7779 28.6304 33.3748 28.6892 33.9172 28.9139C34.4596 29.1385 34.9232 29.519 35.2494 30.0072C35.5756 30.4953 35.7497 31.0692 35.7497 31.6564C35.7485 32.4433 35.4354 33.1976 34.8789 33.754C34.3225 34.3105 33.5682 34.6236 32.7812 34.6248Z" fill="white"/>
    </g>
    <defs>
    <clipPath id="clip0_201_478">
    <rect width="14.6139" height="14.6139" fill="white" transform="translate(25.4746 24.3486)"/>
    </clipPath>
    </defs>
    </svg>
    
  )
}

export default ViewBankRed;