import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { theme } from 'styled-tools';
import GameSearch from './game-search';
import { color } from '../../../sass-files/variable';
import TabIcon from 'src/images/TabIcon.png'
// import casinoGameList from "../../eu-content/casino-page/casino-tab-games";
// import CasinoGames from './casino-slider-Games';
// import ByBudget from './chooseby-budget';
// import LeftArrow from "../../../images/arrow-left_gold.png"
import { useNavigate } from "react-router-dom";
import { useMutation } from '@apollo/client';
import { ONE_CLICK_RECYCLE } from 'src/graphql/system';
import Intl from '../../common/Intl'
import config from 'src/config/config';
// import MenuSidebar from 'src/components/home-exchange/menu-sidebar';
// const { titleHeadColor, tabsignup, secondarycolor } = color;

interface IProps {
    tabs: any,
    isTabsHidden: boolean,
    render: (index: number, changeTab: (index: number) => void) => JSX.Element
    minWidthInContent?: string,
    onSearch,
    budgetClick,
    providerIcons: any
    searchText: string
}



export default function TabBar(props: IProps) {
    const [currentIndex, setCurrentIndex] = useState(0)
    const { tabs, render, minWidthInContent, providerIcons } = props
    const [triggerOneClickRecycle, { recycleLoading, recycleError, recycleData }]: any = useMutation(ONE_CLICK_RECYCLE)
    const [isGameTrigger, setGameTrigger] = useState(false);
    const [disabled, setDisabled] = useState(false)

 

    const handleChange = (index) => {
        setCurrentIndex(index)
    }

    const [value, setValue] = useState('false')

    const searchTerm = (val) => {
        props.onSearch(val)
    }

    const clickHandler = (val) => {
        setValue(val)
    }


    // useEffect(()=>{

    //     setDisabled(true)
    // })

    let navigate: any = useNavigate();
    const backButton = () => {
        // let path = '/casino';
        // navigate(path);
        // navigate(-1)
        // window.location.reload();
        triggerOneClickRecycle()
        .then((response) => {
            if (response) {
                return;
            }
        })
        .catch((error) => {
            console.log("Error in one click recycle");
        });

        if (triggerOneClickRecycle()) {
            setDisabled(false)
        } else {
            setDisabled(true)
        }
    }

    return (
        <TabContainer>
            {/* <MenuSidebar/> */}

            <TabWrapper>
                <BorderDiv>
                    <Intl langKey="GLOBAL@SEARCHBYGAMES">
                        {(message: string) => {
                            return (
                                <GameSearch placeholder={message} onSearch={searchTerm} searchText={props.searchText} />
                            );
                        }}
                    </Intl>                <ColoumnBg>
                        <Tabtittle>

                            <Intl langKey="GLOBAL@PROVIDERS" />
                        </Tabtittle>
                        {
                            providerIcons && tabs.map((name, index, id) => (

                                <SelectTab
                                    isActive={currentIndex === index}
                                    onClick={() => handleChange(index)}
                                    key={name}
                                >
                                    <Icon ><img src={`${config.imageV3Host}${providerIcons[index].icon}`} style={{ width: "20px", height: "20px" }} alt="providerIcons" /></Icon>
                                    <span>{name}</span>
                                    <TapOpen></TapOpen>
                                </SelectTab>
                            ))
                        }

                        {/* <ByBudget onClick={clickHandler} budgetClick={value}/> */}
                    </ColoumnBg>
                </BorderDiv>
            </TabWrapper>
            <TabContent minWidthInContent={minWidthInContent}>
                {render(currentIndex, handleChange)}
{/* 
                <BackButton onClick={backButton} disabled={disabled}> <img src={LeftArrow}/>
                <Intl langKey="BUTTON@BACK" />
                </BackButton> */}

            </TabContent>
        </TabContainer>
    )
}

const Icon = styled.span`
    width: 40px;
    color: #000000;
    height: 31px;
    position: absolute;
    top: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0px;
    border-radius: 10px 0px 0px 10px;
`;
const BorderDiv = styled.div`
    border-width: 1px;
    border: 1px solid whitesmoke;
    border-image: linear-gradient(to top,rgba(255,255,255,0),#ffffff6b) 1;

`;

// const BlueShade = styled.div`
//     width: 20px;
//     height: 54px;
//     position: absolute;
//     left: 0;
//     background: ${theme('palette.secondary-background-color-linear-new')};
//     box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.25);
// `;

// const YellowShade = styled.div`
//     width: 25px;
//     height: 54px;
//     position: absolute;
//     left: 20px;
//     background: ${theme('palette.primary-background-color-linear-new')};
// `;

// const AntiqueWhite = styled.div`
//     width: 14px;
//     height: 54px;
//     position: absolute;
//     left: 35px;
//     background: #fff;
//     transform: skew(8deg);
// `;

// const AntiqueWhiteTab = styled.div`
//     width: 14px;
//     height: 31px;
//     position: absolute;
//     left: 38px;
//     top: 0;
//     background: #DEC59D;
//     transform: skew(8deg);
//     z-index: 1;
// `;

// const GradientColor = styled.div`
//     width: 10px;
//     height: 31px;
//     position: absolute;
//     left: 49px;
//     top: 0;
//     background: linear-gradient(180deg, #CB9640 0%, #9F8E78 52.4%, #214076 100%);
//     transform: skew(8deg);
//     z-index: 2;
// `;

const Tabtittle = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${theme('palette.secondary-background-color-linear-new')};
    font-size: 18px;
    color: white;
    letter-spacing: 0.05em;
    padding: 15px 8px 15px 8px;
    text-transform: uppercase;
    position: relative;
    margin-bottom: 5px;
    border-radius: 3px;
`;

const ColoumnBg = styled.div`
    padding: 5px;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    background: linear-gradient(0deg,rgba(255,255,255,0.2),rgba(255,255,255,0.2)),rgba(0,0,0,0.3);
`;

const TapOpen = styled.div`
     ${(props: any) =>
        props.isActive &&
        css`
            /* background: ${theme('palette.casino-tab-background')};
            color: ${theme('palette.agent-slected-text')}; */
		`}

    width: 40px;
    height: 29px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    border-radius: 4px 4px 4px 4px;
    right: 0;
    top: 0;
    cursor: pointer;
    background:  #ABABAB;
    
    :after {
        content:"";
        width: 15px;
        height: 15px;
        display: inline-block;
        position: absolute;
        right: 13px;
        top: 9px;
        background-image: url(${TabIcon});
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }
    `;

const SelectTab: any = styled.div`
    cursor: pointer; 
    letter-spacing: 0.04em;
    text-transform: capitalize;
    font-size: 14px;
    color: ${theme('palette.secondary-background-color-linear-new')};
    
    padding: 6px 15px;
   background:linear-gradient(270deg, #FFFFFF 5.96%, rgba(236, 236, 236, 0.7) 94.58%);
    /* border: 1px solid #0A4BA8; */
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    margin-bottom: 3px;
    position: relative;
    padding-left: 4em;

    
    & span{
        color: black;
    }
    & :hover {
		background: ${theme('palette.agent-slected-text')};
        transition: .5s ease;
    }
	
	${(props: any) =>
        props.isActive &&
        css`
            ${TapOpen}{
                background: ${theme('palette.secondary-background-color-linear-new')};            }
		`}
        :after {
                content:"";
                width: 15px;
                height: 15px;
                display: inline-block;
                position: absolute;
                right: 13px;
                top: 9px;
                background-image: url(${TabIcon});
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
            }
`;

const TabContainer: any = styled.div`
    width: 100%;
    height: 100%;
	display: flex;
	flex-direction: row;
    margin: ${(props: any) => props.minWidthInContent || '0px'};
    position: relative;
    // background: linear-gradient(180.25deg, rgba(255, 255, 255, 0.9) -4.05%, rgba(255, 255, 255, 0.9) 32.18%, rgba(255, 255, 255, 0.9) 68.36%, rgba(255, 255, 255, 0.9) 99.78%);
`;

const TabWrapper = styled.div`
    width: 270px;
    display: flex;
    flex-direction: column;
    margin: 0 0 10px;
    // background: linear-gradient(180.22deg, rgba(33, 64, 118, 0.6) -3.47%, rgba(20, 70, 146, 0.6) 17.13%, rgba(10, 75, 168, 0.6) 57.31%, rgba(33, 64, 118, 0.6) 83.68%);
    
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    padding: 6px;
    // margin-left: 70px;
`;

const TabContent: any = styled.div`
	// background-color: ${theme('palette.agent-tab-color')};
    min-width: ${(props: any) => props.minWidthInContent || 'initial'};
    width: calc(100% - 270px);
    display: flex;
    flex-direction: row;
    /* height: calc(100vh - 160px); */
    height: calc(100vh - 100px);
    overflow-y: scroll;
    overflow: overlay;
    /* height: 100vh; */
   ::-webkit-scrollbar {
        width: 8px;
    }

::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #dfdcdc;
    -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 50%);
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
    border-radius: 5px;
}

`;

// const BackButton = styled.button`
//     display: flex;
//     font-size: 15px;
//     color: #D0B99F;
//     -webkit-box-align: center;
//     align-items: center;
//     -webkit-box-pack: start;
//     justify-content: flex-start;
//     padding: -0.1em 1.7em;
//     background: ${theme('palette.backButtonColor')};
//     border-radius: 3px;
//     // margin-left: 150px;
//     position: absolute;
//     top: 125px;
//     right: 0px;
//     cursor:pointer;
//     border: 0.25px solid #D0B99F;
//     // box-shadow: 0 0 3px #786B57;

// img{
//     padding-right:5px;
//     color:#fff;
    
// }

// :disabled{
//     color: red;
//     background-color: red;
//     display: none;
// }

// `;