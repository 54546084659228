import React from 'react'

type Props = {}

const PromoRed = (props: Props) => {
  return (
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.0883 7.36055C16.8003 13.871 12.7428 17.767 11 18.9012C11.4467 19.1894 11.8646 19.8575 12.0176 20.1555C12.4085 21.2254 13.29 23.6459 13.6898 24.7683C14.0896 25.8908 14.2 26.9137 14.2052 27.2849C19.0582 27.0087 25.1705 29.6028 27.62 30.9345L19.0883 7.36055Z" fill="white"/>
    <path d="M30.11 19.631L28.2437 14.5394C29.6582 14.5394 30.4811 15.6854 30.7158 16.2584C31.2921 17.8694 30.5521 19.178 30.11 19.631Z" fill="white"/>
    <path d="M19.6776 5.24634L29.2989 31.4886C30.642 30.1914 31.8224 29.5067 32.2447 29.3265L22.8667 3.73283C22.5874 3.37257 21.7694 2.74395 20.7316 3.1115C19.6938 3.47905 19.5965 4.68788 19.6776 5.24634Z" fill="white"/>
    <rect x="29.917" y="12.0117" width="11.2999" height="1.46857" rx="0.734283" transform="rotate(-76.2301 29.917 12.0117)" fill="white"/>
    <rect x="31.876" y="13.4141" width="7.68492" height="1.46857" rx="0.734283" transform="rotate(-30.1316 31.876 13.4141)" fill="white"/>
    <rect x="32.8589" y="15.1953" width="7.68492" height="1.46857" rx="0.734283" transform="rotate(-3.81893 32.8589 15.1953)" fill="white"/>
    <rect x="33.0894" y="18.0039" width="11.284" height="1.46857" rx="0.734283" transform="rotate(42.2255 33.0894 18.0039)" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.18581 20.8339C2.08308 21.2259 1.50688 22.4375 1.89883 23.5403L3.78781 28.8549C4.17976 29.9576 5.39142 30.5338 6.49415 30.1419L11.3849 28.4035C12.4876 28.0116 13.0638 26.7999 12.6718 25.6972L10.7829 20.3826C10.3909 19.2799 9.17925 18.7037 8.07653 19.0956L3.18581 20.8339ZM3.73538 22.8555C3.37154 22.9867 3.18298 23.3881 3.31422 23.7519C3.44546 24.1158 3.8468 24.3043 4.21064 24.1731L9.25683 22.3529C9.62067 22.2216 9.80923 21.8203 9.67799 21.4565C9.54675 21.0926 9.14541 20.9041 8.78157 21.0353L3.73538 22.8555ZM4.11512 25.7542C3.98388 25.3903 4.17244 24.989 4.53627 24.8577L9.58247 23.0375C9.9463 22.9063 10.3476 23.0949 10.4789 23.4587C10.6101 23.8225 10.4216 24.2239 10.0577 24.3551L5.01153 26.1753C4.64769 26.3066 4.24635 26.118 4.11512 25.7542ZM5.29289 26.8455C4.92905 26.9768 4.74049 27.3781 4.87173 27.742C5.00297 28.1058 5.40431 28.2943 5.76815 28.1631L10.8143 26.3429C11.1782 26.2117 11.3667 25.8103 11.2355 25.4465C11.1043 25.0827 10.7029 24.8941 10.3391 25.0253L5.29289 26.8455Z" fill="white"/>
    <path d="M27.6291 34.1239C27.7046 33.3567 28.4997 32.1259 28.8878 31.6064H20.7073C20.2543 31.6064 20.1412 32.0681 20.1413 32.299C20.1466 32.9438 20.1539 34.3531 20.1413 34.8312C20.1288 35.3093 20.6188 35.4394 20.8654 35.4446H21.8252C22.102 34.5764 23.1256 34.2024 23.6028 34.1239H27.6291Z" fill="white"/>
    <path d="M27.4074 38.0083C27.2301 37.5805 27.2601 36.1209 27.2973 35.4446H23.7585C23.2804 35.4446 23.0455 35.9479 22.9878 36.1995V38.0083H27.4074Z" fill="white"/>
    <path d="M30.9148 43.2614C29.4804 42.4184 28.2725 40.3308 27.8478 39.3923H20.8654C20.2496 39.3923 20.1261 39.8432 20.1413 40.0687V42.5851C20.1413 43.2457 20.8654 43.3401 20.8654 43.2614C20.8654 43.1985 27.565 43.2352 30.9148 43.2614Z" fill="white"/>
    <path d="M8.23127 32.8401C10.0887 33.5183 11.1492 31.0835 11.4472 29.7813C12.6627 29.4765 13.4267 28.4342 13.6567 27.9512L15.0634 29.4915C16.7067 31.7779 16.2989 34.1209 15.8895 35.0066C15.5472 35.7732 14.7106 37.4135 14.1033 37.8423C13.4959 38.271 12.9869 39.3457 12.8082 39.8295L12.3393 42.1963H5.84206L7.4271 38.1995V36.7928C7.38245 36.5919 7.14132 35.9533 6.53399 35.0066C5.92666 34.0598 5.81965 32.3793 5.84206 31.6573L6.91431 31.434C7.50377 32.5236 8.03789 32.8254 8.23127 32.8401Z" fill="white"/>
    <path d="M9.85873 30.3848L8.22119 30.996L8.46868 31.4509C9.01963 31.8771 9.30464 31.5418 9.37827 31.3209L9.85873 30.3848Z" fill="white"/>
    <path d="M15.3945 38.3252L16.6035 36.5439C17.1895 37.1801 17.4079 38.2338 16.6035 39.4552C15.96 40.4324 14.7063 40.5554 14.16 40.4948C14.0456 39.8087 14.9353 38.7625 15.3945 38.3252Z" fill="white"/>
    <rect x="5.53174" y="4.43945" width="7.17236" height="7.04421" fill="white"/>
    <path d="M15.8716 7.7007L14.6893 6.41008L15.1959 4.73449L13.5693 4.08697L13.2987 2.35727L11.5522 2.4769L10.5578 1.03613L9.0371 1.90443L7.51638 1.03613L6.52199 2.4769L4.77557 2.35724L4.50494 4.08692L2.87829 4.73443L3.38492 6.41002L2.20264 7.7007L3.38492 8.99132L2.87829 10.6669L4.50494 11.3144L4.77557 13.0441L6.52199 12.9245L7.51638 14.3652L9.0371 13.497L10.5578 14.3652L11.5522 12.9244L13.2986 13.0441L13.5692 11.3144L15.1959 10.6669L14.6893 8.99132L15.8716 7.7007ZM7.59781 5.55363C8.09015 5.55363 8.48925 5.95276 8.48925 6.44508C8.48925 6.93743 8.09015 7.33652 7.59781 7.33652C7.10548 7.33652 6.70636 6.9374 6.70636 6.44508C6.70634 5.95276 7.10543 5.55363 7.59781 5.55363ZM7.60104 10.1894L6.90736 9.62951C8.2999 7.90436 9.08063 6.93714 10.4732 5.21199L11.1668 5.77191L9.38394 7.98067L7.60104 10.1894ZM10.4764 10.1145C9.98405 10.1145 9.58495 9.71542 9.58495 9.2231C9.58495 8.73075 9.98405 8.33166 10.4764 8.33166C10.9687 8.33166 11.3678 8.73075 11.3678 9.2231C11.3679 9.71539 10.9687 10.1145 10.4764 10.1145Z" fill="#B21318"/>
    <circle cx="35.8348" cy="36.4286" r="6.83483" fill="#B21318"/>
    <path d="M38.3978 34.9392L38.1191 36.0004H33L33.2749 34.9392H38.3978ZM35.7371 40.818L33.126 37.7106L33.1298 36.7563H34.4201C34.7076 36.7563 34.9481 36.7028 35.1415 36.596C35.335 36.4891 35.48 36.344 35.5767 36.1608C35.6734 35.9775 35.7231 35.7689 35.7256 35.5347C35.7231 35.1835 35.62 34.8972 35.4164 34.6758C35.2153 34.4519 34.8832 34.3399 34.4201 34.3399H33L33.313 33H34.4201C35.069 33 35.6098 33.098 36.0424 33.2939C36.4776 33.4874 36.8034 33.7635 37.0197 34.1223C37.2385 34.4811 37.3493 34.9087 37.3518 35.4049C37.3493 35.8452 37.2754 36.2295 37.1304 36.5578C36.9879 36.8861 36.7627 37.1597 36.4547 37.3785C36.1493 37.5974 35.751 37.7603 35.2599 37.8671L35.1988 37.89L37.535 40.7493V40.818H35.7371ZM38.4054 33L38.1191 34.0765H33.9047L34.191 33H38.4054Z" fill="white"/>
    </svg>
    
  )
}

export default PromoRed