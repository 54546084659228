const _data: any = {
    "best-casino-games": {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [{
            "@type": "Question",
            "name": "Which Are the Top Five Best Casino Games?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "if you are looking for the top five games with simple gameplay and maximum payouts, here is the list: Aviator, Lightning Roulette, Crazy Time, Dragon Tiger & Teen Patti."
            }
        }, {
            "@type": "Question",
            "name": "Can I Improve My Chances of Winning By Playing The Best Casino Games?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Some strategies and techniques can improve your chances of winning. Studying the game rules, learning optimal strategy, and managing your money effectively are essential. It is never about luck when it comes to online casinos. It is all about your skills and strategies."
            }
        }, {
            "@type": "Question",
            "name": "Are Online Casino Games Fair and Secure?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Online casinos use random number generators (RNGs) to ensure fair and unbiased outcomes in their games, and the RNGs maintain fairness and integrity. So, be assured that online casino games are safe and secure."
            }
        }]
    },
    "online-casino-games": {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [{
            "@type": "Question",
            "name": "What are Live Online Casino Games?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Live casino online games bring all the fun and excitement of a real casino."
            }
        }, {
            "@type": "Question",
            "name": "Which are some of the most popular Live Online Casino Games?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Andar Bahar, Dragon Tiger, Baccarat, Poker, and Blackjack are popular live online casino games."
            }
        }]
    },
    "betting-exchange": {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [{
            "@type": "Question",
            "name": "Can I place live bets on a betting exchange?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Yes, most exchanges offer live betting options to place bets during a game or event."
            }
        }, {
            "@type": "Question",
            "name": "Are there any limits on the types of bets I can place on a betting exchange?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Betting exchange sites may have some limits on the types of bets, such as minimum and maximum bet amounts or restrictions on specific betting markets."
            }
        }, {
            "@type": "Question",
            "name": "How do I know which games are available for betting?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "The betting platform will list available games on its homepage or in a dedicated sports section."
            }
        }, {
            "@type": "Question",
            "name": "What is the difference between a betting exchange and a conventional bookmaker?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "The difference is that an exchange allows users to bet against each other, while a traditional bookmaker sets the odds and accepts user bets. Additionally, the exchange offers better odds and more options than conventional bookmakers."
            }
        }]
    },
    "slotGames": {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [{
            "@type": "Question",
            "name": "Which are the popular types of Sportsbook betting on yolo247 ?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Cricket, football, basketball, tennis, and kabaddi are popular types of Sportsbook betting."
            }
        }, {
            "@type": "Question",
            "name": "Does Yolo247 have domestic cricket matches too?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Be it international or domestic cricket matches, Yolo247 hosts all kinds of cricket matches."
            }
        }]
    },
    "sportsbook": {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [{
            "@type": "Question",
            "name": "Which are the popular types of Sportsbook betting on yolo247 ?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Cricket, football, basketball, tennis, and kabaddi are popular types of Sportsbook betting."
            }
        }, {
            "@type": "Question",
            "name": "Does Yolo247 have domestic cricket matches too?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Be it international or domestic cricket matches, Yolo247 hosts all kinds of cricket matches."
            }
        }]
    },
    "evo": {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [{
            "@type": "Question",
            "name": "Why is Evolution among the best casino game providers in India?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Evolution Gaming is among the best casino game providers in India because it has state-of-the-art technology under its wings. It has already taken some of the best interactive studios on board, which helps develop games with exceptional graphics."
            }
        }, {
            "@type": "Question",
            "name": "When was Evolution Gaming founded?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Evolution was founded in 2006. Since then, it has increased, signing contracts with live dealers. The gaming platform continues to grow at a rapid rate, often expanding across various countries."
            }
        }]
    },
    "supernowa": {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [{
            "@type": "Question",
            "name": "Which are the Supernowa games available on Yolo247?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Baccarat, Poker, Worli Matka, Akbar Romeo Walter, 32 Cards, Kings Race, and Andar Bahar are the Supernowa games available on Yolo247."
            }
        }, {
            "@type": "Question",
            "name": "Are Supernowa games rewarding?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Yes, exciting cash rewards are up for grabs in the Supernow games."
            }
        }]
    },
    "spribe": {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [{
            "@type": "Question",
            "name": "What are some of the best games offered by Yolo247 Spribe?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Yolo247 Spribe games include Aviator, Dice, Goal, Hi-Lo, Mini Roulette, Plinko, Keno, and Mines."
            }
        }, {
            "@type": "Question",
            "name": "Why play Yolo247 Spribe games?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Yolo247 Spribe games are fun and exciting. They are visually appealing and built using the latest technology."
            }
        }]
    },
    "vfec": {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [{
            "@type": "Question",
            "name": "How many teams are there in the virtual Euro league on Yolo247?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "There are 16 teams in the virtual Euro league on Yolo247."
            }
        }, {
            "@type": "Question",
            "name": "]Which are the popular European Cup betting markets?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Match Result, Correct Score, Over/Under Goals, Total Goals, etc., are some of the most popular betting markets."
            }
        }, {
            "@type": "Question",
            "name": "What makes virtual European Cup betting exciting?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "The fact that you can bet on Euro league matches anytime, enhanced graphics, smooth navigation, the best odds, and trusted platforms like Yolo247 make European Cup betting exciting."
            }
        }]
    },
    "vfel": {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [{
            "@type": "Question",
            "name": "What is virtual EPL betting?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Virtual EPL betting is online football betting where football fans can bet on simulated matches of the English Premier League."
            }
        }, {
            "@type": "Question",
            "name": "What are some of the most popular EPL betting markets?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Fulltime, Over/Under, Correct Score, First Goal, etc., are some of the most popular betting markets."
            }
        }]
    },
    "betgames": {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [{
            "@type": "Question",
            "name": "What are the games offered by BetGames ?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Bet on Poker, Dice 6+ Poker, Blackjack, Lucky 5, Lucky 6, Lucky 7, War of Bets, Wheel, and Baccarat are the games offered by BetGames provider."
            }
        }, {
            "@type": "Question",
            "name": "Why is BetGames popular?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "They are popular because they offer casino gaming enthusiasts an immersive gaming experience."
            }
        }]
    },
    "ezugi": {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [{
            "@type": "Question",
            "name": "Can betting strategies help win big in Ezugi Live Casino Games?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Yes, strategies will help you win live casino games. Make sure that you develop a plan before placing bets."
            }
        }, {
            "@type": "Question",
            "name": "Can I talk to live dealers?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Yes. You can use the chat box to get your point of view across. Some games, like Live Andar Bahar and Online Baccarat, have dealers who keep the conversation going, giving you a land-based casino vibe."
            }
        }]
    },
    "sexygaming": {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [{
            "@type": "Question",
            "name": "What are the top live casino games available on Yolo247 Sexy Gaming?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "The top live casino games on Yolo247 Sexy Gaming are Roulette, Sic Bo, Dragon Tiger, Baccarat Classic, and Baccarat Insurance."
            }
        }, {
            "@type": "Question",
            "name": "Why play Yolo247 Sexy Gaming live casino games?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Yolo247 Sexy Gaming live casino games are fun, and glamorous dealers make these games even more engaging and exciting."
            }
        }, {
            "@type": "Question",
            "name": "",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": ""
            }
        }]
    },
    "superspade": {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [{
            "@type": "Question",
            "name": "Is Super Spade Games Available on Yolo247 Website and App?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "The Super Spade games are available on the Yolo247 website and app."
            }
        }, {
            "@type": "Question",
            "name": "What are some of the best games from Super Spade Games?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "On Yolo247, you get to play three games. These three most thrilling games keep you hooked - Andar Bahar, Dragon Tiger, and Teen Patti."
            }
        }]
    },
    "vbl": {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [{
            "@type": "Question",
            "name": "What is Virtual Basketball betting?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "It is a form of virtual sports betting where you can bet on virtual basketball matches."
            }
        }, {
            "@type": "Question",
            "name": "Is Virtual Basketball Betting Available 24*7?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Virtual Basketball is available around the clock. You can place bets on virtual matches anytime, irrespective of the real-world basketball schedule."
            }
        }, {
            "@type": "Question",
            "name": "Can I Watch Virtual Basketball matches?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Yes. You can watch and bet on virtual basketball matches."
            }
        }]
    },
    "vfwc": {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [{
            "@type": "Question",
            "name": "How does virtual football World Cup betting work?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Virtual World Cup football betting uses computer-generated algorithms to simulate football matches between national teams. These algorithms generate random results, ensuring that virtual football World Cup betting remains fair. Bettors can place wagers on various markets, such as correct score, first goal, over/under goals, etc."
            }
        }, {
            "@type": "Question",
            "name": "Which are the teams that feature in virtual World Cup betting?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Your favourite teams like Brazil, Argentina, Spain, Germany, France, etc., feature in the virtual World Cup betting."
            }
        }, {
            "@type": "Question",
            "name": "Why should you place a bet on virtual World Cup betting?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "On the Yolo247 platform, you can bet on your preferred football match anytime and win big. You don't have to wait for the actual World Cup event to place bets."
            }
        }]
    },
    "7MOJOS": {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [{
            "@type": "Question",
            "name": "How can I play 7Mojos live casino games?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "To play the 7Mojos live casino games, visit the Yolo247 website and navigate to the live casino section. Select the game provider \"7Mojos\" from there and explore the available games."
            }
        }, {
            "@type": "Question",
            "name": "Can I chat with the live dealers and other players during the games?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Yes, Yolo247 provides a chat feature where you can interact with live dealers and fellow players while playing games offered by 7Mojos."
            }
        }, {
            "@type": "Question",
            "name": "Is there a demo mode available for 7Mojos live casino games?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Yes, Yolo247 offers a demo for the 7Mojos live casino games. It allows you to know the gameplay before playing for real money."
            }
        }]
    },
    "teenpatti2020": {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [{
            "@type": "Question",
            "name": "Can I play Teenpatti 2020 on Yolo247 for free?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Yes. Yolo247 offers the option to play for free in demo mode. It lets you practise and learn about the game before playing with real money."
            }
        }, {
            "@type": "Question",
            "name": "Is Teenpatti 2020 suitable for beginners?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "The game caters to players of all skill levels, including beginners. The game is easy to understand."
            }
        }]
    },
    "macaw": {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [{
            "@type": "Question",
            "name": "What are the Macaw games available on Yolo247?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "MacLudo and Hot Balloon are the two Macaw games available on Yolo247."
            }
        }, {
            "@type": "Question",
            "name": "How can I deposit and withdraw funds on Yolo247?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Yolo247 offers a variety of secure and convenient payment methods for depositing and withdrawing funds. Players can choose from Net Banking, UPI, and other payment methods."
            }
        }]
    },
    "tvbet": {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [{
            "@type": "Question",
            "name": "Which games are offered by Yolo TVBET?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Yolo247 TVBET casino games include 1Bet, Keno, 7Bet, 5Bet, Poker, Wheel Bet, War of Elements, and Lucky 6."
            }
        }, {
            "@type": "Question",
            "name": "Why play Yolo247 TVBET games?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Yolo247 TVBET games are a perfect mix of entertainment and technology. Also, the games are visually appealing and easy to play."
            }
        }]
    },
    "teenpatti-online-play": {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [{
            "@type": "Question",
            "name": "What are the basic rules of the TeenPatti card game?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "The TeenPatti game can accommodate 3 to 6 players, and each player is dealt three cards face down. The goal of the TeenPatti game is to have the best three-card hand or to bluff your opponents into folding. There are various hand rankings in TeenPatti, starting from the highest: trail, pure sequence, sequence, color, pair, and high card."
            }
        }, {
            "@type": "Question",
            "name": "How do you place bets on online TeenPatti?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Betting in TeenPatti requires a predetermined betting amount to be placed by all players before the cards are dealt. Players can then choose to play 'blind' (without looking at their cards) or 'seen' (after looking at their cards). Blind players bet half or equal to the current stake, while seen players must bet double the current stake."
            }
        }, {
            "@type": "Question",
            "name": "Where can I play 3 Patti online?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "You can play 3 Patti online on Yolo247."
            }
        }]
    },
    "ag": {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [{
            "@type": "Question",
            "name": "Why is Amazing Gaming among the best Game providers in India?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "There are two main reasons. First is state-of-the-art technology, meaning the in-house developer creates real-life graphics that the user will love the games. Secondly, Amazing Gaming has proved credible since many European countries regulate it, so you can safely put your money."
            }
        }, {
            "@type": "Question",
            "name": "Which are the games available on Yolo247 Amazing Gaming?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Amazing Gaming has games like Teen Patti, Andar Bahar, Baccarat, Blackjack, Dragon Tiger, and Roulette."
            }
        }]
    },
    "vfb": {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [{
            "@type": "Question",
            "name": "What is Virtual Bundesliga Betting?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Virtual Bundesliga is a virtual football league where football fans can place bets on various German League matches."
            }
        }, {
            "@type": "Question",
            "name": "Which is the most popular betting option on Virtual Bundesliga Betting?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "1X2 is one of the most popular virtual Bundesliga betting markets."
            }
        }]
    },
    "ipl-betting": {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [{
            "@type": "Question",
            "name": "What is IPL betting?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "IPL betting is placing bets on Yolo247 during the IPL season. It involves placing bets in all 74 matches. Bettors can place IPL bets on Sportsbook and Exchange."
            }
        }, {
            "@type": "Question",
            "name": "What are some exciting IPL betting markets?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "There are plenty of betting markets in IPL. Toss winner, Powerplay score, First innings total, etc"
            }
        }, {
            "@type": "Question",
            "name": "Who are the most popular teams in IPL betting?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Mumbai Indians, Chennai Super Kings, Kolkata Knight Riders, and Royal Challengers Bangalore are the most popular teams in IPL betting."
            }
        }]
    },
    "cricket-world-cup-betting": {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [{
            "@type": "Question",
            "name": "When can I place a bet on cricket world cup?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "For special market bets, you can place bets even before the tournament starts. For fancy bets & straight bets, you can bet during the matches."
            }
        }, {
            "@type": "Question",
            "name": "For ODI cricket world cup betting, sportsbook is better or exchange is better?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Both are great options to bet on cricket world cup. It is completely based on your preference. To your advantage, both these options are available on Yolo247. Check out sportsbook and exchange for cricket world cup betting on Yolo247 & find what suits you."
            }
        }, {
            "@type": "Question",
            "name": "When is the ICC World Cup 2023 starting?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "It starts on 5th October and ends on 19th November."
            }
        }]
    },
    "asia-cup-betting": {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [{
            "@type": "Question",
            "name": "What is Asia Cup betting?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Asia Cup betting involves placing bets on one of the world's most popular cricket tournaments. A total of 13 matches will be played in the tournament, with India, Pakistan, Sri Lanka, Bangladesh, Afghanistan, and Nepal featuring in the 2023 edition."
            }
        }, {
            "@type": "Question",
            "name": "Which are the most popular Asia Cup betting markets?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Popular Asia Cup betting markets include Match Odds, Fancy, 2 Way Money Line Winner, FT Winner, Outrights, 1st Over Total Runs, Total Sixes, First Innings Total, A Fifty Score in Match, A Hundred Score in Match, etc."
            }
        }, {
            "@type": "Question",
            "name": "When will the 2023 Asia Cup begin?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "The 2023 Asia Cup edition will start on August 30. India will take on Pakistan on September 2 in Kandy. Meanwhile, the final will be played on September 17."
            }
        }]
    },
    "major-league-cricket-betting": {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [{
            "@type": "Question",
            "name": "What is MLC Betting?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "MLC betting is placing bets on Major League Cricket (MLC). It is a six-team league involving some of the finest international and USA players."
            }
        }, {
            "@type": "Question",
            "name": "Can I do MLC betting on Yolo247?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Yolo247 - the Principal Partner of the Los Angeles Knight Riders franchise - hosts all MLC matches. You can find the best MLC odds on the award-winning online gaming platform."
            }
        }, {
            "@type": "Question",
            "name": "Which are the six teams to place bets on MLC betting?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Los Angeles Knight Riders, MI New York, San Francisco Unicorns, Seattle Orcas, Texas Super Kings, and Washington Freedom are the six teams available to place a bet on MLC betting."
            }
        }]
    },
    "online-cricket-satta-bazar": {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [{
            "@type": "Question",
            "name": "Can I bet on all cricket matches on Yolo247?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Yes. You can place bets on all cricket leagues, matches and formats. Yolo247 is available for all matches throughout the year."
            }
        }, {
            "@type": "Question",
            "name": "What is the best cricket betting type?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "It depends on your preference. On Yolo247, you have all the betting options available. From live betting to fancy bets, you have the best cricket odds."
            }
        }, {
            "@type": "Question",
            "name": "How can I bet on cricket matches online?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Login to your Yolo247 account. If you are new to cricket betting, register with Yolo247, the best online cricket betting site in India. Deposit. Pick any match. Place your bet. Win & withdraw your profits anytime."
            }
        }, {
            "@type": "Question",
            "name": "Is cricket betting easy?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Yes. On Yolo247, cricket betting is made easier. You have transparent odds, betting options and profit calculation. All you need to do is pick a match, choose your betting option and place your bet."
            }
        }, {
            "@type": "Question",
            "name": "How to calculate my profit in cricket betting?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Mostly, your profit is determined by the odds. For a successful Back Bet, you can calculate your profit by multiplying your bet amount by the odds offered at the end of the event."
            }
        }]
    },
    "cpl-betting": {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [{
            "@type": "Question",
            "name": "Which Team Has Won the Most CPL Titles?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Trinbago Knight Riders have won the most CPL titles, winning four times in 2015, 2017, 2018, and 2020."
            }
        }, {
            "@type": "Question",
            "name": "When is CPL 2023 Starting?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "CPL 2023 will start on 17th August 2023 and end on 25th September 2023."
            }
        }, {
            "@type": "Question",
            "name": "How Many Teams Participate in Caribbean Premier League?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Total six teams are in the Caribbean Premier League - Trinbago Knight Riders, Saint Lucia Kings, St. Kitts and Nevis Patriots, Jamaica Tallawahs, Guyana Amazon Warriors, and Barbados Royals."
            }
        }]
    },
    "home": {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [{
            "@type": "Question",
            "name": "How can I withdraw the amount on Yolo247?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Yolo247 supports instant withdrawal. Go to your Account Details page. Under “Finance” section, you can find “Withdrawal”. You can opt “Insta Withdrawal” or Normal Withdrawal based on your preference"
            }
        }, {
            "@type": "Question",
            "name": "How can I deposit the amount on Yolo247?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Deposits are faster on Yolo247. You can deposit using Fast UPI, Bank Transfers, Regular UPI modes & other methods."
            }
        }, {
            "@type": "Question",
            "name": "How to check my Bet Record?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "On Yolo247, you can track all your betting records and stats. Go to your Account details page. Under “Bet Details”, you can find all the following options. My Bets Profit & Loss Account Statement, Casino Statement Sportsbook Statement, Virtual Sports Statement,  Turnover Statement and Split the Pot Statement"
            }
        }, {
            "@type": "Question",
            "name": "What is the maximum deposit amount on Yolo247?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Five Lakh Rupees is the maximum deposit amount on Yolo247."
            }
        }, {
            "@type": "Question",
            "name": "What is the minimum deposit amount on Yolo247?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "100 Rupees is the minimum deposit amount on Yolo247."
            }
        }, {
            "@type": "Question",
            "name": "How to create a Yolo247 betting account?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Registering with Yolo247 is simple & fast. Visit the site. Click 'Sign Up'. Enter your details. Your account will be activated in just a few seconds."
            }
        }, {
            "@type": "Question",
            "name": "How long is the withdrawal processing time on Yolo247?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "It depends on the withdrawal mode you choose. Usually, it will be credited to your account within 30-45 minutes."
            }
        }, {
            "@type": "Question",
            "name": "Where should I follow up on my pending withdrawals?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "You can track all the pending, in-process, initiated and processed items in “Payment History” section. It includes both withdrawals and deposits. Along with that, you always have 24x7 customer support. Get instant replies through the Chat option."
            }
        }, {
            "@type": "Question",
            "name": "How to make the first bet?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Your first bet is always special. On Yolo247, you can bet on all sports and play the best casino games.  Login to your account. Deposit the amount of your choice. Pick a match/game. Place your first bet. Win & withdraw anytime."
            }
        }, {
            "@type": "Question",
            "name": "Where can I see my transaction history?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "You can check all your betting transactions under “Finance” section in your “Account Details” page."
            }
        }]
    }
}

const brandData = {
    31: {
        ..._data
    },
    32: {
        ..._data
    },
    33: {

    },
    34: {

    }
}


export default function FNQSchemaData(brand: any, url: string) {
    const data = brandData[brand];
    const q = url.split('/').filter(e => e);
    const currentPage = q[q.length - 1] || "home";
    
    if (data && data.hasOwnProperty(currentPage)) {
        const retV = data[currentPage];
        return JSON.stringify(retV)
    }

    return null;
}
