import React from 'react'
import ReactDOM from 'react-dom';
import { withAppContext } from 'src/store/initAppContext';

const LoaderImage = ({ brandId }) => {
    if (brandId == 33 || brandId == 34) {
        return <img src='https://v3-files.raksahb.com/static/media/betloader.gif' alt='BLoader' />
    }

    return <img src='/yoloload247.gif' alt='Loader' />

}


function GlobalLoaderImg(props) {
    const { app: { brandId } } = props;
    return ReactDOM.createPortal(<LoaderImage brandId={brandId} />, document.getElementById('globalLoader'))
}

export default withAppContext(GlobalLoaderImg)