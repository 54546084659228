import React, { useEffect } from 'react'
import { MainInfoWrapper } from 'src/components/udb/commonStyle';
import config from 'src/config/config';
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';
import { theme } from 'styled-tools';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { BannerWrapper, FifaFeverFirstSecHead, StepTittle, TextContent } from './BblBonusInner';
import PlaceholderImage from 'src/components/Placeholder';
import cookieUtil from 'src/util/cookieUtil';
import { ECookieName } from 'src/util/utilModel';
import FooterContainer from "src/components/footer/footer";
import { useNavigate } from "react-router-dom";
import Intl from 'src/components/common/Intl';
import { ContentWrapper } from './aviatorBonusPage';
import { BannerWrapper1 } from './PSLPromo';
import { ItalicTextFull, OffersSection, PlayNowButtonNew, TermsConditionPoints } from './crazyCrashPromo';
import IPLseo from 'src/components/SEOComponents/IPLseo';



const IplDhamaka = (props) => {

    const { app: { brandId }, theme } = props

    const teams = [
        { imageSvg: `${config.imageV3Host}${theme.image.kkr}` },
        { imageSvg: `${config.imageV3Host}${theme.image.kxip}` },
        { imageSvg: `${config.imageV3Host}${theme.image.srh}` },
        { imageSvg: `${config.imageV3Host}${theme.image.dc}` },
        { imageSvg: `${config.imageV3Host}${theme.image.lsg}` },
        { imageSvg: `${config.imageV3Host}${theme.image.rcb}` },
        { imageSvg: `${config.imageV3Host}${theme.image.csk}` },
        { imageSvg: `${config.imageV3Host}${theme.image.mi}` },
        { imageSvg: `${config.imageV3Host}${theme.image.rr}` },
        { imageSvg: `${config.imageV3Host}${theme.image.gt}` },
    ]

    const Language = cookieUtil.get(ECookieName.COOKIE_LANG)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const navigate= useNavigate()

    return (
        <>
        <MainInfoWrapper>
            <BannerWrap>
                <LazyLoadImage src={(Language === 'hindi-ind') ? `${config.imageV3Host}/static/cashsite/${theme.image.IPLInnerLandingBannerEng}` : `${config.imageV3Host}/static/cashsite/${theme.image.IPLInnerLandingBannerEng}`} effect="none" height="100%" width="100%" placeholder={<PlaceholderImage />} />
                <BetnowBTN onClick={()=>navigate('/betting-exchange/online-cricket-satta-bazar')}>Bet Now</BetnowBTN>
            </BannerWrap>
            <ContentWrapper1>
                <TextDiv>
                    <Intl langKey="iplinfo"/> <span> <Intl langKey="infospan"/> </span> <Intl langKey="iplinfo1"/> 
                    <br></br> <Intl langKey="iplinfo2"/>
                </TextDiv>
                <TeamsSection>
                    {teams.map((elm) => (
                        <LazyLoadImage src={elm.imageSvg} effect="none" height="100%" width="100%" placeholder={<PlaceholderImage />} />))}
                </TeamsSection>
                <FifaFeverFirstSecHead className="enterPromo">Two special programs are rolled out</FifaFeverFirstSecHead>
                <ImgSection>
                <> { brandId == 31 || brandId == 32 ?
                       
                        <><LazyLoadImage src={(Language === 'hindi-ind') ? `${config.imageV3Host}/static/cashsite/${theme.image.entryrule1}` : `${config.imageV3Host}/static/cashsite/${theme.image.entryrule1}`} />
                            <LazyLoadImage src={(Language === 'hindi-ind') ? `${config.imageV3Host}/static/cashsite/${theme.image.entryrule2}` : `${config.imageV3Host}/static/cashsite/${theme.image.entryrule2}`} /></> :
                        
                        <><LazyLoadImage src={(Language === 'hindi-ind') ? `${config.imageV3Host}/static/cashsite/${theme.image.entryrule1}` : `${config.imageV3Host}/static/cashsite/${theme.image.entryrule1}`} />
                            <LazyLoadImage src={(Language === 'hindi-ind') ? `${config.imageV3Host}/static/cashsite/${theme.image.entryrule2}` : `${config.imageV3Host}/static/cashsite/${theme.image.entryrule2}`} />
                    <LazyLoadImage src={(Language === 'hindi-ind') ? `${config.imageV3Host}/static/cashsite/${theme.image.entryrule2}` : `${config.imageV3Host}/static/cashsite/${theme.image.entryrule3}`} className='lastImg'/> </>
                   }
                     </> 
                </ImgSection>
                <OffersSection>
                    <ItalicTextFull className='marginZero readMoreOffer'>Read more about the offer below:</ItalicTextFull>
                </OffersSection>
                <ImgSection className='infosec'>
                    <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.iplticket}`} className="ipl1" />
                    <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.iplinfo}`} className="ipl2" />
                </ImgSection>
                <ImgSection>
                    <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.iplinfo1}`} className="ipl2" />
                    <LazyLoadImage src={(Language === 'hindi-ind') ? `${config.imageV3Host}/static/cashsite/${theme.image.iplx2}` : `${config.imageV3Host}/static/cashsite/${theme.image.iplx2}`} className="ipl1" />
                </ImgSection>
                <FifaFeverFirstSecHead className="priceAlloc">Prize Allocation</FifaFeverFirstSecHead>
                <ImgSection className='table'>
                    <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.tabletier}`} />
                </ImgSection>

                <TermsConditionPoints1>
                    <FifaFeverFirstSecHead className='terms'>Terms & Conditions</FifaFeverFirstSecHead>
                    <ul>
                        <li><TextContent><Intl langKey="ipl@GeneralTermsConditionFirst" /></TextContent></li>
                        <li><TextContent><Intl langKey="ipl@GeneralTermsConditionSecond" /> <StepTittle><Intl langKey="ipl@GeneralTermsConditionSecondValidFrom" /></StepTittle> <Intl langKey="ipl@GeneralTermsConditionSecondWinnersWillBe" /> <StepTittle><Intl langKey="crazyCrash@GeneralTermsConditionSecondValidOn" /></StepTittle> </TextContent></li>
                        <li><TextContent><Intl langKey="ipl@GeneralTermsConditionThird" /></TextContent></li>
                        {/* <li><TextContent><Intl langKey="ipl@GeneralTermsConditionThirdext" /></TextContent></li>  */}
                        <li><TextContent><Intl langKey="ipl@GeneralTermsConditionFourth" /></TextContent></li>
                        <li><TextContent><Intl langKey="ipl@GeneralTermsConditionFifth" /></TextContent></li>
                        <li><TextContent><Intl langKey="ipl@GeneralTermsConditionSixth" /></TextContent></li>
                        <li><TextContent><Intl langKey="ipl@GeneralTermsConditionSeventh" /></TextContent></li>
                        <li><TextContent><Intl langKey="ipl@GeneralTermsConditionEighth" /></TextContent></li>
                    </ul>
 
                </TermsConditionPoints1>
                        <BetnowBTN1 onClick={()=>navigate('/betting-exchange/online-cricket-satta-bazar')}>Bet Now</BetnowBTN1>  
            </ContentWrapper1>
        </MainInfoWrapper>
        <IPLseo/>
         <FooterContainer />
              </>

    )
}

export default withTheme(withAppContext(IplDhamaka));

const BannerWrap = styled(BannerWrapper1)`
margin-bottom: -6px;
position: relative;
`

const TermsConditionPoints1 = styled(TermsConditionPoints)`
position: relative;

 ::after{
            content: '';
            background: url(${config.imageV3Host}/static/cashsite/${theme('image.linesright')});
            position: absolute;
            right: -5%;
    top: 43%;
    height: 500px;
    width: 100%;
    background-size: contain;
    background-position: right;
    background-repeat: no-repeat;
                }
`


const BetnowBTN = styled(PlayNowButtonNew)`
background: linear-gradient(183.17deg, #CEA63D 0%, #FDF3A0 50.52%, #CFA840 100%), #EF4123;
border: 2px solid #FCF29E;
box-shadow: 0px 0px 30px rgba(247, 233, 147, 0.5);
border-radius: 38.7857px;
right: 44%;
bottom: 8%;
padding: 18px 50px;
font-size: 25.8571px;
line-height: 31px;
display: flex;
align-items: center;
letter-spacing: 0.01em;
text-transform: uppercase;

color: #000000;
`

export const BetnowBTN1 = styled.button`
margin-top: 4rem;
    background: linear-gradient(183.17deg, #CEA63D 0%, #FDF3A0 50.52%, #CFA840 100%), #EF4123;
border: 2px solid #FCF29E;
box-shadow: 0px 0px 30px rgba(247, 233, 147, 0.5);
border-radius: 38.7857px;
right: 44%;
bottom: 2%;
padding: 18px 50px;
cursor:pointer;
font-size: 25.8571px;
line-height: 31px;
display: flex;
align-items: center;
letter-spacing: 0.01em;
text-transform: uppercase;
color: #000000;
font-weight: 700;
z-index: 9;
&:hover {
        background: #FFFFFF;
        border: 2px solid #FEFD00;
    }
`

const ContentWrapper1 = styled(ContentWrapper)`
background: url(${config.imageV3Host}/static/cashsite/${theme('image.iplBGpromo')});
background-repeat: no-repeat;
background-size: cover;


.infosec{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap:3rem;
    padding-top: 3rem;
}

.ipl1{
      width:20%;

    }
    .ipl2{
        width:55%
    }

    .table{
        padding:2rem 19rem 2rem 19rem;
        position: relative;
        img{
            width: 100%;
        }
        ::before{
            content: '';
            background: url(${config.imageV3Host}/static/cashsite/${theme('image.linesleft')});
            position: absolute;
            left: 0%;
            top:-5%;
            /* height: 1289px; */
            width: 100%;
            background-size: contain;
            background-position: left;
            background-repeat: no-repeat;
                }
    }

    .enterPromo{
        background: url(${config.imageV3Host}/static/cashsite/${theme('image.crazyCrashEnterHead')});
        height: 100px;
        width: 270px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        font-weight: 700;
font-size: 30px;
line-height: 36px;
text-align: center;
text-transform: uppercase;;
        white-space: nowrap;
        color:#D1AD46

        
     }



     .priceAlloc{
        background: url(${config.imageV3Host}/static/cashsite/${theme('image.crazyCrashPrizeHead')});
        height: 100px;
        width: 200px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        font-size: 30px;
line-height: 36px;
text-align: center;
text-transform: uppercase;
white-space: nowrap;
        color:#D1AD46

     }

     
     .terms{
        background: url(${config.imageV3Host}/static/cashsite/${theme('image.crazyCrashTCHead')});
        height: 100px;
        width: 200px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        font-size: 30px;
        display: flex;
        align-items: center;
        text-align: center;
text-transform: uppercase;
white-space: nowrap;
        color:#D1AD46
     }

     .lastImg{
        margin-top: 4rem;
     }
`
const ImgSection = styled.div`
width: 100%;
display: flex;
align-items: center;
        justify-content: space-around;
    flex-direction: row;
    padding: 0rem 10rem 4rem 10rem;
    flex-wrap: wrap;
    img{
        width: 45%;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }
    
`

const TextDiv = styled.div`
font-size: 19px;
text-align: center;
text-transform: capitalize;
padding: 5rem 14rem 2rem 14rem;
color: #FFFFFF;
span{
/* font-family: 'Inter'; */
font-style: normal;
font-weight: 700;
font-size: 19px;
text-align: center;
text-transform: capitalize;
color: #DABA57;
}
`

const TeamsSection = styled.div`
width: 100%;
display: flex;
align-items: center;
justify-content: center;
flex-direction: row;
padding: 2rem 15rem;
img{
    width:100%; 
}
`